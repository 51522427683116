// Generated by purs version 0.15.4
import * as Data_Semigroup from "../Data.Semigroup/index.js";
var append = /* #__PURE__ */ Data_Semigroup.append(Data_Semigroup.semigroupArray);
var Fist1 = /* #__PURE__ */ (function () {
    function Fist1(value0) {
        this.value0 = value0;
    };
    Fist1.create = function (value0) {
        return new Fist1(value0);
    };
    return Fist1;
})();
var Fist2 = /* #__PURE__ */ (function () {
    function Fist2(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Fist2.create = function (value0) {
        return function (value1) {
            return new Fist2(value0, value1);
        };
    };
    return Fist2;
})();
var Fist3 = /* #__PURE__ */ (function () {
    function Fist3(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Fist3.create = function (value0) {
        return function (value1) {
            return new Fist3(value0, value1);
        };
    };
    return Fist3;
})();
var Fist4 = /* #__PURE__ */ (function () {
    function Fist4(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Fist4.create = function (value0) {
        return function (value1) {
            return new Fist4(value0, value1);
        };
    };
    return Fist4;
})();
var Fist5 = /* #__PURE__ */ (function () {
    function Fist5(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Fist5.create = function (value0) {
        return function (value1) {
            return new Fist5(value0, value1);
        };
    };
    return Fist5;
})();
var Fist6 = /* #__PURE__ */ (function () {
    function Fist6(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Fist6.create = function (value0) {
        return function (value1) {
            return new Fist6(value0, value1);
        };
    };
    return Fist6;
})();
var Fist7 = /* #__PURE__ */ (function () {
    function Fist7(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Fist7.create = function (value0) {
        return function (value1) {
            return new Fist7(value0, value1);
        };
    };
    return Fist7;
})();
var Fist8 = /* #__PURE__ */ (function () {
    function Fist8(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Fist8.create = function (value0) {
        return function (value1) {
            return new Fist8(value0, value1);
        };
    };
    return Fist8;
})();
var Fist9 = /* #__PURE__ */ (function () {
    function Fist9(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Fist9.create = function (value0) {
        return function (value1) {
            return new Fist9(value0, value1);
        };
    };
    return Fist9;
})();
var Fist10 = /* #__PURE__ */ (function () {
    function Fist10(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Fist10.create = function (value0) {
        return function (value1) {
            return new Fist10(value0, value1);
        };
    };
    return Fist10;
})();
var toArray = function (dict) {
    return dict.toArray;
};
var ft1Fixed = {
    toArray: function (v) {
        return [ v.value0 ];
    }
};
var toArray1 = /* #__PURE__ */ toArray(ft1Fixed);
var ft2Fixed = {
    toArray: function (v) {
        return append([ v.value0 ])(toArray1(v.value1));
    }
};
var toArray2 = /* #__PURE__ */ toArray(ft2Fixed);
var ft3Fixed = {
    toArray: function (v) {
        return append([ v.value0 ])(toArray2(v.value1));
    }
};
var toArray3 = /* #__PURE__ */ toArray(ft3Fixed);
var ft4Fixed = {
    toArray: function (v) {
        return append([ v.value0 ])(toArray3(v.value1));
    }
};
var toArray4 = /* #__PURE__ */ toArray(ft4Fixed);
var ft5Fixed = {
    toArray: function (v) {
        return append([ v.value0 ])(toArray4(v.value1));
    }
};
var toArray5 = /* #__PURE__ */ toArray(ft5Fixed);
var ft6Fixed = {
    toArray: function (v) {
        return append([ v.value0 ])(toArray5(v.value1));
    }
};
var toArray6 = /* #__PURE__ */ toArray(ft6Fixed);
var ft7Fixed = {
    toArray: function (v) {
        return append([ v.value0 ])(toArray6(v.value1));
    }
};
var toArray7 = /* #__PURE__ */ toArray(ft7Fixed);
var ft8Fixed = {
    toArray: function (v) {
        return append([ v.value0 ])(toArray7(v.value1));
    }
};
var toArray8 = /* #__PURE__ */ toArray(ft8Fixed);
var ft9Fixed = {
    toArray: function (v) {
        return append([ v.value0 ])(toArray8(v.value1));
    }
};
var toArray9 = /* #__PURE__ */ toArray(ft9Fixed);
var ft10Fixed = {
    toArray: function (v) {
        return append([ v.value0 ])(toArray9(v.value1));
    }
};
var fist1 = /* #__PURE__ */ (function () {
    return Fist1.create;
})();
var fist2 = function (a) {
    var $51 = Fist2.create(a);
    return function ($52) {
        return $51(fist1($52));
    };
};
var fist3 = function (a) {
    return function (b) {
        var $53 = Fist3.create(a);
        var $54 = fist2(b);
        return function ($55) {
            return $53($54($55));
        };
    };
};
var fist4 = function (a) {
    return function (b) {
        return function (c) {
            var $56 = Fist4.create(a);
            var $57 = fist3(b)(c);
            return function ($58) {
                return $56($57($58));
            };
        };
    };
};
var fist5 = function (a) {
    return function (b) {
        return function (c) {
            return function (d) {
                var $59 = Fist5.create(a);
                var $60 = fist4(b)(c)(d);
                return function ($61) {
                    return $59($60($61));
                };
            };
        };
    };
};
var fist6 = function (a) {
    return function (b) {
        return function (c) {
            return function (d) {
                return function (e) {
                    var $62 = Fist6.create(a);
                    var $63 = fist5(b)(c)(d)(e);
                    return function ($64) {
                        return $62($63($64));
                    };
                };
            };
        };
    };
};
var fist7 = function (a) {
    return function (b) {
        return function (c) {
            return function (d) {
                return function (e) {
                    return function (f) {
                        var $65 = Fist7.create(a);
                        var $66 = fist6(b)(c)(d)(e)(f);
                        return function ($67) {
                            return $65($66($67));
                        };
                    };
                };
            };
        };
    };
};
var fist8 = function (a) {
    return function (b) {
        return function (c) {
            return function (d) {
                return function (e) {
                    return function (f) {
                        return function (g) {
                            var $68 = Fist8.create(a);
                            var $69 = fist7(b)(c)(d)(e)(f)(g);
                            return function ($70) {
                                return $68($69($70));
                            };
                        };
                    };
                };
            };
        };
    };
};
var fist9 = function (a) {
    return function (b) {
        return function (c) {
            return function (d) {
                return function (e) {
                    return function (f) {
                        return function (g) {
                            return function (h) {
                                var $71 = Fist9.create(a);
                                var $72 = fist8(b)(c)(d)(e)(f)(g)(h);
                                return function ($73) {
                                    return $71($72($73));
                                };
                            };
                        };
                    };
                };
            };
        };
    };
};
var fist10 = function (a) {
    return function (b) {
        return function (c) {
            return function (d) {
                return function (e) {
                    return function (f) {
                        return function (g) {
                            return function (h) {
                                return function (i) {
                                    var $74 = Fist10.create(a);
                                    var $75 = fist9(b)(c)(d)(e)(f)(g)(h)(i);
                                    return function ($76) {
                                        return $74($75($76));
                                    };
                                };
                            };
                        };
                    };
                };
            };
        };
    };
};
export {
    toArray,
    Fist1,
    Fist2,
    Fist3,
    Fist4,
    Fist5,
    Fist6,
    Fist7,
    Fist8,
    Fist9,
    Fist10,
    fist1,
    fist2,
    fist3,
    fist4,
    fist5,
    fist6,
    fist7,
    fist8,
    fist9,
    fist10,
    ft1Fixed,
    ft2Fixed,
    ft3Fixed,
    ft4Fixed,
    ft5Fixed,
    ft6Fixed,
    ft7Fixed,
    ft8Fixed,
    ft9Fixed,
    ft10Fixed
};
