// Generated by purs version 0.15.4
import * as CSS from "../CSS/index.js";
import * as CSS_Background from "../CSS.Background/index.js";
import * as CSS_Common from "../CSS.Common/index.js";
import * as CSS_Property from "../CSS.Property/index.js";
import * as CSS_Render from "../CSS.Render/index.js";
import * as CSS_Selector from "../CSS.Selector/index.js";
import * as CSS_Size from "../CSS.Size/index.js";
import * as CSS_String from "../CSS.String/index.js";
import * as CSS_Stylesheet from "../CSS.Stylesheet/index.js";
import * as CSS_Transition from "../CSS.Transition/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Color_OkLab from "../Data.Color.OkLab/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_String_Common from "../Data.String.Common/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
import * as Halogen_HTML_Properties from "../Halogen.HTML.Properties/index.js";
import * as Toad_Css_Color from "../Toad.Css.Color/index.js";
import * as Toad_Css_Font from "../Toad.Css.Font/index.js";
var fromString = /* #__PURE__ */ CSS_String.fromString(CSS_Property.isStringValue);
var fromString1 = /* #__PURE__ */ CSS_String.fromString(CSS_String.isStringString);
var bind = /* #__PURE__ */ Control_Bind.bind(Control_Bind.bindArray);
var append1 = /* #__PURE__ */ Data_Semigroup.append(Data_Semigroup.semigroupArray);
var append2 = /* #__PURE__ */ Data_Semigroup.append(CSS_Property.semigroupPrefixed);
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit)(CSS_Stylesheet.bindStyleM);
var valList = /* #__PURE__ */ CSS_Property.valList(CSS_Background.valBackgroundImage);
var key = /* #__PURE__ */ CSS_Stylesheet.key(CSS_Property.valValue);
var fromString2 = /* #__PURE__ */ CSS_String.fromString(CSS_Property.isStringKey);
var MaskAlpha = /* #__PURE__ */ (function () {
    function MaskAlpha() {

    };
    MaskAlpha.value = new MaskAlpha();
    return MaskAlpha;
})();
var MaskLuminance = /* #__PURE__ */ (function () {
    function MaskLuminance() {

    };
    MaskLuminance.value = new MaskLuminance();
    return MaskLuminance;
})();
var MaskAdd = /* #__PURE__ */ (function () {
    function MaskAdd() {

    };
    MaskAdd.value = new MaskAdd();
    return MaskAdd;
})();
var MaskSubtract = /* #__PURE__ */ (function () {
    function MaskSubtract() {

    };
    MaskSubtract.value = new MaskSubtract();
    return MaskSubtract;
})();
var MaskIntersect = /* #__PURE__ */ (function () {
    function MaskIntersect() {

    };
    MaskIntersect.value = new MaskIntersect();
    return MaskIntersect;
})();
var MaskExclude = /* #__PURE__ */ (function () {
    function MaskExclude() {

    };
    MaskExclude.value = new MaskExclude();
    return MaskExclude;
})();
var webkitMaskComposite = function ($45) {
    return fromString((function (v) {
        if (v instanceof MaskAdd) {
            return fromString1("source-over");
        };
        if (v instanceof MaskSubtract) {
            return fromString1("source-out");
        };
        if (v instanceof MaskIntersect) {
            return fromString1("source-in");
        };
        if (v instanceof MaskExclude) {
            return fromString1("xor");
        };
        throw new Error("Failed pattern match at Toad.Css (line 98, column 40 - line 102, column 38): " + [ v.constructor.name ]);
    })($45));
};
var valueMaskMode = {
    value: function (v) {
        if (v instanceof MaskAlpha) {
            return fromString("alpha");
        };
        if (v instanceof MaskLuminance) {
            return fromString("luminance");
        };
        throw new Error("Failed pattern match at Toad.Css (line 107, column 11 - line 109, column 48): " + [ v.constructor.name ]);
    }
};
var valueMaskComposite = {
    value: function (v) {
        if (v instanceof MaskAdd) {
            return fromString("add");
        };
        if (v instanceof MaskSubtract) {
            return fromString("subtract");
        };
        if (v instanceof MaskIntersect) {
            return fromString("intersect");
        };
        if (v instanceof MaskExclude) {
            return fromString("exclude");
        };
        throw new Error("Failed pattern match at Toad.Css (line 91, column 11 - line 95, column 44): " + [ v.constructor.name ]);
    }
};
var value = /* #__PURE__ */ CSS_Property.value(valueMaskComposite);
var style = /* #__PURE__ */ (function () {
    var toString = (function () {
        var $46 = Data_String_Common.joinWith("; ");
        var $47 = Data_Functor.map(Data_Functor.functorArray)(function (v) {
            return v.value0 + (": " + v.value1);
        });
        return function ($48) {
            return $46($47($48));
        };
    })();
    var rights = Data_Array.concatMap(Data_Foldable.foldMap(Data_Foldable.foldableEither)(Data_Monoid.monoidArray)(Data_Array.singleton));
    var property = function (v) {
        if (v instanceof CSS_Stylesheet.Property) {
            return new Data_Maybe.Just(new Data_Tuple.Tuple(v.value0, v.value1));
        };
        return Data_Maybe.Nothing.value;
    };
    var rules = function (rs) {
        return bind(Data_Array.mapMaybe(property)(rs))(function ($49) {
            return rights(CSS_Render.collect($49));
        });
    };
    var $50 = Halogen_HTML_Properties.attr("style");
    return function ($51) {
        return $50(toString(rules(CSS_Stylesheet.runS($51))));
    };
})();
var refinements = /* #__PURE__ */ (function () {
    var $52 = Data_Foldable.foldl(Data_Foldable.foldableArray)(function (preds) {
        return function (v) {
            return append1(preds)(v);
        };
    })([  ]);
    return function ($53) {
        return CSS_Selector.Refinement($52($53));
    };
})();
var oklab = Data_Color_OkLab.css;
var mask = function (dictLoc) {
    var Val0 = dictLoc.Val0();
    return function (images) {
        return function (composite) {
            return function (mode) {
                return function (loc) {
                    return function (size) {
                        var key1 = function (dictVal) {
                            var prefixed = CSS_Stylesheet.prefixed(dictVal);
                            return function (k) {
                                return prefixed(append2(CSS_Common.browsers)(new CSS_Property.Plain(k)));
                            };
                        };
                        var key2 = key1(valueMaskMode);
                        var key3 = key1(Val0);
                        var key4 = key1(CSS_Size.valSize);
                        var key5 = key1(CSS_Property.valString);
                        return discard(key1(valList)("mask-image")(images))(function () {
                            return discard(key2("mask-mode")(mode))(function () {
                                return discard(key3("mask-position")(loc))(function () {
                                    return discard(key4("mask-size")(size))(function () {
                                        return discard(key5("mask-repeat")("no-repeat"))(function () {
                                            return discard(key(fromString2("mask-composite"))(value(composite)))(function () {
                                                return key(fromString2("-webkit-mask-composite"))(webkitMaskComposite(composite));
                                            });
                                        });
                                    });
                                });
                            });
                        });
                    };
                };
            };
        };
    };
};
var kwapEasing = /* #__PURE__ */ CSS_Transition.cubicBezier(0.25)(1.0)(0.5)(1.0);
var definedIn = /* #__PURE__ */ (function () {
    return CSS_Stylesheet.key(CSS_Property.valString)(CSS_Property.Key(CSS_Property.Plain.create("--defined-in")));
})();
var anySize = /* #__PURE__ */ (function () {
    var $54 = CSS_Property.value(CSS_Property.valString);
    return function ($55) {
        return CSS_Size.BasicSize.create($54(CSS_Size.sizeToString($55)));
    };
})();
export {
    MaskAdd,
    MaskSubtract,
    MaskIntersect,
    MaskExclude,
    MaskAlpha,
    MaskLuminance,
    oklab,
    anySize,
    definedIn,
    mask,
    style,
    refinements,
    kwapEasing,
    valueMaskComposite,
    valueMaskMode
};
export {
    AlignContentValue,
    AlignItemsValue,
    AlignSelfValue,
    Angle,
    AnimationDirection,
    AnimationName,
    Child,
    Pop,
    Root,
    Self,
    Sub,
    ClearFloatBoth,
    ClearFloatInherit,
    ClearFloatInlineEnd,
    ClearFloatInlineStart,
    ClearFloatLeft,
    ClearFloatNone,
    ClearFloatRight,
    HSL,
    LCh,
    Lab,
    RGB,
    Display,
    Feature,
    FillMode,
    FlexDirection,
    FlexWrap,
    FloatLeft,
    FloatNone,
    FloatRight,
    EmbeddedOpenType,
    OpenType,
    SVG,
    TrueType,
    WOFF,
    WOFF2,
    FontFaceSrcLocal,
    FontFaceSrcUrl,
    GenericFontFamily,
    Inline,
    IterationCount,
    JustifyContentValue,
    Key,
    Keyframes,
    Literal,
    MediaQuery,
    MediaType,
    Not,
    Only,
    Adjacent,
    Combined,
    Deep,
    Elem,
    PathChild,
    Star,
    Position,
    Attr,
    AttrBegins,
    AttrContains,
    AttrEnds,
    AttrHyph,
    AttrSpace,
    AttrVal,
    Class,
    Id,
    Pseudo,
    PseudoFunc,
    Plain,
    Prefixed,
    Refinement,
    Face,
    Import,
    Keyframe,
    Nested,
    Property,
    Query,
    Selector,
    Sheet,
    BasicSize,
    DiffSize,
    DivSize,
    MultSize,
    SumSize,
    Stroke,
    S,
    TextDecoration,
    Ltr,
    Rtl,
    Time,
    CubicBezier,
    Ease,
    EaseIn,
    EaseInOut,
    EaseOut,
    Linear,
    StepEnd,
    StepStart,
    Steps,
    Inherit,
    Initial,
    TransformOrigin,
    Unset,
    OffsetBottom,
    OffsetCenter,
    OffsetLeft,
    OffsetLength,
    OffsetRight,
    OffsetTop,
    Transformation,
    Value,
    a,
    abbr,
    absolute,
    address,
    alignContent,
    alignItems,
    alignSelf,
    alternate,
    alternateReverse,
    angular,
    animation,
    area,
    article,
    aside,
    attachFixed,
    attachScroll,
    attr,
    audio,
    b,
    background,
    backgroundAttachment,
    backgroundAttachments,
    backgroundClip,
    backgroundClips,
    backgroundColor,
    backgroundImage,
    backgroundImages,
    backgroundOrigin,
    backgroundOrigins,
    backgroundPosition,
    backgroundPositions,
    backgroundRepeat,
    backgroundRepeats,
    backgroundSize,
    backgroundSizes,
    backwards,
    bdi,
    bdo,
    black,
    blink,
    block,
    blockquote,
    body,
    bold,
    bolder,
    border,
    borderBottom,
    borderBox,
    borderColor,
    borderLeft,
    borderRadius,
    borderRight,
    borderSpacing,
    borderTop,
    bottom,
    boxClip,
    boxShadow,
    boxSizing,
    br,
    brightness,
    button,
    by,
    byClass,
    byId,
    canvas,
    caption,
    cast,
    circle,
    circular,
    cite,
    clear,
    clearBoth,
    clearInlineEnd,
    clearInlineStart,
    clearLeft,
    clearRight,
    closestCorner,
    closestSide,
    code,
    col,
    colgroup,
    collapse,
    collect,
    collect$prime,
    color,
    column,
    columnReverse,
    complementary,
    contain,
    contentBox,
    contrast,
    cover,
    cssStringHSLA,
    cssStringRGBA,
    cubicBezier,
    cursor,
    darken,
    dashed,
    datalist,
    dd,
    deg,
    del,
    desaturate,
    details,
    dfn,
    direction,
    display,
    displayInherit,
    displayNone,
    displayTable,
    distance,
    div,
    dl,
    dotted,
    double,
    dt,
    ease,
    easeIn,
    easeInOut,
    easeOut,
    element,
    ellipse,
    elliptical,
    em,
    embed,
    ex,
    face,
    farthestCorner,
    farthestSide,
    feature,
    fieldset,
    figcaption,
    figure,
    fixed,
    flex,
    flexBasis,
    flexDirection,
    flexEnd,
    flexFlow,
    flexGrow,
    flexShrink,
    flexStart,
    flexWrap,
    float,
    floatLeft,
    floatRight,
    fontFace,
    fontFaceFamily,
    fontFaceSrc,
    fontStyle,
    footer,
    form,
    formatName,
    forwards,
    frame,
    fromHexString,
    fromInt,
    fromString,
    func,
    getInline,
    getSheet,
    graytone,
    grid,
    groove,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    hGradient,
    hRepeatingGradient,
    header,
    height,
    hover,
    hr,
    hsl,
    hsla,
    html,
    i,
    iframe,
    img,
    imp,
    importUrl,
    infinite,
    inline,
    inlineBlock,
    inlineFlex,
    inlineGrid,
    inlineTable,
    input,
    ins,
    inset,
    isLight,
    isReadable,
    iterationCount,
    justifyContent,
    kbd,
    key,
    keyframes,
    keyframesFromTo,
    kframe,
    lab,
    label,
    lch,
    left,
    legend,
    letterSpacing,
    li,
    lighten,
    lighter,
    lineHeight,
    lineThrough,
    linear,
    linearGradient,
    listItem,
    location,
    luminance,
    main,
    map,
    margin,
    marginBottom,
    marginLeft,
    marginRight,
    marginTop,
    mark,
    maxHeight,
    maxWidth,
    mediaQuery,
    mediaType,
    merger,
    meter,
    minHeight,
    minWidth,
    mix,
    ms,
    nav,
    nel,
    nil,
    noCommas,
    noRepeat,
    noneTextDecoration,
    normalAnimationDirection,
    nowrap,
    object,
    offset,
    offsetBottom,
    offsetCenter,
    offsetLeft,
    offsetRight,
    offsetTop,
    ol,
    opacity,
    optgroup,
    order,
    origin,
    outline,
    outlineColor,
    outlineOffset,
    outlineStyle,
    outlineWidth,
    output,
    outset,
    overline,
    p,
    padding,
    paddingBottom,
    paddingBox,
    paddingLeft,
    paddingRight,
    paddingTop,
    pct,
    placed,
    plain,
    position,
    positioned,
    pre,
    predicate,
    prefixed,
    progress,
    properties,
    pseudo,
    pt,
    putInline,
    putStyleSheet,
    px,
    q,
    query,
    query$prime,
    quote,
    rad,
    radialGradient,
    relative,
    rem,
    render,
    renderedInline,
    renderedSheet,
    repeat,
    repeatX,
    repeatY,
    repeatingLinearGradient,
    repeatingRadialGradient,
    reverse,
    rgb,
    rgb$prime,
    rgba,
    rgba$prime,
    ridge,
    right,
    rotate,
    rotateHue,
    round,
    row,
    rowReverse,
    rule,
    rule$prime,
    rules,
    runIn,
    runS,
    s,
    samp,
    sansSerif,
    saturate,
    sec,
    section,
    select,
    selector,
    selector$prime,
    selector$prime$prime,
    sepWith,
    sideBottom,
    sideCenter,
    sideLeft,
    sideMiddle,
    sideRight,
    sideTop,
    small,
    solid,
    space,
    spaceAround,
    spaceBetween,
    span,
    star,
    static,
    stepEnd,
    stepStart,
    steps,
    straight,
    stretch,
    strong,
    sub,
    summary,
    sup,
    sym,
    table,
    tableCaption,
    tableCell,
    tableColumn,
    tableColumnGroup,
    tableFooterGroup,
    tableHeaderGroup,
    tableRow,
    tableRowGroup,
    tbody,
    td,
    textColor,
    textDecoration,
    textOverflow,
    textTransform,
    textWhitespace,
    textarea,
    tfoot,
    th,
    thead,
    toGray,
    toHSLA,
    toHexString,
    toLCh,
    toLab,
    toRGBA,
    toRGBA$prime,
    toXYZ,
    top,
    tr,
    transform,
    transformOrigin,
    transforms,
    transition,
    transitionDelay,
    transitionDelays,
    transitionDuration,
    transitionDurations,
    transitionProperties,
    transitionProperty,
    transitionTimingFunction,
    transitionTimingFunctions,
    translate,
    u,
    ul,
    underline,
    url,
    vGradient,
    vRepeatingGradient,
    value,
    var,
    vh,
    visibility,
    vmax,
    vmin,
    vw,
    wavy,
    weight,
    white,
    whitespaceNoWrap,
    whitespaceNormal,
    whitespacePre,
    whitespacePreLine,
    whitespacePreWrap,
    width,
    wrap,
    wrapReverse,
    xyRepeat,
    xyz,
    zIndex
} from "../CSS/index.js";
export {
    colorBg,
    colorBg2,
    colorFg,
    colorPrimary,
    colorPrimary2,
    colorPrimary3,
    colorPrimary3_5,
    colorPrimary4,
    colorPrimary5,
    green,
    grey
} from "../Toad.Css.Color/index.js";
export {
    Font,
    AtkinsonBold,
    AtkinsonMedium,
    FontFamilyDefault,
    LibreBaskervilleBold,
    WorkSansBold,
    WorkSansSemibold,
    FontSizeBody,
    FontSizeDefault,
    FontSizeH1,
    FontSizeH2,
    FontSizeH3,
    FontSizeH4,
    FontSizeH5,
    FontSizeH6,
    FontSizeHuge,
    FontSizeSmall,
    font,
    fontFamily,
    fontSize,
    fontSizePt
} from "../Toad.Css.Font/index.js";
