// Generated by purs version 0.15.4
import * as CSS_Background from "../CSS.Background/index.js";
import * as CSS_Border from "../CSS.Border/index.js";
import * as CSS_Cursor from "../CSS.Cursor/index.js";
import * as CSS_Geometry from "../CSS.Geometry/index.js";
import * as CSS_Selector from "../CSS.Selector/index.js";
import * as CSS_Size from "../CSS.Size/index.js";
import * as CSS_Stylesheet from "../CSS.Stylesheet/index.js";
import * as CSS_Time from "../CSS.Time/index.js";
import * as CSS_Transition from "../CSS.Transition/index.js";
import * as Color from "../Color/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Toad_Css from "../Toad.Css/index.js";
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit)(CSS_Stylesheet.bindStyleM);
var Theme = /* #__PURE__ */ (function () {
    function Theme(value0) {
        this.value0 = value0;
    };
    Theme.create = function (value0) {
        return new Theme(value0);
    };
    return Theme;
})();
var primary = function (color) {
    return new Theme({
        color: color,
        inert: 0.96,
        active: 0.75,
        hover: 0.9
    });
};
var className = "button";
var activeClassName = "button-active";
var button = function (v) {
    return discard(CSS_Stylesheet.select(CSS_Selector["with"](CSS_Selector.element("button"))(CSS_Selector.byClass(className)))(discard(CSS_Geometry.height(CSS_Size.pct(100.0)))(function () {
        return discard(CSS_Geometry.width(CSS_Size.pct(100.0)))(function () {
            return discard(CSS_Border.border(CSS_Border.solid)(CSS_Size.px(0.0))(Color.black))(function () {
                return discard(CSS_Size.sym(CSS_Geometry.padding)(CSS_Size.px(0.0)))(function () {
                    return discard(CSS_Cursor.cursor(CSS_Cursor.pointer))(function () {
                        return discard(CSS_Background.backgroundColor(Toad_Css.oklab(v.value0.color(v.value0.inert))))(function () {
                            return CSS_Transition.transition("background-color")(CSS_Time.ms(75.0))(Toad_Css.kwapEasing)(CSS_Time.ms(0.0));
                        });
                    });
                });
            });
        });
    })))(function () {
        return discard(CSS_Stylesheet.select(CSS_Selector["with"](CSS_Selector["with"](CSS_Selector.element("button"))(CSS_Selector.byClass(className)))(CSS_Selector.pseudo("hover")))(CSS_Background.backgroundColor(Toad_Css.oklab(v.value0.color(v.value0.hover)))))(function () {
            return discard(CSS_Stylesheet.select(CSS_Selector["with"](CSS_Selector["with"](CSS_Selector.element("button"))(CSS_Selector.byClass(className)))(CSS_Selector.pseudo("active")))(CSS_Background.backgroundColor(Toad_Css.oklab(v.value0.color(v.value0.active)))))(function () {
                return CSS_Stylesheet.select(CSS_Selector["with"](CSS_Selector.element("button"))(CSS_Selector.byClass(activeClassName)))(CSS_Background.backgroundColor(Toad_Css.oklab(v.value0.color(v.value0.active))));
            });
        });
    });
};
export {
    button,
    className,
    activeClassName,
    Theme,
    primary
};
