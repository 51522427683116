// Generated by purs version 0.15.4
import * as CSS_Geometry from "../CSS.Geometry/index.js";
import * as CSS_Size from "../CSS.Size/index.js";
import * as CSS_Stylesheet from "../CSS.Stylesheet/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Halogen_HTML from "../Halogen.HTML/index.js";
import * as Halogen_HTML_CSS from "../Halogen.HTML.CSS/index.js";
import * as Halogen_HTML_Core from "../Halogen.HTML.Core/index.js";
import * as Halogen_HTML_Elements from "../Halogen.HTML.Elements/index.js";
import * as Halogen_HTML_Properties from "../Halogen.HTML.Properties/index.js";
import * as Toad_Css_Font from "../Toad.Css.Font/index.js";
import * as Toad_Route from "../Toad.Route/index.js";
import * as Web_HTML_Common from "../Web.HTML.Common/index.js";
var mempty = /* #__PURE__ */ Data_Monoid.mempty(Toad_Css_Font.fontMonoid);
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit)(CSS_Stylesheet.bindStyleM);
var append = /* #__PURE__ */ Data_Semigroup.append(Toad_Css_Font.fontSemigroup);
var append2 = /* #__PURE__ */ Data_Semigroup.append(Data_Semigroup.semigroupArray);
var withText = function (ctor) {
    return function ($12) {
        return ctor(Data_Array.singleton(Halogen_HTML_Core.text($12)));
    };
};
var span_ = /* #__PURE__ */ Halogen_HTML_Elements.span([ /* #__PURE__ */ Halogen_HTML_CSS.style(/* #__PURE__ */ Toad_Css_Font.font(mempty)) ]);
var p_ = /* #__PURE__ */ Halogen_HTML_Elements.p([ /* #__PURE__ */ Halogen_HTML_CSS.style(/* #__PURE__ */ discard(/* #__PURE__ */ Toad_Css_Font.font(mempty))(function () {
    return CSS_Geometry.margin(CSS_Size.nil)(CSS_Size.nil)(CSS_Size.nil)(CSS_Size.nil);
})) ]);
var headingStyle = function (font$prime) {
    return discard(Toad_Css_Font.font(font$prime))(function () {
        return discard(CSS_Geometry.lineHeight(Toad_Css_Font.fontSizePt(font$prime.value1)))(function () {
            return CSS_Geometry.margin(CSS_Size.nil)(CSS_Size.nil)(CSS_Size.nil)(CSS_Size.nil);
        });
    });
};
var h6Font = /* #__PURE__ */ (function () {
    return append(Toad_Css_Font.fontSize(Toad_Css_Font.FontSizeH6.value))(Toad_Css_Font.fontFamily(Toad_Css_Font.WorkSansSemibold.value));
})();
var h6_ = /* #__PURE__ */ Halogen_HTML_Elements.h6([ /* #__PURE__ */ Halogen_HTML_CSS.style(/* #__PURE__ */ headingStyle(h6Font)) ]);
var h5Font = /* #__PURE__ */ (function () {
    return append(Toad_Css_Font.fontSize(Toad_Css_Font.FontSizeH5.value))(Toad_Css_Font.fontFamily(Toad_Css_Font.WorkSansSemibold.value));
})();
var h5_ = /* #__PURE__ */ Halogen_HTML_Elements.h5([ /* #__PURE__ */ Halogen_HTML_CSS.style(/* #__PURE__ */ headingStyle(h5Font)) ]);
var h4Font = /* #__PURE__ */ (function () {
    return append(Toad_Css_Font.fontSize(Toad_Css_Font.FontSizeH4.value))(Toad_Css_Font.fontFamily(Toad_Css_Font.WorkSansSemibold.value));
})();
var h4_ = /* #__PURE__ */ Halogen_HTML_Elements.h4([ /* #__PURE__ */ Halogen_HTML_CSS.style(/* #__PURE__ */ headingStyle(h4Font)) ]);
var h3Font = /* #__PURE__ */ (function () {
    return append(Toad_Css_Font.fontSize(Toad_Css_Font.FontSizeH3.value))(Toad_Css_Font.fontFamily(Toad_Css_Font.WorkSansSemibold.value));
})();
var h3_ = /* #__PURE__ */ Halogen_HTML_Elements.h3([ /* #__PURE__ */ Halogen_HTML_CSS.style(/* #__PURE__ */ headingStyle(h3Font)) ]);
var h2Font = /* #__PURE__ */ (function () {
    return append(Toad_Css_Font.fontSize(Toad_Css_Font.FontSizeH2.value))(Toad_Css_Font.fontFamily(Toad_Css_Font.WorkSansBold.value));
})();
var h2_ = /* #__PURE__ */ Halogen_HTML_Elements.h2([ /* #__PURE__ */ Halogen_HTML_CSS.style(/* #__PURE__ */ headingStyle(h2Font)) ]);
var h1Font = /* #__PURE__ */ (function () {
    return append(Toad_Css_Font.fontSize(Toad_Css_Font.FontSizeH1.value))(Toad_Css_Font.fontFamily(Toad_Css_Font.WorkSansBold.value));
})();
var h1_ = /* #__PURE__ */ Halogen_HTML_Elements.h1([ /* #__PURE__ */ Halogen_HTML_CSS.style(/* #__PURE__ */ headingStyle(h1Font)) ]);
var classNames = /* #__PURE__ */ (function () {
    var $13 = Data_Functor.map(Data_Functor.functorArray)(Web_HTML_Common.ClassName);
    return function ($14) {
        return Halogen_HTML_Properties.classes($13($14));
    };
})();
var a_ = function (r) {
    return function (t) {
        return Halogen_HTML_Elements.a([ Halogen_HTML_Properties.href("#" + Toad_Route.print(r)) ])(t);
    };
};
var a$prime = Halogen_HTML_Elements.a;
var a = function (ps) {
    return function (r) {
        return function (t) {
            return Halogen_HTML_Elements.a(append2(ps)([ Halogen_HTML_Properties.href("#" + Toad_Route.print(r)) ]))(t);
        };
    };
};
export {
    headingStyle,
    withText,
    classNames,
    a$prime,
    a,
    a_,
    h1_,
    h2_,
    h3_,
    h4_,
    h5_,
    h6_,
    h1Font,
    h2Font,
    h3Font,
    h4Font,
    h5Font,
    h6Font,
    p_,
    span_
};
export {
    AttrName,
    ClassName,
    ElemName,
    HTML,
    Namespace,
    PropName,
    abbr,
    abbr_,
    address,
    address_,
    area,
    article,
    article_,
    aside,
    aside_,
    attr,
    attrNS,
    audio,
    audio_,
    b,
    b_,
    base,
    bdi,
    bdi_,
    bdo,
    bdo_,
    blockquote,
    blockquote_,
    body,
    body_,
    br,
    br_,
    button,
    button_,
    canvas,
    caption,
    caption_,
    cite,
    cite_,
    code,
    code_,
    col,
    colgroup,
    colgroup_,
    command,
    datalist,
    datalist_,
    dd,
    dd_,
    del,
    del_,
    details,
    details_,
    dfn,
    dfn_,
    dialog,
    dialog_,
    div,
    div_,
    dl,
    dl_,
    dt,
    dt_,
    element,
    elementNS,
    em,
    em_,
    embed,
    embed_,
    fieldset,
    fieldset_,
    figcaption,
    figcaption_,
    figure,
    figure_,
    footer,
    footer_,
    form,
    form_,
    fromPlainHTML,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    handler,
    head,
    head_,
    header,
    header_,
    hr,
    hr_,
    html,
    html_,
    i,
    i_,
    iframe,
    img,
    input,
    ins,
    ins_,
    kbd,
    kbd_,
    keyed,
    keyedNS,
    label,
    label_,
    lazy,
    lazy2,
    lazy3,
    legend,
    legend_,
    li,
    li_,
    link,
    main,
    main_,
    map_,
    mark,
    mark_,
    memoized,
    menu,
    menu_,
    menuitem,
    menuitem_,
    meta,
    meter,
    meter_,
    nav,
    nav_,
    noscript,
    noscript_,
    object,
    object_,
    ol,
    ol_,
    optgroup,
    optgroup_,
    option,
    option_,
    output,
    output_,
    p,
    param,
    pre,
    pre_,
    progress,
    progress_,
    prop,
    q,
    q_,
    rp,
    rp_,
    rt,
    rt_,
    ruby,
    ruby_,
    samp,
    samp_,
    script,
    script_,
    section,
    section_,
    select,
    select_,
    slot,
    slot_,
    small,
    small_,
    source,
    span,
    strong,
    strong_,
    style,
    style_,
    sub,
    sub_,
    summary,
    summary_,
    sup,
    sup_,
    table,
    table_,
    tbody,
    tbody_,
    td,
    td_,
    text,
    textarea,
    tfoot,
    tfoot_,
    th,
    th_,
    thead,
    thead_,
    time,
    time_,
    title,
    title_,
    tr,
    tr_,
    track,
    u,
    u_,
    ul,
    ul_,
    var,
    var_,
    video,
    video_,
    wbr,
    withKeys,
    withKeys_
} from "../Halogen.HTML/index.js";
