// Generated by purs version 0.15.4
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Semiring from "../Data.Semiring/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
var append = /* #__PURE__ */ Data_Semigroup.append(Data_Semigroup.semigroupArray);
var fromJust = /* #__PURE__ */ Data_Maybe.fromJust();
var add = /* #__PURE__ */ Data_Semiring.add(Data_Semiring.semiringInt);
var map = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var foldl = /* #__PURE__ */ Data_Foldable.foldl(Data_Foldable.foldableArray);
var foldr = /* #__PURE__ */ Data_Foldable.foldr(Data_Foldable.foldableArray);
var foldMap = /* #__PURE__ */ Data_Foldable.foldMap(Data_Foldable.foldableArray);
var map1 = /* #__PURE__ */ Data_Functor.map(Data_Maybe.functorMaybe);
var findMap = /* #__PURE__ */ Data_Foldable.findMap(Data_Foldable.foldableArray);
var ArrayMve = /* #__PURE__ */ (function () {
    function ArrayMve(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    ArrayMve.create = function (value0) {
        return function (value1) {
            return new ArrayMve(value0, value1);
        };
    };
    return ArrayMve;
})();
var unsafeModifyArray = function (f) {
    return function (v) {
        return new ArrayMve(f(v.value0), v.value1);
    };
};
var unsafeFromIndexArray = /* #__PURE__ */ (function () {
    return Data_Function.flip(ArrayMve.create);
})();
var unsafeFromArrayIndex = /* #__PURE__ */ (function () {
    return ArrayMve.create;
})();
var toArray = function (v) {
    return v.value0;
};
var singleton = function (a) {
    return new ArrayMve([ a ], 0);
};
var semigroupMve = {
    append: function (a) {
        return function (b) {
            return unsafeModifyArray(function (v) {
                return append(v)(toArray(b));
            })(a);
        };
    }
};
var mve = function (v) {
    return fromJust(Data_Array.index(v.value0)(v.value1));
};
var parts = function (v) {
    return new Data_Tuple.Tuple(Data_Array.slice(0)(v.value1)(v.value0), new Data_Tuple.Tuple(mve(v), (Data_Array.splitAt(v.value1 + 1 | 0)(v.value0)).after));
};
var functorMve = {
    map: function (f) {
        return function (v) {
            return unsafeFromIndexArray(v.value1)(map(f)(v.value0));
        };
    }
};
var foldParts = function (f) {
    return function ($48) {
        return (function (v) {
            return f(v.value0)(v.value1.value0)(v.value1.value1);
        })(parts($48));
    };
};
var foldMve = {
    foldl: function (f) {
        return function (b) {
            var $49 = foldl(f)(b);
            return function ($50) {
                return $49(toArray($50));
            };
        };
    },
    foldr: function (f) {
        return function (b) {
            var $51 = foldr(f)(b);
            return function ($52) {
                return $51(toArray($52));
            };
        };
    },
    foldMap: function (dictMonoid) {
        var foldMap1 = foldMap(dictMonoid);
        return function (f) {
            var $53 = foldMap1(f);
            return function ($54) {
                return $53(toArray($54));
            };
        };
    }
};
var enumerate = /* #__PURE__ */ foldl(function (b) {
    return function (a$prime) {
        return append(b)([ new Data_Tuple.Tuple(a$prime, Data_Maybe.fromMaybe(0)(map1((function () {
            var $55 = add(1);
            return function ($56) {
                return $55(Data_Tuple.snd($56));
            };
        })())(Data_Array.last(b)))) ]);
    };
})(/* #__PURE__ */ Data_Monoid.mempty(Data_Monoid.monoidArray));
var fromArray = function (f) {
    return function (a) {
        return map1(unsafeFromArrayIndex(a))(findMap(function (v) {
            var $45 = f(v.value0);
            if ($45) {
                return new Data_Maybe.Just(v.value1);
            };
            return Data_Maybe.Nothing.value;
        })(enumerate(a)));
    };
};
var changeMve = function (f) {
    var $57 = fromArray(f);
    return function ($58) {
        return $57(toArray($58));
    };
};
export {
    toArray,
    fromArray,
    changeMve,
    mve,
    parts,
    foldParts,
    singleton,
    functorMve,
    foldMve,
    semigroupMve
};
