// Generated by purs version 0.15.4
import * as CSS_Display from "../CSS.Display/index.js";
import * as CSS_Property from "../CSS.Property/index.js";
import * as CSS_Size from "../CSS.Size/index.js";
import * as CSS_String from "../CSS.String/index.js";
import * as CSS_Stylesheet from "../CSS.Stylesheet/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Fist from "../Data.Fist/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
var key = /* #__PURE__ */ CSS_Stylesheet.key(CSS_Property.valString);
var fromString = /* #__PURE__ */ CSS_String.fromString(CSS_Property.isStringKey);
var noCommas = /* #__PURE__ */ CSS_Property.noCommas(CSS_Property.valValue);
var value = /* #__PURE__ */ CSS_Property.value(CSS_Property.valString);
var intercalate = /* #__PURE__ */ Data_Foldable.intercalate(Data_Foldable.foldableArray)(Data_Monoid.monoidString);
var map = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var noCommas1 = /* #__PURE__ */ CSS_Property.noCommas(CSS_Size.valSize);
var key1 = /* #__PURE__ */ CSS_Stylesheet.key(CSS_Property.valValue);
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit)(CSS_Stylesheet.bindStyleM);
var GridRow = /* #__PURE__ */ (function () {
    function GridRow(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    GridRow.create = function (value0) {
        return function (value1) {
            return new GridRow(value0, value1);
        };
    };
    return GridRow;
})();
var GridCol = function (x) {
    return x;
};
var rowSize = function (dictFist) {
    return function (v) {
        return v.value0;
    };
};
var rowAreas = function (dictFist) {
    return function (v) {
        return v.value1;
    };
};
var gridRowStart = function (f) {
    var $35 = key(fromString("grid-row-start"));
    return function ($36) {
        return $35(f($36));
    };
};
var gridArea = function (f) {
    var $37 = key(fromString("grid-area"));
    return function ($38) {
        return $37(f($38));
    };
};
var columnSize = function (v) {
    return v;
};
var grid = function (dictFist) {
    var toArray = Data_Fist.toArray(dictFist);
    var rowAreas1 = rowAreas(dictFist);
    var rowSize1 = rowSize(dictFist);
    return function (dictFunctor) {
        var map1 = Data_Functor.map(dictFunctor);
        return function (dictFoldable) {
            var fromFoldable = Data_Array.fromFoldable(dictFoldable);
            return function (columns) {
                return function (rows) {
                    return function (label) {
                        var templateLabels = noCommas(fromFoldable(map1((function () {
                            var $39 = intercalate(" ");
                            var $40 = map(label);
                            return function ($41) {
                                return value((function (v) {
                                    return "\"" + v;
                                })((function (v) {
                                    return v + "\"";
                                })($39($40(toArray(rowAreas1($41)))))));
                            };
                        })())(rows)));
                        var rowSizes = noCommas1(fromFoldable(map1(rowSize1)(rows)));
                        var key2 = function ($42) {
                            return key1(fromString($42));
                        };
                        var columnSizes = noCommas1(map(columnSize)(toArray(columns)));
                        return discard(CSS_Display.display(CSS_Display.grid))(function () {
                            return discard(key2("grid-template-areas")(templateLabels))(function () {
                                return discard(key2("grid-template-rows")(rowSizes))(function () {
                                    return key2("grid-template-columns")(columnSizes);
                                });
                            });
                        });
                    };
                };
            };
        };
    };
};
export {
    GridCol,
    GridRow,
    grid,
    gridRowStart,
    gridArea
};
