// Generated by purs version 0.15.4
import * as CSS_Background from "../CSS.Background/index.js";
import * as CSS_Display from "../CSS.Display/index.js";
import * as CSS_Geometry from "../CSS.Geometry/index.js";
import * as CSS_Overflow from "../CSS.Overflow/index.js";
import * as CSS_Size from "../CSS.Size/index.js";
import * as CSS_Stylesheet from "../CSS.Stylesheet/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Toad_Css from "../Toad.Css/index.js";
import * as Toad_Css_Color from "../Toad.Css.Color/index.js";
import * as Toad_Css_Grid from "../Toad.Css.Grid/index.js";
import * as Toad_Layout from "../Toad.Layout/index.js";
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit)(CSS_Stylesheet.bindStyleM);
var navbarWrap = /* #__PURE__ */ discard(Toad_Css_Grid.inAppNavbar)(function () {
    return CSS_Overflow.overflow(CSS_Overflow.overflowAuto);
});
var appWrap = /* #__PURE__ */ discard(/* #__PURE__ */ CSS_Display.position(CSS_Display.fixed))(function () {
    return discard(CSS_Geometry.width(CSS_Size.pct(100.0)))(function () {
        return discard(CSS_Geometry.height(CSS_Size.pct(100.0)))(function () {
            return discard(CSS_Geometry.top(CSS_Size.px(0.0)))(function () {
                return discard(CSS_Geometry.left(CSS_Size.px(0.0)))(function () {
                    return discard(Toad_Css_Grid.appGrid(Toad_Layout.AppLayoutDesktop.value))(function () {
                        return CSS_Background.backgroundColor(Toad_Css.oklab(Toad_Css_Color.colorBg(Toad_Css_Color.grey)));
                    });
                });
            });
        });
    });
});
export {
    appWrap,
    navbarWrap
};
