// Generated by purs version 0.15.4
import * as CSS_Common from "../CSS.Common/index.js";
import * as CSS_Property from "../CSS.Property/index.js";
import * as CSS_Size from "../CSS.Size/index.js";
import * as CSS_String from "../CSS.String/index.js";
import * as CSS_Stylesheet from "../CSS.Stylesheet/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
var valTuple = /* #__PURE__ */ CSS_Property.valTuple(CSS_Size.valSize);
var valTuple1 = /* #__PURE__ */ valTuple(CSS_Size.valSize);
var valTuple2 = /* #__PURE__ */ valTuple(valTuple1);
var value = /* #__PURE__ */ CSS_Property.value(/* #__PURE__ */ valTuple(valTuple2));
var value1 = /* #__PURE__ */ CSS_Property.value(valTuple2);
var value2 = /* #__PURE__ */ CSS_Property.value(valTuple1);
var fromString = /* #__PURE__ */ CSS_String.fromString(CSS_Property.isStringValue);
var eq = /* #__PURE__ */ Data_Eq.eq(CSS_Property.eqValue);
var compare = /* #__PURE__ */ Data_Ord.compare(CSS_Property.ordValue);
var value3 = /* #__PURE__ */ CSS_Property.value(/* #__PURE__ */ CSS_Property.valTuple(CSS_Property.valString)(CSS_Property.valValue));
var value4 = /* #__PURE__ */ CSS_Property.value(/* #__PURE__ */ CSS_Property.valTuple(CSS_Property.valValue)(CSS_Property.valColor));
var BoxType = function (x) {
    return x;
};
var BoxShadow = function (x) {
    return x;
};
var valBoxType = {
    value: function (v) {
        return v;
    }
};
var valBoxShadow = {
    value: function (v) {
        return v;
    }
};
var shadowWithSpread = function (x) {
    return function (y) {
        return function (blurRadius) {
            return function (spreadRadius) {
                return BoxShadow(value(new Data_Tuple.Tuple(x, new Data_Tuple.Tuple(y, new Data_Tuple.Tuple(blurRadius, spreadRadius)))));
            };
        };
    };
};
var shadowWithBlur = function (x) {
    return function (y) {
        return function (w) {
            return BoxShadow(value1(new Data_Tuple.Tuple(x, new Data_Tuple.Tuple(y, w))));
        };
    };
};
var shadow = function (x) {
    return function (y) {
        return BoxShadow(value2(new Data_Tuple.Tuple(x, y)));
    };
};
var paddingBox = /* #__PURE__ */ fromString("padding-box");
var isStringBoxType = {
    fromString: function ($59) {
        return BoxType(fromString($59));
    }
};
var isStringBoxShadow = {
    fromString: function ($60) {
        return BoxShadow(fromString($60));
    }
};
var fromString1 = /* #__PURE__ */ CSS_String.fromString(isStringBoxShadow);
var noneBoxShadow = {
    none: /* #__PURE__ */ fromString1("none")
};
var unsetBoxShadow = {
    unset: /* #__PURE__ */ fromString1("unset")
};
var initialBoxShadow = {
    initial: /* #__PURE__ */ fromString1("initial")
};
var inheritBoxType = {
    inherit: /* #__PURE__ */ CSS_String.fromString(isStringBoxType)("inherit")
};
var inheritBoxShadow = {
    inherit: /* #__PURE__ */ fromString1("inherit")
};
var eqBoxType = {
    eq: function (x) {
        return function (y) {
            return eq(x)(y);
        };
    }
};
var ordBoxType = {
    compare: function (x) {
        return function (y) {
            return compare(x)(y);
        };
    },
    Eq0: function () {
        return eqBoxType;
    }
};
var eqBoxShadow = {
    eq: function (x) {
        return function (y) {
            return eq(x)(y);
        };
    }
};
var ordBoxShadow = {
    compare: function (x) {
        return function (y) {
            return compare(x)(y);
        };
    },
    Eq0: function () {
        return eqBoxShadow;
    }
};
var contentBox = /* #__PURE__ */ fromString("content-box");
var bsInset = function (v) {
    return BoxShadow(value3(new Data_Tuple.Tuple("inset", v)));
};
var bsColor = function (c) {
    return function (v) {
        return BoxShadow(value4(new Data_Tuple.Tuple(v, c)));
    };
};
var boxSizing = /* #__PURE__ */ CSS_Stylesheet.key(valBoxType)(/* #__PURE__ */ CSS_String.fromString(CSS_Property.isStringKey)("box-sizing"));
var boxShadow = /* #__PURE__ */ (function () {
    var $61 = CSS_Stylesheet.prefixed(CSS_Property.valValue)(Data_Semigroup.append(CSS_Property.semigroupPrefixed)(CSS_Common.browsers)(CSS_String.fromString(CSS_Property.isStringPrefixed)("box-shadow")));
    var $62 = CSS_Property.value(CSS_Property.valNonEmpty(valBoxShadow));
    return function ($63) {
        return $61($62($63));
    };
})();
var borderBox = /* #__PURE__ */ fromString("border-box");
export {
    paddingBox,
    borderBox,
    contentBox,
    boxSizing,
    boxShadow,
    shadow,
    shadowWithBlur,
    shadowWithSpread,
    bsInset,
    bsColor,
    eqBoxType,
    ordBoxType,
    isStringBoxType,
    valBoxType,
    inheritBoxType,
    eqBoxShadow,
    ordBoxShadow,
    valBoxShadow,
    isStringBoxShadow,
    noneBoxShadow,
    inheritBoxShadow,
    initialBoxShadow,
    unsetBoxShadow
};
