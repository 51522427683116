// Generated by purs version 0.15.4
import * as CSS_Font from "../CSS.Font/index.js";
import * as CSS_Size from "../CSS.Size/index.js";
import * as CSS_Stylesheet from "../CSS.Stylesheet/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Array_NonEmpty from "../Data.Array.NonEmpty/index.js";
import * as Data_Array_NonEmpty_Internal from "../Data.Array.NonEmpty.Internal/index.js";
import * as Data_Boolean from "../Data.Boolean/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Eq_Generic from "../Data.Eq.Generic/index.js";
import * as Data_Generic_Rep from "../Data.Generic.Rep/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
var genericEqConstructor = /* #__PURE__ */ Data_Eq_Generic.genericEqConstructor(Data_Eq_Generic.genericEqNoArguments);
var genericEqSum = /* #__PURE__ */ Data_Eq_Generic.genericEqSum(genericEqConstructor);
var genericEqSum1 = /* #__PURE__ */ genericEqSum(/* #__PURE__ */ genericEqSum(/* #__PURE__ */ genericEqSum(/* #__PURE__ */ genericEqSum(/* #__PURE__ */ genericEqSum(genericEqConstructor)))));
var pure = /* #__PURE__ */ Control_Applicative.pure(Control_Applicative.applicativeArray);
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit)(CSS_Stylesheet.bindStyleM);
var Medium = /* #__PURE__ */ (function () {
    function Medium() {

    };
    Medium.value = new Medium();
    return Medium;
})();
var Light = /* #__PURE__ */ (function () {
    function Light() {

    };
    Light.value = new Light();
    return Light;
})();
var Semibold = /* #__PURE__ */ (function () {
    function Semibold() {

    };
    Semibold.value = new Semibold();
    return Semibold;
})();
var Bold = /* #__PURE__ */ (function () {
    function Bold() {

    };
    Bold.value = new Bold();
    return Bold;
})();
var ExtraBold = /* #__PURE__ */ (function () {
    function ExtraBold() {

    };
    ExtraBold.value = new ExtraBold();
    return ExtraBold;
})();
var FontSizeDefault = /* #__PURE__ */ (function () {
    function FontSizeDefault() {

    };
    FontSizeDefault.value = new FontSizeDefault();
    return FontSizeDefault;
})();
var FontSizeSmall = /* #__PURE__ */ (function () {
    function FontSizeSmall() {

    };
    FontSizeSmall.value = new FontSizeSmall();
    return FontSizeSmall;
})();
var FontSizeBody = /* #__PURE__ */ (function () {
    function FontSizeBody() {

    };
    FontSizeBody.value = new FontSizeBody();
    return FontSizeBody;
})();
var FontSizeHuge = /* #__PURE__ */ (function () {
    function FontSizeHuge() {

    };
    FontSizeHuge.value = new FontSizeHuge();
    return FontSizeHuge;
})();
var FontSizeH1 = /* #__PURE__ */ (function () {
    function FontSizeH1() {

    };
    FontSizeH1.value = new FontSizeH1();
    return FontSizeH1;
})();
var FontSizeH2 = /* #__PURE__ */ (function () {
    function FontSizeH2() {

    };
    FontSizeH2.value = new FontSizeH2();
    return FontSizeH2;
})();
var FontSizeH3 = /* #__PURE__ */ (function () {
    function FontSizeH3() {

    };
    FontSizeH3.value = new FontSizeH3();
    return FontSizeH3;
})();
var FontSizeH4 = /* #__PURE__ */ (function () {
    function FontSizeH4() {

    };
    FontSizeH4.value = new FontSizeH4();
    return FontSizeH4;
})();
var FontSizeH5 = /* #__PURE__ */ (function () {
    function FontSizeH5() {

    };
    FontSizeH5.value = new FontSizeH5();
    return FontSizeH5;
})();
var FontSizeH6 = /* #__PURE__ */ (function () {
    function FontSizeH6() {

    };
    FontSizeH6.value = new FontSizeH6();
    return FontSizeH6;
})();
var FontFamilyDefault = /* #__PURE__ */ (function () {
    function FontFamilyDefault() {

    };
    FontFamilyDefault.value = new FontFamilyDefault();
    return FontFamilyDefault;
})();
var AtkinsonBold = /* #__PURE__ */ (function () {
    function AtkinsonBold() {

    };
    AtkinsonBold.value = new AtkinsonBold();
    return AtkinsonBold;
})();
var AtkinsonMedium = /* #__PURE__ */ (function () {
    function AtkinsonMedium() {

    };
    AtkinsonMedium.value = new AtkinsonMedium();
    return AtkinsonMedium;
})();
var LibreBaskervilleBold = /* #__PURE__ */ (function () {
    function LibreBaskervilleBold() {

    };
    LibreBaskervilleBold.value = new LibreBaskervilleBold();
    return LibreBaskervilleBold;
})();
var WorkSansSemibold = /* #__PURE__ */ (function () {
    function WorkSansSemibold() {

    };
    WorkSansSemibold.value = new WorkSansSemibold();
    return WorkSansSemibold;
})();
var WorkSansBold = /* #__PURE__ */ (function () {
    function WorkSansBold() {

    };
    WorkSansBold.value = new WorkSansBold();
    return WorkSansBold;
})();
var Font = /* #__PURE__ */ (function () {
    function Font(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Font.create = function (value0) {
        return function (value1) {
            return new Font(value0, value1);
        };
    };
    return Font;
})();
var weight = function (v) {
    if (v instanceof FontFamilyDefault) {
        return Medium.value;
    };
    if (v instanceof AtkinsonMedium) {
        return Medium.value;
    };
    if (v instanceof AtkinsonBold) {
        return Bold.value;
    };
    if (v instanceof LibreBaskervilleBold) {
        return Bold.value;
    };
    if (v instanceof WorkSansSemibold) {
        return Semibold.value;
    };
    if (v instanceof WorkSansBold) {
        return Bold.value;
    };
    throw new Error("Failed pattern match at Toad.Css.Font (line 48, column 10 - line 54, column 23): " + [ v.constructor.name ]);
};
var fontSizePt = /* #__PURE__ */ (function () {
    var pt = function ($copy_v) {
        var $tco_done = false;
        var $tco_result;
        function $tco_loop(v) {
            if (v instanceof FontSizeDefault) {
                $copy_v = FontSizeBody.value;
                return;
            };
            if (v instanceof FontSizeSmall) {
                $tco_done = true;
                return 8.0;
            };
            if (v instanceof FontSizeH6) {
                $tco_done = true;
                return 10.0;
            };
            if (v instanceof FontSizeBody) {
                $tco_done = true;
                return 16.0;
            };
            if (v instanceof FontSizeH5) {
                $tco_done = true;
                return 12.0;
            };
            if (v instanceof FontSizeH4) {
                $tco_done = true;
                return 16.0;
            };
            if (v instanceof FontSizeH3) {
                $tco_done = true;
                return 24.0;
            };
            if (v instanceof FontSizeH2) {
                $tco_done = true;
                return 32.0;
            };
            if (v instanceof FontSizeH1) {
                $tco_done = true;
                return 48.0;
            };
            if (v instanceof FontSizeHuge) {
                $tco_done = true;
                return 72.0;
            };
            throw new Error("Failed pattern match at Toad.Css.Font (line 90, column 10 - line 100, column 27): " + [ v.constructor.name ]);
        };
        while (!$tco_done) {
            $tco_result = $tco_loop($copy_v);
        };
        return $tco_result;
    };
    return function ($131) {
        return CSS_Size.pt(pt($131));
    };
})();
var fontSizeGeneric = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return FontSizeDefault.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return FontSizeSmall.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return FontSizeBody.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
            return FontSizeHuge.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))) {
            return FontSizeH1.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))) {
            return FontSizeH2.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))))) {
            return FontSizeH3.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))))) {
            return FontSizeH4.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))))))) {
            return FontSizeH5.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr)))))))) {
            return FontSizeH6.value;
        };
        throw new Error("Failed pattern match at Toad.Css.Font (line 124, column 1 - line 124, column 54): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof FontSizeDefault) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof FontSizeSmall) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
        };
        if (x instanceof FontSizeBody) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
        };
        if (x instanceof FontSizeHuge) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))));
        };
        if (x instanceof FontSizeH1) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)))));
        };
        if (x instanceof FontSizeH2) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))))));
        };
        if (x instanceof FontSizeH3) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)))))));
        };
        if (x instanceof FontSizeH4) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))))))));
        };
        if (x instanceof FontSizeH5) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)))))))));
        };
        if (x instanceof FontSizeH6) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value)))))))));
        };
        throw new Error("Failed pattern match at Toad.Css.Font (line 124, column 1 - line 124, column 54): " + [ x.constructor.name ]);
    }
};
var fontSizeEq = {
    eq: /* #__PURE__ */ Data_Eq_Generic.genericEq(fontSizeGeneric)(/* #__PURE__ */ genericEqSum(/* #__PURE__ */ genericEqSum(/* #__PURE__ */ genericEqSum(/* #__PURE__ */ genericEqSum(genericEqSum1)))))
};
var eq = /* #__PURE__ */ Data_Eq.eq(fontSizeEq);
var fontSizeSemigroup = {
    append: function (a) {
        return function (b) {
            if (eq(b)(Data_Monoid.mempty(fontSizeMonoid))) {
                return a;
            };
            if (Data_Boolean.otherwise) {
                return b;
            };
            throw new Error("Failed pattern match at Toad.Css.Font (line 116, column 1 - line 119, column 20): " + [ a.constructor.name, b.constructor.name ]);
        };
    }
};
var fontSizeMonoid = /* #__PURE__ */ (function () {
    return {
        mempty: FontSizeDefault.value,
        Semigroup0: function () {
            return fontSizeSemigroup;
        }
    };
})();
var append = /* #__PURE__ */ Data_Semigroup.append(fontSizeSemigroup);
var mempty = /* #__PURE__ */ Data_Monoid.mempty(fontSizeMonoid);
var fontFamilyGeneric = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return FontFamilyDefault.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return AtkinsonBold.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return AtkinsonMedium.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
            return LibreBaskervilleBold.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))) {
            return WorkSansSemibold.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr)))) {
            return WorkSansBold.value;
        };
        throw new Error("Failed pattern match at Toad.Css.Font (line 137, column 1 - line 137, column 58): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof FontFamilyDefault) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof AtkinsonBold) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
        };
        if (x instanceof AtkinsonMedium) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
        };
        if (x instanceof LibreBaskervilleBold) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))));
        };
        if (x instanceof WorkSansSemibold) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)))));
        };
        if (x instanceof WorkSansBold) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value)))));
        };
        throw new Error("Failed pattern match at Toad.Css.Font (line 137, column 1 - line 137, column 58): " + [ x.constructor.name ]);
    }
};
var fontFamilyEq = {
    eq: /* #__PURE__ */ Data_Eq_Generic.genericEq(fontFamilyGeneric)(genericEqSum1)
};
var eq1 = /* #__PURE__ */ Data_Eq.eq(fontFamilyEq);
var fontFamilySemigroup = {
    append: function (a) {
        return function (b) {
            if (eq1(b)(Data_Monoid.mempty(fontFamilyMonoid))) {
                return a;
            };
            if (Data_Boolean.otherwise) {
                return b;
            };
            throw new Error("Failed pattern match at Toad.Css.Font (line 129, column 1 - line 132, column 20): " + [ a.constructor.name, b.constructor.name ]);
        };
    }
};
var fontFamilyMonoid = /* #__PURE__ */ (function () {
    return {
        mempty: FontFamilyDefault.value,
        Semigroup0: function () {
            return fontFamilySemigroup;
        }
    };
})();
var mempty1 = /* #__PURE__ */ Data_Monoid.mempty(fontFamilyMonoid);
var append1 = /* #__PURE__ */ Data_Semigroup.append(fontFamilySemigroup);
var fontSize = /* #__PURE__ */ (function () {
    return Font.create(mempty1);
})();
var fontSemigroup = {
    append: function (v) {
        return function (v1) {
            return new Font(append1(v.value0)(v1.value0), append(v.value1)(v1.value1));
        };
    }
};
var fontMonoid = /* #__PURE__ */ (function () {
    return {
        mempty: new Font(mempty1, mempty),
        Semigroup0: function () {
            return fontSemigroup;
        }
    };
})();
var fontFamily = function (family) {
    return new Font(family, mempty);
};
var cssFontWeight = function (v) {
    if (v instanceof ExtraBold) {
        return CSS_Font.fontWeight(CSS_Font.weight(900.0));
    };
    if (v instanceof Bold) {
        return CSS_Font.fontWeight(CSS_Font.weight(700.0));
    };
    if (v instanceof Semibold) {
        return CSS_Font.fontWeight(CSS_Font.weight(600.0));
    };
    if (v instanceof Medium) {
        return CSS_Font.fontWeight(CSS_Font.weight(500.0));
    };
    if (v instanceof Light) {
        return CSS_Font.fontWeight(CSS_Font.weight(300.0));
    };
    throw new Error("Failed pattern match at Toad.Css.Font (line 80, column 17 - line 85, column 45): " + [ v.constructor.name ]);
};
var cssFontFamily = /* #__PURE__ */ (function () {
    var fallback = (function () {
        var $132 = Control_Applicative.pure(Data_Array_NonEmpty_Internal.applicativeNonEmptyArray);
        return function ($133) {
            return Data_Array_NonEmpty.toNonEmpty($132($133));
        };
    })();
    var wrk = CSS_Font.fontFamily(pure("Work Sans"))(fallback(CSS_Font.sansSerif));
    var bsk = CSS_Font.fontFamily(pure("Libre Baskerville"))(fallback(CSS_Font.serif));
    var atk = CSS_Font.fontFamily(pure("Atkinson Hyperlegible"))(fallback(CSS_Font.sansSerif));
    return function (v) {
        if (v instanceof FontFamilyDefault) {
            return cssFontFamily(AtkinsonMedium.value);
        };
        if (v instanceof AtkinsonBold) {
            return atk;
        };
        if (v instanceof AtkinsonMedium) {
            return atk;
        };
        if (v instanceof WorkSansSemibold) {
            return wrk;
        };
        if (v instanceof WorkSansBold) {
            return wrk;
        };
        if (v instanceof LibreBaskervilleBold) {
            return bsk;
        };
        throw new Error("Failed pattern match at Toad.Css.Font (line 71, column 5 - line 77, column 34): " + [ v.constructor.name ]);
    };
})();
var font = function (v) {
    return discard(cssFontFamily(v.value0))(function () {
        return discard(CSS_Font.fontSize(fontSizePt(v.value1)))(function () {
            return cssFontWeight(weight(v.value0));
        });
    });
};
export {
    Font,
    FontSizeDefault,
    FontSizeSmall,
    FontSizeBody,
    FontSizeHuge,
    FontSizeH1,
    FontSizeH2,
    FontSizeH3,
    FontSizeH4,
    FontSizeH5,
    FontSizeH6,
    FontFamilyDefault,
    AtkinsonBold,
    AtkinsonMedium,
    LibreBaskervilleBold,
    WorkSansSemibold,
    WorkSansBold,
    font,
    fontSize,
    fontFamily,
    fontSizePt,
    fontSizeSemigroup,
    fontSizeMonoid,
    fontSizeGeneric,
    fontSizeEq,
    fontFamilySemigroup,
    fontFamilyMonoid,
    fontFamilyGeneric,
    fontFamilyEq,
    fontSemigroup,
    fontMonoid
};
