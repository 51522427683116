// Generated by purs version 0.15.4
import * as Control_Category from "../Control.Category/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Function from "../Data.Function/index.js";
var identity = /* #__PURE__ */ Control_Category.identity(Control_Category.categoryFn);
var Nop = /* #__PURE__ */ (function () {
    function Nop() {

    };
    Nop.value = new Nop();
    return Nop;
})();
var Init = /* #__PURE__ */ (function () {
    function Init() {

    };
    Init.value = new Init();
    return Init;
})();
var AppTitleChanged = /* #__PURE__ */ (function () {
    function AppTitleChanged(value0) {
        this.value0 = value0;
    };
    AppTitleChanged.create = function (value0) {
        return new AppTitleChanged(value0);
    };
    return AppTitleChanged;
})();
var NavbarSectionPicked = /* #__PURE__ */ (function () {
    function NavbarSectionPicked() {

    };
    NavbarSectionPicked.value = new NavbarSectionPicked();
    return NavbarSectionPicked;
})();
var Navigate = /* #__PURE__ */ (function () {
    function Navigate(value0) {
        this.value0 = value0;
    };
    Navigate.create = function (value0) {
        return new Navigate(value0);
    };
    return Navigate;
})();
var Tick = /* #__PURE__ */ (function () {
    function Tick() {

    };
    Tick.value = new Tick();
    return Tick;
})();
var DismissError = /* #__PURE__ */ (function () {
    function DismissError() {

    };
    DismissError.value = new DismissError();
    return DismissError;
})();
var ConceptsPageOutput = /* #__PURE__ */ (function () {
    function ConceptsPageOutput(value0) {
        this.value0 = value0;
    };
    ConceptsPageOutput.create = function (value0) {
        return new ConceptsPageOutput(value0);
    };
    return ConceptsPageOutput;
})();
var NavAccordionExpandBook = /* #__PURE__ */ (function () {
    function NavAccordionExpandBook() {

    };
    NavAccordionExpandBook.value = new NavAccordionExpandBook();
    return NavAccordionExpandBook;
})();
var NavAccordionExpandConcepts = /* #__PURE__ */ (function () {
    function NavAccordionExpandConcepts() {

    };
    NavAccordionExpandConcepts.value = new NavAccordionExpandConcepts();
    return NavAccordionExpandConcepts;
})();
var fromFoldable = function (dictFoldable) {
    return Data_Foldable.foldl(dictFoldable)(Data_Function["const"](identity))(Nop.value);
};
export {
    Nop,
    Init,
    AppTitleChanged,
    NavbarSectionPicked,
    Navigate,
    Tick,
    DismissError,
    ConceptsPageOutput,
    NavAccordionExpandBook,
    NavAccordionExpandConcepts,
    fromFoldable
};
