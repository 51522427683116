// Generated by purs version 0.15.4
import * as CSS_Common from "../CSS.Common/index.js";
import * as CSS_Property from "../CSS.Property/index.js";
import * as CSS_String from "../CSS.String/index.js";
import * as CSS_Stylesheet from "../CSS.Stylesheet/index.js";
import * as CSS_Time from "../CSS.Time/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Ordering from "../Data.Ordering/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
var fromString = /* #__PURE__ */ CSS_String.fromString(CSS_Property.isStringValue);
var append = /* #__PURE__ */ Data_Semigroup.append(CSS_Property.semigroupValue);
var value = /* #__PURE__ */ CSS_Property.value(/* #__PURE__ */ CSS_Property.valList(CSS_Property.valValue));
var show = /* #__PURE__ */ Data_Show.show(Data_Show.showInt);
var value1 = /* #__PURE__ */ CSS_Property.value(/* #__PURE__ */ CSS_Property.valList(CSS_Property.valNumber));
var key = /* #__PURE__ */ CSS_Stylesheet.key(CSS_Property.valValue);
var fromString1 = /* #__PURE__ */ CSS_String.fromString(CSS_Property.isStringKey);
var none = /* #__PURE__ */ CSS_Common.none(CSS_Common.noneValue);
var key1 = /* #__PURE__ */ CSS_Stylesheet.key(CSS_Property.valString);
var key2 = /* #__PURE__ */ CSS_Stylesheet.key(/* #__PURE__ */ CSS_Property.valList(CSS_Property.valString));
var append1 = /* #__PURE__ */ Data_Semigroup.append(CSS_Property.semigroupPrefixed);
var fromString2 = /* #__PURE__ */ CSS_String.fromString(CSS_Property.isStringPrefixed);
var compare = /* #__PURE__ */ Data_Ord.compare(Data_Ord.ordInt);
var compare1 = /* #__PURE__ */ Data_Ord.compare(Data_Ord.ordNumber);
var Start = /* #__PURE__ */ (function () {
    function Start() {

    };
    Start.value = new Start();
    return Start;
})();
var End = /* #__PURE__ */ (function () {
    function End() {

    };
    End.value = new End();
    return End;
})();
var Ease = /* #__PURE__ */ (function () {
    function Ease() {

    };
    Ease.value = new Ease();
    return Ease;
})();
var EaseIn = /* #__PURE__ */ (function () {
    function EaseIn() {

    };
    EaseIn.value = new EaseIn();
    return EaseIn;
})();
var EaseOut = /* #__PURE__ */ (function () {
    function EaseOut() {

    };
    EaseOut.value = new EaseOut();
    return EaseOut;
})();
var EaseInOut = /* #__PURE__ */ (function () {
    function EaseInOut() {

    };
    EaseInOut.value = new EaseInOut();
    return EaseInOut;
})();
var Linear = /* #__PURE__ */ (function () {
    function Linear() {

    };
    Linear.value = new Linear();
    return Linear;
})();
var StepStart = /* #__PURE__ */ (function () {
    function StepStart() {

    };
    StepStart.value = new StepStart();
    return StepStart;
})();
var StepEnd = /* #__PURE__ */ (function () {
    function StepEnd() {

    };
    StepEnd.value = new StepEnd();
    return StepEnd;
})();
var Steps = /* #__PURE__ */ (function () {
    function Steps(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Steps.create = function (value0) {
        return function (value1) {
            return new Steps(value0, value1);
        };
    };
    return Steps;
})();
var CubicBezier = /* #__PURE__ */ (function () {
    function CubicBezier(value0, value1, value2, value3) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
        this.value3 = value3;
    };
    CubicBezier.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return function (value3) {
                    return new CubicBezier(value0, value1, value2, value3);
                };
            };
        };
    };
    return CubicBezier;
})();
var valTimingStepsValue = {
    value: function (v) {
        if (v instanceof Start) {
            return fromString("start");
        };
        if (v instanceof End) {
            return fromString("end");
        };
        throw new Error("Failed pattern match at CSS.Transition (line 16, column 1 - line 18, column 31): " + [ v.constructor.name ]);
    }
};
var value2 = /* #__PURE__ */ CSS_Property.value(valTimingStepsValue);
var valTimingFunction = {
    value: function (v) {
        if (v instanceof Ease) {
            return fromString("ease");
        };
        if (v instanceof EaseIn) {
            return fromString("ease-in");
        };
        if (v instanceof EaseOut) {
            return fromString("ease-out");
        };
        if (v instanceof EaseInOut) {
            return fromString("ease-in-out");
        };
        if (v instanceof Linear) {
            return fromString("linear");
        };
        if (v instanceof StepStart) {
            return fromString("step-start");
        };
        if (v instanceof StepEnd) {
            return fromString("step-end");
        };
        if (v instanceof Steps) {
            return append(fromString("steps("))(append(value([ fromString(show(v.value0)), value2(v.value1) ]))(fromString(")")));
        };
        if (v instanceof CubicBezier) {
            return append(fromString("cubic-bezier("))(append(value1([ v.value0, v.value1, v.value2, v.value3 ]))(fromString(")")));
        };
        throw new Error("Failed pattern match at CSS.Transition (line 34, column 1 - line 43, column 101): " + [ v.constructor.name ]);
    }
};
var key3 = /* #__PURE__ */ CSS_Stylesheet.key(/* #__PURE__ */ CSS_Property.valList(valTimingFunction));
var prefixed = /* #__PURE__ */ CSS_Stylesheet.prefixed(/* #__PURE__ */ CSS_Property.valTuple(CSS_Property.valString)(/* #__PURE__ */ CSS_Property.valTuple(CSS_Time.valTime)(/* #__PURE__ */ CSS_Property.valTuple(valTimingFunction)(CSS_Time.valTime))));
var transitionTimingFunctions = function (v) {
    if (v.length === 0) {
        return key(fromString1("transition-timing-function"))(none);
    };
    return key3(fromString1("transition-timing-function"))(v);
};
var transitionTimingFunction = /* #__PURE__ */ CSS_Stylesheet.key(valTimingFunction)(/* #__PURE__ */ fromString1("transition-timing-function"));
var transitionProperty = /* #__PURE__ */ key1(/* #__PURE__ */ fromString1("transition-property"));
var transitionProperties = function (v) {
    if (v.length === 0) {
        return key(fromString1("transition-property"))(none);
    };
    return key2(fromString1("transition-property"))(v);
};
var transitionDurations = function (v) {
    if (v.length === 0) {
        return key(fromString1("transition-duration"))(none);
    };
    return key2(fromString1("transition-duration"))(v);
};
var transitionDuration = /* #__PURE__ */ key1(/* #__PURE__ */ fromString1("transition-duration"));
var transitionDelays = /* #__PURE__ */ CSS_Stylesheet.key(/* #__PURE__ */ CSS_Property.valList(CSS_Time.valTime))(/* #__PURE__ */ fromString1("transition-delay"));
var transitionDelay = /* #__PURE__ */ CSS_Stylesheet.key(CSS_Time.valTime)(/* #__PURE__ */ fromString1("transition-delay"));
var transition = function (p) {
    return function (d) {
        return function (f) {
            return function (e) {
                return prefixed(append1(CSS_Common.browsers)(fromString2("transition")))(new Data_Tuple.Tuple(p, new Data_Tuple.Tuple(d, new Data_Tuple.Tuple(f, e))));
            };
        };
    };
};
var steps = function (n) {
    return Steps.create(n);
};
var stepStart = /* #__PURE__ */ (function () {
    return StepStart.value;
})();
var stepEnd = /* #__PURE__ */ (function () {
    return StepEnd.value;
})();
var start = /* #__PURE__ */ (function () {
    return Start.value;
})();
var linear = /* #__PURE__ */ (function () {
    return Linear.value;
})();
var eqTimingStepsValue = {
    eq: function (x) {
        return function (y) {
            if (x instanceof Start && y instanceof Start) {
                return true;
            };
            if (x instanceof End && y instanceof End) {
                return true;
            };
            return false;
        };
    }
};
var eq2 = /* #__PURE__ */ Data_Eq.eq(eqTimingStepsValue);
var ordTimingStepsValue = {
    compare: function (x) {
        return function (y) {
            if (x instanceof Start && y instanceof Start) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Start) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Start) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof End && y instanceof End) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at CSS.Transition (line 0, column 0 - line 0, column 0): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqTimingStepsValue;
    }
};
var compare2 = /* #__PURE__ */ Data_Ord.compare(ordTimingStepsValue);
var eqTimingFunction = {
    eq: function (x) {
        return function (y) {
            if (x instanceof Ease && y instanceof Ease) {
                return true;
            };
            if (x instanceof EaseIn && y instanceof EaseIn) {
                return true;
            };
            if (x instanceof EaseOut && y instanceof EaseOut) {
                return true;
            };
            if (x instanceof EaseInOut && y instanceof EaseInOut) {
                return true;
            };
            if (x instanceof Linear && y instanceof Linear) {
                return true;
            };
            if (x instanceof StepStart && y instanceof StepStart) {
                return true;
            };
            if (x instanceof StepEnd && y instanceof StepEnd) {
                return true;
            };
            if (x instanceof Steps && y instanceof Steps) {
                return x.value0 === y.value0 && eq2(x.value1)(y.value1);
            };
            if (x instanceof CubicBezier && y instanceof CubicBezier) {
                return x.value0 === y.value0 && x.value1 === y.value1 && x.value2 === y.value2 && x.value3 === y.value3;
            };
            return false;
        };
    }
};
var ordTimingFunction = {
    compare: function (x) {
        return function (y) {
            if (x instanceof Ease && y instanceof Ease) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Ease) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Ease) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof EaseIn && y instanceof EaseIn) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof EaseIn) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof EaseIn) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof EaseOut && y instanceof EaseOut) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof EaseOut) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof EaseOut) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof EaseInOut && y instanceof EaseInOut) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof EaseInOut) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof EaseInOut) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Linear && y instanceof Linear) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Linear) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Linear) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof StepStart && y instanceof StepStart) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof StepStart) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof StepStart) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof StepEnd && y instanceof StepEnd) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof StepEnd) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof StepEnd) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Steps && y instanceof Steps) {
                var v = compare(x.value0)(y.value0);
                if (v instanceof Data_Ordering.LT) {
                    return Data_Ordering.LT.value;
                };
                if (v instanceof Data_Ordering.GT) {
                    return Data_Ordering.GT.value;
                };
                return compare2(x.value1)(y.value1);
            };
            if (x instanceof Steps) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Steps) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof CubicBezier && y instanceof CubicBezier) {
                var v = compare1(x.value0)(y.value0);
                if (v instanceof Data_Ordering.LT) {
                    return Data_Ordering.LT.value;
                };
                if (v instanceof Data_Ordering.GT) {
                    return Data_Ordering.GT.value;
                };
                var v1 = compare1(x.value1)(y.value1);
                if (v1 instanceof Data_Ordering.LT) {
                    return Data_Ordering.LT.value;
                };
                if (v1 instanceof Data_Ordering.GT) {
                    return Data_Ordering.GT.value;
                };
                var v2 = compare1(x.value2)(y.value2);
                if (v2 instanceof Data_Ordering.LT) {
                    return Data_Ordering.LT.value;
                };
                if (v2 instanceof Data_Ordering.GT) {
                    return Data_Ordering.GT.value;
                };
                return compare1(x.value3)(y.value3);
            };
            throw new Error("Failed pattern match at CSS.Transition (line 0, column 0 - line 0, column 0): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqTimingFunction;
    }
};
var end = /* #__PURE__ */ (function () {
    return End.value;
})();
var easeOut = /* #__PURE__ */ (function () {
    return EaseOut.value;
})();
var easeInOut = /* #__PURE__ */ (function () {
    return EaseInOut.value;
})();
var easeIn = /* #__PURE__ */ (function () {
    return EaseIn.value;
})();
var ease = /* #__PURE__ */ (function () {
    return Ease.value;
})();
var cubicBezier = function (a) {
    return function (b) {
        return function (c) {
            return function (d) {
                return new CubicBezier(a, b, c, d);
            };
        };
    };
};
export {
    Start,
    End,
    Ease,
    EaseIn,
    EaseOut,
    EaseInOut,
    Linear,
    StepStart,
    StepEnd,
    Steps,
    CubicBezier,
    ease,
    easeIn,
    easeOut,
    easeInOut,
    linear,
    stepStart,
    stepEnd,
    steps,
    cubicBezier,
    start,
    end,
    transition,
    transitionProperty,
    transitionProperties,
    transitionDuration,
    transitionDurations,
    transitionTimingFunction,
    transitionTimingFunctions,
    transitionDelay,
    transitionDelays,
    eqTimingStepsValue,
    ordTimingStepsValue,
    valTimingStepsValue,
    eqTimingFunction,
    ordTimingFunction,
    valTimingFunction
};
