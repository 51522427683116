// Generated by purs version 0.15.4
import * as Control_Category from "../Control.Category/index.js";
var isStringString = {
    fromString: /* #__PURE__ */ Control_Category.identity(Control_Category.categoryFn)
};
var fromString = function (dict) {
    return dict.fromString;
};
export {
    fromString,
    isStringString
};
