// Generated by purs version 0.15.4
import * as CSS_Property from "../CSS.Property/index.js";
import * as CSS_String from "../CSS.String/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
var append = /* #__PURE__ */ Data_Semigroup.append(CSS_Property.semigroupValue);
var value = /* #__PURE__ */ CSS_Property.value(CSS_Property.valNumber);
var fromString = /* #__PURE__ */ CSS_String.fromString(CSS_Property.isStringValue);
var eq = /* #__PURE__ */ Data_Eq.eq(CSS_Property.eqValue);
var compare = /* #__PURE__ */ Data_Ord.compare(CSS_Property.ordValue);
var Time = function (x) {
    return x;
};
var valTime = {
    value: function (v) {
        return v;
    }
};
var sec = function (i) {
    return append(value(i))(fromString("s"));
};
var ms = function (i) {
    return append(value(i))(fromString("ms"));
};
var eqTime = {
    eq: function (x) {
        return function (y) {
            return eq(x)(y);
        };
    }
};
var ordTime = {
    compare: function (x) {
        return function (y) {
            return compare(x)(y);
        };
    },
    Eq0: function () {
        return eqTime;
    }
};
export {
    Time,
    sec,
    ms,
    eqTime,
    ordTime,
    valTime
};
