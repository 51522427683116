// Generated by purs version 0.15.4
import * as Data_Generic_Rep from "../Data.Generic.Rep/index.js";
import * as Data_Show_Generic from "../Data.Show.Generic/index.js";
var genericShowConstructor = /* #__PURE__ */ Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsNoArguments);
var Active = /* #__PURE__ */ (function () {
    function Active() {

    };
    Active.value = new Active();
    return Active;
})();
var Inactive = /* #__PURE__ */ (function () {
    function Inactive() {

    };
    Inactive.value = new Inactive();
    return Inactive;
})();
var genericActive = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return Active.value;
        };
        if (x instanceof Data_Generic_Rep.Inr) {
            return Inactive.value;
        };
        throw new Error("Failed pattern match at Data.Active (line 10, column 1 - line 10, column 50): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof Active) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof Inactive) {
            return new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value);
        };
        throw new Error("Failed pattern match at Data.Active (line 10, column 1 - line 10, column 50): " + [ x.constructor.name ]);
    }
};
var showActive = {
    show: /* #__PURE__ */ Data_Show_Generic.genericShow(genericActive)(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "Active";
        }
    }))(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "Inactive";
        }
    })))
};
var eqActive = {
    eq: function (x) {
        return function (y) {
            if (x instanceof Active && y instanceof Active) {
                return true;
            };
            if (x instanceof Inactive && y instanceof Inactive) {
                return true;
            };
            return false;
        };
    }
};
export {
    Active,
    Inactive,
    genericActive,
    showActive,
    eqActive
};
