// Generated by purs version 0.15.4
import * as CSS_Property from "../CSS.Property/index.js";
import * as CSS_String from "../CSS.String/index.js";
import * as CSS_Stylesheet from "../CSS.Stylesheet/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
var fromString = /* #__PURE__ */ CSS_String.fromString(CSS_Property.isStringValue);
var fromString1 = /* #__PURE__ */ CSS_String.fromString(CSS_Property.isStringKey);
var eq = /* #__PURE__ */ Data_Eq.eq(CSS_Property.eqValue);
var compare = /* #__PURE__ */ Data_Ord.compare(CSS_Property.ordValue);
var Overflow = function (x) {
    return x;
};
var visible = /* #__PURE__ */ fromString("visible");
var valOverflow = {
    value: function (v) {
        return v;
    }
};
var key = /* #__PURE__ */ CSS_Stylesheet.key(valOverflow);
var scroll = /* #__PURE__ */ fromString("scroll");
var overflowY = /* #__PURE__ */ key(/* #__PURE__ */ fromString1("overflow-y"));
var overflowX = /* #__PURE__ */ key(/* #__PURE__ */ fromString1("overflow-x"));
var overflowInherit = /* #__PURE__ */ fromString("inherit");
var overflowAuto = /* #__PURE__ */ fromString("auto");
var overflow = /* #__PURE__ */ key(/* #__PURE__ */ fromString1("overflow"));
var hidden = /* #__PURE__ */ fromString("hidden");
var eqOverflow = {
    eq: function (x) {
        return function (y) {
            return eq(x)(y);
        };
    }
};
var ordOverflow = {
    compare: function (x) {
        return function (y) {
            return compare(x)(y);
        };
    },
    Eq0: function () {
        return eqOverflow;
    }
};
export {
    Overflow,
    overflow,
    overflowX,
    overflowY,
    overflowAuto,
    hidden,
    scroll,
    visible,
    overflowInherit,
    eqOverflow,
    ordOverflow,
    valOverflow
};
