// Generated by purs version 0.15.4
import * as Data_Coord_Polar from "../Data.Coord.Polar/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Number from "../Data.Number/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_Show_Generic from "../Data.Show.Generic/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
var genericShowConstructor = /* #__PURE__ */ Data_Show_Generic.genericShowConstructor(/* #__PURE__ */ Data_Show_Generic.genericShowArgsArgument(/* #__PURE__ */ Data_Tuple.showTuple(Data_Show.showNumber)(Data_Show.showNumber)));
var eqTuple = /* #__PURE__ */ Data_Tuple.eqTuple(Data_Eq.eqNumber)(Data_Eq.eqNumber);
var Velocity = function (x1) {
    return x1;
};
var Pos = function (x1) {
    return x1;
};
var Accel = function (x1) {
    return x1;
};
var genericVelocity = {
    to: function (x1) {
        return x1;
    },
    from: function (x1) {
        return x1;
    }
};
var showVelocity = {
    show: /* #__PURE__ */ Data_Show_Generic.genericShow(genericVelocity)(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "Velocity";
        }
    }))
};
var genericPos = {
    to: function (x1) {
        return x1;
    },
    from: function (x1) {
        return x1;
    }
};
var showPos = {
    show: /* #__PURE__ */ Data_Show_Generic.genericShow(genericPos)(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "Pos";
        }
    }))
};
var genericAccel = {
    to: function (x1) {
        return x1;
    },
    from: function (x1) {
        return x1;
    }
};
var showAccel = {
    show: /* #__PURE__ */ Data_Show_Generic.genericShow(genericAccel)(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "Accel";
        }
    }))
};
var fromCoords = function (dict) {
    return dict.fromCoords;
};
var make = function (dictCartesian) {
    var fromCoords1 = fromCoords(dictCartesian);
    return function (x1) {
        return function (y1) {
            return fromCoords1(new Data_Tuple.Tuple(x1, y1));
        };
    };
};
var fromPolar = function (dictPolar) {
    var radius = Data_Coord_Polar.radius(dictPolar);
    var angle = Data_Coord_Polar.angle(dictPolar);
    return function (dictCartesian) {
        var make1 = make(dictCartesian);
        return function (p) {
            return make1(radius(p) * Data_Number.cos(Data_Coord_Polar.radiansFloat(angle(p))))(radius(p) * Data_Number.sin(Data_Coord_Polar.radiansFloat(angle(p))));
        };
    };
};
var eqVelocity = eqTuple;
var eqPos = eqTuple;
var eqAccel = eqTuple;
var coords = function (dict) {
    return dict.coords;
};
var integral = function (dictCartesian) {
    var coords1 = coords(dictCartesian);
    return function (dictCartesian1) {
        var fromCoords1 = fromCoords(dictCartesian1);
        var coords2 = coords(dictCartesian1);
        return function (df) {
            return function (f) {
                var go = function (v) {
                    return function (v1) {
                        return fromCoords1(new Data_Tuple.Tuple(v.value0 + v1.value0, v.value1 + v1.value1));
                    };
                };
                var f$prime = coords2(f);
                var df$prime = coords1(df);
                return go(df$prime)(f$prime);
            };
        };
    };
};
var magnitude = function (dictCartesian) {
    var $92 = coords(dictCartesian);
    return function ($93) {
        return (function (v) {
            return Data_Number.sqrt(Data_Number.pow(v.value0)(2.0) + Data_Number.pow(v.value1)(2.0));
        })($92($93));
    };
};
var mulBy = function (dictCartesian) {
    var fromCoords1 = fromCoords(dictCartesian);
    var coords1 = coords(dictCartesian);
    return function (k) {
        return function ($94) {
            return fromCoords1((function (v) {
                return new Data_Tuple.Tuple(v.value0 * k, v.value1 * k);
            })(coords1($94)));
        };
    };
};
var normalize = function (dictCartesian) {
    var mulBy1 = mulBy(dictCartesian);
    var magnitude1 = magnitude(dictCartesian);
    return function (f) {
        return mulBy1(1.0 / magnitude1(f))(f);
    };
};
var toPolar = function (dictPolar) {
    var make1 = Data_Coord_Polar.make(dictPolar);
    return function (dictCartesian) {
        var magnitude1 = magnitude(dictCartesian);
        var coords1 = coords(dictCartesian);
        return function (f) {
            var toPolar$prime = function (v) {
                return make1(magnitude1(f))(Data_Number.atan2(v.value1)(v.value0));
            };
            return toPolar$prime(coords1(f));
        };
    };
};
var x = function (dictCartesian) {
    var $95 = coords(dictCartesian);
    return function ($96) {
        return Data_Tuple.fst($95($96));
    };
};
var y = function (dictCartesian) {
    var $97 = coords(dictCartesian);
    return function ($98) {
        return Data_Tuple.snd($97($98));
    };
};
var cartesianVelocity = {
    coords: function (v) {
        return v;
    },
    fromCoords: function (t) {
        return t;
    }
};
var cartesianPos = {
    coords: function (v) {
        return v;
    },
    fromCoords: function (t) {
        return t;
    }
};
var cartesianAccel = {
    coords: function (v) {
        return v;
    },
    fromCoords: function (t) {
        return t;
    }
};
export {
    coords,
    fromCoords,
    x,
    y,
    fromPolar,
    toPolar,
    make,
    Pos,
    Velocity,
    Accel,
    integral,
    magnitude,
    normalize,
    mulBy,
    eqPos,
    showPos,
    genericPos,
    cartesianPos,
    eqVelocity,
    showVelocity,
    genericVelocity,
    cartesianVelocity,
    eqAccel,
    showAccel,
    genericAccel,
    cartesianAccel
};
