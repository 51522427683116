// Generated by purs version 0.15.4
import * as Control_Monad_Trans_Class from "../Control.Monad.Trans.Class/index.js";
import * as Halogen_Query_HalogenM from "../Halogen.Query.HalogenM/index.js";
var lift = /* #__PURE__ */ Control_Monad_Trans_Class.lift(Halogen_Query_HalogenM.monadTransHalogenM);
var navigate = function (dict) {
    return dict.navigate;
};
var navigateHalogenM = function (dictNavigate) {
    return {
        navigate: (function () {
            var $7 = lift(dictNavigate.Monad0());
            var $8 = navigate(dictNavigate);
            return function ($9) {
                return $7($8($9));
            };
        })(),
        Monad0: function () {
            return Halogen_Query_HalogenM.monadHalogenM;
        }
    };
};
export {
    navigate,
    navigateHalogenM
};
