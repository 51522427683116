// Generated by purs version 0.15.4
import * as $foreign from "./foreign.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Halogen_HTML_Core from "../Halogen.HTML.Core/index.js";
var bind = /* #__PURE__ */ Control_Bind.bind(Data_Maybe.bindMaybe);
var shadow = /* #__PURE__ */ (function () {
    var refHandler = function (el) {
        return bind(el)(function (el$prime) {
            var v = $foreign.unsafeWrapInShadow(el$prime);
            return Data_Maybe.Nothing.value;
        });
    };
    return Halogen_HTML_Core.ref(refHandler);
})();
export {
    shadow
};
