// Generated by purs version 0.15.4
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Category from "../Control.Category/index.js";
import * as Control_Monad_Reader_Trans from "../Control.Monad.Reader.Trans/index.js";
import * as Control_Monad_Writer from "../Control.Monad.Writer/index.js";
import * as Data_Argonaut_Core from "../Data.Argonaut.Core/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Bifunctor from "../Data.Bifunctor/index.js";
import * as Data_Codec from "../Data.Codec/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Generic_Rep from "../Data.Generic.Rep/index.js";
import * as Data_Int from "../Data.Int/index.js";
import * as Data_List_Types from "../Data.List.Types/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Ordering from "../Data.Ordering/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_String_CodePoints from "../Data.String.CodePoints/index.js";
import * as Data_String_CodeUnits from "../Data.String.CodeUnits/index.js";
import * as Data_Symbol from "../Data.Symbol/index.js";
import * as Data_Traversable from "../Data.Traversable/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
import * as Data_Void from "../Data.Void/index.js";
import * as Foreign_Object from "../Foreign.Object/index.js";
var show = /* #__PURE__ */ Data_Show.show(Data_Show.showString);
var show1 = /* #__PURE__ */ Data_Show.show(Data_Show.showInt);
var fromJust = /* #__PURE__ */ Data_Maybe.fromJust();
var bind = /* #__PURE__ */ Control_Bind.bind(Data_Either.bindEither);
var lmap = /* #__PURE__ */ Data_Bifunctor.lmap(Data_Bifunctor.bifunctorEither);
var pure = /* #__PURE__ */ Control_Applicative.pure(Data_Either.applicativeEither);
var pure1 = /* #__PURE__ */ Control_Applicative.pure(Data_List_Types.applicativeList);
var bindFlipped = /* #__PURE__ */ Control_Bind.bindFlipped(Data_Either.bindEither);
var composeKleisliFlipped = /* #__PURE__ */ Control_Bind.composeKleisliFlipped(Data_Either.bindEither);
var rmap = /* #__PURE__ */ Data_Bifunctor.rmap(Data_Bifunctor.bifunctorTuple);
var fromFoldable = /* #__PURE__ */ Foreign_Object.fromFoldable(Data_List_Types.foldableList);
var composeKleisliFlipped1 = /* #__PURE__ */ Control_Bind.composeKleisliFlipped(Data_Maybe.bindMaybe);
var fromFoldable1 = /* #__PURE__ */ Data_Array.fromFoldable(Data_List_Types.foldableList);
var eq1 = /* #__PURE__ */ Data_Eq.eq(Data_Argonaut_Core.eqJson);
var compare = /* #__PURE__ */ Data_Ord.compare(Data_Ord.ordString);
var compare1 = /* #__PURE__ */ Data_Ord.compare(Data_Argonaut_Core.ordJson);
var compare2 = /* #__PURE__ */ Data_Ord.compare(Data_Ord.ordInt);
var map = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var traverse = /* #__PURE__ */ Data_Traversable.traverse(Data_Traversable.traversableArray)(Data_Either.applicativeEither);
var TypeMismatch = /* #__PURE__ */ (function () {
    function TypeMismatch(value0) {
        this.value0 = value0;
    };
    TypeMismatch.create = function (value0) {
        return new TypeMismatch(value0);
    };
    return TypeMismatch;
})();
var UnexpectedValue = /* #__PURE__ */ (function () {
    function UnexpectedValue(value0) {
        this.value0 = value0;
    };
    UnexpectedValue.create = function (value0) {
        return new UnexpectedValue(value0);
    };
    return UnexpectedValue;
})();
var AtIndex = /* #__PURE__ */ (function () {
    function AtIndex(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    AtIndex.create = function (value0) {
        return function (value1) {
            return new AtIndex(value0, value1);
        };
    };
    return AtIndex;
})();
var AtKey = /* #__PURE__ */ (function () {
    function AtKey(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    AtKey.create = function (value0) {
        return function (value1) {
            return new AtKey(value0, value1);
        };
    };
    return AtKey;
})();
var Named = /* #__PURE__ */ (function () {
    function Named(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Named.create = function (value0) {
        return function (value1) {
            return new Named(value0, value1);
        };
    };
    return Named;
})();
var MissingValue = /* #__PURE__ */ (function () {
    function MissingValue() {

    };
    MissingValue.value = new MissingValue();
    return MissingValue;
})();
var showJsonDecodeError = {
    show: function (v) {
        if (v instanceof TypeMismatch) {
            return "(TypeMismatch " + (show(v.value0) + ")");
        };
        if (v instanceof UnexpectedValue) {
            return "(UnexpectedValue " + (Data_Argonaut_Core.stringify(v.value0) + ")");
        };
        if (v instanceof AtIndex) {
            return "(AtIndex " + (show1(v.value0) + (" " + (Data_Show.show(showJsonDecodeError)(v.value1) + ")")));
        };
        if (v instanceof AtKey) {
            return "(AtKey " + (show(v.value0) + (" " + (Data_Show.show(showJsonDecodeError)(v.value1) + ")")));
        };
        if (v instanceof Named) {
            return "(Named " + (show(v.value0) + (" " + (Data_Show.show(showJsonDecodeError)(v.value1) + ")")));
        };
        if (v instanceof MissingValue) {
            return "MissingValue";
        };
        throw new Error("Failed pattern match at Data.Codec.Argonaut (line 74, column 10 - line 80, column 35): " + [ v.constructor.name ]);
    }
};
var recordProp = function (dictIsSymbol) {
    var reflectSymbol = Data_Symbol.reflectSymbol(dictIsSymbol);
    return function () {
        return function (p) {
            return function (codecA) {
                return function (codecR) {
                    var unsafeSet = function (key) {
                        return function (a) {
                            var $202 = Foreign_Object.insert(key)(a);
                            return function ($203) {
                                return $202($203);
                            };
                        };
                    };
                    var unsafeGet = function (s) {
                        var $205 = Foreign_Object.lookup(s);
                        return function ($206) {
                            return fromJust($205($206));
                        };
                    };
                    var enc$prime = function (key) {
                        return function (val) {
                            return Control_Monad_Writer.writer(new Data_Tuple.Tuple(val, new Data_List_Types.Cons(new Data_Tuple.Tuple(key, Data_Codec.encode(codecA)(unsafeGet(key)(val))), Data_Codec.encode(codecR)(val))));
                        };
                    };
                    var dec$prime = function (key) {
                        return function (obj) {
                            return bind(Data_Codec.decode(codecR)(obj))(function (r) {
                                return bind(lmap(AtKey.create(key))((function () {
                                    var v = Foreign_Object.lookup(key)(obj);
                                    if (v instanceof Data_Maybe.Just) {
                                        return Data_Codec.decode(codecA)(v.value0);
                                    };
                                    if (v instanceof Data_Maybe.Nothing) {
                                        return new Data_Either.Left(MissingValue.value);
                                    };
                                    throw new Error("Failed pattern match at Data.Codec.Argonaut (line 270, column 31 - line 272, column 36): " + [ v.constructor.name ]);
                                })()))(function (a) {
                                    return pure(unsafeSet(key)(a)(r));
                                });
                            });
                        };
                    };
                    var key = reflectSymbol(p);
                    return new Data_Codec.GCodec(dec$prime(key), enc$prime(key));
                };
            };
        };
    };
};
var record = /* #__PURE__ */ (function () {
    return new Data_Codec.GCodec(Control_Applicative.pure(Control_Monad_Reader_Trans.applicativeReaderT(Data_Either.applicativeEither))({}), function (val) {
        return Control_Monad_Writer.writer(new Data_Tuple.Tuple(val, Data_List_Types.Nil.value));
    });
})();
var prop = function (key) {
    return function (codec) {
        var enc = function (val) {
            return Control_Monad_Writer.writer(new Data_Tuple.Tuple(val, pure1(new Data_Tuple.Tuple(key, Data_Codec.encode(codec)(val)))));
        };
        var dec = function (obj) {
            return lmap(AtKey.create(key))((function () {
                var v = Foreign_Object.lookup(key)(obj);
                if (v instanceof Data_Maybe.Just) {
                    return Data_Codec.decode(codec)(v.value0);
                };
                if (v instanceof Data_Maybe.Nothing) {
                    return new Data_Either.Left(MissingValue.value);
                };
                throw new Error("Failed pattern match at Data.Codec.Argonaut (line 224, column 25 - line 226, column 34): " + [ v.constructor.name ]);
            })());
        };
        return new Data_Codec.GCodec(dec, enc);
    };
};
var prismaticCodec = function (name) {
    return function (f) {
        return function (g) {
            return function (orig) {
                return Data_Codec.basicCodec(function (json$prime) {
                    return bindFlipped((function () {
                        var $207 = Data_Either.note(new Named(name, new UnexpectedValue(json$prime)));
                        return function ($208) {
                            return $207(f($208));
                        };
                    })())(Data_Codec.decode(orig)(json$prime));
                })((function () {
                    var $209 = Data_Codec.encode(orig);
                    return function ($210) {
                        return $209(g($210));
                    };
                })());
            };
        };
    };
};
var printJsonDecodeError = function (err) {
    var go = function (v) {
        if (v instanceof TypeMismatch) {
            return "  Expected value of type '" + (v.value0 + "'.");
        };
        if (v instanceof UnexpectedValue) {
            return "  Unexpected value " + (Data_Argonaut_Core.stringify(v.value0) + ".");
        };
        if (v instanceof AtIndex) {
            return "  At array index " + (show1(v.value0) + (":\x0a" + go(v.value1)));
        };
        if (v instanceof AtKey) {
            return "  At object key " + (v.value0 + (":\x0a" + go(v.value1)));
        };
        if (v instanceof Named) {
            return "  Under '" + (v.value0 + ("':\x0a" + go(v.value1)));
        };
        if (v instanceof MissingValue) {
            return "  No value was found.";
        };
        throw new Error("Failed pattern match at Data.Codec.Argonaut (line 87, column 10 - line 93, column 45): " + [ v.constructor.name ]);
    };
    return "An error occurred while decoding a JSON value:\x0a" + go(err);
};
var jsonPrimCodec = function (ty) {
    return function (f) {
        return Data_Codec.basicCodec((function () {
            var $211 = Data_Maybe.maybe(new Data_Either.Left(new TypeMismatch(ty)))(pure);
            return function ($212) {
                return $211(f($212));
            };
        })());
    };
};
var $$null = /* #__PURE__ */ jsonPrimCodec("Null")(Data_Argonaut_Core.toNull)(/* #__PURE__ */ Data_Function["const"](Data_Argonaut_Core.jsonNull));
var number = /* #__PURE__ */ jsonPrimCodec("Number")(Data_Argonaut_Core.toNumber)(Data_Argonaut_Core.fromNumber);
var string = /* #__PURE__ */ jsonPrimCodec("String")(Data_Argonaut_Core.toString)(Data_Argonaut_Core.fromString);
var $$void = /* #__PURE__ */ (function () {
    return jsonPrimCodec("Void")(Data_Function["const"](Data_Maybe.Nothing.value))(Data_Void.absurd);
})();
var json = /* #__PURE__ */ Data_Codec.basicCodec(pure)(/* #__PURE__ */ Control_Category.identity(Control_Category.categoryFn));
var jobject = /* #__PURE__ */ jsonPrimCodec("Object")(Data_Argonaut_Core.toObject)(Data_Argonaut_Core.fromObject);
var object = function (name) {
    return Data_Codec.bihoistGCodec(function (r) {
        return composeKleisliFlipped((function () {
            var $213 = lmap(Named.create(name));
            var $214 = Control_Monad_Reader_Trans.runReaderT(r);
            return function ($215) {
                return $213($214($215));
            };
        })())(Data_Codec.decode(jobject));
    })(Control_Monad_Writer.mapWriter(rmap(function ($216) {
        return Data_Argonaut_Core.fromObject(fromFoldable($216));
    })));
};
var jarray = /* #__PURE__ */ jsonPrimCodec("Array")(Data_Argonaut_Core.toArray)(Data_Argonaut_Core.fromArray);
var $$int = /* #__PURE__ */ jsonPrimCodec("Int")(/* #__PURE__ */ composeKleisliFlipped1(Data_Int.fromNumber)(Data_Argonaut_Core.toNumber))(function ($217) {
    return Data_Argonaut_Core.fromNumber(Data_Int.toNumber($217));
});
var indexedArray = function (name) {
    return Data_Codec.bihoistGCodec(function (r) {
        return composeKleisliFlipped((function () {
            var $218 = lmap(Named.create(name));
            var $219 = Control_Monad_Reader_Trans.runReaderT(r);
            return function ($220) {
                return $218($219($220));
            };
        })())(Data_Codec.decode(jarray));
    })(Control_Monad_Writer.mapWriter(rmap(function ($221) {
        return Data_Argonaut_Core.fromArray(fromFoldable1($221));
    })));
};
var index = function (ix) {
    return function (codec) {
        var enc = function (val) {
            return Control_Monad_Writer.writer(new Data_Tuple.Tuple(val, pure1(Data_Codec.encode(codec)(val))));
        };
        var dec = function (xs) {
            return lmap(AtIndex.create(ix))((function () {
                var v = Data_Array.index(xs)(ix);
                if (v instanceof Data_Maybe.Just) {
                    return Data_Codec.decode(codec)(v.value0);
                };
                if (v instanceof Data_Maybe.Nothing) {
                    return new Data_Either.Left(MissingValue.value);
                };
                throw new Error("Failed pattern match at Data.Codec.Argonaut (line 195, column 26 - line 197, column 34): " + [ v.constructor.name ]);
            })());
        };
        return new Data_Codec.GCodec(dec, enc);
    };
};
var genericJsonDecodeError = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return new TypeMismatch(x.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return new UnexpectedValue(x.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return new AtIndex(x.value0.value0.value0.value0, x.value0.value0.value0.value1);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
            return new AtKey(x.value0.value0.value0.value0.value0, x.value0.value0.value0.value0.value1);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))) {
            return new Named(x.value0.value0.value0.value0.value0.value0, x.value0.value0.value0.value0.value0.value1);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr)))) {
            return MissingValue.value;
        };
        throw new Error("Failed pattern match at Data.Codec.Argonaut (line 71, column 1 - line 71, column 67): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof TypeMismatch) {
            return new Data_Generic_Rep.Inl(x.value0);
        };
        if (x instanceof UnexpectedValue) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
        };
        if (x instanceof AtIndex) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(new Data_Generic_Rep.Product(x.value0, x.value1))));
        };
        if (x instanceof AtKey) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(new Data_Generic_Rep.Product(x.value0, x.value1)))));
        };
        if (x instanceof Named) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(new Data_Generic_Rep.Product(x.value0, x.value1))))));
        };
        if (x instanceof MissingValue) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value)))));
        };
        throw new Error("Failed pattern match at Data.Codec.Argonaut (line 71, column 1 - line 71, column 67): " + [ x.constructor.name ]);
    }
};
var fix = function (f) {
    return Data_Codec.basicCodec(function (x) {
        return Data_Codec.decode(f(fix(f)))(x);
    })(function (x) {
        return Data_Codec.encode(f(fix(f)))(x);
    });
};
var eqJsonDecodeError = {
    eq: function (x) {
        return function (y) {
            if (x instanceof TypeMismatch && y instanceof TypeMismatch) {
                return x.value0 === y.value0;
            };
            if (x instanceof UnexpectedValue && y instanceof UnexpectedValue) {
                return eq1(x.value0)(y.value0);
            };
            if (x instanceof AtIndex && y instanceof AtIndex) {
                return x.value0 === y.value0 && Data_Eq.eq(eqJsonDecodeError)(x.value1)(y.value1);
            };
            if (x instanceof AtKey && y instanceof AtKey) {
                return x.value0 === y.value0 && Data_Eq.eq(eqJsonDecodeError)(x.value1)(y.value1);
            };
            if (x instanceof Named && y instanceof Named) {
                return x.value0 === y.value0 && Data_Eq.eq(eqJsonDecodeError)(x.value1)(y.value1);
            };
            if (x instanceof MissingValue && y instanceof MissingValue) {
                return true;
            };
            return false;
        };
    }
};
var ordJsonDecodeError = {
    compare: function (x) {
        return function (y) {
            if (x instanceof TypeMismatch && y instanceof TypeMismatch) {
                return compare(x.value0)(y.value0);
            };
            if (x instanceof TypeMismatch) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof TypeMismatch) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof UnexpectedValue && y instanceof UnexpectedValue) {
                return compare1(x.value0)(y.value0);
            };
            if (x instanceof UnexpectedValue) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof UnexpectedValue) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AtIndex && y instanceof AtIndex) {
                var v = compare2(x.value0)(y.value0);
                if (v instanceof Data_Ordering.LT) {
                    return Data_Ordering.LT.value;
                };
                if (v instanceof Data_Ordering.GT) {
                    return Data_Ordering.GT.value;
                };
                return Data_Ord.compare(ordJsonDecodeError)(x.value1)(y.value1);
            };
            if (x instanceof AtIndex) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AtIndex) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AtKey && y instanceof AtKey) {
                var v = compare(x.value0)(y.value0);
                if (v instanceof Data_Ordering.LT) {
                    return Data_Ordering.LT.value;
                };
                if (v instanceof Data_Ordering.GT) {
                    return Data_Ordering.GT.value;
                };
                return Data_Ord.compare(ordJsonDecodeError)(x.value1)(y.value1);
            };
            if (x instanceof AtKey) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AtKey) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Named && y instanceof Named) {
                var v = compare(x.value0)(y.value0);
                if (v instanceof Data_Ordering.LT) {
                    return Data_Ordering.LT.value;
                };
                if (v instanceof Data_Ordering.GT) {
                    return Data_Ordering.GT.value;
                };
                return Data_Ord.compare(ordJsonDecodeError)(x.value1)(y.value1);
            };
            if (x instanceof Named) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Named) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof MissingValue && y instanceof MissingValue) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at Data.Codec.Argonaut (line 0, column 0 - line 0, column 0): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqJsonDecodeError;
    }
};
var codePoint = /* #__PURE__ */ jsonPrimCodec("CodePoint")(/* #__PURE__ */ composeKleisliFlipped1(/* #__PURE__ */ Data_String_CodePoints.codePointAt(0))(Data_Argonaut_Core.toString))(function ($222) {
    return Data_Argonaut_Core.fromString(Data_String_CodePoints.singleton($222));
});
var $$char = /* #__PURE__ */ jsonPrimCodec("Char")(/* #__PURE__ */ composeKleisliFlipped1(Data_String_CodeUnits.toChar)(Data_Argonaut_Core.toString))(function ($223) {
    return Data_Argonaut_Core.fromString(Data_String_CodeUnits.singleton($223));
});
var $$boolean = /* #__PURE__ */ jsonPrimCodec("Boolean")(Data_Argonaut_Core.toBoolean)(Data_Argonaut_Core.fromBoolean);
var array = function (codec) {
    var enc = function (xs) {
        return Control_Monad_Writer.writer(new Data_Tuple.Tuple(xs, Data_Argonaut_Core.fromArray(map(Data_Codec.encode(codec))(xs))));
    };
    var dec = function (j) {
        return bindFlipped((function () {
            var $224 = traverse(function (v) {
                return lmap(AtIndex.create(v.value0))(Data_Codec.decode(codec)(v.value1));
            });
            var $225 = Data_Array.mapWithIndex(Data_Tuple.Tuple.create);
            return function ($226) {
                return $224($225($226));
            };
        })())(Data_Codec.decode(jarray)(j));
    };
    return new Data_Codec.GCodec(dec, enc);
};
export {
    TypeMismatch,
    UnexpectedValue,
    AtIndex,
    AtKey,
    Named,
    MissingValue,
    printJsonDecodeError,
    json,
    $$null as null,
    $$boolean as boolean,
    number,
    $$int as int,
    string,
    codePoint,
    $$char as char,
    jarray,
    jobject,
    $$void as void,
    array,
    indexedArray,
    index,
    object,
    prop,
    record,
    recordProp,
    fix,
    prismaticCodec,
    eqJsonDecodeError,
    ordJsonDecodeError,
    genericJsonDecodeError,
    showJsonDecodeError
};
export {
    decode,
    encode
} from "../Data.Codec/index.js";
