// Generated by purs version 0.15.4
import * as CSS_Property from "../CSS.Property/index.js";
import * as CSS_Size from "../CSS.Size/index.js";
import * as CSS_String from "../CSS.String/index.js";
import * as CSS_Stylesheet from "../CSS.Stylesheet/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
var fromString = /* #__PURE__ */ CSS_String.fromString(CSS_Property.isStringValue);
var key = /* #__PURE__ */ CSS_Stylesheet.key(CSS_Property.valValue);
var fromString1 = /* #__PURE__ */ CSS_String.fromString(CSS_Property.isStringKey);
var show = /* #__PURE__ */ Data_Show.show(Data_Show.showInt);
var show1 = /* #__PURE__ */ Data_Show.show(Data_Show.showNumber);
var valTuple = /* #__PURE__ */ CSS_Property.valTuple(CSS_Property.valValue);
var key1 = /* #__PURE__ */ CSS_Stylesheet.key(/* #__PURE__ */ valTuple(/* #__PURE__ */ valTuple(CSS_Property.valValue)));
var value = /* #__PURE__ */ CSS_Property.value(CSS_Size.valSize);
var eq = /* #__PURE__ */ Data_Eq.eq(CSS_Property.eqValue);
var compare = /* #__PURE__ */ Data_Ord.compare(CSS_Property.ordValue);
var JustifyContentValue = function (x) {
    return x;
};
var FlexWrap = function (x) {
    return x;
};
var FlexDirection = function (x) {
    return x;
};
var AlignSelfValue = function (x) {
    return x;
};
var AlignItemsValue = function (x) {
    return x;
};
var AlignContentValue = function (x) {
    return x;
};
var wrapReverse = /* #__PURE__ */ fromString("wrap-reverse");
var wrap = /* #__PURE__ */ fromString("wrap");
var valJustifyContentValue = {
    value: function (v) {
        return v;
    }
};
var valFlexWrap = {
    value: function (v) {
        return v;
    }
};
var valFlexDirection = {
    value: function (v) {
        return v;
    }
};
var key2 = /* #__PURE__ */ CSS_Stylesheet.key(/* #__PURE__ */ CSS_Property.valTuple(valFlexDirection)(valFlexWrap));
var valAlignSelfValue = {
    value: function (v) {
        return v;
    }
};
var valAlignItemsValue = {
    value: function (v) {
        return v;
    }
};
var valAlignContentValue = {
    value: function (v) {
        return v;
    }
};
var stretchValue = {
    stretch: /* #__PURE__ */ fromString("stretch")
};
var stretch = function (dict) {
    return dict.stretch;
};
var spaceBetweenValue = {
    spaceBetween: /* #__PURE__ */ fromString("space-between")
};
var spaceBetween = function (dict) {
    return dict.spaceBetween;
};
var spaceAroundValue = {
    spaceAround: /* #__PURE__ */ fromString("space-around")
};
var spaceAround = function (dict) {
    return dict.spaceAround;
};
var rowReverse = /* #__PURE__ */ fromString("row-reverse");
var row = /* #__PURE__ */ fromString("row");
var otherJustifyContentValue = {
    other: JustifyContentValue
};
var otherFlexWrap = {
    other: FlexWrap
};
var otherFlexDirection = {
    other: FlexDirection
};
var otherAlignSelfValue = {
    other: AlignSelfValue
};
var otherAlignItemsValue = {
    other: AlignItemsValue
};
var otherAlignContentValue = {
    other: AlignContentValue
};
var order = function (i) {
    return key(fromString1("order"))(fromString(show(i)));
};
var nowrap = /* #__PURE__ */ fromString("nowrap");
var justifyContent = /* #__PURE__ */ CSS_Stylesheet.key(valJustifyContentValue)(/* #__PURE__ */ fromString1("justify-content"));
var isStringJustifyContentValue = {
    fromString: function ($116) {
        return JustifyContentValue(fromString($116));
    }
};
var fromString2 = /* #__PURE__ */ CSS_String.fromString(isStringJustifyContentValue);
var spaceAroundJustifyContentValue = {
    spaceAround: /* #__PURE__ */ fromString2("space-around")
};
var spaceBetweenJustifyContentValue = {
    spaceBetween: /* #__PURE__ */ fromString2("space-between")
};
var isStringAlignSelfValue = {
    fromString: function ($117) {
        return AlignSelfValue(fromString($117));
    }
};
var fromString3 = /* #__PURE__ */ CSS_String.fromString(isStringAlignSelfValue);
var stretchAlignSelfValue = {
    stretch: /* #__PURE__ */ fromString3("stretch")
};
var isStringAlignItemsValue = {
    fromString: function ($118) {
        return AlignItemsValue(fromString($118));
    }
};
var fromString4 = /* #__PURE__ */ CSS_String.fromString(isStringAlignItemsValue);
var stretchAlignItemsValue = {
    stretch: /* #__PURE__ */ fromString4("stretch")
};
var isStringAlignContentValue = {
    fromString: function ($119) {
        return AlignContentValue(fromString($119));
    }
};
var fromString5 = /* #__PURE__ */ CSS_String.fromString(isStringAlignContentValue);
var spaceAroundAlignContentValue = {
    spaceAround: /* #__PURE__ */ fromString5("space-around")
};
var spaceBetweenAlignContentValue = {
    spaceBetween: /* #__PURE__ */ fromString5("space-between")
};
var stretchAlignContentValue = {
    stretch: /* #__PURE__ */ fromString5("stretch")
};
var inheritJustifyContentValue = {
    inherit: /* #__PURE__ */ fromString2("inherit")
};
var inheritAlignSelfValue = {
    inherit: /* #__PURE__ */ fromString3("inherit")
};
var inheritAlignItemsValue = {
    inherit: /* #__PURE__ */ fromString4("inherit")
};
var inheritAlignContentValue = {
    inherit: /* #__PURE__ */ fromString5("inherit")
};
var flexWrap = /* #__PURE__ */ CSS_Stylesheet.key(valFlexWrap)(/* #__PURE__ */ fromString1("flex-wrap"));
var flexStartValue = {
    flexStart: /* #__PURE__ */ fromString("flex-start")
};
var flexStartJustifyContentValue = {
    flexStart: /* #__PURE__ */ fromString2("flex-start")
};
var flexStartAlignSelfValue = {
    flexStart: /* #__PURE__ */ fromString3("flex-start")
};
var flexStartAlignItemsValue = {
    flexStart: /* #__PURE__ */ fromString4("flex-start")
};
var flexStartAlignContentValue = {
    flexStart: /* #__PURE__ */ fromString5("flex-start")
};
var flexStart = function (dict) {
    return dict.flexStart;
};
var flexShrink = function (i) {
    return key(fromString1("flex-shrink"))(fromString(show1(i)));
};
var flexGrow = function (i) {
    return key(fromString1("flex-grow"))(fromString(show1(i)));
};
var flexFlow = function (d) {
    return function (w) {
        return key2(fromString1("flex-flow"))(new Data_Tuple.Tuple(d, w));
    };
};
var flexEndValue = {
    flexEnd: /* #__PURE__ */ fromString("flex-end")
};
var flexEndJustifyContentValue = {
    flexEnd: /* #__PURE__ */ fromString2("flex-end")
};
var flexEndAlignSelfValue = {
    flexEnd: /* #__PURE__ */ fromString3("flex-end")
};
var flexEndAlignItemsValue = {
    flexEnd: /* #__PURE__ */ fromString4("flex-end")
};
var flexEndAlignContentValue = {
    flexEnd: /* #__PURE__ */ fromString5("flex-end")
};
var flexEnd = function (dict) {
    return dict.flexEnd;
};
var flexDirection = /* #__PURE__ */ CSS_Stylesheet.key(valFlexDirection)(/* #__PURE__ */ fromString1("flex-direction"));
var flexBasis = /* #__PURE__ */ CSS_Stylesheet.key(CSS_Size.valSize)(/* #__PURE__ */ fromString1("flex-basis"));
var flex = function (g) {
    return function (s) {
        return function (b) {
            var ss = fromString(show1(s));
            var gs = fromString(show1(g));
            return key1(fromString1("flex"))(new Data_Tuple.Tuple(gs, new Data_Tuple.Tuple(ss, value(b))));
        };
    };
};
var eqJustifyContentValue = {
    eq: function (x) {
        return function (y) {
            return eq(x)(y);
        };
    }
};
var ordJustifyContentValue = {
    compare: function (x) {
        return function (y) {
            return compare(x)(y);
        };
    },
    Eq0: function () {
        return eqJustifyContentValue;
    }
};
var eqFlexWrap = {
    eq: function (x) {
        return function (y) {
            return eq(x)(y);
        };
    }
};
var ordFlexWrap = {
    compare: function (x) {
        return function (y) {
            return compare(x)(y);
        };
    },
    Eq0: function () {
        return eqFlexWrap;
    }
};
var eqFlexDirection = {
    eq: function (x) {
        return function (y) {
            return eq(x)(y);
        };
    }
};
var ordFlexDirection = {
    compare: function (x) {
        return function (y) {
            return compare(x)(y);
        };
    },
    Eq0: function () {
        return eqFlexDirection;
    }
};
var eqAlignSelfValue = {
    eq: function (x) {
        return function (y) {
            return eq(x)(y);
        };
    }
};
var ordAlignSelfValue = {
    compare: function (x) {
        return function (y) {
            return compare(x)(y);
        };
    },
    Eq0: function () {
        return eqAlignSelfValue;
    }
};
var eqAlignItemsValue = {
    eq: function (x) {
        return function (y) {
            return eq(x)(y);
        };
    }
};
var ordAlignItemsValue = {
    compare: function (x) {
        return function (y) {
            return compare(x)(y);
        };
    },
    Eq0: function () {
        return eqAlignItemsValue;
    }
};
var eqAlignContentValue = {
    eq: function (x) {
        return function (y) {
            return eq(x)(y);
        };
    }
};
var ordAlignContentValue = {
    compare: function (x) {
        return function (y) {
            return compare(x)(y);
        };
    },
    Eq0: function () {
        return eqAlignContentValue;
    }
};
var columnReverse = /* #__PURE__ */ fromString("column-reverse");
var column = /* #__PURE__ */ fromString("column");
var centerJustifyContentValue = {
    center: /* #__PURE__ */ fromString2("center")
};
var centerAlignSelfValue = {
    center: /* #__PURE__ */ fromString3("center")
};
var centerAlignItemsValue = {
    center: /* #__PURE__ */ fromString4("center")
};
var centerAlignContentValue = {
    center: /* #__PURE__ */ fromString5("center")
};
var baselineAlignSelfValue = {
    baseline: /* #__PURE__ */ fromString3("baseline")
};
var baselineAlignItemsValue = {
    baseline: /* #__PURE__ */ fromString4("baseline")
};
var autoAlignSelfValue = {
    auto: /* #__PURE__ */ fromString3("auto")
};
var alignSelf = /* #__PURE__ */ CSS_Stylesheet.key(valAlignSelfValue)(/* #__PURE__ */ fromString1("align-self"));
var alignItems = /* #__PURE__ */ CSS_Stylesheet.key(valAlignItemsValue)(/* #__PURE__ */ fromString1("align-items"));
var alignContent = /* #__PURE__ */ CSS_Stylesheet.key(valAlignContentValue)(/* #__PURE__ */ fromString1("align-content"));
export {
    flexEnd,
    flexStart,
    spaceAround,
    spaceBetween,
    stretch,
    AlignContentValue,
    alignContent,
    AlignItemsValue,
    alignItems,
    AlignSelfValue,
    alignSelf,
    flex,
    flexBasis,
    FlexDirection,
    row,
    rowReverse,
    column,
    columnReverse,
    flexDirection,
    flexFlow,
    flexGrow,
    flexShrink,
    FlexWrap,
    nowrap,
    wrap,
    wrapReverse,
    flexWrap,
    JustifyContentValue,
    justifyContent,
    order,
    flexEndValue,
    flexStartValue,
    spaceAroundValue,
    spaceBetweenValue,
    stretchValue,
    eqAlignContentValue,
    ordAlignContentValue,
    isStringAlignContentValue,
    valAlignContentValue,
    otherAlignContentValue,
    inheritAlignContentValue,
    flexStartAlignContentValue,
    flexEndAlignContentValue,
    centerAlignContentValue,
    spaceBetweenAlignContentValue,
    spaceAroundAlignContentValue,
    stretchAlignContentValue,
    eqAlignItemsValue,
    ordAlignItemsValue,
    isStringAlignItemsValue,
    valAlignItemsValue,
    otherAlignItemsValue,
    inheritAlignItemsValue,
    baselineAlignItemsValue,
    centerAlignItemsValue,
    flexEndAlignItemsValue,
    flexStartAlignItemsValue,
    stretchAlignItemsValue,
    eqAlignSelfValue,
    ordAlignSelfValue,
    isStringAlignSelfValue,
    valAlignSelfValue,
    otherAlignSelfValue,
    inheritAlignSelfValue,
    autoAlignSelfValue,
    baselineAlignSelfValue,
    centerAlignSelfValue,
    flexEndAlignSelfValue,
    flexStartAlignSelfValue,
    stretchAlignSelfValue,
    eqFlexDirection,
    ordFlexDirection,
    valFlexDirection,
    otherFlexDirection,
    eqFlexWrap,
    ordFlexWrap,
    valFlexWrap,
    otherFlexWrap,
    eqJustifyContentValue,
    ordJustifyContentValue,
    isStringJustifyContentValue,
    valJustifyContentValue,
    otherJustifyContentValue,
    inheritJustifyContentValue,
    centerJustifyContentValue,
    flexEndJustifyContentValue,
    flexStartJustifyContentValue,
    spaceAroundJustifyContentValue,
    spaceBetweenJustifyContentValue
};
