// Generated by purs version 0.15.4
import * as CSS_Common from "../CSS.Common/index.js";
import * as CSS_Cursor from "../CSS.Cursor/index.js";
import * as CSS_Display from "../CSS.Display/index.js";
import * as CSS_Flexbox from "../CSS.Flexbox/index.js";
import * as CSS_Property from "../CSS.Property/index.js";
import * as CSS_Pseudo from "../CSS.Pseudo/index.js";
import * as CSS_Selector from "../CSS.Selector/index.js";
import * as CSS_Stylesheet from "../CSS.Stylesheet/index.js";
import * as CSS_Time from "../CSS.Time/index.js";
import * as CSS_Transition from "../CSS.Transition/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Toad_Css from "../Toad.Css/index.js";
import * as Toad_Css_Font from "../Toad.Css.Font/index.js";
import * as Toad_Html from "../Toad.Html/index.js";
var append = /* #__PURE__ */ Data_Semigroup.append(Toad_Css_Font.fontSemigroup);
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit)(CSS_Stylesheet.bindStyleM);
var center = /* #__PURE__ */ CSS_Common.center(CSS_Flexbox.centerJustifyContentValue);
var center1 = /* #__PURE__ */ CSS_Common.center(CSS_Flexbox.centerAlignItemsValue);
var prefixed = /* #__PURE__ */ CSS_Stylesheet.prefixed(CSS_Property.valString);
var append1 = /* #__PURE__ */ Data_Semigroup.append(CSS_Property.semigroupPrefixed);
var textClass = "navbar-button-text";
var selectedClass = "navbar-button-selected";
var selectContainerSelected = /* #__PURE__ */ CSS_Stylesheet.select(/* #__PURE__ */ CSS_Selector["with"](CSS_Selector.star)(/* #__PURE__ */ CSS_Selector.byClass(selectedClass)));
var font$prime = /* #__PURE__ */ (function () {
    return append(Data_Monoid.mempty(Toad_Css_Font.fontMonoid))(Toad_Css_Font.fontSize(Toad_Css_Font.FontSizeH3.value));
})();
var containerRules = /* #__PURE__ */ discard(/* #__PURE__ */ Toad_Css.definedIn("Toad.Navbar.Button.Style"))(function () {
    return discard(CSS_Cursor.cursor(CSS_Cursor.Pointer.value))(function () {
        return discard(CSS_Display.display(CSS_Display.flex))(function () {
            return discard(CSS_Flexbox.justifyContent(center))(function () {
                return discard(CSS_Flexbox.alignItems(center1))(function () {
                    return discard(CSS_Transition.transition("border")(CSS_Time.ms(100.0))(Toad_Css.kwapEasing)(CSS_Time.ms(0.0)))(function () {
                        return CSS_Stylesheet.select(CSS_Selector["with"](CSS_Selector.star)(CSS_Selector.byClass(textClass)))(discard(Toad_Css.definedIn("Toad.Navbar.Button.Style"))(function () {
                            return discard(prefixed(append1(CSS_Common.browsers)(new CSS_Property.Plain("user-select")))("none"))(function () {
                                return discard(Toad_Html.headingStyle(font$prime))(function () {
                                    return CSS_Transition.transition("font-size")(CSS_Time.ms(100.0))(Toad_Css.kwapEasing)(CSS_Time.ms(0.0));
                                });
                            });
                        }));
                    });
                });
            });
        });
    });
});
var containerHoverRules = /* #__PURE__ */ Toad_Css.definedIn("Toad.Navbar.Button.Style");
var selectedRules = /* #__PURE__ */ discard(containerHoverRules)(function () {
    return CSS_Stylesheet.select(CSS_Selector["with"](CSS_Selector.star)(CSS_Selector.byClass(textClass)))(Toad_Html.headingStyle(append(font$prime)(Toad_Css_Font.fontSize(Toad_Css_Font.FontSizeH2.value))));
});
var containerClickRules = /* #__PURE__ */ discard(/* #__PURE__ */ Toad_Css.definedIn("Toad.Navbar.Button.Style"))(function () {
    return discard(CSS_Transition.transition("border")(CSS_Time.ms(50.0))(Toad_Css.kwapEasing)(CSS_Time.ms(0.0)))(function () {
        return CSS_Stylesheet.select(CSS_Selector["with"](CSS_Selector.star)(CSS_Selector.byClass(textClass)))(discard(Toad_Css.definedIn("Toad.Navbar.Button.Style"))(function () {
            return CSS_Transition.transition("font-weight")(CSS_Time.ms(50.0))(Toad_Css.kwapEasing)(CSS_Time.ms(0.0));
        }));
    });
});
var containerClass = "navbar-button";
var selectContainer = /* #__PURE__ */ CSS_Stylesheet.select(/* #__PURE__ */ CSS_Selector["with"](CSS_Selector.star)(/* #__PURE__ */ CSS_Selector.byClass(containerClass)));
var selectContainerClick = /* #__PURE__ */ (function () {
    return CSS_Stylesheet.select(CSS_Selector["with"](CSS_Selector.star)(Toad_Css.refinements([ CSS_Selector.byClass(containerClass), CSS_Selector.pseudo("not(." + (selectedClass + ")")), CSS_Selector.pseudo("active") ])));
})();
var selectContainerHover = /* #__PURE__ */ CSS_Stylesheet.select(/* #__PURE__ */ CSS_Selector["with"](CSS_Selector.star)(/* #__PURE__ */ Toad_Css.refinements([ /* #__PURE__ */ CSS_Selector.byClass(containerClass), CSS_Pseudo.hover ])));
var global = /* #__PURE__ */ discard(/* #__PURE__ */ selectContainer(containerRules))(function () {
    return discard(selectContainerSelected(selectedRules))(function () {
        return discard(selectContainerHover(containerHoverRules))(function () {
            return selectContainerClick(containerClickRules);
        });
    });
});
export {
    selectedClass,
    containerClass,
    textClass,
    global
};
