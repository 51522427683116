// Generated by purs version 0.15.4
import * as Data_Ordering from "../Data.Ordering/index.js";
var AutocompleteOff = /* #__PURE__ */ (function () {
    function AutocompleteOff() {

    };
    AutocompleteOff.value = new AutocompleteOff();
    return AutocompleteOff;
})();
var AutocompleteOn = /* #__PURE__ */ (function () {
    function AutocompleteOn() {

    };
    AutocompleteOn.value = new AutocompleteOn();
    return AutocompleteOn;
})();
var AutocompleteName = /* #__PURE__ */ (function () {
    function AutocompleteName() {

    };
    AutocompleteName.value = new AutocompleteName();
    return AutocompleteName;
})();
var AutocompleteHonorificPrefix = /* #__PURE__ */ (function () {
    function AutocompleteHonorificPrefix() {

    };
    AutocompleteHonorificPrefix.value = new AutocompleteHonorificPrefix();
    return AutocompleteHonorificPrefix;
})();
var AutocompleteGivenName = /* #__PURE__ */ (function () {
    function AutocompleteGivenName() {

    };
    AutocompleteGivenName.value = new AutocompleteGivenName();
    return AutocompleteGivenName;
})();
var AutocompleteAdditionalName = /* #__PURE__ */ (function () {
    function AutocompleteAdditionalName() {

    };
    AutocompleteAdditionalName.value = new AutocompleteAdditionalName();
    return AutocompleteAdditionalName;
})();
var AutocompleteFamilyName = /* #__PURE__ */ (function () {
    function AutocompleteFamilyName() {

    };
    AutocompleteFamilyName.value = new AutocompleteFamilyName();
    return AutocompleteFamilyName;
})();
var AutocompleteHonorificSuffix = /* #__PURE__ */ (function () {
    function AutocompleteHonorificSuffix() {

    };
    AutocompleteHonorificSuffix.value = new AutocompleteHonorificSuffix();
    return AutocompleteHonorificSuffix;
})();
var AutocompleteNickname = /* #__PURE__ */ (function () {
    function AutocompleteNickname() {

    };
    AutocompleteNickname.value = new AutocompleteNickname();
    return AutocompleteNickname;
})();
var AutocompleteEmail = /* #__PURE__ */ (function () {
    function AutocompleteEmail() {

    };
    AutocompleteEmail.value = new AutocompleteEmail();
    return AutocompleteEmail;
})();
var AutocompleteUsername = /* #__PURE__ */ (function () {
    function AutocompleteUsername() {

    };
    AutocompleteUsername.value = new AutocompleteUsername();
    return AutocompleteUsername;
})();
var AutocompleteNewPassword = /* #__PURE__ */ (function () {
    function AutocompleteNewPassword() {

    };
    AutocompleteNewPassword.value = new AutocompleteNewPassword();
    return AutocompleteNewPassword;
})();
var AutocompleteCurrentPassword = /* #__PURE__ */ (function () {
    function AutocompleteCurrentPassword() {

    };
    AutocompleteCurrentPassword.value = new AutocompleteCurrentPassword();
    return AutocompleteCurrentPassword;
})();
var AutocompleteOneTimeCode = /* #__PURE__ */ (function () {
    function AutocompleteOneTimeCode() {

    };
    AutocompleteOneTimeCode.value = new AutocompleteOneTimeCode();
    return AutocompleteOneTimeCode;
})();
var AutocompleteOrganizationTitle = /* #__PURE__ */ (function () {
    function AutocompleteOrganizationTitle() {

    };
    AutocompleteOrganizationTitle.value = new AutocompleteOrganizationTitle();
    return AutocompleteOrganizationTitle;
})();
var AutocompleteOrganization = /* #__PURE__ */ (function () {
    function AutocompleteOrganization() {

    };
    AutocompleteOrganization.value = new AutocompleteOrganization();
    return AutocompleteOrganization;
})();
var AutocompleteStreetAddress = /* #__PURE__ */ (function () {
    function AutocompleteStreetAddress() {

    };
    AutocompleteStreetAddress.value = new AutocompleteStreetAddress();
    return AutocompleteStreetAddress;
})();
var AutocompleteAddressLine1 = /* #__PURE__ */ (function () {
    function AutocompleteAddressLine1() {

    };
    AutocompleteAddressLine1.value = new AutocompleteAddressLine1();
    return AutocompleteAddressLine1;
})();
var AutocompleteAddressLine2 = /* #__PURE__ */ (function () {
    function AutocompleteAddressLine2() {

    };
    AutocompleteAddressLine2.value = new AutocompleteAddressLine2();
    return AutocompleteAddressLine2;
})();
var AutocompleteAddressLine3 = /* #__PURE__ */ (function () {
    function AutocompleteAddressLine3() {

    };
    AutocompleteAddressLine3.value = new AutocompleteAddressLine3();
    return AutocompleteAddressLine3;
})();
var AutocompleteAddressLevel1 = /* #__PURE__ */ (function () {
    function AutocompleteAddressLevel1() {

    };
    AutocompleteAddressLevel1.value = new AutocompleteAddressLevel1();
    return AutocompleteAddressLevel1;
})();
var AutocompleteAddressLevel2 = /* #__PURE__ */ (function () {
    function AutocompleteAddressLevel2() {

    };
    AutocompleteAddressLevel2.value = new AutocompleteAddressLevel2();
    return AutocompleteAddressLevel2;
})();
var AutocompleteAddressLevel3 = /* #__PURE__ */ (function () {
    function AutocompleteAddressLevel3() {

    };
    AutocompleteAddressLevel3.value = new AutocompleteAddressLevel3();
    return AutocompleteAddressLevel3;
})();
var AutocompleteAddressLevel4 = /* #__PURE__ */ (function () {
    function AutocompleteAddressLevel4() {

    };
    AutocompleteAddressLevel4.value = new AutocompleteAddressLevel4();
    return AutocompleteAddressLevel4;
})();
var AutocompleteCountry = /* #__PURE__ */ (function () {
    function AutocompleteCountry() {

    };
    AutocompleteCountry.value = new AutocompleteCountry();
    return AutocompleteCountry;
})();
var AutocompleteCountryName = /* #__PURE__ */ (function () {
    function AutocompleteCountryName() {

    };
    AutocompleteCountryName.value = new AutocompleteCountryName();
    return AutocompleteCountryName;
})();
var AutocompletePostalCode = /* #__PURE__ */ (function () {
    function AutocompletePostalCode() {

    };
    AutocompletePostalCode.value = new AutocompletePostalCode();
    return AutocompletePostalCode;
})();
var AutocompleteCreditCardName = /* #__PURE__ */ (function () {
    function AutocompleteCreditCardName() {

    };
    AutocompleteCreditCardName.value = new AutocompleteCreditCardName();
    return AutocompleteCreditCardName;
})();
var AutocompleteCreditCardGivenName = /* #__PURE__ */ (function () {
    function AutocompleteCreditCardGivenName() {

    };
    AutocompleteCreditCardGivenName.value = new AutocompleteCreditCardGivenName();
    return AutocompleteCreditCardGivenName;
})();
var AutocompleteCreditCardAdditionalName = /* #__PURE__ */ (function () {
    function AutocompleteCreditCardAdditionalName() {

    };
    AutocompleteCreditCardAdditionalName.value = new AutocompleteCreditCardAdditionalName();
    return AutocompleteCreditCardAdditionalName;
})();
var AutocompleteCreditCardFamilyName = /* #__PURE__ */ (function () {
    function AutocompleteCreditCardFamilyName() {

    };
    AutocompleteCreditCardFamilyName.value = new AutocompleteCreditCardFamilyName();
    return AutocompleteCreditCardFamilyName;
})();
var AutocompleteCreditCardNumber = /* #__PURE__ */ (function () {
    function AutocompleteCreditCardNumber() {

    };
    AutocompleteCreditCardNumber.value = new AutocompleteCreditCardNumber();
    return AutocompleteCreditCardNumber;
})();
var AutocompleteCreditCardExpiration = /* #__PURE__ */ (function () {
    function AutocompleteCreditCardExpiration() {

    };
    AutocompleteCreditCardExpiration.value = new AutocompleteCreditCardExpiration();
    return AutocompleteCreditCardExpiration;
})();
var AutocompleteCreditCardExpirationMonth = /* #__PURE__ */ (function () {
    function AutocompleteCreditCardExpirationMonth() {

    };
    AutocompleteCreditCardExpirationMonth.value = new AutocompleteCreditCardExpirationMonth();
    return AutocompleteCreditCardExpirationMonth;
})();
var AutocompleteCreditCardExpirationYear = /* #__PURE__ */ (function () {
    function AutocompleteCreditCardExpirationYear() {

    };
    AutocompleteCreditCardExpirationYear.value = new AutocompleteCreditCardExpirationYear();
    return AutocompleteCreditCardExpirationYear;
})();
var AutocompleteCreditCardSecurityCode = /* #__PURE__ */ (function () {
    function AutocompleteCreditCardSecurityCode() {

    };
    AutocompleteCreditCardSecurityCode.value = new AutocompleteCreditCardSecurityCode();
    return AutocompleteCreditCardSecurityCode;
})();
var AutocompleteCreditCardType = /* #__PURE__ */ (function () {
    function AutocompleteCreditCardType() {

    };
    AutocompleteCreditCardType.value = new AutocompleteCreditCardType();
    return AutocompleteCreditCardType;
})();
var AutocompleteTransactionCurrency = /* #__PURE__ */ (function () {
    function AutocompleteTransactionCurrency() {

    };
    AutocompleteTransactionCurrency.value = new AutocompleteTransactionCurrency();
    return AutocompleteTransactionCurrency;
})();
var AutocompleteTransactionAmount = /* #__PURE__ */ (function () {
    function AutocompleteTransactionAmount() {

    };
    AutocompleteTransactionAmount.value = new AutocompleteTransactionAmount();
    return AutocompleteTransactionAmount;
})();
var AutocompleteLanguage = /* #__PURE__ */ (function () {
    function AutocompleteLanguage() {

    };
    AutocompleteLanguage.value = new AutocompleteLanguage();
    return AutocompleteLanguage;
})();
var AutocompleteBirthday = /* #__PURE__ */ (function () {
    function AutocompleteBirthday() {

    };
    AutocompleteBirthday.value = new AutocompleteBirthday();
    return AutocompleteBirthday;
})();
var AutocompleteBirthdayDay = /* #__PURE__ */ (function () {
    function AutocompleteBirthdayDay() {

    };
    AutocompleteBirthdayDay.value = new AutocompleteBirthdayDay();
    return AutocompleteBirthdayDay;
})();
var AutocompleteBirthdayMonth = /* #__PURE__ */ (function () {
    function AutocompleteBirthdayMonth() {

    };
    AutocompleteBirthdayMonth.value = new AutocompleteBirthdayMonth();
    return AutocompleteBirthdayMonth;
})();
var AutocompleteBirthdayYear = /* #__PURE__ */ (function () {
    function AutocompleteBirthdayYear() {

    };
    AutocompleteBirthdayYear.value = new AutocompleteBirthdayYear();
    return AutocompleteBirthdayYear;
})();
var AutocompleteSex = /* #__PURE__ */ (function () {
    function AutocompleteSex() {

    };
    AutocompleteSex.value = new AutocompleteSex();
    return AutocompleteSex;
})();
var AutocompleteTelephone = /* #__PURE__ */ (function () {
    function AutocompleteTelephone() {

    };
    AutocompleteTelephone.value = new AutocompleteTelephone();
    return AutocompleteTelephone;
})();
var AutocompleteTelephoneCountryCode = /* #__PURE__ */ (function () {
    function AutocompleteTelephoneCountryCode() {

    };
    AutocompleteTelephoneCountryCode.value = new AutocompleteTelephoneCountryCode();
    return AutocompleteTelephoneCountryCode;
})();
var AutocompleteTelephoneNational = /* #__PURE__ */ (function () {
    function AutocompleteTelephoneNational() {

    };
    AutocompleteTelephoneNational.value = new AutocompleteTelephoneNational();
    return AutocompleteTelephoneNational;
})();
var AutocompleteTelephoneAreaCode = /* #__PURE__ */ (function () {
    function AutocompleteTelephoneAreaCode() {

    };
    AutocompleteTelephoneAreaCode.value = new AutocompleteTelephoneAreaCode();
    return AutocompleteTelephoneAreaCode;
})();
var AutocompleteTelephoneLocal = /* #__PURE__ */ (function () {
    function AutocompleteTelephoneLocal() {

    };
    AutocompleteTelephoneLocal.value = new AutocompleteTelephoneLocal();
    return AutocompleteTelephoneLocal;
})();
var AutocompleteTelephoneLocalPrefix = /* #__PURE__ */ (function () {
    function AutocompleteTelephoneLocalPrefix() {

    };
    AutocompleteTelephoneLocalPrefix.value = new AutocompleteTelephoneLocalPrefix();
    return AutocompleteTelephoneLocalPrefix;
})();
var AutocompleteTelephoneLocalSuffix = /* #__PURE__ */ (function () {
    function AutocompleteTelephoneLocalSuffix() {

    };
    AutocompleteTelephoneLocalSuffix.value = new AutocompleteTelephoneLocalSuffix();
    return AutocompleteTelephoneLocalSuffix;
})();
var AutocompleteTelephoneExtension = /* #__PURE__ */ (function () {
    function AutocompleteTelephoneExtension() {

    };
    AutocompleteTelephoneExtension.value = new AutocompleteTelephoneExtension();
    return AutocompleteTelephoneExtension;
})();
var AutocompleteIMPP = /* #__PURE__ */ (function () {
    function AutocompleteIMPP() {

    };
    AutocompleteIMPP.value = new AutocompleteIMPP();
    return AutocompleteIMPP;
})();
var AutocompleteURL = /* #__PURE__ */ (function () {
    function AutocompleteURL() {

    };
    AutocompleteURL.value = new AutocompleteURL();
    return AutocompleteURL;
})();
var AutocompletePhoto = /* #__PURE__ */ (function () {
    function AutocompletePhoto() {

    };
    AutocompletePhoto.value = new AutocompletePhoto();
    return AutocompletePhoto;
})();
var renderAutocompleteType = function (v) {
    if (v instanceof AutocompleteOff) {
        return "off";
    };
    if (v instanceof AutocompleteOn) {
        return "on";
    };
    if (v instanceof AutocompleteName) {
        return "name";
    };
    if (v instanceof AutocompleteHonorificPrefix) {
        return "honorific-prefix";
    };
    if (v instanceof AutocompleteGivenName) {
        return "given-name";
    };
    if (v instanceof AutocompleteAdditionalName) {
        return "additional-name";
    };
    if (v instanceof AutocompleteFamilyName) {
        return "family-name";
    };
    if (v instanceof AutocompleteHonorificSuffix) {
        return "honorific-suffix";
    };
    if (v instanceof AutocompleteNickname) {
        return "nickname";
    };
    if (v instanceof AutocompleteEmail) {
        return "email";
    };
    if (v instanceof AutocompleteUsername) {
        return "username";
    };
    if (v instanceof AutocompleteNewPassword) {
        return "new-password";
    };
    if (v instanceof AutocompleteCurrentPassword) {
        return "current-password";
    };
    if (v instanceof AutocompleteOneTimeCode) {
        return "one-time-code";
    };
    if (v instanceof AutocompleteOrganizationTitle) {
        return "organization-title";
    };
    if (v instanceof AutocompleteOrganization) {
        return "organization";
    };
    if (v instanceof AutocompleteStreetAddress) {
        return "street-address";
    };
    if (v instanceof AutocompleteAddressLine1) {
        return "address-line1";
    };
    if (v instanceof AutocompleteAddressLine2) {
        return "address-line2";
    };
    if (v instanceof AutocompleteAddressLine3) {
        return "address-line3";
    };
    if (v instanceof AutocompleteAddressLevel1) {
        return "address-level1";
    };
    if (v instanceof AutocompleteAddressLevel2) {
        return "address-level2";
    };
    if (v instanceof AutocompleteAddressLevel3) {
        return "address-level3";
    };
    if (v instanceof AutocompleteAddressLevel4) {
        return "address-level4";
    };
    if (v instanceof AutocompleteCountry) {
        return "country";
    };
    if (v instanceof AutocompleteCountryName) {
        return "country-name";
    };
    if (v instanceof AutocompletePostalCode) {
        return "postal-code";
    };
    if (v instanceof AutocompleteCreditCardName) {
        return "cc-name";
    };
    if (v instanceof AutocompleteCreditCardGivenName) {
        return "cc-given-name";
    };
    if (v instanceof AutocompleteCreditCardAdditionalName) {
        return "cc-additional-name";
    };
    if (v instanceof AutocompleteCreditCardFamilyName) {
        return "cc-family-name";
    };
    if (v instanceof AutocompleteCreditCardNumber) {
        return "cc-number";
    };
    if (v instanceof AutocompleteCreditCardExpiration) {
        return "cc-exp";
    };
    if (v instanceof AutocompleteCreditCardExpirationMonth) {
        return "cc-exp-month";
    };
    if (v instanceof AutocompleteCreditCardExpirationYear) {
        return "cc-exp-year";
    };
    if (v instanceof AutocompleteCreditCardSecurityCode) {
        return "cc-csc";
    };
    if (v instanceof AutocompleteCreditCardType) {
        return "cc-type";
    };
    if (v instanceof AutocompleteTransactionCurrency) {
        return "transaction-currency";
    };
    if (v instanceof AutocompleteTransactionAmount) {
        return "transaction-amount";
    };
    if (v instanceof AutocompleteLanguage) {
        return "language";
    };
    if (v instanceof AutocompleteBirthday) {
        return "bday";
    };
    if (v instanceof AutocompleteBirthdayDay) {
        return "bday-day";
    };
    if (v instanceof AutocompleteBirthdayMonth) {
        return "bday-month";
    };
    if (v instanceof AutocompleteBirthdayYear) {
        return "bday-year";
    };
    if (v instanceof AutocompleteSex) {
        return "sex";
    };
    if (v instanceof AutocompleteTelephone) {
        return "tel";
    };
    if (v instanceof AutocompleteTelephoneCountryCode) {
        return "tel-country-code";
    };
    if (v instanceof AutocompleteTelephoneNational) {
        return "tel-national";
    };
    if (v instanceof AutocompleteTelephoneAreaCode) {
        return "telarea-code";
    };
    if (v instanceof AutocompleteTelephoneLocal) {
        return "tel-local";
    };
    if (v instanceof AutocompleteTelephoneLocalPrefix) {
        return "tel-local-prefix";
    };
    if (v instanceof AutocompleteTelephoneLocalSuffix) {
        return "tel-local-suffix";
    };
    if (v instanceof AutocompleteTelephoneExtension) {
        return "tel-extension";
    };
    if (v instanceof AutocompleteIMPP) {
        return "impp";
    };
    if (v instanceof AutocompleteURL) {
        return "url";
    };
    if (v instanceof AutocompletePhoto) {
        return "photo";
    };
    throw new Error("Failed pattern match at DOM.HTML.Indexed.AutocompleteType (line 67, column 26 - line 123, column 31): " + [ v.constructor.name ]);
};
var eqAutocompleteType = {
    eq: function (x) {
        return function (y) {
            if (x instanceof AutocompleteOff && y instanceof AutocompleteOff) {
                return true;
            };
            if (x instanceof AutocompleteOn && y instanceof AutocompleteOn) {
                return true;
            };
            if (x instanceof AutocompleteName && y instanceof AutocompleteName) {
                return true;
            };
            if (x instanceof AutocompleteHonorificPrefix && y instanceof AutocompleteHonorificPrefix) {
                return true;
            };
            if (x instanceof AutocompleteGivenName && y instanceof AutocompleteGivenName) {
                return true;
            };
            if (x instanceof AutocompleteAdditionalName && y instanceof AutocompleteAdditionalName) {
                return true;
            };
            if (x instanceof AutocompleteFamilyName && y instanceof AutocompleteFamilyName) {
                return true;
            };
            if (x instanceof AutocompleteHonorificSuffix && y instanceof AutocompleteHonorificSuffix) {
                return true;
            };
            if (x instanceof AutocompleteNickname && y instanceof AutocompleteNickname) {
                return true;
            };
            if (x instanceof AutocompleteEmail && y instanceof AutocompleteEmail) {
                return true;
            };
            if (x instanceof AutocompleteUsername && y instanceof AutocompleteUsername) {
                return true;
            };
            if (x instanceof AutocompleteNewPassword && y instanceof AutocompleteNewPassword) {
                return true;
            };
            if (x instanceof AutocompleteCurrentPassword && y instanceof AutocompleteCurrentPassword) {
                return true;
            };
            if (x instanceof AutocompleteOneTimeCode && y instanceof AutocompleteOneTimeCode) {
                return true;
            };
            if (x instanceof AutocompleteOrganizationTitle && y instanceof AutocompleteOrganizationTitle) {
                return true;
            };
            if (x instanceof AutocompleteOrganization && y instanceof AutocompleteOrganization) {
                return true;
            };
            if (x instanceof AutocompleteStreetAddress && y instanceof AutocompleteStreetAddress) {
                return true;
            };
            if (x instanceof AutocompleteAddressLine1 && y instanceof AutocompleteAddressLine1) {
                return true;
            };
            if (x instanceof AutocompleteAddressLine2 && y instanceof AutocompleteAddressLine2) {
                return true;
            };
            if (x instanceof AutocompleteAddressLine3 && y instanceof AutocompleteAddressLine3) {
                return true;
            };
            if (x instanceof AutocompleteAddressLevel1 && y instanceof AutocompleteAddressLevel1) {
                return true;
            };
            if (x instanceof AutocompleteAddressLevel2 && y instanceof AutocompleteAddressLevel2) {
                return true;
            };
            if (x instanceof AutocompleteAddressLevel3 && y instanceof AutocompleteAddressLevel3) {
                return true;
            };
            if (x instanceof AutocompleteAddressLevel4 && y instanceof AutocompleteAddressLevel4) {
                return true;
            };
            if (x instanceof AutocompleteCountry && y instanceof AutocompleteCountry) {
                return true;
            };
            if (x instanceof AutocompleteCountryName && y instanceof AutocompleteCountryName) {
                return true;
            };
            if (x instanceof AutocompletePostalCode && y instanceof AutocompletePostalCode) {
                return true;
            };
            if (x instanceof AutocompleteCreditCardName && y instanceof AutocompleteCreditCardName) {
                return true;
            };
            if (x instanceof AutocompleteCreditCardGivenName && y instanceof AutocompleteCreditCardGivenName) {
                return true;
            };
            if (x instanceof AutocompleteCreditCardAdditionalName && y instanceof AutocompleteCreditCardAdditionalName) {
                return true;
            };
            if (x instanceof AutocompleteCreditCardFamilyName && y instanceof AutocompleteCreditCardFamilyName) {
                return true;
            };
            if (x instanceof AutocompleteCreditCardNumber && y instanceof AutocompleteCreditCardNumber) {
                return true;
            };
            if (x instanceof AutocompleteCreditCardExpiration && y instanceof AutocompleteCreditCardExpiration) {
                return true;
            };
            if (x instanceof AutocompleteCreditCardExpirationMonth && y instanceof AutocompleteCreditCardExpirationMonth) {
                return true;
            };
            if (x instanceof AutocompleteCreditCardExpirationYear && y instanceof AutocompleteCreditCardExpirationYear) {
                return true;
            };
            if (x instanceof AutocompleteCreditCardSecurityCode && y instanceof AutocompleteCreditCardSecurityCode) {
                return true;
            };
            if (x instanceof AutocompleteCreditCardType && y instanceof AutocompleteCreditCardType) {
                return true;
            };
            if (x instanceof AutocompleteTransactionCurrency && y instanceof AutocompleteTransactionCurrency) {
                return true;
            };
            if (x instanceof AutocompleteTransactionAmount && y instanceof AutocompleteTransactionAmount) {
                return true;
            };
            if (x instanceof AutocompleteLanguage && y instanceof AutocompleteLanguage) {
                return true;
            };
            if (x instanceof AutocompleteBirthday && y instanceof AutocompleteBirthday) {
                return true;
            };
            if (x instanceof AutocompleteBirthdayDay && y instanceof AutocompleteBirthdayDay) {
                return true;
            };
            if (x instanceof AutocompleteBirthdayMonth && y instanceof AutocompleteBirthdayMonth) {
                return true;
            };
            if (x instanceof AutocompleteBirthdayYear && y instanceof AutocompleteBirthdayYear) {
                return true;
            };
            if (x instanceof AutocompleteSex && y instanceof AutocompleteSex) {
                return true;
            };
            if (x instanceof AutocompleteTelephone && y instanceof AutocompleteTelephone) {
                return true;
            };
            if (x instanceof AutocompleteTelephoneCountryCode && y instanceof AutocompleteTelephoneCountryCode) {
                return true;
            };
            if (x instanceof AutocompleteTelephoneNational && y instanceof AutocompleteTelephoneNational) {
                return true;
            };
            if (x instanceof AutocompleteTelephoneAreaCode && y instanceof AutocompleteTelephoneAreaCode) {
                return true;
            };
            if (x instanceof AutocompleteTelephoneLocal && y instanceof AutocompleteTelephoneLocal) {
                return true;
            };
            if (x instanceof AutocompleteTelephoneLocalPrefix && y instanceof AutocompleteTelephoneLocalPrefix) {
                return true;
            };
            if (x instanceof AutocompleteTelephoneLocalSuffix && y instanceof AutocompleteTelephoneLocalSuffix) {
                return true;
            };
            if (x instanceof AutocompleteTelephoneExtension && y instanceof AutocompleteTelephoneExtension) {
                return true;
            };
            if (x instanceof AutocompleteIMPP && y instanceof AutocompleteIMPP) {
                return true;
            };
            if (x instanceof AutocompleteURL && y instanceof AutocompleteURL) {
                return true;
            };
            if (x instanceof AutocompletePhoto && y instanceof AutocompletePhoto) {
                return true;
            };
            return false;
        };
    }
};
var ordAutocompleteType = {
    compare: function (x) {
        return function (y) {
            if (x instanceof AutocompleteOff && y instanceof AutocompleteOff) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AutocompleteOff) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AutocompleteOff) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AutocompleteOn && y instanceof AutocompleteOn) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AutocompleteOn) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AutocompleteOn) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AutocompleteName && y instanceof AutocompleteName) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AutocompleteName) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AutocompleteName) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AutocompleteHonorificPrefix && y instanceof AutocompleteHonorificPrefix) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AutocompleteHonorificPrefix) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AutocompleteHonorificPrefix) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AutocompleteGivenName && y instanceof AutocompleteGivenName) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AutocompleteGivenName) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AutocompleteGivenName) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AutocompleteAdditionalName && y instanceof AutocompleteAdditionalName) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AutocompleteAdditionalName) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AutocompleteAdditionalName) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AutocompleteFamilyName && y instanceof AutocompleteFamilyName) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AutocompleteFamilyName) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AutocompleteFamilyName) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AutocompleteHonorificSuffix && y instanceof AutocompleteHonorificSuffix) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AutocompleteHonorificSuffix) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AutocompleteHonorificSuffix) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AutocompleteNickname && y instanceof AutocompleteNickname) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AutocompleteNickname) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AutocompleteNickname) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AutocompleteEmail && y instanceof AutocompleteEmail) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AutocompleteEmail) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AutocompleteEmail) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AutocompleteUsername && y instanceof AutocompleteUsername) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AutocompleteUsername) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AutocompleteUsername) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AutocompleteNewPassword && y instanceof AutocompleteNewPassword) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AutocompleteNewPassword) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AutocompleteNewPassword) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AutocompleteCurrentPassword && y instanceof AutocompleteCurrentPassword) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AutocompleteCurrentPassword) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AutocompleteCurrentPassword) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AutocompleteOneTimeCode && y instanceof AutocompleteOneTimeCode) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AutocompleteOneTimeCode) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AutocompleteOneTimeCode) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AutocompleteOrganizationTitle && y instanceof AutocompleteOrganizationTitle) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AutocompleteOrganizationTitle) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AutocompleteOrganizationTitle) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AutocompleteOrganization && y instanceof AutocompleteOrganization) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AutocompleteOrganization) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AutocompleteOrganization) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AutocompleteStreetAddress && y instanceof AutocompleteStreetAddress) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AutocompleteStreetAddress) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AutocompleteStreetAddress) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AutocompleteAddressLine1 && y instanceof AutocompleteAddressLine1) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AutocompleteAddressLine1) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AutocompleteAddressLine1) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AutocompleteAddressLine2 && y instanceof AutocompleteAddressLine2) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AutocompleteAddressLine2) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AutocompleteAddressLine2) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AutocompleteAddressLine3 && y instanceof AutocompleteAddressLine3) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AutocompleteAddressLine3) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AutocompleteAddressLine3) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AutocompleteAddressLevel1 && y instanceof AutocompleteAddressLevel1) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AutocompleteAddressLevel1) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AutocompleteAddressLevel1) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AutocompleteAddressLevel2 && y instanceof AutocompleteAddressLevel2) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AutocompleteAddressLevel2) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AutocompleteAddressLevel2) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AutocompleteAddressLevel3 && y instanceof AutocompleteAddressLevel3) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AutocompleteAddressLevel3) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AutocompleteAddressLevel3) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AutocompleteAddressLevel4 && y instanceof AutocompleteAddressLevel4) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AutocompleteAddressLevel4) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AutocompleteAddressLevel4) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AutocompleteCountry && y instanceof AutocompleteCountry) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AutocompleteCountry) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AutocompleteCountry) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AutocompleteCountryName && y instanceof AutocompleteCountryName) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AutocompleteCountryName) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AutocompleteCountryName) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AutocompletePostalCode && y instanceof AutocompletePostalCode) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AutocompletePostalCode) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AutocompletePostalCode) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AutocompleteCreditCardName && y instanceof AutocompleteCreditCardName) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AutocompleteCreditCardName) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AutocompleteCreditCardName) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AutocompleteCreditCardGivenName && y instanceof AutocompleteCreditCardGivenName) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AutocompleteCreditCardGivenName) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AutocompleteCreditCardGivenName) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AutocompleteCreditCardAdditionalName && y instanceof AutocompleteCreditCardAdditionalName) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AutocompleteCreditCardAdditionalName) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AutocompleteCreditCardAdditionalName) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AutocompleteCreditCardFamilyName && y instanceof AutocompleteCreditCardFamilyName) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AutocompleteCreditCardFamilyName) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AutocompleteCreditCardFamilyName) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AutocompleteCreditCardNumber && y instanceof AutocompleteCreditCardNumber) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AutocompleteCreditCardNumber) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AutocompleteCreditCardNumber) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AutocompleteCreditCardExpiration && y instanceof AutocompleteCreditCardExpiration) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AutocompleteCreditCardExpiration) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AutocompleteCreditCardExpiration) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AutocompleteCreditCardExpirationMonth && y instanceof AutocompleteCreditCardExpirationMonth) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AutocompleteCreditCardExpirationMonth) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AutocompleteCreditCardExpirationMonth) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AutocompleteCreditCardExpirationYear && y instanceof AutocompleteCreditCardExpirationYear) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AutocompleteCreditCardExpirationYear) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AutocompleteCreditCardExpirationYear) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AutocompleteCreditCardSecurityCode && y instanceof AutocompleteCreditCardSecurityCode) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AutocompleteCreditCardSecurityCode) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AutocompleteCreditCardSecurityCode) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AutocompleteCreditCardType && y instanceof AutocompleteCreditCardType) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AutocompleteCreditCardType) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AutocompleteCreditCardType) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AutocompleteTransactionCurrency && y instanceof AutocompleteTransactionCurrency) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AutocompleteTransactionCurrency) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AutocompleteTransactionCurrency) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AutocompleteTransactionAmount && y instanceof AutocompleteTransactionAmount) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AutocompleteTransactionAmount) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AutocompleteTransactionAmount) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AutocompleteLanguage && y instanceof AutocompleteLanguage) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AutocompleteLanguage) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AutocompleteLanguage) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AutocompleteBirthday && y instanceof AutocompleteBirthday) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AutocompleteBirthday) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AutocompleteBirthday) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AutocompleteBirthdayDay && y instanceof AutocompleteBirthdayDay) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AutocompleteBirthdayDay) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AutocompleteBirthdayDay) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AutocompleteBirthdayMonth && y instanceof AutocompleteBirthdayMonth) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AutocompleteBirthdayMonth) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AutocompleteBirthdayMonth) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AutocompleteBirthdayYear && y instanceof AutocompleteBirthdayYear) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AutocompleteBirthdayYear) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AutocompleteBirthdayYear) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AutocompleteSex && y instanceof AutocompleteSex) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AutocompleteSex) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AutocompleteSex) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AutocompleteTelephone && y instanceof AutocompleteTelephone) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AutocompleteTelephone) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AutocompleteTelephone) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AutocompleteTelephoneCountryCode && y instanceof AutocompleteTelephoneCountryCode) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AutocompleteTelephoneCountryCode) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AutocompleteTelephoneCountryCode) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AutocompleteTelephoneNational && y instanceof AutocompleteTelephoneNational) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AutocompleteTelephoneNational) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AutocompleteTelephoneNational) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AutocompleteTelephoneAreaCode && y instanceof AutocompleteTelephoneAreaCode) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AutocompleteTelephoneAreaCode) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AutocompleteTelephoneAreaCode) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AutocompleteTelephoneLocal && y instanceof AutocompleteTelephoneLocal) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AutocompleteTelephoneLocal) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AutocompleteTelephoneLocal) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AutocompleteTelephoneLocalPrefix && y instanceof AutocompleteTelephoneLocalPrefix) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AutocompleteTelephoneLocalPrefix) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AutocompleteTelephoneLocalPrefix) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AutocompleteTelephoneLocalSuffix && y instanceof AutocompleteTelephoneLocalSuffix) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AutocompleteTelephoneLocalSuffix) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AutocompleteTelephoneLocalSuffix) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AutocompleteTelephoneExtension && y instanceof AutocompleteTelephoneExtension) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AutocompleteTelephoneExtension) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AutocompleteTelephoneExtension) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AutocompleteIMPP && y instanceof AutocompleteIMPP) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AutocompleteIMPP) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AutocompleteIMPP) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AutocompleteURL && y instanceof AutocompleteURL) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AutocompleteURL) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AutocompleteURL) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AutocompletePhoto && y instanceof AutocompletePhoto) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at DOM.HTML.Indexed.AutocompleteType (line 0, column 0 - line 0, column 0): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqAutocompleteType;
    }
};
export {
    AutocompleteOff,
    AutocompleteOn,
    AutocompleteName,
    AutocompleteHonorificPrefix,
    AutocompleteGivenName,
    AutocompleteAdditionalName,
    AutocompleteFamilyName,
    AutocompleteHonorificSuffix,
    AutocompleteNickname,
    AutocompleteEmail,
    AutocompleteUsername,
    AutocompleteNewPassword,
    AutocompleteCurrentPassword,
    AutocompleteOneTimeCode,
    AutocompleteOrganizationTitle,
    AutocompleteOrganization,
    AutocompleteStreetAddress,
    AutocompleteAddressLine1,
    AutocompleteAddressLine2,
    AutocompleteAddressLine3,
    AutocompleteAddressLevel1,
    AutocompleteAddressLevel2,
    AutocompleteAddressLevel3,
    AutocompleteAddressLevel4,
    AutocompleteCountry,
    AutocompleteCountryName,
    AutocompletePostalCode,
    AutocompleteCreditCardName,
    AutocompleteCreditCardGivenName,
    AutocompleteCreditCardAdditionalName,
    AutocompleteCreditCardFamilyName,
    AutocompleteCreditCardNumber,
    AutocompleteCreditCardExpiration,
    AutocompleteCreditCardExpirationMonth,
    AutocompleteCreditCardExpirationYear,
    AutocompleteCreditCardSecurityCode,
    AutocompleteCreditCardType,
    AutocompleteTransactionCurrency,
    AutocompleteTransactionAmount,
    AutocompleteLanguage,
    AutocompleteBirthday,
    AutocompleteBirthdayDay,
    AutocompleteBirthdayMonth,
    AutocompleteBirthdayYear,
    AutocompleteSex,
    AutocompleteTelephone,
    AutocompleteTelephoneCountryCode,
    AutocompleteTelephoneNational,
    AutocompleteTelephoneAreaCode,
    AutocompleteTelephoneLocal,
    AutocompleteTelephoneLocalPrefix,
    AutocompleteTelephoneLocalSuffix,
    AutocompleteTelephoneExtension,
    AutocompleteIMPP,
    AutocompleteURL,
    AutocompletePhoto,
    renderAutocompleteType,
    eqAutocompleteType,
    ordAutocompleteType
};
