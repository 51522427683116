// Generated by purs version 0.15.4
import * as CSS_Property from "../CSS.Property/index.js";
import * as CSS_String from "../CSS.String/index.js";
import * as Control_Category from "../Control.Category/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
var fromString = /* #__PURE__ */ CSS_String.fromString(CSS_Property.isStringValue);
var visibleValue = {
    visible: /* #__PURE__ */ fromString("visible")
};
var visible = function (dict) {
    return dict.visible;
};
var urlValue = {
    url: function (s) {
        return fromString("url(\"" + (s + "\")"));
    }
};
var url = function (dict) {
    return dict.url;
};
var unsetValue = {
    unset: /* #__PURE__ */ fromString("unset")
};
var unset = function (dict) {
    return dict.unset;
};
var topValue = {
    top: /* #__PURE__ */ fromString("top")
};
var top = function (dict) {
    return dict.top;
};
var otherValue = {
    other: /* #__PURE__ */ Control_Category.identity(Control_Category.categoryFn)
};
var other = function (dict) {
    return dict.other;
};
var normalValue = {
    normal: /* #__PURE__ */ fromString("normal")
};
var normal = function (dict) {
    return dict.normal;
};
var noneValue = {
    none: /* #__PURE__ */ fromString("none")
};
var none = function (dict) {
    return dict.none;
};
var middleValue = {
    middle: /* #__PURE__ */ fromString("middle")
};
var middle = function (dict) {
    return dict.middle;
};
var initialValue = {
    initial: /* #__PURE__ */ fromString("initial")
};
var initial = function (dict) {
    return dict.initial;
};
var inheritValue = {
    inherit: /* #__PURE__ */ fromString("inherit")
};
var inherit = function (dict) {
    return dict.inherit;
};
var hiddenValue = {
    hidden: /* #__PURE__ */ fromString("hidden")
};
var hidden = function (dict) {
    return dict.hidden;
};
var centerValue = {
    center: /* #__PURE__ */ fromString("center")
};
var center = function (dict) {
    return dict.center;
};
var call = function (dictIsString) {
    var fromString1 = CSS_String.fromString(dictIsString);
    return function (dictMonoid) {
        var append1 = Data_Semigroup.append(dictMonoid.Semigroup0());
        return function (fn) {
            return function (arg) {
                return append1(fn)(append1(fromString1("("))(append1(arg)(fromString1(")"))));
            };
        };
    };
};
var browsers = /* #__PURE__ */ (function () {
    return new CSS_Property.Prefixed([ new Data_Tuple.Tuple("-webkit-", ""), new Data_Tuple.Tuple("-moz-", ""), new Data_Tuple.Tuple("-ms-", ""), new Data_Tuple.Tuple("-o-", ""), new Data_Tuple.Tuple("", "") ]);
})();
var bottomValue = {
    bottom: /* #__PURE__ */ fromString("bottom")
};
var bottom = function (dict) {
    return dict.bottom;
};
var baselineValue = {
    baseline: /* #__PURE__ */ fromString("baseline")
};
var baseline = function (dict) {
    return dict.baseline;
};
var autoValue = {
    auto: /* #__PURE__ */ fromString("auto")
};
var auto = function (dict) {
    return dict.auto;
};
var allValue = {
    all: /* #__PURE__ */ fromString("all")
};
var all = function (dict) {
    return dict.all;
};
export {
    all,
    auto,
    baseline,
    bottom,
    center,
    hidden,
    inherit,
    initial,
    middle,
    none,
    normal,
    other,
    top,
    unset,
    url,
    visible,
    browsers,
    call,
    allValue,
    autoValue,
    baselineValue,
    centerValue,
    inheritValue,
    normalValue,
    noneValue,
    visibleValue,
    hiddenValue,
    otherValue,
    initialValue,
    unsetValue,
    topValue,
    middleValue,
    bottomValue,
    urlValue
};
