// Generated by purs version 0.15.4
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Filterable from "../Data.Filterable/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Generic_Rep from "../Data.Generic.Rep/index.js";
import * as Data_Hashable from "../Data.Hashable/index.js";
import * as Data_HeytingAlgebra from "../Data.HeytingAlgebra/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Profunctor from "../Data.Profunctor/index.js";
import * as Data_Show_Generic from "../Data.Show.Generic/index.js";
import * as Data_String_Common from "../Data.String.Common/index.js";
import * as Routing_Duplex from "../Routing.Duplex/index.js";
import * as Routing_Duplex_Generic from "../Routing.Duplex.Generic/index.js";
import * as Routing_Duplex_Generic_Syntax from "../Routing.Duplex.Generic.Syntax/index.js";
import * as Toad_Concept from "../Toad.Concept/index.js";
import * as Toad_Navbar_Section from "../Toad.Navbar.Section/index.js";
var dimap = /* #__PURE__ */ Data_Profunctor.dimap(Routing_Duplex.profunctorRouteDuplex);
var OneIsSymbol = {
    reflectSymbol: function () {
        return "One";
    }
};
var genericShowConstructor = /* #__PURE__ */ Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsNoArguments);
var genericShowConstructor1 = /* #__PURE__ */ genericShowConstructor({
    reflectSymbol: function () {
        return "All";
    }
});
var HomeIsSymbol = {
    reflectSymbol: function () {
        return "Home";
    }
};
var ConceptsIsSymbol = {
    reflectSymbol: function () {
        return "Concepts";
    }
};
var BookIsSymbol = {
    reflectSymbol: function () {
        return "Book";
    }
};
var hash = /* #__PURE__ */ Data_Hashable.hash(Toad_Concept.hashIdent);
var One = /* #__PURE__ */ (function () {
    function One(value0) {
        this.value0 = value0;
    };
    One.create = function (value0) {
        return new One(value0);
    };
    return One;
})();
var All = /* #__PURE__ */ (function () {
    function All() {

    };
    All.value = new All();
    return All;
})();
var Home = /* #__PURE__ */ (function () {
    function Home() {

    };
    Home.value = new Home();
    return Home;
})();
var Concepts = /* #__PURE__ */ (function () {
    function Concepts(value0) {
        this.value0 = value0;
    };
    Concepts.create = function (value0) {
        return new Concepts(value0);
    };
    return Concepts;
})();
var Book = /* #__PURE__ */ (function () {
    function Book() {

    };
    Book.value = new Book();
    return Book;
})();
var toNavbarSection = function (v) {
    if (v instanceof Home) {
        return Toad_Navbar_Section.Home.value;
    };
    if (v instanceof Concepts) {
        return Toad_Navbar_Section.Concepts.value;
    };
    if (v instanceof Book) {
        return Toad_Navbar_Section.Book.value;
    };
    throw new Error("Failed pattern match at Toad.Route (line 79, column 1 - line 79, column 43): " + [ v.constructor.name ]);
};
var oneMaybe = function (v) {
    if (v instanceof Data_Maybe.Just) {
        return new One(v.value0);
    };
    if (v instanceof Data_Maybe.Nothing) {
        return All.value;
    };
    throw new Error("Failed pattern match at Toad.Route (line 48, column 1 - line 48, column 44): " + [ v.constructor.name ]);
};
var ofNavbarSection = function (v) {
    if (v instanceof Toad_Navbar_Section.Home) {
        return Home.value;
    };
    if (v instanceof Toad_Navbar_Section.Concepts) {
        return new Concepts(All.value);
    };
    if (v instanceof Toad_Navbar_Section.Book) {
        return Book.value;
    };
    throw new Error("Failed pattern match at Toad.Route (line 74, column 1 - line 74, column 43): " + [ v.constructor.name ]);
};
var maybeOne = function (v) {
    if (v instanceof One) {
        return new Data_Maybe.Just(v.value0);
    };
    if (v instanceof All) {
        return Data_Maybe.Nothing.value;
    };
    throw new Error("Failed pattern match at Toad.Route (line 44, column 1 - line 44, column 44): " + [ v.constructor.name ]);
};
var orAll = function (a) {
    return dimap(maybeOne)(oneMaybe)(a);
};
var maybeConceptIdent = /* #__PURE__ */ (function () {
    var toSegments = Data_Maybe.maybe([  ])((function () {
        var $106 = Data_String_Common.split("/");
        return function ($107) {
            return $106(Toad_Concept.identString($107));
        };
    })());
    var ofSegments = (function () {
        var $108 = Data_Functor.map(Data_Maybe.functorMaybe)((function () {
            var $111 = Data_String_Common.joinWith("/");
            return function ($112) {
                return Toad_Concept.Ident($111($112));
            };
        })());
        var $109 = Data_Filterable.filter(Data_Filterable.filterableMaybe)((function () {
            var $113 = Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean);
            return function ($114) {
                return $113(Data_Array["null"]($114));
            };
        })());
        return function ($110) {
            return $108($109(Data_Maybe.Just.create($110)));
        };
    })();
    return dimap(toSegments)(ofSegments)(Routing_Duplex.rest);
})();
var init = /* #__PURE__ */ (function () {
    return Home.value;
})();
var genericRoute = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return Home.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return new Concepts(x.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr) {
            return Book.value;
        };
        throw new Error("Failed pattern match at Toad.Route (line 66, column 1 - line 66, column 48): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof Home) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof Concepts) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
        };
        if (x instanceof Book) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value));
        };
        throw new Error("Failed pattern match at Toad.Route (line 66, column 1 - line 66, column 48): " + [ x.constructor.name ]);
    }
};
var genericOneOrAll = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return new One(x.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr) {
            return All.value;
        };
        throw new Error("Failed pattern match at Toad.Route (line 40, column 1 - line 40, column 58): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof One) {
            return new Data_Generic_Rep.Inl(x.value0);
        };
        if (x instanceof All) {
            return new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value);
        };
        throw new Error("Failed pattern match at Toad.Route (line 40, column 1 - line 40, column 58): " + [ x.constructor.name ]);
    }
};
var genericShow = /* #__PURE__ */ Data_Show_Generic.genericShow(genericOneOrAll);
var showOneOrAll = function (dictShow) {
    return {
        show: genericShow(Data_Show_Generic.genericShowSum(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsArgument(dictShow))(OneIsSymbol))(genericShowConstructor1))
    };
};
var showRoute = {
    show: /* #__PURE__ */ Data_Show_Generic.genericShow(genericRoute)(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ genericShowConstructor(HomeIsSymbol))(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ Data_Show_Generic.genericShowConstructor(/* #__PURE__ */ Data_Show_Generic.genericShowArgsArgument(/* #__PURE__ */ showOneOrAll(Toad_Concept.showIdent)))(ConceptsIsSymbol))(/* #__PURE__ */ genericShowConstructor(BookIsSymbol))))
};
var eqOneOrAll = function (dictEq) {
    var eq1 = Data_Eq.eq(dictEq);
    return {
        eq: function (x) {
            return function (y) {
                if (x instanceof One && y instanceof One) {
                    return eq1(x.value0)(y.value0);
                };
                if (x instanceof All && y instanceof All) {
                    return true;
                };
                return false;
            };
        }
    };
};
var eq = /* #__PURE__ */ Data_Eq.eq(/* #__PURE__ */ eqOneOrAll(Toad_Concept.eqIdent));
var eqRoute = {
    eq: function (x) {
        return function (y) {
            if (x instanceof Home && y instanceof Home) {
                return true;
            };
            if (x instanceof Concepts && y instanceof Concepts) {
                return eq(x.value0)(y.value0);
            };
            if (x instanceof Book && y instanceof Book) {
                return true;
            };
            return false;
        };
    }
};
var hashRoute = {
    hash: function (v) {
        if (v instanceof Home) {
            return 100;
        };
        if (v instanceof Concepts && v.value0 instanceof One) {
            return hash(v.value0.value0);
        };
        if (v instanceof Concepts && v.value0 instanceof All) {
            return 101;
        };
        if (v instanceof Book) {
            return 102;
        };
        throw new Error("Failed pattern match at Toad.Route (line 60, column 1 - line 64, column 18): " + [ v.constructor.name ]);
    },
    Eq0: function () {
        return eqRoute;
    }
};
var codec = /* #__PURE__ */ Routing_Duplex.root(/* #__PURE__ */ Routing_Duplex_Generic.sum(genericRoute)(/* #__PURE__ */ Routing_Duplex_Generic.gRouteSum(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor(HomeIsSymbol)()(Routing_Duplex_Generic.gRouteNoArguments))(/* #__PURE__ */ Routing_Duplex_Generic.gRouteSum(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor(ConceptsIsSymbol)()(Routing_Duplex_Generic.gRouteArgument))(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor(BookIsSymbol)()(Routing_Duplex_Generic.gRouteNoArguments))))({
    Home: Routing_Duplex_Generic.noArgs,
    Concepts: /* #__PURE__ */ Routing_Duplex_Generic_Syntax.gsep(/* #__PURE__ */ Routing_Duplex_Generic_Syntax.gsepStringRoute(Routing_Duplex_Generic.gRouteAll))("concepts")(/* #__PURE__ */ orAll(maybeConceptIdent)),
    Book: /* #__PURE__ */ Routing_Duplex_Generic_Syntax.gsep(/* #__PURE__ */ Routing_Duplex_Generic_Syntax.gsepStringRoute(Routing_Duplex_Generic.gRouteNoArguments))("book")(Routing_Duplex_Generic.noArgs)
}));
var print = /* #__PURE__ */ Routing_Duplex.print(codec);
export {
    Home,
    Concepts,
    Book,
    One,
    All,
    ofNavbarSection,
    toNavbarSection,
    init,
    codec,
    print,
    maybeOne,
    eqOneOrAll,
    genericOneOrAll,
    showOneOrAll,
    hashRoute,
    genericRoute,
    eqRoute,
    showRoute
};
