// Generated by purs version 0.15.4
import * as $foreign from "./foreign.js";
var LanguageAlias = function (x) {
    return x;
};
var Lang1c = /* #__PURE__ */ (function () {
    function Lang1c() {

    };
    Lang1c.value = new Lang1c();
    return Lang1c;
})();
var Abnf = /* #__PURE__ */ (function () {
    function Abnf() {

    };
    Abnf.value = new Abnf();
    return Abnf;
})();
var Accesslog = /* #__PURE__ */ (function () {
    function Accesslog() {

    };
    Accesslog.value = new Accesslog();
    return Accesslog;
})();
var Actionscript = /* #__PURE__ */ (function () {
    function Actionscript() {

    };
    Actionscript.value = new Actionscript();
    return Actionscript;
})();
var Ada = /* #__PURE__ */ (function () {
    function Ada() {

    };
    Ada.value = new Ada();
    return Ada;
})();
var Angelscript = /* #__PURE__ */ (function () {
    function Angelscript() {

    };
    Angelscript.value = new Angelscript();
    return Angelscript;
})();
var Apache = /* #__PURE__ */ (function () {
    function Apache() {

    };
    Apache.value = new Apache();
    return Apache;
})();
var Applescript = /* #__PURE__ */ (function () {
    function Applescript() {

    };
    Applescript.value = new Applescript();
    return Applescript;
})();
var Arcade = /* #__PURE__ */ (function () {
    function Arcade() {

    };
    Arcade.value = new Arcade();
    return Arcade;
})();
var Arduino = /* #__PURE__ */ (function () {
    function Arduino() {

    };
    Arduino.value = new Arduino();
    return Arduino;
})();
var Armasm = /* #__PURE__ */ (function () {
    function Armasm() {

    };
    Armasm.value = new Armasm();
    return Armasm;
})();
var Asciidoc = /* #__PURE__ */ (function () {
    function Asciidoc() {

    };
    Asciidoc.value = new Asciidoc();
    return Asciidoc;
})();
var Aspectj = /* #__PURE__ */ (function () {
    function Aspectj() {

    };
    Aspectj.value = new Aspectj();
    return Aspectj;
})();
var Autohotkey = /* #__PURE__ */ (function () {
    function Autohotkey() {

    };
    Autohotkey.value = new Autohotkey();
    return Autohotkey;
})();
var Autoit = /* #__PURE__ */ (function () {
    function Autoit() {

    };
    Autoit.value = new Autoit();
    return Autoit;
})();
var Avrasm = /* #__PURE__ */ (function () {
    function Avrasm() {

    };
    Avrasm.value = new Avrasm();
    return Avrasm;
})();
var Awk = /* #__PURE__ */ (function () {
    function Awk() {

    };
    Awk.value = new Awk();
    return Awk;
})();
var Axapta = /* #__PURE__ */ (function () {
    function Axapta() {

    };
    Axapta.value = new Axapta();
    return Axapta;
})();
var Bash = /* #__PURE__ */ (function () {
    function Bash() {

    };
    Bash.value = new Bash();
    return Bash;
})();
var Basic = /* #__PURE__ */ (function () {
    function Basic() {

    };
    Basic.value = new Basic();
    return Basic;
})();
var Bnf = /* #__PURE__ */ (function () {
    function Bnf() {

    };
    Bnf.value = new Bnf();
    return Bnf;
})();
var Brainfuck = /* #__PURE__ */ (function () {
    function Brainfuck() {

    };
    Brainfuck.value = new Brainfuck();
    return Brainfuck;
})();
var Cal = /* #__PURE__ */ (function () {
    function Cal() {

    };
    Cal.value = new Cal();
    return Cal;
})();
var Capnproto = /* #__PURE__ */ (function () {
    function Capnproto() {

    };
    Capnproto.value = new Capnproto();
    return Capnproto;
})();
var Ceylon = /* #__PURE__ */ (function () {
    function Ceylon() {

    };
    Ceylon.value = new Ceylon();
    return Ceylon;
})();
var C = /* #__PURE__ */ (function () {
    function C() {

    };
    C.value = new C();
    return C;
})();
var Clean = /* #__PURE__ */ (function () {
    function Clean() {

    };
    Clean.value = new Clean();
    return Clean;
})();
var Clojure = /* #__PURE__ */ (function () {
    function Clojure() {

    };
    Clojure.value = new Clojure();
    return Clojure;
})();
var ClojureRepl = /* #__PURE__ */ (function () {
    function ClojureRepl() {

    };
    ClojureRepl.value = new ClojureRepl();
    return ClojureRepl;
})();
var Cmake = /* #__PURE__ */ (function () {
    function Cmake() {

    };
    Cmake.value = new Cmake();
    return Cmake;
})();
var Coffeescript = /* #__PURE__ */ (function () {
    function Coffeescript() {

    };
    Coffeescript.value = new Coffeescript();
    return Coffeescript;
})();
var Coq = /* #__PURE__ */ (function () {
    function Coq() {

    };
    Coq.value = new Coq();
    return Coq;
})();
var Cos = /* #__PURE__ */ (function () {
    function Cos() {

    };
    Cos.value = new Cos();
    return Cos;
})();
var Cpp = /* #__PURE__ */ (function () {
    function Cpp() {

    };
    Cpp.value = new Cpp();
    return Cpp;
})();
var Crmsh = /* #__PURE__ */ (function () {
    function Crmsh() {

    };
    Crmsh.value = new Crmsh();
    return Crmsh;
})();
var Crystal = /* #__PURE__ */ (function () {
    function Crystal() {

    };
    Crystal.value = new Crystal();
    return Crystal;
})();
var Csharp = /* #__PURE__ */ (function () {
    function Csharp() {

    };
    Csharp.value = new Csharp();
    return Csharp;
})();
var Csp = /* #__PURE__ */ (function () {
    function Csp() {

    };
    Csp.value = new Csp();
    return Csp;
})();
var Css = /* #__PURE__ */ (function () {
    function Css() {

    };
    Css.value = new Css();
    return Css;
})();
var Dart = /* #__PURE__ */ (function () {
    function Dart() {

    };
    Dart.value = new Dart();
    return Dart;
})();
var Delphi = /* #__PURE__ */ (function () {
    function Delphi() {

    };
    Delphi.value = new Delphi();
    return Delphi;
})();
var Diff = /* #__PURE__ */ (function () {
    function Diff() {

    };
    Diff.value = new Diff();
    return Diff;
})();
var Django = /* #__PURE__ */ (function () {
    function Django() {

    };
    Django.value = new Django();
    return Django;
})();
var D = /* #__PURE__ */ (function () {
    function D() {

    };
    D.value = new D();
    return D;
})();
var Dns = /* #__PURE__ */ (function () {
    function Dns() {

    };
    Dns.value = new Dns();
    return Dns;
})();
var Dockerfile = /* #__PURE__ */ (function () {
    function Dockerfile() {

    };
    Dockerfile.value = new Dockerfile();
    return Dockerfile;
})();
var Dos = /* #__PURE__ */ (function () {
    function Dos() {

    };
    Dos.value = new Dos();
    return Dos;
})();
var Dsconfig = /* #__PURE__ */ (function () {
    function Dsconfig() {

    };
    Dsconfig.value = new Dsconfig();
    return Dsconfig;
})();
var Dts = /* #__PURE__ */ (function () {
    function Dts() {

    };
    Dts.value = new Dts();
    return Dts;
})();
var Dust = /* #__PURE__ */ (function () {
    function Dust() {

    };
    Dust.value = new Dust();
    return Dust;
})();
var Ebnf = /* #__PURE__ */ (function () {
    function Ebnf() {

    };
    Ebnf.value = new Ebnf();
    return Ebnf;
})();
var Elixir = /* #__PURE__ */ (function () {
    function Elixir() {

    };
    Elixir.value = new Elixir();
    return Elixir;
})();
var Elm = /* #__PURE__ */ (function () {
    function Elm() {

    };
    Elm.value = new Elm();
    return Elm;
})();
var Erb = /* #__PURE__ */ (function () {
    function Erb() {

    };
    Erb.value = new Erb();
    return Erb;
})();
var Erlang = /* #__PURE__ */ (function () {
    function Erlang() {

    };
    Erlang.value = new Erlang();
    return Erlang;
})();
var ErlangRepl = /* #__PURE__ */ (function () {
    function ErlangRepl() {

    };
    ErlangRepl.value = new ErlangRepl();
    return ErlangRepl;
})();
var Excel = /* #__PURE__ */ (function () {
    function Excel() {

    };
    Excel.value = new Excel();
    return Excel;
})();
var Fix = /* #__PURE__ */ (function () {
    function Fix() {

    };
    Fix.value = new Fix();
    return Fix;
})();
var Flix = /* #__PURE__ */ (function () {
    function Flix() {

    };
    Flix.value = new Flix();
    return Flix;
})();
var Fortran = /* #__PURE__ */ (function () {
    function Fortran() {

    };
    Fortran.value = new Fortran();
    return Fortran;
})();
var Fsharp = /* #__PURE__ */ (function () {
    function Fsharp() {

    };
    Fsharp.value = new Fsharp();
    return Fsharp;
})();
var Gams = /* #__PURE__ */ (function () {
    function Gams() {

    };
    Gams.value = new Gams();
    return Gams;
})();
var Gauss = /* #__PURE__ */ (function () {
    function Gauss() {

    };
    Gauss.value = new Gauss();
    return Gauss;
})();
var Gcode = /* #__PURE__ */ (function () {
    function Gcode() {

    };
    Gcode.value = new Gcode();
    return Gcode;
})();
var Gherkin = /* #__PURE__ */ (function () {
    function Gherkin() {

    };
    Gherkin.value = new Gherkin();
    return Gherkin;
})();
var Glsl = /* #__PURE__ */ (function () {
    function Glsl() {

    };
    Glsl.value = new Glsl();
    return Glsl;
})();
var Gml = /* #__PURE__ */ (function () {
    function Gml() {

    };
    Gml.value = new Gml();
    return Gml;
})();
var Go = /* #__PURE__ */ (function () {
    function Go() {

    };
    Go.value = new Go();
    return Go;
})();
var Golo = /* #__PURE__ */ (function () {
    function Golo() {

    };
    Golo.value = new Golo();
    return Golo;
})();
var Gradle = /* #__PURE__ */ (function () {
    function Gradle() {

    };
    Gradle.value = new Gradle();
    return Gradle;
})();
var Graphql = /* #__PURE__ */ (function () {
    function Graphql() {

    };
    Graphql.value = new Graphql();
    return Graphql;
})();
var Groovy = /* #__PURE__ */ (function () {
    function Groovy() {

    };
    Groovy.value = new Groovy();
    return Groovy;
})();
var Haml = /* #__PURE__ */ (function () {
    function Haml() {

    };
    Haml.value = new Haml();
    return Haml;
})();
var Handlebars = /* #__PURE__ */ (function () {
    function Handlebars() {

    };
    Handlebars.value = new Handlebars();
    return Handlebars;
})();
var Haskell = /* #__PURE__ */ (function () {
    function Haskell() {

    };
    Haskell.value = new Haskell();
    return Haskell;
})();
var Haxe = /* #__PURE__ */ (function () {
    function Haxe() {

    };
    Haxe.value = new Haxe();
    return Haxe;
})();
var Hsp = /* #__PURE__ */ (function () {
    function Hsp() {

    };
    Hsp.value = new Hsp();
    return Hsp;
})();
var Html = /* #__PURE__ */ (function () {
    function Html() {

    };
    Html.value = new Html();
    return Html;
})();
var Http = /* #__PURE__ */ (function () {
    function Http() {

    };
    Http.value = new Http();
    return Http;
})();
var Hy = /* #__PURE__ */ (function () {
    function Hy() {

    };
    Hy.value = new Hy();
    return Hy;
})();
var Inform7 = /* #__PURE__ */ (function () {
    function Inform7() {

    };
    Inform7.value = new Inform7();
    return Inform7;
})();
var Ini = /* #__PURE__ */ (function () {
    function Ini() {

    };
    Ini.value = new Ini();
    return Ini;
})();
var Irpf90 = /* #__PURE__ */ (function () {
    function Irpf90() {

    };
    Irpf90.value = new Irpf90();
    return Irpf90;
})();
var Isbl = /* #__PURE__ */ (function () {
    function Isbl() {

    };
    Isbl.value = new Isbl();
    return Isbl;
})();
var Java = /* #__PURE__ */ (function () {
    function Java() {

    };
    Java.value = new Java();
    return Java;
})();
var Javascript = /* #__PURE__ */ (function () {
    function Javascript() {

    };
    Javascript.value = new Javascript();
    return Javascript;
})();
var JbossCli = /* #__PURE__ */ (function () {
    function JbossCli() {

    };
    JbossCli.value = new JbossCli();
    return JbossCli;
})();
var Json = /* #__PURE__ */ (function () {
    function Json() {

    };
    Json.value = new Json();
    return Json;
})();
var Julia = /* #__PURE__ */ (function () {
    function Julia() {

    };
    Julia.value = new Julia();
    return Julia;
})();
var JuliaRepl = /* #__PURE__ */ (function () {
    function JuliaRepl() {

    };
    JuliaRepl.value = new JuliaRepl();
    return JuliaRepl;
})();
var Kotlin = /* #__PURE__ */ (function () {
    function Kotlin() {

    };
    Kotlin.value = new Kotlin();
    return Kotlin;
})();
var Lasso = /* #__PURE__ */ (function () {
    function Lasso() {

    };
    Lasso.value = new Lasso();
    return Lasso;
})();
var Latex = /* #__PURE__ */ (function () {
    function Latex() {

    };
    Latex.value = new Latex();
    return Latex;
})();
var Ldif = /* #__PURE__ */ (function () {
    function Ldif() {

    };
    Ldif.value = new Ldif();
    return Ldif;
})();
var Leaf = /* #__PURE__ */ (function () {
    function Leaf() {

    };
    Leaf.value = new Leaf();
    return Leaf;
})();
var Less = /* #__PURE__ */ (function () {
    function Less() {

    };
    Less.value = new Less();
    return Less;
})();
var Lisp = /* #__PURE__ */ (function () {
    function Lisp() {

    };
    Lisp.value = new Lisp();
    return Lisp;
})();
var Livecodeserver = /* #__PURE__ */ (function () {
    function Livecodeserver() {

    };
    Livecodeserver.value = new Livecodeserver();
    return Livecodeserver;
})();
var Livescript = /* #__PURE__ */ (function () {
    function Livescript() {

    };
    Livescript.value = new Livescript();
    return Livescript;
})();
var Llvm = /* #__PURE__ */ (function () {
    function Llvm() {

    };
    Llvm.value = new Llvm();
    return Llvm;
})();
var Lsl = /* #__PURE__ */ (function () {
    function Lsl() {

    };
    Lsl.value = new Lsl();
    return Lsl;
})();
var Lua = /* #__PURE__ */ (function () {
    function Lua() {

    };
    Lua.value = new Lua();
    return Lua;
})();
var Makefile = /* #__PURE__ */ (function () {
    function Makefile() {

    };
    Makefile.value = new Makefile();
    return Makefile;
})();
var Markdown = /* #__PURE__ */ (function () {
    function Markdown() {

    };
    Markdown.value = new Markdown();
    return Markdown;
})();
var Mathematica = /* #__PURE__ */ (function () {
    function Mathematica() {

    };
    Mathematica.value = new Mathematica();
    return Mathematica;
})();
var Matlab = /* #__PURE__ */ (function () {
    function Matlab() {

    };
    Matlab.value = new Matlab();
    return Matlab;
})();
var Maxima = /* #__PURE__ */ (function () {
    function Maxima() {

    };
    Maxima.value = new Maxima();
    return Maxima;
})();
var Mel = /* #__PURE__ */ (function () {
    function Mel() {

    };
    Mel.value = new Mel();
    return Mel;
})();
var Mercury = /* #__PURE__ */ (function () {
    function Mercury() {

    };
    Mercury.value = new Mercury();
    return Mercury;
})();
var Mipsasm = /* #__PURE__ */ (function () {
    function Mipsasm() {

    };
    Mipsasm.value = new Mipsasm();
    return Mipsasm;
})();
var Mizar = /* #__PURE__ */ (function () {
    function Mizar() {

    };
    Mizar.value = new Mizar();
    return Mizar;
})();
var Mojolicious = /* #__PURE__ */ (function () {
    function Mojolicious() {

    };
    Mojolicious.value = new Mojolicious();
    return Mojolicious;
})();
var Monkey = /* #__PURE__ */ (function () {
    function Monkey() {

    };
    Monkey.value = new Monkey();
    return Monkey;
})();
var Moonscript = /* #__PURE__ */ (function () {
    function Moonscript() {

    };
    Moonscript.value = new Moonscript();
    return Moonscript;
})();
var N1ql = /* #__PURE__ */ (function () {
    function N1ql() {

    };
    N1ql.value = new N1ql();
    return N1ql;
})();
var Nestedtext = /* #__PURE__ */ (function () {
    function Nestedtext() {

    };
    Nestedtext.value = new Nestedtext();
    return Nestedtext;
})();
var Nginx = /* #__PURE__ */ (function () {
    function Nginx() {

    };
    Nginx.value = new Nginx();
    return Nginx;
})();
var Nim = /* #__PURE__ */ (function () {
    function Nim() {

    };
    Nim.value = new Nim();
    return Nim;
})();
var Nix = /* #__PURE__ */ (function () {
    function Nix() {

    };
    Nix.value = new Nix();
    return Nix;
})();
var NodeRepl = /* #__PURE__ */ (function () {
    function NodeRepl() {

    };
    NodeRepl.value = new NodeRepl();
    return NodeRepl;
})();
var Nsis = /* #__PURE__ */ (function () {
    function Nsis() {

    };
    Nsis.value = new Nsis();
    return Nsis;
})();
var Objectivec = /* #__PURE__ */ (function () {
    function Objectivec() {

    };
    Objectivec.value = new Objectivec();
    return Objectivec;
})();
var Ocaml = /* #__PURE__ */ (function () {
    function Ocaml() {

    };
    Ocaml.value = new Ocaml();
    return Ocaml;
})();
var Openscad = /* #__PURE__ */ (function () {
    function Openscad() {

    };
    Openscad.value = new Openscad();
    return Openscad;
})();
var Oxygene = /* #__PURE__ */ (function () {
    function Oxygene() {

    };
    Oxygene.value = new Oxygene();
    return Oxygene;
})();
var Parser3 = /* #__PURE__ */ (function () {
    function Parser3() {

    };
    Parser3.value = new Parser3();
    return Parser3;
})();
var Perl = /* #__PURE__ */ (function () {
    function Perl() {

    };
    Perl.value = new Perl();
    return Perl;
})();
var Pf = /* #__PURE__ */ (function () {
    function Pf() {

    };
    Pf.value = new Pf();
    return Pf;
})();
var Pgsql = /* #__PURE__ */ (function () {
    function Pgsql() {

    };
    Pgsql.value = new Pgsql();
    return Pgsql;
})();
var Php = /* #__PURE__ */ (function () {
    function Php() {

    };
    Php.value = new Php();
    return Php;
})();
var PhpTemplate = /* #__PURE__ */ (function () {
    function PhpTemplate() {

    };
    PhpTemplate.value = new PhpTemplate();
    return PhpTemplate;
})();
var Plaintext = /* #__PURE__ */ (function () {
    function Plaintext() {

    };
    Plaintext.value = new Plaintext();
    return Plaintext;
})();
var Pony = /* #__PURE__ */ (function () {
    function Pony() {

    };
    Pony.value = new Pony();
    return Pony;
})();
var Powershell = /* #__PURE__ */ (function () {
    function Powershell() {

    };
    Powershell.value = new Powershell();
    return Powershell;
})();
var Processing = /* #__PURE__ */ (function () {
    function Processing() {

    };
    Processing.value = new Processing();
    return Processing;
})();
var Profile = /* #__PURE__ */ (function () {
    function Profile() {

    };
    Profile.value = new Profile();
    return Profile;
})();
var Prolog = /* #__PURE__ */ (function () {
    function Prolog() {

    };
    Prolog.value = new Prolog();
    return Prolog;
})();
var Properties = /* #__PURE__ */ (function () {
    function Properties() {

    };
    Properties.value = new Properties();
    return Properties;
})();
var Protobuf = /* #__PURE__ */ (function () {
    function Protobuf() {

    };
    Protobuf.value = new Protobuf();
    return Protobuf;
})();
var Puppet = /* #__PURE__ */ (function () {
    function Puppet() {

    };
    Puppet.value = new Puppet();
    return Puppet;
})();
var Purebasic = /* #__PURE__ */ (function () {
    function Purebasic() {

    };
    Purebasic.value = new Purebasic();
    return Purebasic;
})();
var Python = /* #__PURE__ */ (function () {
    function Python() {

    };
    Python.value = new Python();
    return Python;
})();
var PythonRepl = /* #__PURE__ */ (function () {
    function PythonRepl() {

    };
    PythonRepl.value = new PythonRepl();
    return PythonRepl;
})();
var Q = /* #__PURE__ */ (function () {
    function Q() {

    };
    Q.value = new Q();
    return Q;
})();
var Qml = /* #__PURE__ */ (function () {
    function Qml() {

    };
    Qml.value = new Qml();
    return Qml;
})();
var Reasonml = /* #__PURE__ */ (function () {
    function Reasonml() {

    };
    Reasonml.value = new Reasonml();
    return Reasonml;
})();
var Rib = /* #__PURE__ */ (function () {
    function Rib() {

    };
    Rib.value = new Rib();
    return Rib;
})();
var R = /* #__PURE__ */ (function () {
    function R() {

    };
    R.value = new R();
    return R;
})();
var Roboconf = /* #__PURE__ */ (function () {
    function Roboconf() {

    };
    Roboconf.value = new Roboconf();
    return Roboconf;
})();
var Routeros = /* #__PURE__ */ (function () {
    function Routeros() {

    };
    Routeros.value = new Routeros();
    return Routeros;
})();
var Rsl = /* #__PURE__ */ (function () {
    function Rsl() {

    };
    Rsl.value = new Rsl();
    return Rsl;
})();
var Ruby = /* #__PURE__ */ (function () {
    function Ruby() {

    };
    Ruby.value = new Ruby();
    return Ruby;
})();
var Ruleslanguage = /* #__PURE__ */ (function () {
    function Ruleslanguage() {

    };
    Ruleslanguage.value = new Ruleslanguage();
    return Ruleslanguage;
})();
var Rust = /* #__PURE__ */ (function () {
    function Rust() {

    };
    Rust.value = new Rust();
    return Rust;
})();
var Sas = /* #__PURE__ */ (function () {
    function Sas() {

    };
    Sas.value = new Sas();
    return Sas;
})();
var Scala = /* #__PURE__ */ (function () {
    function Scala() {

    };
    Scala.value = new Scala();
    return Scala;
})();
var Scheme = /* #__PURE__ */ (function () {
    function Scheme() {

    };
    Scheme.value = new Scheme();
    return Scheme;
})();
var Scilab = /* #__PURE__ */ (function () {
    function Scilab() {

    };
    Scilab.value = new Scilab();
    return Scilab;
})();
var Scss = /* #__PURE__ */ (function () {
    function Scss() {

    };
    Scss.value = new Scss();
    return Scss;
})();
var Shell = /* #__PURE__ */ (function () {
    function Shell() {

    };
    Shell.value = new Shell();
    return Shell;
})();
var Smali = /* #__PURE__ */ (function () {
    function Smali() {

    };
    Smali.value = new Smali();
    return Smali;
})();
var Smalltalk = /* #__PURE__ */ (function () {
    function Smalltalk() {

    };
    Smalltalk.value = new Smalltalk();
    return Smalltalk;
})();
var Sml = /* #__PURE__ */ (function () {
    function Sml() {

    };
    Sml.value = new Sml();
    return Sml;
})();
var Sqf = /* #__PURE__ */ (function () {
    function Sqf() {

    };
    Sqf.value = new Sqf();
    return Sqf;
})();
var Sql = /* #__PURE__ */ (function () {
    function Sql() {

    };
    Sql.value = new Sql();
    return Sql;
})();
var Stan = /* #__PURE__ */ (function () {
    function Stan() {

    };
    Stan.value = new Stan();
    return Stan;
})();
var Stata = /* #__PURE__ */ (function () {
    function Stata() {

    };
    Stata.value = new Stata();
    return Stata;
})();
var Step21 = /* #__PURE__ */ (function () {
    function Step21() {

    };
    Step21.value = new Step21();
    return Step21;
})();
var Stylus = /* #__PURE__ */ (function () {
    function Stylus() {

    };
    Stylus.value = new Stylus();
    return Stylus;
})();
var Subunit = /* #__PURE__ */ (function () {
    function Subunit() {

    };
    Subunit.value = new Subunit();
    return Subunit;
})();
var Swift = /* #__PURE__ */ (function () {
    function Swift() {

    };
    Swift.value = new Swift();
    return Swift;
})();
var Taggerscript = /* #__PURE__ */ (function () {
    function Taggerscript() {

    };
    Taggerscript.value = new Taggerscript();
    return Taggerscript;
})();
var Tap = /* #__PURE__ */ (function () {
    function Tap() {

    };
    Tap.value = new Tap();
    return Tap;
})();
var Tcl = /* #__PURE__ */ (function () {
    function Tcl() {

    };
    Tcl.value = new Tcl();
    return Tcl;
})();
var Thrift = /* #__PURE__ */ (function () {
    function Thrift() {

    };
    Thrift.value = new Thrift();
    return Thrift;
})();
var Tp = /* #__PURE__ */ (function () {
    function Tp() {

    };
    Tp.value = new Tp();
    return Tp;
})();
var Twig = /* #__PURE__ */ (function () {
    function Twig() {

    };
    Twig.value = new Twig();
    return Twig;
})();
var Typescript = /* #__PURE__ */ (function () {
    function Typescript() {

    };
    Typescript.value = new Typescript();
    return Typescript;
})();
var Vala = /* #__PURE__ */ (function () {
    function Vala() {

    };
    Vala.value = new Vala();
    return Vala;
})();
var Vbnet = /* #__PURE__ */ (function () {
    function Vbnet() {

    };
    Vbnet.value = new Vbnet();
    return Vbnet;
})();
var VbscriptHtml = /* #__PURE__ */ (function () {
    function VbscriptHtml() {

    };
    VbscriptHtml.value = new VbscriptHtml();
    return VbscriptHtml;
})();
var Vbscript = /* #__PURE__ */ (function () {
    function Vbscript() {

    };
    Vbscript.value = new Vbscript();
    return Vbscript;
})();
var Verilog = /* #__PURE__ */ (function () {
    function Verilog() {

    };
    Verilog.value = new Verilog();
    return Verilog;
})();
var Vhdl = /* #__PURE__ */ (function () {
    function Vhdl() {

    };
    Vhdl.value = new Vhdl();
    return Vhdl;
})();
var Vim = /* #__PURE__ */ (function () {
    function Vim() {

    };
    Vim.value = new Vim();
    return Vim;
})();
var Wasm = /* #__PURE__ */ (function () {
    function Wasm() {

    };
    Wasm.value = new Wasm();
    return Wasm;
})();
var Wren = /* #__PURE__ */ (function () {
    function Wren() {

    };
    Wren.value = new Wren();
    return Wren;
})();
var X86asm = /* #__PURE__ */ (function () {
    function X86asm() {

    };
    X86asm.value = new X86asm();
    return X86asm;
})();
var Xl = /* #__PURE__ */ (function () {
    function Xl() {

    };
    Xl.value = new Xl();
    return Xl;
})();
var Xml = /* #__PURE__ */ (function () {
    function Xml() {

    };
    Xml.value = new Xml();
    return Xml;
})();
var Xquery = /* #__PURE__ */ (function () {
    function Xquery() {

    };
    Xquery.value = new Xquery();
    return Xquery;
})();
var Yaml = /* #__PURE__ */ (function () {
    function Yaml() {

    };
    Yaml.value = new Yaml();
    return Yaml;
})();
var Zephir = /* #__PURE__ */ (function () {
    function Zephir() {

    };
    Zephir.value = new Zephir();
    return Zephir;
})();
var requireLanguage = function (v) {
    if (v instanceof Lang1c) {
        return $foreign.lang1c;
    };
    if (v instanceof Abnf) {
        return $foreign.abnf;
    };
    if (v instanceof Accesslog) {
        return $foreign.accesslog;
    };
    if (v instanceof Actionscript) {
        return $foreign.actionscript;
    };
    if (v instanceof Ada) {
        return $foreign.ada;
    };
    if (v instanceof Angelscript) {
        return $foreign.angelscript;
    };
    if (v instanceof Apache) {
        return $foreign.apache;
    };
    if (v instanceof Applescript) {
        return $foreign.applescript;
    };
    if (v instanceof Arcade) {
        return $foreign.arcade;
    };
    if (v instanceof Arduino) {
        return $foreign.arduino;
    };
    if (v instanceof Armasm) {
        return $foreign.armasm;
    };
    if (v instanceof Asciidoc) {
        return $foreign.asciidoc;
    };
    if (v instanceof Aspectj) {
        return $foreign.aspectj;
    };
    if (v instanceof Autohotkey) {
        return $foreign.autohotkey;
    };
    if (v instanceof Autoit) {
        return $foreign.autoit;
    };
    if (v instanceof Avrasm) {
        return $foreign.avrasm;
    };
    if (v instanceof Awk) {
        return $foreign.awk;
    };
    if (v instanceof Axapta) {
        return $foreign.axapta;
    };
    if (v instanceof Bash) {
        return $foreign.bash;
    };
    if (v instanceof Basic) {
        return $foreign.basic;
    };
    if (v instanceof Bnf) {
        return $foreign.bnf;
    };
    if (v instanceof Brainfuck) {
        return $foreign.brainfuck;
    };
    if (v instanceof Cal) {
        return $foreign.cal;
    };
    if (v instanceof Capnproto) {
        return $foreign.capnproto;
    };
    if (v instanceof Ceylon) {
        return $foreign.ceylon;
    };
    if (v instanceof C) {
        return $foreign.c;
    };
    if (v instanceof Clean) {
        return $foreign.clean;
    };
    if (v instanceof Clojure) {
        return $foreign.clojure;
    };
    if (v instanceof ClojureRepl) {
        return $foreign.clojureRepl;
    };
    if (v instanceof Cmake) {
        return $foreign.cmake;
    };
    if (v instanceof Coffeescript) {
        return $foreign.coffeescript;
    };
    if (v instanceof Coq) {
        return $foreign.coq;
    };
    if (v instanceof Cos) {
        return $foreign.cos;
    };
    if (v instanceof Cpp) {
        return $foreign.cpp;
    };
    if (v instanceof Crmsh) {
        return $foreign.crmsh;
    };
    if (v instanceof Crystal) {
        return $foreign.crystal;
    };
    if (v instanceof Csharp) {
        return $foreign.csharp;
    };
    if (v instanceof Csp) {
        return $foreign.csp;
    };
    if (v instanceof Css) {
        return $foreign.css;
    };
    if (v instanceof Dart) {
        return $foreign.dart;
    };
    if (v instanceof Delphi) {
        return $foreign.delphi;
    };
    if (v instanceof Diff) {
        return $foreign.diff;
    };
    if (v instanceof Django) {
        return $foreign.django;
    };
    if (v instanceof D) {
        return $foreign.d;
    };
    if (v instanceof Dns) {
        return $foreign.dns;
    };
    if (v instanceof Dockerfile) {
        return $foreign.dockerfile;
    };
    if (v instanceof Dos) {
        return $foreign.dos;
    };
    if (v instanceof Dsconfig) {
        return $foreign.dsconfig;
    };
    if (v instanceof Dts) {
        return $foreign.dts;
    };
    if (v instanceof Dust) {
        return $foreign.dust;
    };
    if (v instanceof Ebnf) {
        return $foreign.ebnf;
    };
    if (v instanceof Elixir) {
        return $foreign.elixir;
    };
    if (v instanceof Elm) {
        return $foreign.elm;
    };
    if (v instanceof Erb) {
        return $foreign.erb;
    };
    if (v instanceof Erlang) {
        return $foreign.erlang;
    };
    if (v instanceof ErlangRepl) {
        return $foreign.erlangRepl;
    };
    if (v instanceof Excel) {
        return $foreign.excel;
    };
    if (v instanceof Fix) {
        return $foreign.fix;
    };
    if (v instanceof Flix) {
        return $foreign.flix;
    };
    if (v instanceof Fortran) {
        return $foreign.fortran;
    };
    if (v instanceof Fsharp) {
        return $foreign.fsharp;
    };
    if (v instanceof Gams) {
        return $foreign.gams;
    };
    if (v instanceof Gauss) {
        return $foreign.gauss;
    };
    if (v instanceof Gcode) {
        return $foreign.gcode;
    };
    if (v instanceof Gherkin) {
        return $foreign.gherkin;
    };
    if (v instanceof Glsl) {
        return $foreign.glsl;
    };
    if (v instanceof Gml) {
        return $foreign.gml;
    };
    if (v instanceof Go) {
        return $foreign.go;
    };
    if (v instanceof Golo) {
        return $foreign.golo;
    };
    if (v instanceof Gradle) {
        return $foreign.gradle;
    };
    if (v instanceof Graphql) {
        return $foreign.graphql;
    };
    if (v instanceof Groovy) {
        return $foreign.groovy;
    };
    if (v instanceof Haml) {
        return $foreign.haml;
    };
    if (v instanceof Handlebars) {
        return $foreign.handlebars;
    };
    if (v instanceof Haskell) {
        return $foreign.haskell;
    };
    if (v instanceof Haxe) {
        return $foreign.haxe;
    };
    if (v instanceof Hsp) {
        return $foreign.hsp;
    };
    if (v instanceof Html) {
        return $foreign.xml;
    };
    if (v instanceof Http) {
        return $foreign.http;
    };
    if (v instanceof Hy) {
        return $foreign.hy;
    };
    if (v instanceof Inform7) {
        return $foreign.inform7;
    };
    if (v instanceof Ini) {
        return $foreign.ini;
    };
    if (v instanceof Irpf90) {
        return $foreign.irpf90;
    };
    if (v instanceof Isbl) {
        return $foreign.isbl;
    };
    if (v instanceof Java) {
        return $foreign.java;
    };
    if (v instanceof Javascript) {
        return $foreign.javascript;
    };
    if (v instanceof JbossCli) {
        return $foreign.jbossCli;
    };
    if (v instanceof Json) {
        return $foreign.json;
    };
    if (v instanceof Julia) {
        return $foreign.julia;
    };
    if (v instanceof JuliaRepl) {
        return $foreign.juliaRepl;
    };
    if (v instanceof Kotlin) {
        return $foreign.kotlin;
    };
    if (v instanceof Lasso) {
        return $foreign.lasso;
    };
    if (v instanceof Latex) {
        return $foreign.latex;
    };
    if (v instanceof Ldif) {
        return $foreign.ldif;
    };
    if (v instanceof Leaf) {
        return $foreign.leaf;
    };
    if (v instanceof Less) {
        return $foreign.less;
    };
    if (v instanceof Lisp) {
        return $foreign.lisp;
    };
    if (v instanceof Livecodeserver) {
        return $foreign.livecodeserver;
    };
    if (v instanceof Livescript) {
        return $foreign.livescript;
    };
    if (v instanceof Llvm) {
        return $foreign.llvm;
    };
    if (v instanceof Lsl) {
        return $foreign.lsl;
    };
    if (v instanceof Lua) {
        return $foreign.lua;
    };
    if (v instanceof Makefile) {
        return $foreign.makefile;
    };
    if (v instanceof Markdown) {
        return $foreign.markdown;
    };
    if (v instanceof Mathematica) {
        return $foreign.mathematica;
    };
    if (v instanceof Matlab) {
        return $foreign.matlab;
    };
    if (v instanceof Maxima) {
        return $foreign.maxima;
    };
    if (v instanceof Mel) {
        return $foreign.mel;
    };
    if (v instanceof Mercury) {
        return $foreign.mercury;
    };
    if (v instanceof Mipsasm) {
        return $foreign.mipsasm;
    };
    if (v instanceof Mizar) {
        return $foreign.mizar;
    };
    if (v instanceof Mojolicious) {
        return $foreign.mojolicious;
    };
    if (v instanceof Monkey) {
        return $foreign.monkey;
    };
    if (v instanceof Moonscript) {
        return $foreign.moonscript;
    };
    if (v instanceof N1ql) {
        return $foreign.n1ql;
    };
    if (v instanceof Nestedtext) {
        return $foreign.nestedtext;
    };
    if (v instanceof Nginx) {
        return $foreign.nginx;
    };
    if (v instanceof Nim) {
        return $foreign.nim;
    };
    if (v instanceof Nix) {
        return $foreign.nix;
    };
    if (v instanceof NodeRepl) {
        return $foreign.nodeRepl;
    };
    if (v instanceof Nsis) {
        return $foreign.nsis;
    };
    if (v instanceof Objectivec) {
        return $foreign.objectivec;
    };
    if (v instanceof Ocaml) {
        return $foreign.ocaml;
    };
    if (v instanceof Openscad) {
        return $foreign.openscad;
    };
    if (v instanceof Oxygene) {
        return $foreign.oxygene;
    };
    if (v instanceof Parser3) {
        return $foreign.parser3;
    };
    if (v instanceof Perl) {
        return $foreign.perl;
    };
    if (v instanceof Pf) {
        return $foreign.pf;
    };
    if (v instanceof Pgsql) {
        return $foreign.pgsql;
    };
    if (v instanceof Php) {
        return $foreign.php;
    };
    if (v instanceof PhpTemplate) {
        return $foreign.phpTemplate;
    };
    if (v instanceof Plaintext) {
        return $foreign.plaintext;
    };
    if (v instanceof Pony) {
        return $foreign.pony;
    };
    if (v instanceof Powershell) {
        return $foreign.powershell;
    };
    if (v instanceof Processing) {
        return $foreign.processing;
    };
    if (v instanceof Profile) {
        return $foreign.profile;
    };
    if (v instanceof Prolog) {
        return $foreign.prolog;
    };
    if (v instanceof Properties) {
        return $foreign.properties;
    };
    if (v instanceof Protobuf) {
        return $foreign.protobuf;
    };
    if (v instanceof Puppet) {
        return $foreign.puppet;
    };
    if (v instanceof Purebasic) {
        return $foreign.purebasic;
    };
    if (v instanceof Python) {
        return $foreign.python;
    };
    if (v instanceof PythonRepl) {
        return $foreign.pythonRepl;
    };
    if (v instanceof Q) {
        return $foreign.q;
    };
    if (v instanceof Qml) {
        return $foreign.qml;
    };
    if (v instanceof Reasonml) {
        return $foreign.reasonml;
    };
    if (v instanceof Rib) {
        return $foreign.rib;
    };
    if (v instanceof R) {
        return $foreign.r;
    };
    if (v instanceof Roboconf) {
        return $foreign.roboconf;
    };
    if (v instanceof Routeros) {
        return $foreign.routeros;
    };
    if (v instanceof Rsl) {
        return $foreign.rsl;
    };
    if (v instanceof Ruby) {
        return $foreign.ruby;
    };
    if (v instanceof Ruleslanguage) {
        return $foreign.ruleslanguage;
    };
    if (v instanceof Rust) {
        return $foreign.rust;
    };
    if (v instanceof Sas) {
        return $foreign.sas;
    };
    if (v instanceof Scala) {
        return $foreign.scala;
    };
    if (v instanceof Scheme) {
        return $foreign.scheme;
    };
    if (v instanceof Scilab) {
        return $foreign.scilab;
    };
    if (v instanceof Scss) {
        return $foreign.scss;
    };
    if (v instanceof Shell) {
        return $foreign.shell;
    };
    if (v instanceof Smali) {
        return $foreign.smali;
    };
    if (v instanceof Smalltalk) {
        return $foreign.smalltalk;
    };
    if (v instanceof Sml) {
        return $foreign.sml;
    };
    if (v instanceof Sqf) {
        return $foreign.sqf;
    };
    if (v instanceof Sql) {
        return $foreign.sql;
    };
    if (v instanceof Stan) {
        return $foreign.stan;
    };
    if (v instanceof Stata) {
        return $foreign.stata;
    };
    if (v instanceof Step21) {
        return $foreign.step21;
    };
    if (v instanceof Stylus) {
        return $foreign.stylus;
    };
    if (v instanceof Subunit) {
        return $foreign.subunit;
    };
    if (v instanceof Swift) {
        return $foreign.swift;
    };
    if (v instanceof Taggerscript) {
        return $foreign.taggerscript;
    };
    if (v instanceof Tap) {
        return $foreign.tap;
    };
    if (v instanceof Tcl) {
        return $foreign.tcl;
    };
    if (v instanceof Thrift) {
        return $foreign.thrift;
    };
    if (v instanceof Tp) {
        return $foreign.tp;
    };
    if (v instanceof Twig) {
        return $foreign.twig;
    };
    if (v instanceof Typescript) {
        return $foreign.typescript;
    };
    if (v instanceof Vala) {
        return $foreign.vala;
    };
    if (v instanceof Vbnet) {
        return $foreign.vbnet;
    };
    if (v instanceof VbscriptHtml) {
        return $foreign.vbscriptHtml;
    };
    if (v instanceof Vbscript) {
        return $foreign.vbscript;
    };
    if (v instanceof Verilog) {
        return $foreign.verilog;
    };
    if (v instanceof Vhdl) {
        return $foreign.vhdl;
    };
    if (v instanceof Vim) {
        return $foreign.vim;
    };
    if (v instanceof Wasm) {
        return $foreign.wasm;
    };
    if (v instanceof Wren) {
        return $foreign.wren;
    };
    if (v instanceof X86asm) {
        return $foreign.x86asm;
    };
    if (v instanceof Xl) {
        return $foreign.xl;
    };
    if (v instanceof Xml) {
        return $foreign.xml;
    };
    if (v instanceof Xquery) {
        return $foreign.xquery;
    };
    if (v instanceof Yaml) {
        return $foreign.yaml;
    };
    if (v instanceof Zephir) {
        return $foreign.zephir;
    };
    throw new Error("Failed pattern match at HighlightJs.Language (line 407, column 19 - line 600, column 19): " + [ v.constructor.name ]);
};
var defaultAlias = /* #__PURE__ */ (function () {
    var str = function (v) {
        if (v instanceof Lang1c) {
            return "1c";
        };
        if (v instanceof Abnf) {
            return "abnf";
        };
        if (v instanceof Accesslog) {
            return "accesslog";
        };
        if (v instanceof Actionscript) {
            return "actionscript";
        };
        if (v instanceof Ada) {
            return "ada";
        };
        if (v instanceof Angelscript) {
            return "angelscript";
        };
        if (v instanceof Apache) {
            return "apache";
        };
        if (v instanceof Applescript) {
            return "applescript";
        };
        if (v instanceof Arcade) {
            return "arcade";
        };
        if (v instanceof Arduino) {
            return "arduino";
        };
        if (v instanceof Armasm) {
            return "armasm";
        };
        if (v instanceof Asciidoc) {
            return "asciidoc";
        };
        if (v instanceof Aspectj) {
            return "aspectj";
        };
        if (v instanceof Autohotkey) {
            return "autohotkey";
        };
        if (v instanceof Autoit) {
            return "autoit";
        };
        if (v instanceof Avrasm) {
            return "avrasm";
        };
        if (v instanceof Awk) {
            return "awk";
        };
        if (v instanceof Axapta) {
            return "axapta";
        };
        if (v instanceof Bash) {
            return "bash";
        };
        if (v instanceof Basic) {
            return "basic";
        };
        if (v instanceof Bnf) {
            return "bnf";
        };
        if (v instanceof Brainfuck) {
            return "brainfuck";
        };
        if (v instanceof Cal) {
            return "cal";
        };
        if (v instanceof Capnproto) {
            return "capnproto";
        };
        if (v instanceof Ceylon) {
            return "ceylon";
        };
        if (v instanceof C) {
            return "c";
        };
        if (v instanceof Clean) {
            return "clean";
        };
        if (v instanceof Clojure) {
            return "clojure";
        };
        if (v instanceof ClojureRepl) {
            return "clojure-repl";
        };
        if (v instanceof Cmake) {
            return "cmake";
        };
        if (v instanceof Coffeescript) {
            return "coffeescript";
        };
        if (v instanceof Coq) {
            return "coq";
        };
        if (v instanceof Cos) {
            return "cos";
        };
        if (v instanceof Cpp) {
            return "cpp";
        };
        if (v instanceof Crmsh) {
            return "crmsh";
        };
        if (v instanceof Crystal) {
            return "crystal";
        };
        if (v instanceof Csharp) {
            return "csharp";
        };
        if (v instanceof Csp) {
            return "csp";
        };
        if (v instanceof Css) {
            return "css";
        };
        if (v instanceof Dart) {
            return "dart";
        };
        if (v instanceof Delphi) {
            return "delphi";
        };
        if (v instanceof Diff) {
            return "diff";
        };
        if (v instanceof Django) {
            return "django";
        };
        if (v instanceof D) {
            return "d";
        };
        if (v instanceof Dns) {
            return "dns";
        };
        if (v instanceof Dockerfile) {
            return "dockerfile";
        };
        if (v instanceof Dos) {
            return "dos";
        };
        if (v instanceof Dsconfig) {
            return "dsconfig";
        };
        if (v instanceof Dts) {
            return "dts";
        };
        if (v instanceof Dust) {
            return "dust";
        };
        if (v instanceof Ebnf) {
            return "ebnf";
        };
        if (v instanceof Elixir) {
            return "elixir";
        };
        if (v instanceof Elm) {
            return "elm";
        };
        if (v instanceof Erb) {
            return "erb";
        };
        if (v instanceof Erlang) {
            return "erlang";
        };
        if (v instanceof ErlangRepl) {
            return "erlang-repl";
        };
        if (v instanceof Excel) {
            return "excel";
        };
        if (v instanceof Fix) {
            return "fix";
        };
        if (v instanceof Flix) {
            return "flix";
        };
        if (v instanceof Fortran) {
            return "fortran";
        };
        if (v instanceof Fsharp) {
            return "fsharp";
        };
        if (v instanceof Gams) {
            return "gams";
        };
        if (v instanceof Gauss) {
            return "gauss";
        };
        if (v instanceof Gcode) {
            return "gcode";
        };
        if (v instanceof Gherkin) {
            return "gherkin";
        };
        if (v instanceof Glsl) {
            return "glsl";
        };
        if (v instanceof Gml) {
            return "gml";
        };
        if (v instanceof Go) {
            return "go";
        };
        if (v instanceof Golo) {
            return "golo";
        };
        if (v instanceof Gradle) {
            return "gradle";
        };
        if (v instanceof Graphql) {
            return "graphql";
        };
        if (v instanceof Groovy) {
            return "groovy";
        };
        if (v instanceof Haml) {
            return "haml";
        };
        if (v instanceof Handlebars) {
            return "handlebars";
        };
        if (v instanceof Haskell) {
            return "haskell";
        };
        if (v instanceof Haxe) {
            return "haxe";
        };
        if (v instanceof Hsp) {
            return "hsp";
        };
        if (v instanceof Html) {
            return "html";
        };
        if (v instanceof Http) {
            return "http";
        };
        if (v instanceof Hy) {
            return "hy";
        };
        if (v instanceof Inform7) {
            return "inform7";
        };
        if (v instanceof Ini) {
            return "ini";
        };
        if (v instanceof Irpf90) {
            return "irpf90";
        };
        if (v instanceof Isbl) {
            return "isbl";
        };
        if (v instanceof Java) {
            return "java";
        };
        if (v instanceof Javascript) {
            return "javascript";
        };
        if (v instanceof JbossCli) {
            return "jboss-cli";
        };
        if (v instanceof Json) {
            return "json";
        };
        if (v instanceof Julia) {
            return "julia";
        };
        if (v instanceof JuliaRepl) {
            return "julia-repl";
        };
        if (v instanceof Kotlin) {
            return "kotlin";
        };
        if (v instanceof Lasso) {
            return "lasso";
        };
        if (v instanceof Latex) {
            return "latex";
        };
        if (v instanceof Ldif) {
            return "ldif";
        };
        if (v instanceof Leaf) {
            return "leaf";
        };
        if (v instanceof Less) {
            return "less";
        };
        if (v instanceof Lisp) {
            return "lisp";
        };
        if (v instanceof Livecodeserver) {
            return "livecodeserver";
        };
        if (v instanceof Livescript) {
            return "livescript";
        };
        if (v instanceof Llvm) {
            return "llvm";
        };
        if (v instanceof Lsl) {
            return "lsl";
        };
        if (v instanceof Lua) {
            return "lua";
        };
        if (v instanceof Makefile) {
            return "makefile";
        };
        if (v instanceof Markdown) {
            return "markdown";
        };
        if (v instanceof Mathematica) {
            return "mathematica";
        };
        if (v instanceof Matlab) {
            return "matlab";
        };
        if (v instanceof Maxima) {
            return "maxima";
        };
        if (v instanceof Mel) {
            return "mel";
        };
        if (v instanceof Mercury) {
            return "mercury";
        };
        if (v instanceof Mipsasm) {
            return "mipsasm";
        };
        if (v instanceof Mizar) {
            return "mizar";
        };
        if (v instanceof Mojolicious) {
            return "mojolicious";
        };
        if (v instanceof Monkey) {
            return "monkey";
        };
        if (v instanceof Moonscript) {
            return "moonscript";
        };
        if (v instanceof N1ql) {
            return "n1ql";
        };
        if (v instanceof Nestedtext) {
            return "nestedtext";
        };
        if (v instanceof Nginx) {
            return "nginx";
        };
        if (v instanceof Nim) {
            return "nim";
        };
        if (v instanceof Nix) {
            return "nix";
        };
        if (v instanceof NodeRepl) {
            return "node-repl";
        };
        if (v instanceof Nsis) {
            return "nsis";
        };
        if (v instanceof Objectivec) {
            return "objectivec";
        };
        if (v instanceof Ocaml) {
            return "ocaml";
        };
        if (v instanceof Openscad) {
            return "openscad";
        };
        if (v instanceof Oxygene) {
            return "oxygene";
        };
        if (v instanceof Parser3) {
            return "parser3";
        };
        if (v instanceof Perl) {
            return "perl";
        };
        if (v instanceof Pf) {
            return "pf";
        };
        if (v instanceof Pgsql) {
            return "pgsql";
        };
        if (v instanceof Php) {
            return "php";
        };
        if (v instanceof PhpTemplate) {
            return "php-template";
        };
        if (v instanceof Plaintext) {
            return "plaintext";
        };
        if (v instanceof Pony) {
            return "pony";
        };
        if (v instanceof Powershell) {
            return "powershell";
        };
        if (v instanceof Processing) {
            return "processing";
        };
        if (v instanceof Profile) {
            return "profile";
        };
        if (v instanceof Prolog) {
            return "prolog";
        };
        if (v instanceof Properties) {
            return "properties";
        };
        if (v instanceof Protobuf) {
            return "protobuf";
        };
        if (v instanceof Puppet) {
            return "puppet";
        };
        if (v instanceof Purebasic) {
            return "purebasic";
        };
        if (v instanceof Python) {
            return "python";
        };
        if (v instanceof PythonRepl) {
            return "python-repl";
        };
        if (v instanceof Q) {
            return "q";
        };
        if (v instanceof Qml) {
            return "qml";
        };
        if (v instanceof Reasonml) {
            return "reasonml";
        };
        if (v instanceof Rib) {
            return "rib";
        };
        if (v instanceof R) {
            return "r";
        };
        if (v instanceof Roboconf) {
            return "roboconf";
        };
        if (v instanceof Routeros) {
            return "routeros";
        };
        if (v instanceof Rsl) {
            return "rsl";
        };
        if (v instanceof Ruby) {
            return "ruby";
        };
        if (v instanceof Ruleslanguage) {
            return "ruleslanguage";
        };
        if (v instanceof Rust) {
            return "rust";
        };
        if (v instanceof Sas) {
            return "sas";
        };
        if (v instanceof Scala) {
            return "scala";
        };
        if (v instanceof Scheme) {
            return "scheme";
        };
        if (v instanceof Scilab) {
            return "scilab";
        };
        if (v instanceof Scss) {
            return "scss";
        };
        if (v instanceof Shell) {
            return "shell";
        };
        if (v instanceof Smali) {
            return "smali";
        };
        if (v instanceof Smalltalk) {
            return "smalltalk";
        };
        if (v instanceof Sml) {
            return "sml";
        };
        if (v instanceof Sqf) {
            return "sqf";
        };
        if (v instanceof Sql) {
            return "sql";
        };
        if (v instanceof Stan) {
            return "stan";
        };
        if (v instanceof Stata) {
            return "stata";
        };
        if (v instanceof Step21) {
            return "step21";
        };
        if (v instanceof Stylus) {
            return "stylus";
        };
        if (v instanceof Subunit) {
            return "subunit";
        };
        if (v instanceof Swift) {
            return "swift";
        };
        if (v instanceof Taggerscript) {
            return "taggerscript";
        };
        if (v instanceof Tap) {
            return "tap";
        };
        if (v instanceof Tcl) {
            return "tcl";
        };
        if (v instanceof Thrift) {
            return "thrift";
        };
        if (v instanceof Tp) {
            return "tp";
        };
        if (v instanceof Twig) {
            return "twig";
        };
        if (v instanceof Typescript) {
            return "typescript";
        };
        if (v instanceof Vala) {
            return "vala";
        };
        if (v instanceof Vbnet) {
            return "vbnet";
        };
        if (v instanceof VbscriptHtml) {
            return "vbscript-html";
        };
        if (v instanceof Vbscript) {
            return "vbscript";
        };
        if (v instanceof Verilog) {
            return "verilog";
        };
        if (v instanceof Vhdl) {
            return "vhdl";
        };
        if (v instanceof Vim) {
            return "vim";
        };
        if (v instanceof Wasm) {
            return "wasm";
        };
        if (v instanceof Wren) {
            return "wren";
        };
        if (v instanceof X86asm) {
            return "x86asm";
        };
        if (v instanceof Xl) {
            return "xl";
        };
        if (v instanceof Xml) {
            return "xml";
        };
        if (v instanceof Xquery) {
            return "xquery";
        };
        if (v instanceof Yaml) {
            return "yaml";
        };
        if (v instanceof Zephir) {
            return "zephir";
        };
        throw new Error("Failed pattern match at HighlightJs.Language (line 16, column 9 - line 209, column 23): " + [ v.constructor.name ]);
    };
    return function ($5) {
        return LanguageAlias(str($5));
    };
})();
export {
    requireLanguage,
    Lang1c,
    Abnf,
    Accesslog,
    Actionscript,
    Ada,
    Angelscript,
    Apache,
    Applescript,
    Arcade,
    Arduino,
    Armasm,
    Asciidoc,
    Aspectj,
    Autohotkey,
    Autoit,
    Avrasm,
    Awk,
    Axapta,
    Bash,
    Basic,
    Bnf,
    Brainfuck,
    Cal,
    Capnproto,
    Ceylon,
    C,
    Clean,
    Clojure,
    ClojureRepl,
    Cmake,
    Coffeescript,
    Coq,
    Cos,
    Cpp,
    Crmsh,
    Crystal,
    Csharp,
    Csp,
    Css,
    Dart,
    Delphi,
    Diff,
    Django,
    D,
    Dns,
    Dockerfile,
    Dos,
    Dsconfig,
    Dts,
    Dust,
    Ebnf,
    Elixir,
    Elm,
    Erb,
    Erlang,
    ErlangRepl,
    Excel,
    Fix,
    Flix,
    Fortran,
    Fsharp,
    Gams,
    Gauss,
    Gcode,
    Gherkin,
    Glsl,
    Gml,
    Go,
    Golo,
    Gradle,
    Graphql,
    Groovy,
    Haml,
    Handlebars,
    Haskell,
    Haxe,
    Hsp,
    Html,
    Http,
    Hy,
    Inform7,
    Ini,
    Irpf90,
    Isbl,
    Java,
    Javascript,
    JbossCli,
    Json,
    Julia,
    JuliaRepl,
    Kotlin,
    Lasso,
    Latex,
    Ldif,
    Leaf,
    Less,
    Lisp,
    Livecodeserver,
    Livescript,
    Llvm,
    Lsl,
    Lua,
    Makefile,
    Markdown,
    Mathematica,
    Matlab,
    Maxima,
    Mel,
    Mercury,
    Mipsasm,
    Mizar,
    Mojolicious,
    Monkey,
    Moonscript,
    N1ql,
    Nestedtext,
    Nginx,
    Nim,
    Nix,
    NodeRepl,
    Nsis,
    Objectivec,
    Ocaml,
    Openscad,
    Oxygene,
    Parser3,
    Perl,
    Pf,
    Pgsql,
    Php,
    PhpTemplate,
    Plaintext,
    Pony,
    Powershell,
    Processing,
    Profile,
    Prolog,
    Properties,
    Protobuf,
    Puppet,
    Purebasic,
    Python,
    PythonRepl,
    Q,
    Qml,
    Reasonml,
    Rib,
    R,
    Roboconf,
    Routeros,
    Rsl,
    Ruby,
    Ruleslanguage,
    Rust,
    Sas,
    Scala,
    Scheme,
    Scilab,
    Scss,
    Shell,
    Smali,
    Smalltalk,
    Sml,
    Sqf,
    Sql,
    Stan,
    Stata,
    Step21,
    Stylus,
    Subunit,
    Swift,
    Taggerscript,
    Tap,
    Tcl,
    Thrift,
    Tp,
    Twig,
    Typescript,
    Vala,
    Vbnet,
    VbscriptHtml,
    Vbscript,
    Verilog,
    Vhdl,
    Vim,
    Wasm,
    Wren,
    X86asm,
    Xl,
    Xml,
    Xquery,
    Yaml,
    Zephir,
    LanguageAlias,
    defaultAlias
};
