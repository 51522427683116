// Generated by purs version 0.15.4
import * as CSS_Property from "../CSS.Property/index.js";
import * as CSS_Size from "../CSS.Size/index.js";
import * as CSS_String from "../CSS.String/index.js";
import * as CSS_Stylesheet from "../CSS.Stylesheet/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
var fromString = /* #__PURE__ */ CSS_String.fromString(CSS_Property.isStringValue);
var fromString1 = /* #__PURE__ */ CSS_String.fromString(CSS_Property.isStringKey);
var eq = /* #__PURE__ */ Data_Eq.eq(CSS_Property.eqValue);
var compare = /* #__PURE__ */ Data_Ord.compare(CSS_Property.ordValue);
var TextDecoration = function (x) {
    return x;
};
var valTextDecoration = {
    value: function (v) {
        return v;
    }
};
var underline = /* #__PURE__ */ fromString("underline");
var textDecoration = /* #__PURE__ */ CSS_Stylesheet.key(valTextDecoration)(/* #__PURE__ */ fromString1("text-decoration"));
var overline = /* #__PURE__ */ fromString("overline");
var noneTextDecoration = /* #__PURE__ */ fromString("none");
var lineThrough = /* #__PURE__ */ fromString("line-through");
var letterSpacing = /* #__PURE__ */ CSS_Stylesheet.key(CSS_Size.valSize)(/* #__PURE__ */ fromString1("letter-spacing"));
var eqTextDecoration = {
    eq: function (x) {
        return function (y) {
            return eq(x)(y);
        };
    }
};
var ordTextDecoration = {
    compare: function (x) {
        return function (y) {
            return compare(x)(y);
        };
    },
    Eq0: function () {
        return eqTextDecoration;
    }
};
var blink = /* #__PURE__ */ fromString("blink");
export {
    letterSpacing,
    TextDecoration,
    noneTextDecoration,
    underline,
    overline,
    lineThrough,
    blink,
    textDecoration,
    eqTextDecoration,
    ordTextDecoration,
    valTextDecoration
};
