// Generated by purs version 0.15.4
import * as CSS_Property from "../CSS.Property/index.js";
import * as CSS_String from "../CSS.String/index.js";
import * as CSS_Stylesheet from "../CSS.Stylesheet/index.js";
import * as Data_Ordering from "../Data.Ordering/index.js";
var fromString = /* #__PURE__ */ CSS_String.fromString(CSS_Property.isStringValue);
var Uppercase = /* #__PURE__ */ (function () {
    function Uppercase() {

    };
    Uppercase.value = new Uppercase();
    return Uppercase;
})();
var Lowercase = /* #__PURE__ */ (function () {
    function Lowercase() {

    };
    Lowercase.value = new Lowercase();
    return Lowercase;
})();
var Capitalize = /* #__PURE__ */ (function () {
    function Capitalize() {

    };
    Capitalize.value = new Capitalize();
    return Capitalize;
})();
var None = /* #__PURE__ */ (function () {
    function None() {

    };
    None.value = new None();
    return None;
})();
var Initial = /* #__PURE__ */ (function () {
    function Initial() {

    };
    Initial.value = new Initial();
    return Initial;
})();
var Inherit = /* #__PURE__ */ (function () {
    function Inherit() {

    };
    Inherit.value = new Inherit();
    return Inherit;
})();
var valTextTransform = {
    value: function (v) {
        if (v instanceof Uppercase) {
            return fromString("uppercase");
        };
        if (v instanceof Lowercase) {
            return fromString("lowercase");
        };
        if (v instanceof Capitalize) {
            return fromString("capitalize");
        };
        if (v instanceof None) {
            return fromString("none");
        };
        if (v instanceof Initial) {
            return fromString("initial");
        };
        if (v instanceof Inherit) {
            return fromString("inherit");
        };
        throw new Error("Failed pattern match at CSS.Text.Transform (line 29, column 1 - line 35, column 39): " + [ v.constructor.name ]);
    }
};
var uppercase = /* #__PURE__ */ (function () {
    return Uppercase.value;
})();
var textTransform = /* #__PURE__ */ CSS_Stylesheet.key(valTextTransform)(/* #__PURE__ */ CSS_String.fromString(CSS_Property.isStringKey)("text-transform"));
var showTextTransform = {
    show: function (v) {
        if (v instanceof Uppercase) {
            return "Uppercase";
        };
        if (v instanceof Lowercase) {
            return "Lowercase";
        };
        if (v instanceof Capitalize) {
            return "Capitalize";
        };
        if (v instanceof None) {
            return "None";
        };
        if (v instanceof Initial) {
            return "Initial";
        };
        if (v instanceof Inherit) {
            return "Inherit";
        };
        throw new Error("Failed pattern match at CSS.Text.Transform (line 37, column 1 - line 43, column 27): " + [ v.constructor.name ]);
    }
};
var noneTextTransform = /* #__PURE__ */ (function () {
    return {
        none: None.value
    };
})();
var lowercase = /* #__PURE__ */ (function () {
    return Lowercase.value;
})();
var initialTextTransform = /* #__PURE__ */ (function () {
    return {
        initial: Initial.value
    };
})();
var inheritTextTransform = /* #__PURE__ */ (function () {
    return {
        inherit: Inherit.value
    };
})();
var eqTextTransform = {
    eq: function (x) {
        return function (y) {
            if (x instanceof Uppercase && y instanceof Uppercase) {
                return true;
            };
            if (x instanceof Lowercase && y instanceof Lowercase) {
                return true;
            };
            if (x instanceof Capitalize && y instanceof Capitalize) {
                return true;
            };
            if (x instanceof None && y instanceof None) {
                return true;
            };
            if (x instanceof Initial && y instanceof Initial) {
                return true;
            };
            if (x instanceof Inherit && y instanceof Inherit) {
                return true;
            };
            return false;
        };
    }
};
var ordTextTransform = {
    compare: function (x) {
        return function (y) {
            if (x instanceof Uppercase && y instanceof Uppercase) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Uppercase) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Uppercase) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Lowercase && y instanceof Lowercase) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Lowercase) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Lowercase) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Capitalize && y instanceof Capitalize) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Capitalize) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Capitalize) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof None && y instanceof None) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof None) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof None) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Initial && y instanceof Initial) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Initial) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Initial) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Inherit && y instanceof Inherit) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at CSS.Text.Transform (line 0, column 0 - line 0, column 0): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqTextTransform;
    }
};
var capitalize = /* #__PURE__ */ (function () {
    return Capitalize.value;
})();
export {
    textTransform,
    lowercase,
    uppercase,
    capitalize,
    eqTextTransform,
    ordTextTransform,
    valTextTransform,
    showTextTransform,
    noneTextTransform,
    initialTextTransform,
    inheritTextTransform
};
