// Generated by purs version 0.15.4
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Argonaut_Core from "../Data.Argonaut.Core/index.js";
import * as Data_Argonaut_Parser from "../Data.Argonaut.Parser/index.js";
import * as Data_Bifunctor from "../Data.Bifunctor/index.js";
import * as Data_Codec from "../Data.Codec/index.js";
import * as Data_Codec_Argonaut from "../Data.Codec.Argonaut/index.js";
import * as Data_Codec_Argonaut_Record from "../Data.Codec.Argonaut.Record/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Hashable from "../Data.Hashable/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Type_Equality from "../Type.Equality/index.js";
var identIsSymbol = {
    reflectSymbol: function () {
        return "ident";
    }
};
var pathIsSymbol = {
    reflectSymbol: function () {
        return "path";
    }
};
var titleIsSymbol = {
    reflectSymbol: function () {
        return "title";
    }
};
var map = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var bind = /* #__PURE__ */ Control_Bind.bind(Data_Either.bindEither);
var lmap = /* #__PURE__ */ Data_Bifunctor.lmap(Data_Bifunctor.bifunctorEither);
var pure = /* #__PURE__ */ Control_Applicative.pure(Data_Either.applicativeEither);
var Title = function (x) {
    return x;
};
var Path = function (x) {
    return x;
};
var Ident = function (x) {
    return x;
};
var Decl = function (x) {
    return x;
};
var Manifest = function (x) {
    return x;
};
var titleString = function (v) {
    return v;
};
var title = function (v) {
    return v.title;
};
var showTitle = Data_Show.showString;
var showPath = Data_Show.showString;
var showIdent = Data_Show.showString;
var showOne = /* #__PURE__ */ Data_Show.showRecord()()(/* #__PURE__ */ Data_Show.showRecordFieldsCons(identIsSymbol)(/* #__PURE__ */ Data_Show.showRecordFieldsCons(pathIsSymbol)(/* #__PURE__ */ Data_Show.showRecordFieldsCons(titleIsSymbol)(Data_Show.showRecordFieldsNil)(showTitle))(showPath))(showIdent));
var showManifest = /* #__PURE__ */ Data_Show.showArray(showOne);
var semiTitle = Data_Semigroup.semigroupString;
var semiPath = Data_Semigroup.semigroupString;
var semiIdent = Data_Semigroup.semigroupString;
var pathString = function (v) {
    return v;
};
var path = function (v) {
    return v.path;
};
var ordTitle = Data_Ord.ordString;
var ordPath = Data_Ord.ordString;
var ordIdent = Data_Ord.ordString;
var monoidTitle = Data_Monoid.monoidString;
var monoidPath = Data_Monoid.monoidString;
var monoidIdent = Data_Monoid.monoidString;
var manifestCodec = /* #__PURE__ */ Data_Codec_Argonaut.array(/* #__PURE__ */ Data_Codec_Argonaut_Record.object()(/* #__PURE__ */ Data_Codec_Argonaut_Record.rowListCodecCons(/* #__PURE__ */ Data_Codec_Argonaut_Record.rowListCodecCons(/* #__PURE__ */ Data_Codec_Argonaut_Record.rowListCodecCons(Data_Codec_Argonaut_Record.rowListCodecNil)()()(titleIsSymbol)(Type_Equality.refl))()()(pathIsSymbol)(Type_Equality.refl))()()(identIsSymbol)(Type_Equality.refl))("Manifest")({
    path: Data_Codec_Argonaut.string,
    title: Data_Codec_Argonaut.string,
    ident: Data_Codec_Argonaut.string
}));
var identString = function (v) {
    return v;
};
var ident = function (v) {
    return v.ident;
};
var hashIdent = Data_Hashable.hashableString;
var eqTitle = Data_Eq.eqString;
var eqPath = Data_Eq.eqString;
var eqIdent = Data_Eq.eqString;
var eqOne = /* #__PURE__ */ Data_Eq.eqRec()(/* #__PURE__ */ Data_Eq.eqRowCons(/* #__PURE__ */ Data_Eq.eqRowCons(/* #__PURE__ */ Data_Eq.eqRowCons(Data_Eq.eqRowNil)()(titleIsSymbol)(eqTitle))()(pathIsSymbol)(eqPath))()(identIsSymbol)(eqIdent));
var eqManifest = /* #__PURE__ */ Data_Eq.eqArray(eqOne);
var decls = function (v) {
    return v;
};
var declRecord = function (v) {
    return {
        path: v.path,
        title: v.title,
        ident: v.ident
    };
};
var encodeManifest = /* #__PURE__ */ (function () {
    var $80 = Data_Argonaut_Core.stringifyWithIndent(2);
    var $81 = Data_Codec.encode(manifestCodec);
    var $82 = map(declRecord);
    return function ($83) {
        return $80($81($82(decls($83))));
    };
})();
var declOfRecord = function (v) {
    return {
        path: v.path,
        title: v.title,
        ident: v.ident
    };
};
var decodeManifest = function (s) {
    return bind(Data_Argonaut_Parser.jsonParser(s))(function (json) {
        return bind(lmap(Data_Codec_Argonaut.printJsonDecodeError)(Data_Codec.decode(manifestCodec)(json)))(function (decls$prime) {
            return pure(map(declOfRecord)(decls$prime));
        });
    });
};
export {
    Manifest,
    Decl,
    decls,
    declOfRecord,
    pathString,
    identString,
    titleString,
    path,
    ident,
    title,
    Path,
    Ident,
    Title,
    manifestCodec,
    decodeManifest,
    encodeManifest,
    hashIdent,
    eqIdent,
    ordIdent,
    showIdent,
    semiIdent,
    monoidIdent,
    showPath,
    eqPath,
    ordPath,
    semiPath,
    monoidPath,
    showTitle,
    eqTitle,
    ordTitle,
    semiTitle,
    monoidTitle,
    eqOne,
    showOne,
    eqManifest,
    showManifest
};
