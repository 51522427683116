// Generated by purs version 0.15.4
import * as CSS_Property from "../CSS.Property/index.js";
import * as CSS_Size from "../CSS.Size/index.js";
import * as CSS_String from "../CSS.String/index.js";
import * as CSS_Stylesheet from "../CSS.Stylesheet/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
var key = /* #__PURE__ */ CSS_Stylesheet.key(CSS_Size.valSize);
var fromString = /* #__PURE__ */ CSS_String.fromString(CSS_Property.isStringKey);
var valTuple = /* #__PURE__ */ CSS_Property.valTuple(CSS_Size.valSize)(CSS_Size.valSize);
var key1 = /* #__PURE__ */ CSS_Stylesheet.key(/* #__PURE__ */ CSS_Property.valTuple(valTuple)(valTuple));
var width = /* #__PURE__ */ key(/* #__PURE__ */ fromString("width"));
var top = /* #__PURE__ */ key(/* #__PURE__ */ fromString("top"));
var right = /* #__PURE__ */ key(/* #__PURE__ */ fromString("right"));
var paddingTop = /* #__PURE__ */ key(/* #__PURE__ */ fromString("padding-top"));
var paddingRight = /* #__PURE__ */ key(/* #__PURE__ */ fromString("padding-right"));
var paddingLeft = /* #__PURE__ */ key(/* #__PURE__ */ fromString("padding-left"));
var paddingBottom = /* #__PURE__ */ key(/* #__PURE__ */ fromString("padding-bottom"));
var padding = function (a) {
    return function (b) {
        return function (c) {
            return function (d) {
                return key1(fromString("padding"))(new Data_Tuple.Tuple(new Data_Tuple.Tuple(a, b), new Data_Tuple.Tuple(c, d)));
            };
        };
    };
};
var minWidth = /* #__PURE__ */ key(/* #__PURE__ */ fromString("min-width"));
var minHeight = /* #__PURE__ */ key(/* #__PURE__ */ fromString("min-height"));
var maxWidth = /* #__PURE__ */ key(/* #__PURE__ */ fromString("max-width"));
var maxHeight = /* #__PURE__ */ key(/* #__PURE__ */ fromString("max-height"));
var marginTop = /* #__PURE__ */ key(/* #__PURE__ */ fromString("margin-top"));
var marginRight = /* #__PURE__ */ key(/* #__PURE__ */ fromString("margin-right"));
var marginLeft = /* #__PURE__ */ key(/* #__PURE__ */ fromString("margin-left"));
var marginBottom = /* #__PURE__ */ key(/* #__PURE__ */ fromString("margin-bottom"));
var margin = function (a) {
    return function (b) {
        return function (c) {
            return function (d) {
                return key1(fromString("margin"))(new Data_Tuple.Tuple(new Data_Tuple.Tuple(a, b), new Data_Tuple.Tuple(c, d)));
            };
        };
    };
};
var lineHeight = /* #__PURE__ */ key(/* #__PURE__ */ fromString("line-height"));
var left = /* #__PURE__ */ key(/* #__PURE__ */ fromString("left"));
var height = /* #__PURE__ */ key(/* #__PURE__ */ fromString("height"));
var bottom = /* #__PURE__ */ key(/* #__PURE__ */ fromString("bottom"));
export {
    width,
    height,
    minWidth,
    minHeight,
    maxWidth,
    maxHeight,
    top,
    bottom,
    left,
    right,
    padding,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    margin,
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
    lineHeight
};
