// Generated by purs version 0.15.4
import * as CSS_Property from "../CSS.Property/index.js";
import * as CSS_String from "../CSS.String/index.js";
import * as CSS_Stylesheet from "../CSS.Stylesheet/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Ordering from "../Data.Ordering/index.js";
var fromString = /* #__PURE__ */ CSS_String.fromString(CSS_Property.isStringValue);
var compare = /* #__PURE__ */ Data_Ord.compare(Data_Ord.ordString);
var Clip = /* #__PURE__ */ (function () {
    function Clip() {

    };
    Clip.value = new Clip();
    return Clip;
})();
var Ellipsis = /* #__PURE__ */ (function () {
    function Ellipsis() {

    };
    Ellipsis.value = new Ellipsis();
    return Ellipsis;
})();
var Custom = /* #__PURE__ */ (function () {
    function Custom(value0) {
        this.value0 = value0;
    };
    Custom.create = function (value0) {
        return new Custom(value0);
    };
    return Custom;
})();
var valTextOverflow = {
    value: function (v) {
        if (v instanceof Clip) {
            return fromString("clip");
        };
        if (v instanceof Ellipsis) {
            return fromString("ellipsis");
        };
        if (v instanceof Custom) {
            return fromString(CSS_Property.quote(v.value0));
        };
        throw new Error("Failed pattern match at CSS.Text.Overflow (line 24, column 1 - line 27, column 42): " + [ v.constructor.name ]);
    }
};
var textOverflow = /* #__PURE__ */ CSS_Stylesheet.key(valTextOverflow)(/* #__PURE__ */ CSS_String.fromString(CSS_Property.isStringKey)("text-overflow"));
var eqTextOverflow = {
    eq: function (x) {
        return function (y) {
            if (x instanceof Clip && y instanceof Clip) {
                return true;
            };
            if (x instanceof Ellipsis && y instanceof Ellipsis) {
                return true;
            };
            if (x instanceof Custom && y instanceof Custom) {
                return x.value0 === y.value0;
            };
            return false;
        };
    }
};
var ordTextOverflow = {
    compare: function (x) {
        return function (y) {
            if (x instanceof Clip && y instanceof Clip) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Clip) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Clip) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Ellipsis && y instanceof Ellipsis) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Ellipsis) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Ellipsis) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Custom && y instanceof Custom) {
                return compare(x.value0)(y.value0);
            };
            throw new Error("Failed pattern match at CSS.Text.Overflow (line 0, column 0 - line 0, column 0): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqTextOverflow;
    }
};
var ellipsis = /* #__PURE__ */ (function () {
    return Ellipsis.value;
})();
var custom = /* #__PURE__ */ (function () {
    return Custom.create;
})();
var clip = /* #__PURE__ */ (function () {
    return Clip.value;
})();
export {
    textOverflow,
    clip,
    ellipsis,
    custom,
    eqTextOverflow,
    ordTextOverflow,
    valTextOverflow
};
