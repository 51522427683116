// Generated by purs version 0.15.4
import * as CSS_Property from "../CSS.Property/index.js";
import * as CSS_String from "../CSS.String/index.js";
import * as CSS_Stylesheet from "../CSS.Stylesheet/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
var fromString = /* #__PURE__ */ CSS_String.fromString(CSS_Property.isStringValue);
var eq = /* #__PURE__ */ Data_Eq.eq(CSS_Property.eqValue);
var compare = /* #__PURE__ */ Data_Ord.compare(CSS_Property.ordValue);
var TextWhitespace = function (x) {
    return x;
};
var whitespacePreWrap = /* #__PURE__ */ fromString("pre-wrap");
var whitespacePreLine = /* #__PURE__ */ fromString("pre-line");
var whitespacePre = /* #__PURE__ */ fromString("pre");
var whitespaceNormal = /* #__PURE__ */ fromString("normal");
var whitespaceNoWrap = /* #__PURE__ */ fromString("nowrap");
var valTextWhitespace = {
    value: function (v) {
        return v;
    }
};
var textWhitespace = /* #__PURE__ */ CSS_Stylesheet.key(valTextWhitespace)(/* #__PURE__ */ CSS_String.fromString(CSS_Property.isStringKey)("white-space"));
var eqTextWhitespace = {
    eq: function (x) {
        return function (y) {
            return eq(x)(y);
        };
    }
};
var ordTextWhitespace = {
    compare: function (x) {
        return function (y) {
            return compare(x)(y);
        };
    },
    Eq0: function () {
        return eqTextWhitespace;
    }
};
export {
    whitespaceNormal,
    whitespacePre,
    whitespaceNoWrap,
    whitespacePreWrap,
    whitespacePreLine,
    textWhitespace,
    eqTextWhitespace,
    ordTextWhitespace,
    valTextWhitespace
};
