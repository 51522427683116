// Generated by purs version 0.15.4
var drop = "drop";
var dragstart = "dragstart";
var dragover = "dragover";
var dragleave = "dragleave";
var dragexit = "dragexit";
var dragenter = "dragenter";
var dragend = "dragend";
var drag = "drag";
export {
    dragstart,
    drag,
    dragenter,
    dragexit,
    dragleave,
    dragover,
    drop,
    dragend
};
