// Generated by purs version 0.15.4
import * as CSS_Property from "../CSS.Property/index.js";
import * as CSS_Size from "../CSS.Size/index.js";
import * as CSS_String from "../CSS.String/index.js";
import * as CSS_Stylesheet from "../CSS.Stylesheet/index.js";
import * as Control_Alternative from "../Control.Alternative/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_NonEmpty from "../Data.NonEmpty/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
var value = /* #__PURE__ */ CSS_Property.value(CSS_Property.valNumber);
var fromString = /* #__PURE__ */ CSS_String.fromString(CSS_Property.isStringValue);
var fromString1 = /* #__PURE__ */ CSS_String.fromString(CSS_Property.isStringKey);
var key = /* #__PURE__ */ CSS_Stylesheet.key(CSS_Property.valValue);
var value1 = /* #__PURE__ */ CSS_Property.value(/* #__PURE__ */ CSS_Property.valList(CSS_Property.valValue));
var append = /* #__PURE__ */ Data_Semigroup.append(Data_Semigroup.semigroupArray);
var map = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var value2 = /* #__PURE__ */ CSS_Property.value(CSS_Property.valString);
var oneOf = /* #__PURE__ */ Data_NonEmpty.oneOf(Control_Alternative.alternativeArray);
var map1 = /* #__PURE__ */ Data_Functor.map(/* #__PURE__ */ Data_NonEmpty.functorNonEmpty(Data_Functor.functorArray));
var eq = /* #__PURE__ */ Data_Eq.eq(CSS_Property.eqValue);
var compare = /* #__PURE__ */ Data_Ord.compare(CSS_Property.ordValue);
var GenericFontFamily = function (x) {
    return x;
};
var FontWeight = function (x) {
    return x;
};
var weight = function (i) {
    return value(i);
};
var valGenericFontFamily = {
    value: function (v) {
        return v;
    }
};
var value3 = /* #__PURE__ */ CSS_Property.value(valGenericFontFamily);
var valFontWeight = {
    value: function (v) {
        return v;
    }
};
var unsetFontWeight = {
    unset: /* #__PURE__ */ fromString("unset")
};
var uiSerif = /* #__PURE__ */ fromString("ui-serif");
var uiSansSerif = /* #__PURE__ */ fromString("ui-sans-serif");
var uiRounded = /* #__PURE__ */ fromString("ui-rounded");
var uiMonospace = /* #__PURE__ */ fromString("ui-monospace");
var systemUi = /* #__PURE__ */ fromString("system-ui");
var serif = /* #__PURE__ */ fromString("serif");
var sansSerif = /* #__PURE__ */ fromString("sans-serif");
var normalFontWeight = {
    normal: /* #__PURE__ */ fromString("normal")
};
var monospace = /* #__PURE__ */ fromString("monospace");
var math = /* #__PURE__ */ fromString("math");
var lighter = /* #__PURE__ */ fromString("lighter");
var initialFontWeight = {
    initial: /* #__PURE__ */ fromString("initial")
};
var inheritFontWeight = {
    inherit: /* #__PURE__ */ fromString("inherit")
};
var fontWeight = /* #__PURE__ */ CSS_Stylesheet.key(valFontWeight)(/* #__PURE__ */ fromString1("font-weight"));
var fontSize = /* #__PURE__ */ CSS_Stylesheet.key(CSS_Size.valSize)(/* #__PURE__ */ fromString1("font-size"));
var fontFamily = function (a) {
    return function (b) {
        return key(fromString1("font-family"))(value1(append(map(function ($47) {
            return value2(CSS_Property.quote($47));
        })(a))(oneOf(map1(value3)(b)))));
    };
};
var fantasy = /* #__PURE__ */ fromString("fantasy");
var fangsong = /* #__PURE__ */ fromString("fangsong");
var eqGenericFontFamily = {
    eq: function (x) {
        return function (y) {
            return eq(x)(y);
        };
    }
};
var ordGenericFontFamily = {
    compare: function (x) {
        return function (y) {
            return compare(x)(y);
        };
    },
    Eq0: function () {
        return eqGenericFontFamily;
    }
};
var eqFontWeight = {
    eq: function (x) {
        return function (y) {
            return eq(x)(y);
        };
    }
};
var ordFontWeight = {
    compare: function (x) {
        return function (y) {
            return compare(x)(y);
        };
    },
    Eq0: function () {
        return eqFontWeight;
    }
};
var emoji = /* #__PURE__ */ fromString("emoji");
var cursive = /* #__PURE__ */ fromString("cursive");
var color = /* #__PURE__ */ CSS_Stylesheet.key(CSS_Property.valColor)(/* #__PURE__ */ fromString1("color"));
var bolder = /* #__PURE__ */ fromString("bolder");
var bold = /* #__PURE__ */ fromString("bold");
export {
    color,
    GenericFontFamily,
    serif,
    sansSerif,
    cursive,
    monospace,
    fantasy,
    systemUi,
    uiSerif,
    uiSansSerif,
    uiMonospace,
    uiRounded,
    emoji,
    math,
    fangsong,
    fontFamily,
    fontSize,
    FontWeight,
    bold,
    bolder,
    lighter,
    weight,
    fontWeight,
    eqGenericFontFamily,
    ordGenericFontFamily,
    valGenericFontFamily,
    eqFontWeight,
    ordFontWeight,
    valFontWeight,
    normalFontWeight,
    initialFontWeight,
    inheritFontWeight,
    unsetFontWeight
};
