// Generated by purs version 0.15.4
import * as Data_Active from "../Data.Active/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Halogen_HTML_CSS from "../Halogen.HTML.CSS/index.js";
import * as Halogen_HTML_Elements from "../Halogen.HTML.Elements/index.js";
import * as Halogen_HTML_Events from "../Halogen.HTML.Events/index.js";
import * as Halogen_HTML_Shadow from "../Halogen.HTML.Shadow/index.js";
import * as Toad_Atom_Button_Style from "../Toad.Atom.Button.Style/index.js";
import * as Toad_Css from "../Toad.Css/index.js";
import * as Toad_Html from "../Toad.Html/index.js";
var append = /* #__PURE__ */ Data_Semigroup.append(Data_Semigroup.semigroupArray);
var renderPlain = function (v) {
    return Halogen_HTML_Elements.div([ Halogen_HTML_Shadow.shadow, Toad_Css.style(v.styleContainer) ])([ Halogen_HTML_CSS.stylesheet(Toad_Atom_Button_Style.button(v.theme)), Halogen_HTML_Elements.button([ Toad_Html.classNames([ Toad_Atom_Button_Style.className ]), Toad_Css.style(v.styleButton) ])(v.children) ]);
};
var render = function (v) {
    return Halogen_HTML_Elements.div([ Halogen_HTML_Shadow.shadow, Toad_Css.style(v.styleContainer) ])([ Halogen_HTML_CSS.stylesheet(Toad_Atom_Button_Style.button(v.theme)), Halogen_HTML_Elements.button([ Toad_Html.classNames(append([ Toad_Atom_Button_Style.className ])((function () {
        if (v.active instanceof Data_Active.Active) {
            return [ Toad_Atom_Button_Style.activeClassName ];
        };
        if (v.active instanceof Data_Active.Inactive) {
            return [  ];
        };
        throw new Error("Failed pattern match at Toad.Atom.Button (line 38, column 16 - line 40, column 29): " + [ v.active.constructor.name ]);
    })())), Halogen_HTML_Events.onClick(Data_Function["const"](v.onClick)), Toad_Css.style(v.styleButton) ])(v.children) ]);
};
export {
    render,
    renderPlain
};
export {
    Theme,
    primary
} from "../Toad.Atom.Button.Style/index.js";
