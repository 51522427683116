// Generated by purs version 0.15.4
import * as Control_Alt from "../Control.Alt/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Apply from "../Control.Apply/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Category from "../Control.Category/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Array_NonEmpty from "../Data.Array.NonEmpty/index.js";
import * as Data_Array_NonEmpty_Internal from "../Data.Array.NonEmpty.Internal/index.js";
import * as Data_Boolean from "../Data.Boolean/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Generic_Rep from "../Data.Generic.Rep/index.js";
import * as Data_Identity from "../Data.Identity/index.js";
import * as Data_List_Types from "../Data.List.Types/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_Show_Generic from "../Data.Show.Generic/index.js";
import * as Data_String_Common from "../Data.String.Common/index.js";
import * as Data_String_NonEmpty_CodeUnits from "../Data.String.NonEmpty.CodeUnits/index.js";
import * as Data_String_NonEmpty_Internal from "../Data.String.NonEmpty.Internal/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
import * as Parsing from "../Parsing/index.js";
import * as Parsing_Combinators from "../Parsing.Combinators/index.js";
import * as Parsing_Combinators_Array from "../Parsing.Combinators.Array/index.js";
import * as Parsing_String from "../Parsing.String/index.js";
import * as Parsing_String_Basic from "../Parsing.String.Basic/index.js";
import * as Toad_Concept from "../Toad.Concept/index.js";
var bind = /* #__PURE__ */ Control_Bind.bind(Parsing.bindParserT);
var pure = /* #__PURE__ */ Control_Applicative.pure(Parsing.applicativeParserT);
var choice = /* #__PURE__ */ Parsing_Combinators.choice(Data_Foldable.foldableArray);
var mapFlipped = /* #__PURE__ */ Data_Functor.mapFlipped(Parsing.functorParserT);
var mapFlipped1 = /* #__PURE__ */ Data_Functor.mapFlipped(Data_Functor.functorArray);
var append = /* #__PURE__ */ Data_Semigroup.append(Data_Semigroup.semigroupArray);
var fromFoldable1 = /* #__PURE__ */ Data_Array_NonEmpty.fromFoldable1(Data_List_Types.foldable1NonEmptyList);
var map = /* #__PURE__ */ Data_Functor.map(Parsing.functorParserT);
var genericShowArgsArgument = /* #__PURE__ */ Data_Show_Generic.genericShowArgsArgument(Data_Show.showString);
var genericShowConstructor = /* #__PURE__ */ Data_Show_Generic.genericShowConstructor(genericShowArgsArgument);
var eq1 = /* #__PURE__ */ Data_Eq.eq(Data_String_NonEmpty_Internal.eqNonEmptyString);
var eq2 = /* #__PURE__ */ Data_Eq.eq(Toad_Concept.eqIdent);
var pure1 = /* #__PURE__ */ Control_Applicative.pure(Control_Applicative.applicativeArray);
var append2 = /* #__PURE__ */ Data_Semigroup.append(Data_Array_NonEmpty_Internal.semigroupNonEmptyArray);
var foldl = /* #__PURE__ */ Data_Foldable.foldl(Data_Foldable.foldableArray);
var anyTill = /* #__PURE__ */ Parsing_String.anyTill(Data_Identity.monadIdentity);
var mapFlipped2 = /* #__PURE__ */ Data_Functor.mapFlipped(Data_Maybe.functorMaybe);
var fold = /* #__PURE__ */ Data_Foldable.fold(Data_Array_NonEmpty_Internal.foldableNonEmptyArray)(Data_Monoid.monoidString);
var map1 = /* #__PURE__ */ Data_Functor.map(Data_Array_NonEmpty_Internal.functorNonEmptyArray);
var identity = /* #__PURE__ */ Control_Category.identity(Control_Category.categoryFn);
var alt = /* #__PURE__ */ Control_Alt.alt(Parsing.altParserT);
var map2 = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var applySecond = /* #__PURE__ */ Control_Apply.applySecond(Parsing.applyParserT);
var WrapStar1 = /* #__PURE__ */ (function () {
    function WrapStar1() {

    };
    WrapStar1.value = new WrapStar1();
    return WrapStar1;
})();
var WrapStar2 = /* #__PURE__ */ (function () {
    function WrapStar2() {

    };
    WrapStar2.value = new WrapStar2();
    return WrapStar2;
})();
var WrapStar3 = /* #__PURE__ */ (function () {
    function WrapStar3() {

    };
    WrapStar3.value = new WrapStar3();
    return WrapStar3;
})();
var WrapStar2Under1 = /* #__PURE__ */ (function () {
    function WrapStar2Under1() {

    };
    WrapStar2Under1.value = new WrapStar2Under1();
    return WrapStar2Under1;
})();
var WrapUnder1Star2 = /* #__PURE__ */ (function () {
    function WrapUnder1Star2() {

    };
    WrapUnder1Star2.value = new WrapUnder1Star2();
    return WrapUnder1Star2;
})();
var WrapUnder1 = /* #__PURE__ */ (function () {
    function WrapUnder1() {

    };
    WrapUnder1.value = new WrapUnder1();
    return WrapUnder1;
})();
var WrapBacktick = /* #__PURE__ */ (function () {
    function WrapBacktick() {

    };
    WrapBacktick.value = new WrapBacktick();
    return WrapBacktick;
})();
var Unstyled = /* #__PURE__ */ (function () {
    function Unstyled(value0) {
        this.value0 = value0;
    };
    Unstyled.create = function (value0) {
        return new Unstyled(value0);
    };
    return Unstyled;
})();
var Bold = /* #__PURE__ */ (function () {
    function Bold(value0) {
        this.value0 = value0;
    };
    Bold.create = function (value0) {
        return new Bold(value0);
    };
    return Bold;
})();
var Italic = /* #__PURE__ */ (function () {
    function Italic(value0) {
        this.value0 = value0;
    };
    Italic.create = function (value0) {
        return new Italic(value0);
    };
    return Italic;
})();
var BoldItalic = /* #__PURE__ */ (function () {
    function BoldItalic(value0) {
        this.value0 = value0;
    };
    BoldItalic.create = function (value0) {
        return new BoldItalic(value0);
    };
    return BoldItalic;
})();
var InlineCode = /* #__PURE__ */ (function () {
    function InlineCode(value0) {
        this.value0 = value0;
    };
    InlineCode.create = function (value0) {
        return new InlineCode(value0);
    };
    return InlineCode;
})();
var Stop = function (x) {
    return x;
};
var IndentSpaces = /* #__PURE__ */ (function () {
    function IndentSpaces(value0) {
        this.value0 = value0;
    };
    IndentSpaces.create = function (value0) {
        return new IndentSpaces(value0);
    };
    return IndentSpaces;
})();
var CodeFenceFileType = /* #__PURE__ */ (function () {
    function CodeFenceFileType(value0) {
        this.value0 = value0;
    };
    CodeFenceFileType.create = function (value0) {
        return new CodeFenceFileType(value0);
    };
    return CodeFenceFileType;
})();
var CodeFence = /* #__PURE__ */ (function () {
    function CodeFence(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    CodeFence.create = function (value0) {
        return function (value1) {
            return new CodeFence(value0, value1);
        };
    };
    return CodeFence;
})();
var Anchor = /* #__PURE__ */ (function () {
    function Anchor(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Anchor.create = function (value0) {
        return function (value1) {
            return new Anchor(value0, value1);
        };
    };
    return Anchor;
})();
var ConceptAnchor = /* #__PURE__ */ (function () {
    function ConceptAnchor(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    ConceptAnchor.create = function (value0) {
        return function (value1) {
            return new ConceptAnchor(value0, value1);
        };
    };
    return ConceptAnchor;
})();
var AnchorToken = /* #__PURE__ */ (function () {
    function AnchorToken(value0) {
        this.value0 = value0;
    };
    AnchorToken.create = function (value0) {
        return new AnchorToken(value0);
    };
    return AnchorToken;
})();
var TextToken = /* #__PURE__ */ (function () {
    function TextToken(value0) {
        this.value0 = value0;
    };
    TextToken.create = function (value0) {
        return new TextToken(value0);
    };
    return TextToken;
})();
var Span = /* #__PURE__ */ (function () {
    function Span(value0) {
        this.value0 = value0;
    };
    Span.create = function (value0) {
        return new Span(value0);
    };
    return Span;
})();
var H1 = /* #__PURE__ */ (function () {
    function H1(value0) {
        this.value0 = value0;
    };
    H1.create = function (value0) {
        return new H1(value0);
    };
    return H1;
})();
var H2 = /* #__PURE__ */ (function () {
    function H2(value0) {
        this.value0 = value0;
    };
    H2.create = function (value0) {
        return new H2(value0);
    };
    return H2;
})();
var H3 = /* #__PURE__ */ (function () {
    function H3(value0) {
        this.value0 = value0;
    };
    H3.create = function (value0) {
        return new H3(value0);
    };
    return H3;
})();
var H4 = /* #__PURE__ */ (function () {
    function H4(value0) {
        this.value0 = value0;
    };
    H4.create = function (value0) {
        return new H4(value0);
    };
    return H4;
})();
var H5 = /* #__PURE__ */ (function () {
    function H5(value0) {
        this.value0 = value0;
    };
    H5.create = function (value0) {
        return new H5(value0);
    };
    return H5;
})();
var H6 = /* #__PURE__ */ (function () {
    function H6(value0) {
        this.value0 = value0;
    };
    H6.create = function (value0) {
        return new H6(value0);
    };
    return H6;
})();
var ListTokenSpan = /* #__PURE__ */ (function () {
    function ListTokenSpan(value0) {
        this.value0 = value0;
    };
    ListTokenSpan.create = function (value0) {
        return new ListTokenSpan(value0);
    };
    return ListTokenSpan;
})();
var ListTokenSpanSublist = /* #__PURE__ */ (function () {
    function ListTokenSpanSublist(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    ListTokenSpanSublist.create = function (value0) {
        return function (value1) {
            return new ListTokenSpanSublist(value0, value1);
        };
    };
    return ListTokenSpanSublist;
})();
var OrderedList = /* #__PURE__ */ (function () {
    function OrderedList(value0) {
        this.value0 = value0;
    };
    OrderedList.create = function (value0) {
        return new OrderedList(value0);
    };
    return OrderedList;
})();
var UnorderedList = /* #__PURE__ */ (function () {
    function UnorderedList(value0) {
        this.value0 = value0;
    };
    UnorderedList.create = function (value0) {
        return new UnorderedList(value0);
    };
    return UnorderedList;
})();
var ElementHeading = /* #__PURE__ */ (function () {
    function ElementHeading(value0) {
        this.value0 = value0;
    };
    ElementHeading.create = function (value0) {
        return new ElementHeading(value0);
    };
    return ElementHeading;
})();
var ElementCodeFence = /* #__PURE__ */ (function () {
    function ElementCodeFence(value0) {
        this.value0 = value0;
    };
    ElementCodeFence.create = function (value0) {
        return new ElementCodeFence(value0);
    };
    return ElementCodeFence;
})();
var ElementSpan = /* #__PURE__ */ (function () {
    function ElementSpan(value0) {
        this.value0 = value0;
    };
    ElementSpan.create = function (value0) {
        return new ElementSpan(value0);
    };
    return ElementSpan;
})();
var ElementList = /* #__PURE__ */ (function () {
    function ElementList(value0) {
        this.value0 = value0;
    };
    ElementList.create = function (value0) {
        return new ElementList(value0);
    };
    return ElementList;
})();
var ElementComment = /* #__PURE__ */ (function () {
    function ElementComment(value0) {
        this.value0 = value0;
    };
    ElementComment.create = function (value0) {
        return new ElementComment(value0);
    };
    return ElementComment;
})();
var Document = function (x) {
    return x;
};
var wrapOpen_ = function (v) {
    if (v instanceof WrapStar1) {
        return "*";
    };
    if (v instanceof WrapStar2) {
        return "**";
    };
    if (v instanceof WrapStar3) {
        return "***";
    };
    if (v instanceof WrapStar2Under1) {
        return "**_";
    };
    if (v instanceof WrapUnder1Star2) {
        return "_**";
    };
    if (v instanceof WrapUnder1) {
        return "_";
    };
    if (v instanceof WrapBacktick) {
        return "`";
    };
    throw new Error("Failed pattern match at Toad.Markdown (line 331, column 13 - line 338, column 22): " + [ v.constructor.name ]);
};
var wrapNotFollowedByStar = function (v) {
    if (v instanceof WrapStar1) {
        return true;
    };
    if (v instanceof WrapStar2) {
        return true;
    };
    return false;
};
var wrapOpen = function (w) {
    if (wrapNotFollowedByStar(w)) {
        return bind(Parsing_String.string(wrapOpen_(w)))(function () {
            return bind(Parsing_Combinators.notFollowedBy(Parsing_String.string("*")))(function () {
                return pure(w);
            });
        });
    };
    if (Data_Boolean.otherwise) {
        return bind(Parsing_String.string(wrapOpen_(w)))(function () {
            return pure(w);
        });
    };
    throw new Error("Failed pattern match at Toad.Markdown (line 350, column 1 - line 350, column 39): " + [ w.constructor.name ]);
};
var wrapClose_ = function (v) {
    if (v instanceof WrapStar2Under1) {
        return "_**";
    };
    if (v instanceof WrapUnder1Star2) {
        return "**_";
    };
    return wrapOpen_(v);
};
var wrapClose = function (w) {
    return bind(Parsing_String.string(wrapClose_(w)))(function () {
        return pure(w);
    });
};
var tokenText = function (v) {
    if (v instanceof TextToken) {
        return new Data_Maybe.Just(v.value0);
    };
    return Data_Maybe.Nothing.value;
};
var tokenStop = /* #__PURE__ */ choice([ /* #__PURE__ */ mapFlipped(Parsing_String.eof)(/* #__PURE__ */ Data_Function["const"]("")), /* #__PURE__ */ Parsing_String.string("\x0a\x0a") ]);
var textString = function (v) {
    if (v instanceof Unstyled) {
        return v.value0;
    };
    if (v instanceof Bold) {
        return v.value0;
    };
    if (v instanceof Italic) {
        return v.value0;
    };
    if (v instanceof BoldItalic) {
        return v.value0;
    };
    if (v instanceof InlineCode) {
        return v.value0;
    };
    throw new Error("Failed pattern match at Toad.Markdown (line 46, column 1 - line 46, column 29): " + [ v.constructor.name ]);
};
var stop = function (v) {
    return v;
};
var untilTokenStopOr = function (others) {
    return mapFlipped(Parsing_Combinators.many1Till_(Parsing_String.anyChar)(choice(mapFlipped1(append([ tokenStop ])(others))(stop))))(function ($539) {
        return Data_String_NonEmpty_Internal.toString(Data_String_NonEmpty_CodeUnits.fromNonEmptyCharArray(fromFoldable1(Data_Tuple.fst($539))));
    });
};
var textP = function (stops) {
    var stopOr = function (p) {
        return bind(Parsing_Combinators.notFollowedBy(choice(mapFlipped1(stops)(stop))))(function () {
            return p;
        });
    };
    var textP$prime = function (t) {
        return function (ws) {
            return Parsing_Combinators["try"](stopOr(bind(choice(mapFlipped1(ws)(wrapOpen)))(function (wrap) {
                return bind(untilTokenStopOr(append(stops)([ map(Data_Function["const"](""))(wrapClose(wrap)) ])))(function (s) {
                    return pure(t(s));
                });
            })));
        };
    };
    return choice([ textP$prime(InlineCode.create)([ WrapBacktick.value ]), textP$prime(BoldItalic.create)([ WrapStar3.value, WrapStar2Under1.value, WrapUnder1Star2.value ]), textP$prime(Italic.create)([ WrapStar1.value, WrapUnder1.value ]), textP$prime(Bold.create)([ WrapStar2.value ]), mapFlipped(mapFlipped(mapFlipped(mapFlipped(stopOr(Parsing_String.anyChar))(Data_Array_NonEmpty.singleton))(Data_String_NonEmpty_CodeUnits.fromNonEmptyCharArray))(Data_String_NonEmpty_Internal.toString))(Unstyled.create) ]);
};
var listRootIndentation = /* #__PURE__ */ (function () {
    return [ new IndentSpaces(2), new IndentSpaces(1) ];
})();
var indentP = function (v) {
    return Parsing_Combinators["try"](bind(Parsing_String.string(Data_String_Common.joinWith("")(Data_Array.replicate(v.value0)(" "))))(function () {
        return pure(v);
    }));
};
var genericToken = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return new AnchorToken(x.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr) {
            return new TextToken(x.value0);
        };
        throw new Error("Failed pattern match at Toad.Markdown (line 71, column 1 - line 71, column 48): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof AnchorToken) {
            return new Data_Generic_Rep.Inl(x.value0);
        };
        if (x instanceof TextToken) {
            return new Data_Generic_Rep.Inr(x.value0);
        };
        throw new Error("Failed pattern match at Toad.Markdown (line 71, column 1 - line 71, column 48): " + [ x.constructor.name ]);
    }
};
var genericText = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return new Unstyled(x.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return new Bold(x.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return new Italic(x.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
            return new BoldItalic(x.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inr))) {
            return new InlineCode(x.value0.value0.value0.value0);
        };
        throw new Error("Failed pattern match at Toad.Markdown (line 42, column 1 - line 42, column 46): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof Unstyled) {
            return new Data_Generic_Rep.Inl(x.value0);
        };
        if (x instanceof Bold) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
        };
        if (x instanceof Italic) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)));
        };
        if (x instanceof BoldItalic) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))));
        };
        if (x instanceof InlineCode) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0))));
        };
        throw new Error("Failed pattern match at Toad.Markdown (line 42, column 1 - line 42, column 46): " + [ x.constructor.name ]);
    }
};
var showText = {
    show: /* #__PURE__ */ Data_Show_Generic.genericShow(genericText)(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "Unstyled";
        }
    }))(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "Bold";
        }
    }))(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "Italic";
        }
    }))(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "BoldItalic";
        }
    }))(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "InlineCode";
        }
    }))))))
};
var genericShowArgsProduct = /* #__PURE__ */ Data_Show_Generic.genericShowArgsProduct(/* #__PURE__ */ Data_Show_Generic.genericShowArgsArgument(/* #__PURE__ */ Data_Array_NonEmpty_Internal.showNonEmptyArray(showText)));
var show = /* #__PURE__ */ Data_Show.show(showText);
var genericSpan = {
    to: function (x) {
        return new Span(x);
    },
    from: function (x) {
        return x.value0;
    }
};
var genericIndent = {
    to: function (x) {
        return new IndentSpaces(x);
    },
    from: function (x) {
        return x.value0;
    }
};
var showIndent = {
    show: /* #__PURE__ */ Data_Show_Generic.genericShow(genericIndent)(/* #__PURE__ */ Data_Show_Generic.genericShowConstructor(/* #__PURE__ */ Data_Show_Generic.genericShowArgsArgument(Data_Show.showInt))({
        reflectSymbol: function () {
            return "IndentSpaces";
        }
    }))
};
var genericHeading = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return new H1(x.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return new H2(x.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return new H3(x.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
            return new H4(x.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))) {
            return new H5(x.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr)))) {
            return new H6(x.value0.value0.value0.value0.value0);
        };
        throw new Error("Failed pattern match at Toad.Markdown (line 103, column 1 - line 103, column 52): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof H1) {
            return new Data_Generic_Rep.Inl(x.value0);
        };
        if (x instanceof H2) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
        };
        if (x instanceof H3) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)));
        };
        if (x instanceof H4) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))));
        };
        if (x instanceof H5) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))));
        };
        if (x instanceof H6) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0)))));
        };
        throw new Error("Failed pattern match at Toad.Markdown (line 103, column 1 - line 103, column 52): " + [ x.constructor.name ]);
    }
};
var genericElement = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return new ElementHeading(x.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return new ElementCodeFence(x.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return new ElementSpan(x.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
            return new ElementList(x.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inr))) {
            return new ElementComment(x.value0.value0.value0.value0);
        };
        throw new Error("Failed pattern match at Toad.Markdown (line 149, column 1 - line 149, column 52): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof ElementHeading) {
            return new Data_Generic_Rep.Inl(x.value0);
        };
        if (x instanceof ElementCodeFence) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
        };
        if (x instanceof ElementSpan) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)));
        };
        if (x instanceof ElementList) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))));
        };
        if (x instanceof ElementComment) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0))));
        };
        throw new Error("Failed pattern match at Toad.Markdown (line 149, column 1 - line 149, column 52): " + [ x.constructor.name ]);
    }
};
var genericCodeFenceFileType = {
    to: function (x) {
        return new CodeFenceFileType(x);
    },
    from: function (x) {
        return x.value0;
    }
};
var showCodeFenceFileType = {
    show: /* #__PURE__ */ Data_Show_Generic.genericShow(genericCodeFenceFileType)(/* #__PURE__ */ Data_Show_Generic.genericShowConstructor(/* #__PURE__ */ Data_Show_Generic.genericShowArgsArgument(Data_String_NonEmpty_Internal.showNonEmptyString))({
        reflectSymbol: function () {
            return "CodeFenceFileType";
        }
    }))
};
var genericCodeFence = {
    to: function (x) {
        return new CodeFence(x.value0, x.value1);
    },
    from: function (x) {
        return new Data_Generic_Rep.Product(x.value0, x.value1);
    }
};
var showCodeFence = {
    show: /* #__PURE__ */ Data_Show_Generic.genericShow(genericCodeFence)(/* #__PURE__ */ Data_Show_Generic.genericShowConstructor(/* #__PURE__ */ Data_Show_Generic.genericShowArgsProduct(/* #__PURE__ */ Data_Show_Generic.genericShowArgsArgument(/* #__PURE__ */ Data_Maybe.showMaybe(showCodeFenceFileType)))(genericShowArgsArgument))({
        reflectSymbol: function () {
            return "CodeFence";
        }
    }))
};
var genericAnchor = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return new Anchor(x.value0.value0, x.value0.value1);
        };
        if (x instanceof Data_Generic_Rep.Inr) {
            return new ConceptAnchor(x.value0.value0, x.value0.value1);
        };
        throw new Error("Failed pattern match at Toad.Markdown (line 58, column 1 - line 58, column 50): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof Anchor) {
            return new Data_Generic_Rep.Inl(new Data_Generic_Rep.Product(x.value0, x.value1));
        };
        if (x instanceof ConceptAnchor) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Product(x.value0, x.value1));
        };
        throw new Error("Failed pattern match at Toad.Markdown (line 58, column 1 - line 58, column 50): " + [ x.constructor.name ]);
    }
};
var showAnchor = {
    show: /* #__PURE__ */ Data_Show_Generic.genericShow(genericAnchor)(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ Data_Show_Generic.genericShowConstructor(/* #__PURE__ */ genericShowArgsProduct(genericShowArgsArgument))({
        reflectSymbol: function () {
            return "Anchor";
        }
    }))(/* #__PURE__ */ Data_Show_Generic.genericShowConstructor(/* #__PURE__ */ genericShowArgsProduct(/* #__PURE__ */ Data_Show_Generic.genericShowArgsArgument(Toad_Concept.showIdent)))({
        reflectSymbol: function () {
            return "ConceptAnchor";
        }
    })))
};
var show1 = /* #__PURE__ */ Data_Show.show(showAnchor);
var showToken = {
    show: function (v) {
        if (v instanceof AnchorToken) {
            return "AnchorToken (" + (show1(v.value0) + ")");
        };
        if (v instanceof TextToken) {
            return "TextToken (" + (show(v.value0) + ")");
        };
        throw new Error("Failed pattern match at Toad.Markdown (line 72, column 1 - line 74, column 60): " + [ v.constructor.name ]);
    }
};
var showSpan = {
    show: /* #__PURE__ */ Data_Show_Generic.genericShow(genericSpan)(/* #__PURE__ */ Data_Show_Generic.genericShowConstructor(/* #__PURE__ */ Data_Show_Generic.genericShowArgsArgument(/* #__PURE__ */ Data_Array_NonEmpty_Internal.showNonEmptyArray(showToken)))({
        reflectSymbol: function () {
            return "Span";
        }
    }))
};
var genericShowConstructor1 = /* #__PURE__ */ Data_Show_Generic.genericShowConstructor(/* #__PURE__ */ Data_Show_Generic.genericShowArgsArgument(showSpan));
var show2 = /* #__PURE__ */ Data_Show.show(showSpan);
var showHeading = {
    show: /* #__PURE__ */ Data_Show_Generic.genericShow(genericHeading)(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ genericShowConstructor1({
        reflectSymbol: function () {
            return "H1";
        }
    }))(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ genericShowConstructor1({
        reflectSymbol: function () {
            return "H2";
        }
    }))(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ genericShowConstructor1({
        reflectSymbol: function () {
            return "H3";
        }
    }))(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ genericShowConstructor1({
        reflectSymbol: function () {
            return "H4";
        }
    }))(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ genericShowConstructor1({
        reflectSymbol: function () {
            return "H5";
        }
    }))(/* #__PURE__ */ genericShowConstructor1({
        reflectSymbol: function () {
            return "H6";
        }
    })))))))
};
var showListToken = {
    show: function (v) {
        if (v instanceof ListTokenSpan) {
            return "ListTokenSpan (" + (show2(v.value0) + ")");
        };
        if (v instanceof ListTokenSpanSublist) {
            return "ListTokenSpanSublist (" + (show2(v.value0) + (") (" + (Data_Show.show(showList)(v.value1) + ")")));
        };
        throw new Error("Failed pattern match at Toad.Markdown (line 126, column 1 - line 130, column 11): " + [ v.constructor.name ]);
    }
};
var showList = {
    show: function (v) {
        if (v instanceof UnorderedList) {
            return "UnorderedList (" + (Data_Show.show(Data_Array_NonEmpty_Internal.showNonEmptyArray(showListToken))(v.value0) + ")");
        };
        if (v instanceof OrderedList) {
            return "OrderedList (" + (Data_Show.show(Data_Array_NonEmpty_Internal.showNonEmptyArray(showListToken))(v.value0) + ")");
        };
        throw new Error("Failed pattern match at Toad.Markdown (line 137, column 1 - line 139, column 62): " + [ v.constructor.name ]);
    }
};
var showElement = {
    show: /* #__PURE__ */ Data_Show_Generic.genericShow(genericElement)(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ Data_Show_Generic.genericShowConstructor(/* #__PURE__ */ Data_Show_Generic.genericShowArgsArgument(showHeading))({
        reflectSymbol: function () {
            return "ElementHeading";
        }
    }))(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ Data_Show_Generic.genericShowConstructor(/* #__PURE__ */ Data_Show_Generic.genericShowArgsArgument(showCodeFence))({
        reflectSymbol: function () {
            return "ElementCodeFence";
        }
    }))(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ genericShowConstructor1({
        reflectSymbol: function () {
            return "ElementSpan";
        }
    }))(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ Data_Show_Generic.genericShowConstructor(/* #__PURE__ */ Data_Show_Generic.genericShowArgsArgument(showList))({
        reflectSymbol: function () {
            return "ElementList";
        }
    }))(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "ElementComment";
        }
    }))))))
};
var eqText = {
    eq: function (x) {
        return function (y) {
            if (x instanceof Unstyled && y instanceof Unstyled) {
                return x.value0 === y.value0;
            };
            if (x instanceof Bold && y instanceof Bold) {
                return x.value0 === y.value0;
            };
            if (x instanceof Italic && y instanceof Italic) {
                return x.value0 === y.value0;
            };
            if (x instanceof BoldItalic && y instanceof BoldItalic) {
                return x.value0 === y.value0;
            };
            if (x instanceof InlineCode && y instanceof InlineCode) {
                return x.value0 === y.value0;
            };
            return false;
        };
    }
};
var eq3 = /* #__PURE__ */ Data_Eq.eq(/* #__PURE__ */ Data_Array_NonEmpty_Internal.eqNonEmptyArray(eqText));
var eq4 = /* #__PURE__ */ Data_Eq.eq(eqText);
var eqCodeFenceFileType = {
    eq: function (x) {
        return function (y) {
            return eq1(x.value0)(y.value0);
        };
    }
};
var eq5 = /* #__PURE__ */ Data_Eq.eq(/* #__PURE__ */ Data_Maybe.eqMaybe(eqCodeFenceFileType));
var eqCodeFence = {
    eq: function (x) {
        return function (y) {
            return eq5(x.value0)(y.value0) && x.value1 === y.value1;
        };
    }
};
var eq6 = /* #__PURE__ */ Data_Eq.eq(eqCodeFence);
var eqAnchor = {
    eq: function (x) {
        return function (y) {
            if (x instanceof Anchor && y instanceof Anchor) {
                return eq3(x.value0)(y.value0) && x.value1 === y.value1;
            };
            if (x instanceof ConceptAnchor && y instanceof ConceptAnchor) {
                return eq3(x.value0)(y.value0) && eq2(x.value1)(y.value1);
            };
            return false;
        };
    }
};
var eq7 = /* #__PURE__ */ Data_Eq.eq(eqAnchor);
var eqToken = {
    eq: function (x) {
        return function (y) {
            if (x instanceof AnchorToken && y instanceof AnchorToken) {
                return eq7(x.value0)(y.value0);
            };
            if (x instanceof TextToken && y instanceof TextToken) {
                return eq4(x.value0)(y.value0);
            };
            return false;
        };
    }
};
var eq8 = /* #__PURE__ */ Data_Eq.eq(/* #__PURE__ */ Data_Array_NonEmpty_Internal.eqNonEmptyArray(eqToken));
var eqSpan = {
    eq: function (x) {
        return function (y) {
            return eq8(x.value0)(y.value0);
        };
    }
};
var eq9 = /* #__PURE__ */ Data_Eq.eq(eqSpan);
var eqHeading = {
    eq: function (x) {
        return function (y) {
            if (x instanceof H1 && y instanceof H1) {
                return eq9(x.value0)(y.value0);
            };
            if (x instanceof H2 && y instanceof H2) {
                return eq9(x.value0)(y.value0);
            };
            if (x instanceof H3 && y instanceof H3) {
                return eq9(x.value0)(y.value0);
            };
            if (x instanceof H4 && y instanceof H4) {
                return eq9(x.value0)(y.value0);
            };
            if (x instanceof H5 && y instanceof H5) {
                return eq9(x.value0)(y.value0);
            };
            if (x instanceof H6 && y instanceof H6) {
                return eq9(x.value0)(y.value0);
            };
            return false;
        };
    }
};
var eq10 = /* #__PURE__ */ Data_Eq.eq(eqHeading);
var eqListToken = {
    eq: function (x) {
        return function (y) {
            if (x instanceof ListTokenSpan && y instanceof ListTokenSpan) {
                return eq9(x.value0)(y.value0);
            };
            if (x instanceof ListTokenSpanSublist && y instanceof ListTokenSpanSublist) {
                return eq9(x.value0)(y.value0) && Data_Eq.eq(eqList)(x.value1)(y.value1);
            };
            return false;
        };
    }
};
var eqList = {
    eq: function (x) {
        return function (y) {
            if (x instanceof OrderedList && y instanceof OrderedList) {
                return Data_Eq.eq(Data_Array_NonEmpty_Internal.eqNonEmptyArray(eqListToken))(x.value0)(y.value0);
            };
            if (x instanceof UnorderedList && y instanceof UnorderedList) {
                return Data_Eq.eq(Data_Array_NonEmpty_Internal.eqNonEmptyArray(eqListToken))(x.value0)(y.value0);
            };
            return false;
        };
    }
};
var eq11 = /* #__PURE__ */ Data_Eq.eq(eqList);
var eqElement = {
    eq: function (x) {
        return function (y) {
            if (x instanceof ElementHeading && y instanceof ElementHeading) {
                return eq10(x.value0)(y.value0);
            };
            if (x instanceof ElementCodeFence && y instanceof ElementCodeFence) {
                return eq6(x.value0)(y.value0);
            };
            if (x instanceof ElementSpan && y instanceof ElementSpan) {
                return eq9(x.value0)(y.value0);
            };
            if (x instanceof ElementList && y instanceof ElementList) {
                return eq11(x.value0)(y.value0);
            };
            if (x instanceof ElementComment && y instanceof ElementComment) {
                return x.value0 === y.value0;
            };
            return false;
        };
    }
};
var elements = function (v) {
    return v;
};
var documentSemi = Data_Semigroup.semigroupArray;
var documentMoid = Data_Monoid.monoidArray;
var documentGeneric = {
    to: function (x) {
        return x;
    },
    from: function (x) {
        return x;
    }
};
var showDocument = {
    show: /* #__PURE__ */ Data_Show_Generic.genericShow(documentGeneric)(/* #__PURE__ */ Data_Show_Generic.genericShowConstructor(/* #__PURE__ */ Data_Show_Generic.genericShowArgsArgument(/* #__PURE__ */ Data_Show.showArray(showElement)))({
        reflectSymbol: function () {
            return "Document";
        }
    }))
};
var documentEq = /* #__PURE__ */ Data_Eq.eqArray(eqElement);
var commentP = /* #__PURE__ */ bind(/* #__PURE__ */ Parsing_String.string("<!--"))(function () {
    return bind(untilTokenStopOr(pure1(Stop(Parsing_String.string("-->")))))(function (t) {
        return bind(Parsing_Combinators.optional(Parsing_String.string("\x0a")))(function () {
            return pure(Data_String_Common.trim(t));
        });
    });
});
var combineUnstyled = function (text) {
    return function (ofText) {
        return function (tokens) {
            var collapse = function (soFar) {
                return function (token) {
                    var $518 = [ text(Data_Array_NonEmpty.last(soFar)), text(token) ];
                    if ($518.length === 2 && ($518[0] instanceof Data_Maybe.Just && ($518[0].value0 instanceof Unstyled && ($518[1] instanceof Data_Maybe.Just && $518[1].value0 instanceof Unstyled)))) {
                        return Data_Maybe.maybe(Data_Array_NonEmpty.singleton(ofText(new Unstyled($518[0].value0.value0 + $518[1].value0.value0))))(function (v) {
                            return append2(v)(Data_Array_NonEmpty.singleton(ofText(new Unstyled($518[0].value0.value0 + $518[1].value0.value0))));
                        })(Data_Array_NonEmpty.fromArray(Data_Array_NonEmpty.init(soFar)));
                    };
                    return append2(soFar)(Data_Array_NonEmpty.singleton(token));
                };
            };
            return foldl(collapse)(Data_Array_NonEmpty.singleton(Data_Array_NonEmpty.head(tokens)))(Data_Array_NonEmpty.tail(tokens));
        };
    };
};
var codeFenceP = /* #__PURE__ */ bind(/* #__PURE__ */ Parsing_Combinators.many(/* #__PURE__ */ Parsing_String.string("\x0a")))(function () {
    return bind(Parsing_String.string("```"))(function () {
        return bind(mapFlipped(anyTill(Parsing_String.string("\x0a")))(Data_Tuple.fst))(function (fileType) {
            return bind(mapFlipped(mapFlipped(anyTill(Parsing_String.string("```")))(Data_Tuple.fst))(Data_String_Common.trim))(function (contents) {
                return pure(new CodeFence(mapFlipped2(Data_String_NonEmpty_Internal.fromString(fileType))(CodeFenceFileType.create), contents));
            });
        });
    });
});
var anchorString = function (v) {
    if (v instanceof Anchor) {
        return fold(map1(textString)(v.value0));
    };
    if (v instanceof ConceptAnchor) {
        return fold(map1(textString)(v.value0));
    };
    throw new Error("Failed pattern match at Toad.Markdown (line 62, column 1 - line 62, column 33): " + [ v.constructor.name ]);
};
var tokenString = function (v) {
    if (v instanceof AnchorToken) {
        return anchorString(v.value0);
    };
    if (v instanceof TextToken) {
        return textString(v.value0);
    };
    throw new Error("Failed pattern match at Toad.Markdown (line 76, column 1 - line 76, column 31): " + [ v.constructor.name ]);
};
var spanString = function (v) {
    return fold(map1(tokenString)(v.value0));
};
var anchorP = /* #__PURE__ */ bind(/* #__PURE__ */ Parsing_String["char"]("["))(function () {
    return bind(mapFlipped(mapFlipped(Parsing_Combinators.many1Till_(Parsing_Combinators.advance(textP(pure1(Stop(Parsing_Combinators.lookAhead(Parsing_String.string("]")))))))(Parsing_Combinators.lookAhead(Parsing_String.string("]"))))(Data_Tuple.fst))(fromFoldable1))(function (label) {
        return bind(Parsing_String["char"]("]"))(function () {
            return bind(Parsing_Combinators.many(Parsing_String["char"](" ")))(function () {
                return bind(Parsing_String["char"]("("))(function () {
                    return bind(Parsing_Combinators.optionMaybe(Parsing_String["char"]("@")))(function (at) {
                        return bind(untilTokenStopOr(pure1(Stop(Parsing_String.string(")")))))(function (href) {
                            var text = combineUnstyled(Data_Maybe.Just.create)(identity)(label);
                            return pure(Data_Maybe.maybe(new Anchor(text, href))(Data_Function["const"](ConceptAnchor.create(text)(Toad_Concept.Ident(href))))(at));
                        });
                    });
                });
            });
        });
    });
});
var tokenP = function (stops) {
    return alt(mapFlipped(Parsing_Combinators["try"](anchorP))(AnchorToken.create))(mapFlipped(textP(append([ tokenStop ])(stops)))(TextToken.create));
};
var spanP = function (stops) {
    return mapFlipped(Parsing_Combinators.many1Till_(tokenP(stops))(choice(mapFlipped1(append([ tokenStop ])(stops))(stop))))((function () {
        var $540 = combineUnstyled(tokenText)(TextToken.create);
        return function ($541) {
            return Span.create($540(fromFoldable1(Data_Tuple.fst($541))));
        };
    })());
};
var headingP = /* #__PURE__ */ (function () {
    var h = function (pre) {
        return function (ctor) {
            return bind(Parsing_String.string(pre))(function () {
                return bind(Parsing_Combinators.many(Parsing_String["char"](" ")))(function () {
                    return mapFlipped(spanP([ Parsing_String.string("\x0a") ]))(ctor);
                });
            });
        };
    };
    return alt(h("######")(H6.create))(alt(h("#####")(H5.create))(alt(h("####")(H4.create))(alt(h("###")(H3.create))(alt(h("##")(H2.create))(h("#")(H1.create))))));
})();
var listP_ = function (is) {
    var fromPartsListP = function (pre) {
        return function (l) {
            return map(l)(Parsing_Combinators_Array.many1(bind(choice(map2(indentP)(is)))(function (v) {
                return bind(pre)(function () {
                    return bind(spanP([ Parsing_String.string("\x0a") ]))(function (span) {
                        return bind(Parsing_Combinators.optionMaybe(listP_([ new IndentSpaces(v.value0 + 3 | 0), new IndentSpaces(v.value0 + 2 | 0) ])))(function (child) {
                            return pure((function () {
                                if (child instanceof Data_Maybe.Just) {
                                    return new ListTokenSpanSublist(span, child.value0);
                                };
                                if (child instanceof Data_Maybe.Nothing) {
                                    return new ListTokenSpan(span);
                                };
                                throw new Error("Failed pattern match at Toad.Markdown (line 252, column 11 - line 254, column 42): " + [ child.constructor.name ]);
                            })());
                        });
                    });
                });
            })));
        };
    };
    var olP = fromPartsListP(applySecond(choice([ Parsing_Combinators_Array.many1(Parsing_String_Basic.digit), Parsing_Combinators_Array.many1(Parsing_String_Basic.lower) ]))(Parsing_String.string(". ")))(OrderedList.create);
    var ulP = fromPartsListP(choice([ Parsing_String.string("* "), Parsing_String.string("- ") ]))(UnorderedList.create);
    return alt(ulP)(olP);
};
var listP = /* #__PURE__ */ listP_(listRootIndentation);
var elementP = /* #__PURE__ */ bind(/* #__PURE__ */ Parsing_Combinators.many(/* #__PURE__ */ Parsing_String.string("\x0a")))(function () {
    return alt(mapFlipped(headingP)(ElementHeading.create))(alt(mapFlipped(listP)(ElementList.create))(alt(mapFlipped(codeFenceP)(ElementCodeFence.create))(alt(mapFlipped(commentP)(ElementComment.create))(mapFlipped(spanP([  ]))(ElementSpan.create)))));
});
var documentP = /* #__PURE__ */ mapFlipped(/* #__PURE__ */ Parsing_Combinators_Array.many(elementP))(Document);
export {
    Unstyled,
    Bold,
    Italic,
    BoldItalic,
    InlineCode,
    textString,
    Anchor,
    ConceptAnchor,
    anchorString,
    AnchorToken,
    TextToken,
    tokenString,
    tokenText,
    Span,
    spanString,
    H1,
    H2,
    H3,
    H4,
    H5,
    H6,
    CodeFenceFileType,
    CodeFence,
    ListTokenSpan,
    ListTokenSpanSublist,
    OrderedList,
    UnorderedList,
    ElementHeading,
    ElementCodeFence,
    ElementSpan,
    ElementList,
    ElementComment,
    Document,
    elements,
    Stop,
    stop,
    tokenStop,
    untilTokenStopOr,
    combineUnstyled,
    documentP,
    elementP,
    commentP,
    IndentSpaces,
    indentP,
    listRootIndentation,
    listP_,
    listP,
    codeFenceP,
    headingP,
    spanP,
    tokenP,
    anchorP,
    WrapStar1,
    WrapStar2,
    WrapStar3,
    WrapStar2Under1,
    WrapUnder1Star2,
    WrapUnder1,
    WrapBacktick,
    wrapOpen_,
    wrapClose_,
    wrapNotFollowedByStar,
    wrapOpen,
    wrapClose,
    textP,
    eqText,
    genericText,
    showText,
    eqAnchor,
    genericAnchor,
    showAnchor,
    eqToken,
    genericToken,
    showToken,
    eqSpan,
    genericSpan,
    showSpan,
    eqHeading,
    genericHeading,
    showHeading,
    eqCodeFenceFileType,
    genericCodeFenceFileType,
    showCodeFenceFileType,
    eqCodeFence,
    genericCodeFence,
    showCodeFence,
    eqListToken,
    showListToken,
    eqList,
    showList,
    eqElement,
    genericElement,
    showElement,
    documentGeneric,
    documentEq,
    documentSemi,
    documentMoid,
    showDocument,
    genericIndent,
    showIndent
};
