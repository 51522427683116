// Generated by purs version 0.15.4
import * as CSS_Geometry from "../CSS.Geometry/index.js";
import * as CSS_Size from "../CSS.Size/index.js";
import * as CSS_Stylesheet from "../CSS.Stylesheet/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Halogen_HTML_Elements from "../Halogen.HTML.Elements/index.js";
import * as Halogen_HTML_Properties from "../Halogen.HTML.Properties/index.js";
import * as Toad_Atom_Logo_Style from "../Toad.Atom.Logo.Style/index.js";
import * as Toad_Css from "../Toad.Css/index.js";
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit)(CSS_Stylesheet.bindStyleM);
var render = function (extra) {
    return Halogen_HTML_Elements.img([ Toad_Css.style(discard(CSS_Geometry.height(CSS_Size.pct(100.0)))(function () {
        return extra;
    })), Halogen_HTML_Properties.src(Toad_Atom_Logo_Style.toadLogoUrl) ]);
};
export {
    render
};
