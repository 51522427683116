// Generated by purs version 0.15.4
import * as CSS_Common from "../CSS.Common/index.js";
import * as CSS_Property from "../CSS.Property/index.js";
import * as CSS_String from "../CSS.String/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Exists from "../Data.Exists/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Show from "../Data.Show/index.js";
var append = /* #__PURE__ */ Data_Semigroup.append(CSS_Property.semigroupValue);
var value = /* #__PURE__ */ CSS_Property.value(CSS_Property.valNumber);
var fromString = /* #__PURE__ */ CSS_String.fromString(CSS_Property.isStringValue);
var show = /* #__PURE__ */ Data_Show.show(Data_Show.showNumber);
var append2 = /* #__PURE__ */ Data_Semigroup.append(CSS_Property.semigroupPrefixed);
var eq = /* #__PURE__ */ Data_Eq.eq(CSS_Property.eqValue);
var compare = /* #__PURE__ */ Data_Ord.compare(CSS_Property.ordValue);
var BasicSize = /* #__PURE__ */ (function () {
    function BasicSize(value0) {
        this.value0 = value0;
    };
    BasicSize.create = function (value0) {
        return new BasicSize(value0);
    };
    return BasicSize;
})();
var SumSize = /* #__PURE__ */ (function () {
    function SumSize(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    SumSize.create = function (value0) {
        return function (value1) {
            return new SumSize(value0, value1);
        };
    };
    return SumSize;
})();
var DiffSize = /* #__PURE__ */ (function () {
    function DiffSize(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    DiffSize.create = function (value0) {
        return function (value1) {
            return new DiffSize(value0, value1);
        };
    };
    return DiffSize;
})();
var MultSize = /* #__PURE__ */ (function () {
    function MultSize(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    MultSize.create = function (value0) {
        return function (value1) {
            return new MultSize(value0, value1);
        };
    };
    return MultSize;
})();
var DivSize = /* #__PURE__ */ (function () {
    function DivSize(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    DivSize.create = function (value0) {
        return function (value1) {
            return new DivSize(value0, value1);
        };
    };
    return DivSize;
})();
var Angle = function (x) {
    return x;
};
var sizeCombinationPercentage = {};
var sizeCombinationPercentage1 = {};
var sizeCombinationLengthUnit = {};
var sizeCombinationLengthUnit1 = {};
var vw = function (i) {
    return new BasicSize(append(value(i))(fromString("vw")));
};
var vmin = function (i) {
    return new BasicSize(append(value(i))(fromString("vmin")));
};
var vmax = function (i) {
    return new BasicSize(append(value(i))(fromString("vmax")));
};
var vh = function (i) {
    return new BasicSize(append(value(i))(fromString("vh")));
};
var valAngle = {
    value: function (v) {
        return v;
    }
};
var unitless = function ($46) {
    return BasicSize.create(value($46));
};
var sym = function (f) {
    return function (a) {
        return f(a)(a)(a)(a);
    };
};
var sizeToString = function (v) {
    if (v instanceof BasicSize) {
        return CSS_Property.plain(v.value0);
    };
    if (v instanceof SumSize) {
        return Data_Exists.runExists(function (a$prime) {
            return Data_Exists.runExists(function (b$prime) {
                return "(" + (sizeToString(a$prime) + (" + " + (sizeToString(b$prime) + ")")));
            })(v.value1);
        })(v.value0);
    };
    if (v instanceof DiffSize) {
        return Data_Exists.runExists(function (a$prime) {
            return Data_Exists.runExists(function (b$prime) {
                return "(" + (sizeToString(a$prime) + (" - " + (sizeToString(b$prime) + ")")));
            })(v.value1);
        })(v.value0);
    };
    if (v instanceof MultSize) {
        return Data_Exists.runExists(function (b$prime) {
            return "(" + (show(v.value0) + (" * " + (sizeToString(b$prime) + ")")));
        })(v.value1);
    };
    if (v instanceof DivSize) {
        return Data_Exists.runExists(function (b$prime) {
            return "(" + (sizeToString(b$prime) + (" / " + (show(v.value0) + ")")));
        })(v.value1);
    };
    throw new Error("Failed pattern match at CSS.Size (line 29, column 1 - line 29, column 43): " + [ v.constructor.name ]);
};
var valSize = {
    value: function (v) {
        if (v instanceof BasicSize) {
            return v.value0;
        };
        return append2(CSS_Common.browsers)(new CSS_Property.Plain("calc" + sizeToString(v)));
    }
};
var rem = function (i) {
    return new BasicSize(append(value(i))(fromString("rem")));
};
var rad = function (i) {
    return append(value(i))(fromString("rad"));
};
var px = function (i) {
    return new BasicSize(append(value(i))(fromString("px")));
};
var pt = function (i) {
    return new BasicSize(append(value(i))(fromString("pt")));
};
var pct = function (i) {
    return new BasicSize(append(value(i))(fromString("%")));
};
var nil = /* #__PURE__ */ (function () {
    return new BasicSize(fromString("0"));
})();
var isStringSize = {
    fromString: function ($47) {
        return BasicSize.create(fromString($47));
    }
};
var fromString1 = /* #__PURE__ */ CSS_String.fromString(isStringSize);
var unsetSize = {
    unset: /* #__PURE__ */ fromString1("unset")
};
var initialSize = {
    initial: /* #__PURE__ */ fromString1("initial")
};
var inheritSize = {
    inherit: /* #__PURE__ */ fromString1("inherit")
};
var ex = function (i) {
    return new BasicSize(append(value(i))(fromString("ex")));
};
var eqAngle = function (dictEq) {
    return {
        eq: function (x) {
            return function (y) {
                return eq(x)(y);
            };
        }
    };
};
var ordAngle = function (dictOrd) {
    var eqAngle1 = eqAngle(dictOrd.Eq0());
    return {
        compare: function (x) {
            return function (y) {
                return compare(x)(y);
            };
        },
        Eq0: function () {
            return eqAngle1;
        }
    };
};
var em = function (i) {
    return new BasicSize(append(value(i))(fromString("em")));
};
var deg = function (i) {
    return append(value(i))(fromString("deg"));
};
var ch = function (i) {
    return new BasicSize(append(value(i))(fromString("ch")));
};
var calcSum = function () {
    return function (a) {
        return function (b) {
            return new SumSize(Data_Exists.mkExists(a), Data_Exists.mkExists(b));
        };
    };
};
var calcMult = function (a) {
    return function (b) {
        return new MultSize(a, Data_Exists.mkExists(b));
    };
};
var calcMultFlipped = /* #__PURE__ */ Data_Function.flip(calcMult);
var calcDiv = function (a) {
    return function (b) {
        return new DivSize(b, Data_Exists.mkExists(a));
    };
};
var calcDiff = function () {
    return function (a) {
        return function (b) {
            return new DiffSize(Data_Exists.mkExists(a), Data_Exists.mkExists(b));
        };
    };
};
var autoSize = {
    auto: /* #__PURE__ */ fromString1("auto")
};
export {
    BasicSize,
    SumSize,
    DiffSize,
    MultSize,
    DivSize,
    sizeToString,
    nil,
    unitless,
    px,
    pt,
    em,
    ex,
    ch,
    pct,
    rem,
    vw,
    vh,
    vmin,
    vmax,
    calcSum,
    calcDiff,
    calcMult,
    calcMultFlipped,
    calcDiv,
    sym,
    Angle,
    deg,
    rad,
    isStringSize,
    valSize,
    autoSize,
    inheritSize,
    initialSize,
    unsetSize,
    sizeCombinationPercentage1,
    sizeCombinationLengthUnit1,
    sizeCombinationPercentage,
    sizeCombinationLengthUnit,
    eqAngle,
    ordAngle,
    valAngle
};
