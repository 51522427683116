// Generated by purs version 0.15.4
import * as CSS_Property from "../CSS.Property/index.js";
import * as CSS_String from "../CSS.String/index.js";
import * as CSS_Stylesheet from "../CSS.Stylesheet/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
var fromString = /* #__PURE__ */ CSS_String.fromString(CSS_Property.isStringValue);
var eq = /* #__PURE__ */ Data_Eq.eq(CSS_Property.eqValue);
var compare = /* #__PURE__ */ Data_Ord.compare(CSS_Property.ordValue);
var TextAlign = function (x) {
    return x;
};
var valTextAlign = {
    value: function (v) {
        return v;
    }
};
var textAlign = /* #__PURE__ */ CSS_Stylesheet.key(valTextAlign)(/* #__PURE__ */ CSS_String.fromString(CSS_Property.isStringKey)("text-align"));
var startTextAlign = /* #__PURE__ */ fromString("start");
var rightTextAlign = /* #__PURE__ */ fromString("right");
var leftTextAlign = /* #__PURE__ */ fromString("left");
var justify = /* #__PURE__ */ fromString("justify");
var inheritTextAlign = /* #__PURE__ */ fromString("inherit");
var eqTextAlign = {
    eq: function (x) {
        return function (y) {
            return eq(x)(y);
        };
    }
};
var ordTextAlign = {
    compare: function (x) {
        return function (y) {
            return compare(x)(y);
        };
    },
    Eq0: function () {
        return eqTextAlign;
    }
};
var endTextAlign = /* #__PURE__ */ fromString("end");
var center = /* #__PURE__ */ fromString("center");
export {
    TextAlign,
    textAlign,
    center,
    justify,
    leftTextAlign,
    rightTextAlign,
    inheritTextAlign,
    startTextAlign,
    endTextAlign,
    eqTextAlign,
    ordTextAlign,
    valTextAlign
};
