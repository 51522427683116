// Generated by purs version 0.15.4
import * as CSS_Background from "../CSS.Background/index.js";
import * as CSS_Common from "../CSS.Common/index.js";
import * as CSS_Property from "../CSS.Property/index.js";
import * as CSS_Size from "../CSS.Size/index.js";
import * as CSS_String from "../CSS.String/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
var value = /* #__PURE__ */ CSS_Property.value(/* #__PURE__ */ CSS_Property.valList(CSS_Property.valValue));
var map = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var value1 = /* #__PURE__ */ CSS_Property.value(/* #__PURE__ */ CSS_Property.valTuple(CSS_Property.valValue)(CSS_Property.valValue));
var value2 = /* #__PURE__ */ CSS_Property.value(CSS_Property.valColor);
var value3 = /* #__PURE__ */ CSS_Property.value(CSS_Size.valSize);
var other = /* #__PURE__ */ CSS_Common.other(CSS_Background.otherBackgroundImage);
var append = /* #__PURE__ */ Data_Semigroup.append(CSS_Property.semigroupValue);
var fromString = /* #__PURE__ */ CSS_String.fromString(CSS_Property.isStringValue);
var value4 = /* #__PURE__ */ CSS_Property.value(CSS_Background.valDirection);
var append1 = /* #__PURE__ */ Data_Semigroup.append(CSS_Property.semigroupPrefixed);
var eq = /* #__PURE__ */ Data_Eq.eq(CSS_Property.eqValue);
var compare = /* #__PURE__ */ Data_Ord.compare(CSS_Property.ordValue);
var value5 = /* #__PURE__ */ CSS_Property.value(/* #__PURE__ */ CSS_Property.valTuple(CSS_Size.valSize)(CSS_Size.valSize));
var Radial = function (x) {
    return x;
};
var Extend = function (x) {
    return x;
};
var valRadial = {
    value: function (v) {
        return v;
    }
};
var value6 = /* #__PURE__ */ CSS_Property.value(valRadial);
var valExtend = {
    value: function (v) {
        return v;
    }
};
var value7 = /* #__PURE__ */ CSS_Property.value(valExtend);
var shortcut = function (g) {
    return function (f) {
        return function (t) {
            return g([ new Data_Tuple.Tuple(f, CSS_Size.pct(0.0)), new Data_Tuple.Tuple(t, CSS_Size.pct(100.0)) ]);
        };
    };
};
var ramp = function (xs) {
    return value(map(function (v) {
        return value1(new Data_Tuple.Tuple(value2(v.value0), value3(v.value1)));
    })(xs));
};
var repeatingLinearGradient = function (d) {
    return function (xs) {
        return other((function () {
            var v = append(fromString("repeating-linear-gradient("))(append(value4(d))(append(fromString(","))(append(ramp(xs))(fromString(")")))));
            return append1(CSS_Common.browsers)(v);
        })());
    };
};
var vRepeatingGradient = /* #__PURE__ */ shortcut(/* #__PURE__ */ repeatingLinearGradient(/* #__PURE__ */ CSS_Background.straight(CSS_Background.sideTop)));
var repeatingRadialGradient = function (dictLoc) {
    var value8 = CSS_Property.value(dictLoc.Val0());
    return function (d) {
        return function (r) {
            return function (xs) {
                return other((function () {
                    var v = append(fromString("repeating-radial-gradient("))(append(value([ value8(d), value6(r), ramp(xs) ]))(fromString(")")));
                    return append1(CSS_Common.browsers)(v);
                })());
            };
        };
    };
};
var radialGradient = function (dictLoc) {
    var value8 = CSS_Property.value(dictLoc.Val0());
    return function (d) {
        return function (r) {
            return function (xs) {
                return other((function () {
                    var v = append(fromString("radial-gradient("))(append(value([ value8(d), value6(r), ramp(xs) ]))(fromString(")")));
                    return append1(CSS_Common.browsers)(v);
                })());
            };
        };
    };
};
var otherRadial = {
    other: Radial
};
var otherExtend = {
    other: Extend
};
var linearGradient = function (d) {
    return function (xs) {
        return other((function () {
            var v = append(fromString("linear-gradient("))(append(value4(d))(append(fromString(","))(append(ramp(xs))(fromString(")")))));
            return append1(CSS_Common.browsers)(v);
        })());
    };
};
var vGradient = /* #__PURE__ */ shortcut(/* #__PURE__ */ linearGradient(/* #__PURE__ */ CSS_Background.straight(CSS_Background.sideTop)));
var hRepeatingGradient = /* #__PURE__ */ shortcut(/* #__PURE__ */ repeatingLinearGradient(/* #__PURE__ */ CSS_Background.straight(CSS_Background.sideLeft)));
var hGradient = /* #__PURE__ */ shortcut(/* #__PURE__ */ linearGradient(/* #__PURE__ */ CSS_Background.straight(CSS_Background.sideLeft)));
var farthestSide = /* #__PURE__ */ fromString("farthest-side");
var farthestCorner = /* #__PURE__ */ fromString("farthest-corner");
var eqRadial = {
    eq: function (x) {
        return function (y) {
            return eq(x)(y);
        };
    }
};
var ordRadial = {
    compare: function (x) {
        return function (y) {
            return compare(x)(y);
        };
    },
    Eq0: function () {
        return eqRadial;
    }
};
var eqExtend = {
    eq: function (x) {
        return function (y) {
            return eq(x)(y);
        };
    }
};
var ordExtend = {
    compare: function (x) {
        return function (y) {
            return compare(x)(y);
        };
    },
    Eq0: function () {
        return eqExtend;
    }
};
var elliptical = function (radx) {
    return function (rady) {
        return value5(new Data_Tuple.Tuple(radx, rady));
    };
};
var ellipse = function (ext) {
    return append(fromString("ellipse "))(value7(ext));
};
var closestSide = /* #__PURE__ */ fromString("closest-side");
var closestCorner = /* #__PURE__ */ fromString("closest-corner");
var circular = function (radius) {
    return value5(new Data_Tuple.Tuple(radius, radius));
};
var circle = function (ext) {
    return append(fromString("circle "))(value7(ext));
};
export {
    linearGradient,
    hGradient,
    vGradient,
    circle,
    ellipse,
    circular,
    elliptical,
    closestSide,
    closestCorner,
    farthestSide,
    farthestCorner,
    radialGradient,
    repeatingLinearGradient,
    hRepeatingGradient,
    vRepeatingGradient,
    repeatingRadialGradient,
    eqRadial,
    ordRadial,
    valRadial,
    otherRadial,
    eqExtend,
    ordExtend,
    valExtend,
    otherExtend
};
