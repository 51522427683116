// Generated by purs version 0.15.4
var AppLayoutMobile = /* #__PURE__ */ (function () {
    function AppLayoutMobile() {

    };
    AppLayoutMobile.value = new AppLayoutMobile();
    return AppLayoutMobile;
})();
var AppLayoutDesktop = /* #__PURE__ */ (function () {
    function AppLayoutDesktop() {

    };
    AppLayoutDesktop.value = new AppLayoutDesktop();
    return AppLayoutDesktop;
})();
export {
    AppLayoutMobile,
    AppLayoutDesktop
};
