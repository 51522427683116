// Generated by purs version 0.15.4
import * as CSS_Box from "../CSS.Box/index.js";
import * as CSS_Selector from "../CSS.Selector/index.js";
import * as CSS_Stylesheet from "../CSS.Stylesheet/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Toad_Css from "../Toad.Css/index.js";
var global = /* #__PURE__ */ CSS_Stylesheet.select(CSS_Selector.star)(/* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit)(CSS_Stylesheet.bindStyleM)(/* #__PURE__ */ Toad_Css.definedIn("Toad.Css.BoxSizing"))(function () {
    return CSS_Box.boxSizing(CSS_Box.borderBox);
}));
export {
    global
};
