// Generated by purs version 0.15.4
import * as Control_Category from "../Control.Category/index.js";
import * as Data_Boolean from "../Data.Boolean/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Number from "../Data.Number/index.js";
import * as Data_Ring from "../Data.Ring/index.js";
import * as Data_Semiring from "../Data.Semiring/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
var mul = /* #__PURE__ */ Data_Semiring.mul(Data_Semiring.semiringNumber);
var Radians = function (x) {
    return x;
};
var Pos = function (x) {
    return x;
};
var Degrees = function (x) {
    return x;
};
var toRadians = function (dict) {
    return dict.toRadians;
};
var showRadians = Data_Show.showNumber;
var showPos = /* #__PURE__ */ Data_Tuple.showTuple(Data_Show.showNumber)(showRadians);
var showDegrees = Data_Show.showNumber;
var semiringRadians = Data_Semiring.semiringNumber;
var semiringDegrees = Data_Semiring.semiringNumber;
var ringRadians = Data_Ring.ringNumber;
var ringDegrees = Data_Ring.ringNumber;
var radius = function (dict) {
    return dict.radius;
};
var radiansFloat = function (v) {
    if (v < 2.0 * Data_Number.pi) {
        return v;
    };
    if (Data_Boolean.otherwise) {
        return Data_Number.remainder(v)(2.0 * Data_Number.pi);
    };
    throw new Error("Failed pattern match at Data.Coord.Polar (line 46, column 1 - line 46, column 34): " + [ v.constructor.name ]);
};
var radiansToDegrees = /* #__PURE__ */ (function () {
    var $30 = mul(360.0);
    return function ($31) {
        return Degrees($30((function (v) {
            return v / (2.0 * Data_Number.pi);
        })(radiansFloat($31))));
    };
})();
var polarPos = {
    radius: function (v) {
        return v.value0;
    },
    angle: function (v) {
        return v.value1;
    },
    make: function (r) {
        return function (a) {
            return new Data_Tuple.Tuple(r, a);
        };
    }
};
var radius1 = /* #__PURE__ */ radius(polarPos);
var make = function (dict) {
    return dict.make;
};
var make1 = /* #__PURE__ */ make(polarPos);
var makePos = make1;
var eqRadians = Data_Eq.eqNumber;
var eqPos = /* #__PURE__ */ Data_Tuple.eqTuple(Data_Eq.eqNumber)(eqRadians);
var eqDegrees = Data_Eq.eqNumber;
var degreesFloat = function (v) {
    if (v < 360.0) {
        return v;
    };
    if (Data_Boolean.otherwise) {
        return Data_Number.remainder(v)(360.0);
    };
    throw new Error("Failed pattern match at Data.Coord.Polar (line 51, column 1 - line 51, column 34): " + [ v.constructor.name ]);
};
var degreesToRadians = /* #__PURE__ */ (function () {
    var $32 = mul(2.0 * Data_Number.pi);
    return function ($33) {
        return Radians($32((function (v) {
            return v / 360.0;
        })(degreesFloat($33))));
    };
})();
var angleRadians = {
    toRadians: /* #__PURE__ */ Control_Category.identity(Control_Category.categoryFn)
};
var angleDegrees = {
    toRadians: degreesToRadians
};
var angle = function (dict) {
    return dict.angle;
};
var angle1 = /* #__PURE__ */ angle(polarPos);
var modifyAngle = function (f) {
    return function (p) {
        return make1(radius1(p))(f(angle1(p)));
    };
};
var modifyRadius = function (f) {
    return function (p) {
        return make1(f(radius1(p)))(angle1(p));
    };
};
export {
    Radians,
    Degrees,
    toRadians,
    modifyAngle,
    modifyRadius,
    makePos,
    radius,
    angle,
    make,
    radiansToDegrees,
    degreesToRadians,
    radiansFloat,
    degreesFloat,
    showRadians,
    semiringRadians,
    ringRadians,
    eqRadians,
    angleRadians,
    showDegrees,
    semiringDegrees,
    ringDegrees,
    eqDegrees,
    angleDegrees,
    eqPos,
    showPos,
    polarPos
};
