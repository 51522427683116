// Generated by purs version 0.15.4
import * as $foreign from "./foreign.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Enum from "../Data.Enum/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_List_Types from "../Data.List.Types/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
import * as Record from "../Record/index.js";
import * as Type_Proxy from "../Type.Proxy/index.js";
var eqRec = /* #__PURE__ */ Data_Eq.eqRec();
var hashableVoid = {
    hash: function (v) {
        return 0;
    },
    Eq0: function () {
        return Data_Eq.eqVoid;
    }
};
var hashableUnit = {
    hash: function (v) {
        return 1;
    },
    Eq0: function () {
        return Data_Eq.eqUnit;
    }
};
var hashableString = {
    hash: $foreign.hashString,
    Eq0: function () {
        return Data_Eq.eqString;
    }
};
var hashableRecordNil = {
    hashRecord: function (v) {
        return function (v1) {
            return 0;
        };
    },
    EqRecord0: function () {
        return Data_Eq.eqRowNil;
    }
};
var hashableNumber = {
    hash: $foreign.hashNumber,
    Eq0: function () {
        return Data_Eq.eqNumber;
    }
};
var hashableInt = {
    hash: function (n) {
        return n;
    },
    Eq0: function () {
        return Data_Eq.eqInt;
    }
};
var hashableChar = {
    hash: /* #__PURE__ */ Data_Enum.fromEnum(Data_Enum.boundedEnumChar),
    Eq0: function () {
        return Data_Eq.eqChar;
    }
};
var hashableBoolean = {
    hash: function (v) {
        if (!v) {
            return 0;
        };
        if (v) {
            return 1;
        };
        throw new Error("Failed pattern match at Data.Hashable (line 52, column 1 - line 54, column 16): " + [ v.constructor.name ]);
    },
    Eq0: function () {
        return Data_Eq.eqBoolean;
    }
};
var hashRecord = function (dict) {
    return dict.hashRecord;
};
var hashableRecord = function () {
    return function (dictHashableRecord) {
        var hashRecord1 = hashRecord(dictHashableRecord);
        return function (dictEqRecord) {
            var eqRec1 = eqRec(dictEqRecord);
            return {
                hash: hashRecord1(Type_Proxy["Proxy"].value),
                Eq0: function () {
                    return eqRec1;
                }
            };
        };
    };
};
var hash = function (dict) {
    return dict.hash;
};
var hashFoldable = function (dictFoldable) {
    var foldl = Data_Foldable.foldl(dictFoldable);
    return function (dictHashable) {
        var hash1 = hash(dictHashable);
        return foldl(function (h) {
            return function (a) {
                return (31 * h | 0) + hash1(a) | 0;
            };
        })(1);
    };
};
var hashFoldable1 = /* #__PURE__ */ hashFoldable(Data_Foldable.foldableArray);
var hashFoldable2 = /* #__PURE__ */ hashFoldable(Data_List_Types.foldableList);
var hashableArray = function (dictHashable) {
    var eqArray = Data_Eq.eqArray(dictHashable.Eq0());
    return {
        hash: hashFoldable1(dictHashable),
        Eq0: function () {
            return eqArray;
        }
    };
};
var hashableList = function (dictHashable) {
    var eqList = Data_List_Types.eqList(dictHashable.Eq0());
    return {
        hash: hashFoldable2(dictHashable),
        Eq0: function () {
            return eqList;
        }
    };
};
var hashableEither = function (dictHashable) {
    var hash1 = hash(dictHashable);
    var eqEither = Data_Either.eqEither(dictHashable.Eq0());
    return function (dictHashable1) {
        var hash2 = hash(dictHashable1);
        var eqEither1 = eqEither(dictHashable1.Eq0());
        return {
            hash: function (v) {
                if (v instanceof Data_Either.Left) {
                    return hash1(v.value0) ^ 1431655765;
                };
                if (v instanceof Data_Either.Right) {
                    return hash2(v.value0);
                };
                throw new Error("Failed pattern match at Data.Hashable (line 88, column 1 - line 90, column 26): " + [ v.constructor.name ]);
            },
            Eq0: function () {
                return eqEither1;
            }
        };
    };
};
var hashableMaybe = function (dictHashable) {
    var hash1 = hash(dictHashable);
    var eqMaybe = Data_Maybe.eqMaybe(dictHashable.Eq0());
    return {
        hash: function (v) {
            if (v instanceof Data_Maybe.Nothing) {
                return 0;
            };
            if (v instanceof Data_Maybe.Just) {
                return 1 + hash1(v.value0) | 0;
            };
            throw new Error("Failed pattern match at Data.Hashable (line 84, column 1 - line 86, column 29): " + [ v.constructor.name ]);
        },
        Eq0: function () {
            return eqMaybe;
        }
    };
};
var hashableRecordCons = function (dictHashable) {
    var hash1 = hash(dictHashable);
    var Eq0 = dictHashable.Eq0();
    return function (dictHashableRecord) {
        var hashRecord1 = hashRecord(dictHashableRecord);
        var eqRowCons = Data_Eq.eqRowCons(dictHashableRecord.EqRecord0())();
        return function (dictIsSymbol) {
            var get = Record.get(dictIsSymbol)();
            var eqRowCons1 = eqRowCons(dictIsSymbol)(Eq0);
            return function () {
                return {
                    hashRecord: function (v) {
                        return function (record) {
                            return (hash1(get(Type_Proxy["Proxy"].value)(record)) * 31 | 0) + hashRecord1(Type_Proxy["Proxy"].value)(record) | 0;
                        };
                    },
                    EqRecord0: function () {
                        return eqRowCons1;
                    }
                };
            };
        };
    };
};
var hashableTuple = function (dictHashable) {
    var hash1 = hash(dictHashable);
    var eqTuple = Data_Tuple.eqTuple(dictHashable.Eq0());
    return function (dictHashable1) {
        var hash2 = hash(dictHashable1);
        var eqTuple1 = eqTuple(dictHashable1.Eq0());
        return {
            hash: function (v) {
                return (hash1(v.value0) * 31 | 0) + hash2(v.value1) | 0;
            },
            Eq0: function () {
                return eqTuple1;
            }
        };
    };
};
export {
    hash,
    hashRecord,
    hashableBoolean,
    hashableInt,
    hashableNumber,
    hashableChar,
    hashableString,
    hashableArray,
    hashableList,
    hashableTuple,
    hashableMaybe,
    hashableEither,
    hashableUnit,
    hashableVoid,
    hashableRecordNil,
    hashableRecordCons,
    hashableRecord
};
