// Generated by purs version 0.15.4
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_State_Class from "../Control.Monad.State.Class/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Map_Internal from "../Data.Map.Internal/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_String_Common from "../Data.String.Common/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
import * as Halogen_Component from "../Halogen.Component/index.js";
import * as Halogen_HTML from "../Halogen.HTML/index.js";
import * as Halogen_HTML_Core from "../Halogen.HTML.Core/index.js";
import * as Halogen_HTML_Elements from "../Halogen.HTML.Elements/index.js";
import * as Halogen_Query_HalogenM from "../Halogen.Query.HalogenM/index.js";
import * as Toad_Concept from "../Toad.Concept/index.js";
import * as Toad_Markdown_Html from "../Toad.Markdown.Html/index.js";
import * as Toad_Prelude from "../Toad.Prelude/index.js";
import * as Toad_Route from "../Toad.Route/index.js";
var show = /* #__PURE__ */ Data_Show.show(/* #__PURE__ */ Data_Show.showArray(Data_Show.showInt));
var show1 = /* #__PURE__ */ Data_Show.show(/* #__PURE__ */ Toad_Route.showOneOrAll(Toad_Concept.showIdent));
var show2 = /* #__PURE__ */ Data_Show.show(/* #__PURE__ */ Data_Maybe.showMaybe(Toad_Concept.showManifest));
var lookup = /* #__PURE__ */ Data_Map_Internal.lookup(Toad_Concept.ordIdent);
var eq = /* #__PURE__ */ Data_Eq.eq(Toad_Concept.eqIdent);
var bindFlipped = /* #__PURE__ */ Control_Bind.bindFlipped(Data_Maybe.bindMaybe);
var pure = /* #__PURE__ */ Control_Applicative.pure(Data_Maybe.applicativeMaybe);
var find = /* #__PURE__ */ Data_Foldable.find(Data_Foldable.foldableArray);
var bind = /* #__PURE__ */ Control_Bind.bind(Data_Maybe.bindMaybe);
var eq1 = /* #__PURE__ */ Data_Eq.eq(/* #__PURE__ */ Data_Eq.eqArray(Data_Eq.eqInt));
var bind1 = /* #__PURE__ */ Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM);
var get = /* #__PURE__ */ Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM);
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM);
var put = /* #__PURE__ */ Control_Monad_State_Class.put(Halogen_Query_HalogenM.monadStateHalogenM);
var insert = /* #__PURE__ */ Data_Map_Internal.insert(Toad_Concept.ordIdent);
var pure1 = /* #__PURE__ */ Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM);
var mempty = /* #__PURE__ */ Data_Monoid.mempty(/* #__PURE__ */ Halogen_Query_HalogenM.monoidHalogenM(Data_Monoid.monoidUnit));
var map = /* #__PURE__ */ Data_Functor.map(Data_Maybe.functorMaybe);
var when = /* #__PURE__ */ Control_Applicative.when(Halogen_Query_HalogenM.applicativeHalogenM);
var FetchConcept = /* #__PURE__ */ (function () {
    function FetchConcept(value0) {
        this.value0 = value0;
    };
    FetchConcept.create = function (value0) {
        return new FetchConcept(value0);
    };
    return FetchConcept;
})();
var TitleChanged = /* #__PURE__ */ (function () {
    function TitleChanged(value0) {
        this.value0 = value0;
    };
    TitleChanged.create = function (value0) {
        return new TitleChanged(value0);
    };
    return TitleChanged;
})();
var Input = function (x) {
    return x;
};
var State = function (x) {
    return x;
};
var Init = /* #__PURE__ */ (function () {
    function Init() {

    };
    Init.value = new Init();
    return Init;
})();
var InputChanged = /* #__PURE__ */ (function () {
    function InputChanged(value0) {
        this.value0 = value0;
    };
    InputChanged.create = function (value0) {
        return new InputChanged(value0);
    };
    return InputChanged;
})();
var titleStyle = function (v) {
    return v.input.titleStyle;
};
var showInput = {
    show: function (v) {
        return Data_String_Common.joinWith("\x0a")([ "Input { hash: " + show(v.hash), "      , route: " + show1(v.route), "      , manifest: " + show2(v.manifest), "      , lookupDocument: <Ident -> Maybe Md.Document>", "      , titleStyle: <Css.CSS>", "      , bodyStyle: <Css.CSS>", "      }" ]);
    }
};
var route = function (v) {
    return v.input.route;
};
var manifest = function (v) {
    return v.input.manifest;
};
var lookupRendered = function (v) {
    return Data_Function.flip(lookup)(v.renderedMarkdown);
};
var render = function (v) {
    if (v.input.route instanceof Toad_Route.One) {
        return Halogen_HTML.fromPlainHTML(Data_Maybe.maybe(Halogen_HTML_Elements.div([  ])([  ]))(Toad_Prelude.id)(lookupRendered(v)(v.input.route.value0)));
    };
    return Halogen_HTML_Core.text("TODO: may be unnecessary");
};
var lookupPath = function (s) {
    return function (id) {
        var byIdent = function (v) {
            return eq(v.ident)(id);
        };
        return bindFlipped(function ($92) {
            return pure(Toad_Concept.path($92));
        })(bindFlipped((function () {
            var $93 = find(byIdent);
            return function ($94) {
                return $93(Toad_Concept.decls($94));
            };
        })())(manifest(s)));
    };
};
var lookupDocument = function (v) {
    return v.input.lookupDocument;
};
var title = function (v) {
    if (v.input.route instanceof Toad_Route.One) {
        return bind(lookupPath(v)(v.input.route.value0))(function (path) {
            return bind(lookupDocument(v)(v.input.route.value0))(function (doc) {
                return Toad_Markdown_Html.renderAppTitle(path)(doc);
            });
        });
    };
    return Data_Maybe.Nothing.value;
};
var eqInput = {
    eq: function (v) {
        return function (v1) {
            return eq1(v.hash)(v1.hash);
        };
    }
};
var notEq = /* #__PURE__ */ Data_Eq.notEq(eqInput);
var bodyStyle = function (v) {
    return v.input.bodyStyle;
};
var handleAction = function (dictMonadEffect) {
    var liftEffect = Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadEffect));
    var renderMarkdown = function (css) {
        return function (ident) {
            return function (doc) {
                return bind1(liftEffect(Toad_Markdown_Html.renderBody(css)(doc)))(function (html) {
                    return bind1(get)(function (v) {
                        return discard(put({
                            input: v.input,
                            renderedMarkdown: insert(ident)(html)(v.renderedMarkdown)
                        }))(function () {
                            return pure1(Data_Unit.unit);
                        });
                    });
                });
            };
        };
    };
    var maybeTitleChanged = (function () {
        var $95 = Data_Maybe.maybe(mempty)(Halogen_Query_HalogenM.raise);
        var $96 = map(TitleChanged.create);
        return function ($97) {
            return $95($96(title($97)));
        };
    })();
    var maybeRenderMarkdown = function (s) {
        return Data_Maybe.maybe(pure1(Data_Unit.unit))(Toad_Prelude.id)(bind(Toad_Route.maybeOne(route(s)))(function (ident) {
            return bind(lookupDocument(s)(ident))(function (document) {
                return pure(renderMarkdown(bodyStyle(s))(ident)(document));
            });
        }));
    };
    var maybeFetchMissingConcept = (function () {
        var $98 = Data_Maybe.maybe(mempty)(Halogen_Query_HalogenM.raise);
        var $99 = map(FetchConcept.create);
        return function ($100) {
            return $98($99(Toad_Route.maybeOne(route($100))));
        };
    })();
    return function (v) {
        if (v instanceof Init) {
            return bind1(get)(function (input) {
                return discard(maybeFetchMissingConcept(input))(function () {
                    return maybeTitleChanged(input);
                });
            });
        };
        if (v instanceof InputChanged) {
            return bind1(get)(function (v1) {
                return when(notEq(v1.input)(v.value0))((function () {
                    var $$new = {
                        input: v.value0,
                        renderedMarkdown: v1.renderedMarkdown
                    };
                    return discard(put($$new))(function () {
                        return discard(maybeTitleChanged($$new))(function () {
                            return discard(maybeFetchMissingConcept($$new))(function () {
                                return maybeRenderMarkdown($$new);
                            });
                        });
                    });
                })());
            });
        };
        throw new Error("Failed pattern match at Toad.Page.Concepts (line 145, column 5 - line 159, column 34): " + [ v.constructor.name ]);
    };
};
var concepts = function (dictMonadEffect) {
    return Halogen_Component.mkComponent({
        initialState: function (i) {
            return {
                input: i,
                renderedMarkdown: Data_Map_Internal.empty
            };
        },
        render: render,
        "eval": Halogen_Component.mkEval({
            handleAction: handleAction(dictMonadEffect),
            handleQuery: Halogen_Component.defaultEval.handleQuery,
            receive: function ($101) {
                return Data_Maybe.Just.create(InputChanged.create($101));
            },
            initialize: new Data_Maybe.Just(Init.value),
            finalize: Halogen_Component.defaultEval.finalize
        })
    });
};
export {
    concepts,
    Input,
    FetchConcept,
    TitleChanged,
    eqInput,
    showInput
};
