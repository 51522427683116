// Generated by purs version 0.15.4
import * as CSS_Background from "../CSS.Background/index.js";
import * as CSS_Common from "../CSS.Common/index.js";
import * as CSS_Display from "../CSS.Display/index.js";
import * as CSS_Flexbox from "../CSS.Flexbox/index.js";
import * as CSS_Font from "../CSS.Font/index.js";
import * as CSS_Geometry from "../CSS.Geometry/index.js";
import * as CSS_Selector from "../CSS.Selector/index.js";
import * as CSS_Size from "../CSS.Size/index.js";
import * as CSS_Stylesheet from "../CSS.Stylesheet/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Toad_Css from "../Toad.Css/index.js";
import * as Toad_Css_Color from "../Toad.Css.Color/index.js";
import * as Toad_Html from "../Toad.Html/index.js";
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit)(CSS_Stylesheet.bindStyleM);
var center = /* #__PURE__ */ CSS_Common.center(CSS_Flexbox.centerAlignItemsValue);
var spaceBetween = /* #__PURE__ */ CSS_Flexbox.spaceBetween(CSS_Flexbox.spaceBetweenJustifyContentValue);
var h1ClassString = "app-title";
var h1Class = /* #__PURE__ */ Toad_Html.classNames([ h1ClassString ]);
var global = /* #__PURE__ */ discard(/* #__PURE__ */ Toad_Css.definedIn("Toad.Atom.AppTitle.Style"))(function () {
    return discard(CSS_Stylesheet.select(CSS_Selector["with"](CSS_Selector.element("h1"))(CSS_Selector.byClass(h1ClassString)))(Toad_Html.headingStyle(Toad_Html.h1Font)))(function () {
        return CSS_Stylesheet.select(CSS_Selector.deep(CSS_Selector["with"](CSS_Selector.element("h1"))(CSS_Selector.byClass(h1ClassString)))(CSS_Selector.star))(CSS_Font.color(Toad_Css.oklab(Toad_Css_Color.green(5.0e-2))));
    });
});
var backgroundLightness = 0.75;
var container = /* #__PURE__ */ discard(/* #__PURE__ */ CSS_Background.backgroundColor(/* #__PURE__ */ Toad_Css.oklab(/* #__PURE__ */ Toad_Css_Color.green(backgroundLightness))))(function () {
    return discard(CSS_Geometry.paddingLeft(CSS_Size.rem(4.0)))(function () {
        return discard(CSS_Geometry.paddingRight(CSS_Size.rem(4.0)))(function () {
            return discard(CSS_Display.display(CSS_Display.flex))(function () {
                return discard(CSS_Flexbox.alignItems(center))(function () {
                    return CSS_Flexbox.justifyContent(spaceBetween);
                });
            });
        });
    });
});
export {
    global,
    backgroundLightness,
    container,
    h1Class
};
