// Generated by purs version 0.15.4
import * as $foreign from "./foreign.js";
import * as Control_Promise from "../Control.Promise/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Unsafe_Coerce from "../Unsafe.Coerce/index.js";
var URL = function (x) {
    return x;
};
var showURL = Data_Show.showString;
var newtypeURL_ = {
    Coercible0: function () {
        return undefined;
    }
};
var eqURL = {
    eq: function (x) {
        return function (y) {
            return x === y;
        };
    }
};
var url = function (response) {
    return response.url;
};
var text = function (res) {
    return Control_Promise.toAffE($foreign.textImpl(res));
};
var statusCode = function (response) {
    return response.status;
};
var sameOriginCredentials = "same-origin";
var redirectManual = "manual";
var redirectFollow = "follow";
var redirectError = "error";
var putMethod = "PUT";
var postMethod = "POST";
var patchMethod = "PATCH";
var omitCredentials = "omit";
var json = function (res) {
    return Control_Promise.toAffE($foreign.jsonImpl(res));
};
var includeCredentials = "include";
var headers = $foreign.headersImpl;
var headMethod = "HEAD";
var getMethod = "GET";
var fromRecord = function () {
    return Unsafe_Coerce.unsafeCoerce;
};
var fromRecord1 = /* #__PURE__ */ fromRecord();
var makeHeaders = function () {
    return fromRecord1;
};
var fetch = function (impl) {
    return function () {
        return function (url$prime) {
            return function (opts) {
                return Control_Promise.toAffE($foreign["_fetch"](impl)(url$prime)(opts));
            };
        };
    };
};
var deleteMethod = "DELETE";
var defaultFetchOptions = {
    method: getMethod
};
var arrayBuffer = function (res) {
    return Control_Promise.toAffE($foreign.arrayBufferImpl(res));
};
export {
    URL,
    defaultFetchOptions,
    getMethod,
    postMethod,
    putMethod,
    patchMethod,
    deleteMethod,
    headMethod,
    redirectError,
    redirectFollow,
    redirectManual,
    omitCredentials,
    sameOriginCredentials,
    includeCredentials,
    fetch,
    json,
    text,
    headers,
    arrayBuffer,
    makeHeaders,
    statusCode,
    url,
    newtypeURL_,
    showURL,
    eqURL
};
