// Generated by purs version 0.15.4
import * as Data_Boolean from "../Data.Boolean/index.js";
import * as Data_Color_Rgb from "../Data.Color.Rgb/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_EuclideanRing from "../Data.EuclideanRing/index.js";
import * as Data_Generic_Rep from "../Data.Generic.Rep/index.js";
import * as Data_Mat from "../Data.Mat/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Number from "../Data.Number/index.js";
import * as Data_Semiring from "../Data.Semiring/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_Show_Generic from "../Data.Show.Generic/index.js";
var fromJust = /* #__PURE__ */ Data_Maybe.fromJust();
var mul3x3_1x3 = /* #__PURE__ */ Data_Mat.mul3x3_1x3(Data_Semiring.semiringNumber);
var genericShowConstructor = /* #__PURE__ */ Data_Show_Generic.genericShowConstructor(/* #__PURE__ */ Data_Show_Generic.genericShowArgsArgument(Data_Show.showNumber));
var Z = function (x) {
    return x;
};
var Y = function (x) {
    return x;
};
var X = function (x) {
    return x;
};
var Xyz = /* #__PURE__ */ (function () {
    function Xyz(value0, value1, value2) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
    };
    Xyz.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return new Xyz(value0, value1, value2);
            };
        };
    };
    return Xyz;
})();
var srgbToLrgb = function (v) {
    var f = function (n) {
        if (n <= 4.045e-2) {
            return n / 12.92;
        };
        if (Data_Boolean.otherwise) {
            return Data_Number.pow((n + 5.5e-2) / 1.055)(2.4);
        };
        throw new Error("Failed pattern match at Data.Color.Cie1931 (line 77, column 5 - line 79, column 50): " + [ n.constructor.name ]);
    };
    return new Data_Color_Rgb.Rgb(f(v.value0), f(v.value1), f(v.value2));
};
var mLrgbToXyz = /* #__PURE__ */ (function () {
    return new Data_Mat.M3x3(0.4124, 0.3576, 0.1805, 0.2126, 0.7152, 7.22e-2, 1.93e-2, 0.1192, 0.9505);
})();
var mXyzToLrgb = /* #__PURE__ */ fromJust(/* #__PURE__ */ Data_Mat.inverse3x3(Data_Eq.eqNumber)(Data_EuclideanRing.euclideanRingNumber)(mLrgbToXyz));
var ofRgb = function (rgb) {
    var xyz = (function () {
        var v = srgbToLrgb(rgb);
        return mul3x3_1x3(mLrgbToXyz)(new Data_Mat.M1x3(v.value0, v.value1, v.value2));
    })();
    return new Xyz(xyz.value0, xyz.value1, xyz.value2);
};
var lrgbToSrgb = function (v) {
    var f = function (n) {
        if (n <= 3.1308e-3) {
            return 12.92 * n;
        };
        if (Data_Boolean.otherwise) {
            return 1.055 * Data_Number.pow(n)(1.0 / 2.4) - 5.5e-2;
        };
        throw new Error("Failed pattern match at Data.Color.Cie1931 (line 66, column 5 - line 68, column 58): " + [ n.constructor.name ]);
    };
    return new Data_Color_Rgb.Rgb(f(v.value0), f(v.value1), f(v.value2));
};
var toRgb = function (v) {
    var rgb = mul3x3_1x3(mXyzToLrgb)(new Data_Mat.M1x3(v.value0, v.value1, v.value2));
    return lrgbToSrgb(new Data_Color_Rgb.Rgb(rgb.value0, rgb.value1, rgb.value2));
};
var genericZ = {
    to: function (x) {
        return x;
    },
    from: function (x) {
        return x;
    }
};
var showZ = {
    show: /* #__PURE__ */ Data_Show_Generic.genericShow(genericZ)(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "Z";
        }
    }))
};
var genericY = {
    to: function (x) {
        return x;
    },
    from: function (x) {
        return x;
    }
};
var showY = {
    show: /* #__PURE__ */ Data_Show_Generic.genericShow(genericY)(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "Y";
        }
    }))
};
var genericXyz = {
    to: function (x) {
        return new Xyz(x.value0, x.value1.value0, x.value1.value1);
    },
    from: function (x) {
        return new Data_Generic_Rep.Product(x.value0, new Data_Generic_Rep.Product(x.value1, x.value2));
    }
};
var genericX = {
    to: function (x) {
        return x;
    },
    from: function (x) {
        return x;
    }
};
var showX = {
    show: /* #__PURE__ */ Data_Show_Generic.genericShow(genericX)(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "X";
        }
    }))
};
var showXyz = {
    show: /* #__PURE__ */ Data_Show_Generic.genericShow(genericXyz)(/* #__PURE__ */ Data_Show_Generic.genericShowConstructor(/* #__PURE__ */ Data_Show_Generic.genericShowArgsProduct(/* #__PURE__ */ Data_Show_Generic.genericShowArgsArgument(showX))(/* #__PURE__ */ Data_Show_Generic.genericShowArgsProduct(/* #__PURE__ */ Data_Show_Generic.genericShowArgsArgument(showY))(/* #__PURE__ */ Data_Show_Generic.genericShowArgsArgument(showZ))))({
        reflectSymbol: function () {
            return "Xyz";
        }
    }))
};
export {
    Xyz,
    X,
    Y,
    Z,
    ofRgb,
    toRgb,
    mXyzToLrgb,
    genericX,
    showX,
    genericY,
    showY,
    genericZ,
    showZ,
    genericXyz,
    showXyz
};
