// Generated by purs version 0.15.4
import * as CSS_Box from "../CSS.Box/index.js";
import * as CSS_Common from "../CSS.Common/index.js";
import * as CSS_Property from "../CSS.Property/index.js";
import * as CSS_Size from "../CSS.Size/index.js";
import * as CSS_String from "../CSS.String/index.js";
import * as CSS_Stylesheet from "../CSS.Stylesheet/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
var value = /* #__PURE__ */ CSS_Property.value(CSS_Property.valString);
var fromString = /* #__PURE__ */ CSS_String.fromString(CSS_Property.isStringValue);
var value1 = /* #__PURE__ */ CSS_Property.value(/* #__PURE__ */ CSS_Property.valTuple(CSS_Size.valSize)(CSS_Size.valSize));
var value2 = /* #__PURE__ */ CSS_Property.value(CSS_Box.valBoxType);
var eq = /* #__PURE__ */ Data_Eq.eq(CSS_Property.eqValue);
var compare = /* #__PURE__ */ Data_Ord.compare(CSS_Property.ordValue);
var fromString1 = /* #__PURE__ */ CSS_String.fromString(CSS_Property.isStringKey);
var key = /* #__PURE__ */ CSS_Stylesheet.key(CSS_Property.valColor);
var auto = /* #__PURE__ */ CSS_Common.auto(CSS_Size.autoSize);
var value3 = /* #__PURE__ */ CSS_Property.value(CSS_Size.valAngle);
var Side = function (x) {
    return x;
};
var Location = function (x) {
    return x;
};
var Direction = function (x) {
    return x;
};
var BackgroundSize = function (x) {
    return x;
};
var BackgroundRepeat = function (x) {
    return x;
};
var BackgroundPosition = function (x) {
    return x;
};
var BackgroundOrigin = function (x) {
    return x;
};
var BackgroundImage = function (x) {
    return x;
};
var BackgroundClip = function (x) {
    return x;
};
var BackgroundAttachment = function (x) {
    return x;
};
var valSide = {
    value: function (v) {
        return v;
    }
};
var value4 = /* #__PURE__ */ CSS_Property.value(valSide);
var value5 = /* #__PURE__ */ CSS_Property.value(/* #__PURE__ */ CSS_Property.valTuple(valSide)(valSide));
var valLocation = {
    value: function (v) {
        return v;
    }
};
var valDirection = {
    value: function (v) {
        return v;
    }
};
var valBackgroundSize = {
    value: function (v) {
        return v;
    }
};
var key1 = /* #__PURE__ */ CSS_Stylesheet.key(valBackgroundSize);
var valBackgroundRepeat = {
    value: function (v) {
        return v;
    }
};
var value6 = /* #__PURE__ */ CSS_Property.value(/* #__PURE__ */ CSS_Property.valTuple(valBackgroundRepeat)(valBackgroundRepeat));
var key2 = /* #__PURE__ */ CSS_Stylesheet.key(valBackgroundRepeat);
var xyRepeat = function (a) {
    return function (b) {
        return value6(new Data_Tuple.Tuple(a, b));
    };
};
var valBackgroundPosition = {
    value: function (v) {
        return v;
    }
};
var key3 = /* #__PURE__ */ CSS_Stylesheet.key(valBackgroundPosition);
var valBackgroundOrigin = {
    value: function (v) {
        return v;
    }
};
var key4 = /* #__PURE__ */ CSS_Stylesheet.key(valBackgroundOrigin);
var valBackgroundImage = {
    value: function (v) {
        return v;
    }
};
var key5 = /* #__PURE__ */ CSS_Stylesheet.key(valBackgroundImage);
var valBackgroundClip = {
    value: function (v) {
        return v;
    }
};
var key6 = /* #__PURE__ */ CSS_Stylesheet.key(valBackgroundClip);
var valBackgroundAttachment = {
    value: function (v) {
        return v;
    }
};
var key7 = /* #__PURE__ */ CSS_Stylesheet.key(valBackgroundAttachment);
var url = function (u) {
    return value("url(\"" + (u + "\")"));
};
var straight = function (a) {
    return value4(a);
};
var space = /* #__PURE__ */ fromString("space");
var sideTop = /* #__PURE__ */ fromString("top");
var sideRight = /* #__PURE__ */ fromString("right");
var sideMiddle = /* #__PURE__ */ fromString("middle");
var sideLeft = /* #__PURE__ */ fromString("left");
var sideCenter = /* #__PURE__ */ fromString("center");
var sideBottom = /* #__PURE__ */ fromString("bottom");
var round = /* #__PURE__ */ fromString("round");
var repeat = /* #__PURE__ */ fromString("repeat");
var positioned = function (a) {
    return function (b) {
        return value1(new Data_Tuple.Tuple(a, b));
    };
};
var placed = function (a) {
    return function (b) {
        return value5(new Data_Tuple.Tuple(a, b));
    };
};
var otherSide = {
    other: Side
};
var otherLocation = {
    other: Location
};
var otherDirection = {
    other: Direction
};
var otherBackgroundSize = {
    other: BackgroundSize
};
var otherBackgroundRepeat = {
    other: BackgroundRepeat
};
var otherBackgroundPosition = {
    other: BackgroundPosition
};
var otherBackgroundOrigin = {
    other: BackgroundOrigin
};
var otherBackgroundImage = {
    other: BackgroundImage
};
var otherBackgroundClip = {
    other: BackgroundClip
};
var otherBackgroundAttachment = {
    other: BackgroundAttachment
};
var origin = function (b) {
    return value2(b);
};
var noRepeat = /* #__PURE__ */ fromString("no-repeat");
var repeatX = /* #__PURE__ */ xyRepeat(repeat)(noRepeat);
var repeatY = /* #__PURE__ */ xyRepeat(noRepeat)(repeat);
var location = function (dict) {
    return dict.location;
};
var locTuple = function (dictLoc) {
    var valTuple = CSS_Property.valTuple(dictLoc.Val0());
    return function (dictLoc1) {
        var valTuple1 = valTuple(dictLoc1.Val0());
        return {
            location: (function () {
                var $211 = CSS_Property.value(valTuple1);
                return function ($212) {
                    return Location($211($212));
                };
            })(),
            Val0: function () {
                return valTuple1;
            }
        };
    };
};
var locSize = {
    location: /* #__PURE__ */ (function () {
        var $213 = CSS_Property.value(CSS_Size.valSize);
        return function ($214) {
            return Location($213($214));
        };
    })(),
    Val0: function () {
        return CSS_Size.valSize;
    }
};
var locSide = {
    location: function ($215) {
        return Location(value4($215));
    },
    Val0: function () {
        return valSide;
    }
};
var isStringSide = {
    fromString: function ($216) {
        return Side(fromString($216));
    }
};
var isStringBackgroundSize = {
    fromString: function ($217) {
        return BackgroundSize(fromString($217));
    }
};
var isStringBackgroundRepeat = {
    fromString: function ($218) {
        return BackgroundRepeat(fromString($218));
    }
};
var fromString2 = /* #__PURE__ */ CSS_String.fromString(isStringBackgroundRepeat);
var noneBackgroundRepeat = {
    none: /* #__PURE__ */ fromString2("none")
};
var isStringBackgroundPosition = {
    fromString: function ($219) {
        return BackgroundPosition(fromString($219));
    }
};
var isStringBackgroundOrigin = {
    fromString: function ($220) {
        return BackgroundOrigin(fromString($220));
    }
};
var isStringBackgroundImage = {
    fromString: function ($221) {
        return BackgroundImage(fromString($221));
    }
};
var fromString3 = /* #__PURE__ */ CSS_String.fromString(isStringBackgroundImage);
var noneBackgroundImage = {
    none: /* #__PURE__ */ fromString3("none")
};
var isStringBackgroundClip = {
    fromString: function ($222) {
        return BackgroundClip(fromString($222));
    }
};
var isStringBackgroundAttachment = {
    fromString: function ($223) {
        return BackgroundAttachment(fromString($223));
    }
};
var inheritSide = {
    inherit: /* #__PURE__ */ CSS_String.fromString(isStringSide)("inherit")
};
var inheritBackgroundSize = {
    inherit: /* #__PURE__ */ CSS_String.fromString(isStringBackgroundSize)("inherit")
};
var inheritBackgroundRepeat = {
    inherit: /* #__PURE__ */ fromString2("inherit")
};
var inheritBackgroundPosition = {
    inherit: /* #__PURE__ */ CSS_String.fromString(isStringBackgroundPosition)("inherit")
};
var inheritBackgroundOrigin = {
    inherit: /* #__PURE__ */ CSS_String.fromString(isStringBackgroundOrigin)("inherit")
};
var inheritBackgroundImage = {
    inherit: /* #__PURE__ */ fromString3("inherit")
};
var inheritBackgroundClip = {
    inherit: /* #__PURE__ */ CSS_String.fromString(isStringBackgroundClip)("inherit")
};
var inheritBackgroundAttachment = {
    inherit: /* #__PURE__ */ CSS_String.fromString(isStringBackgroundAttachment)("inherit")
};
var eqSide = {
    eq: function (x) {
        return function (y) {
            return eq(x)(y);
        };
    }
};
var ordSide = {
    compare: function (x) {
        return function (y) {
            return compare(x)(y);
        };
    },
    Eq0: function () {
        return eqSide;
    }
};
var eqLocation = {
    eq: function (x) {
        return function (y) {
            return eq(x)(y);
        };
    }
};
var ordLocation = {
    compare: function (x) {
        return function (y) {
            return compare(x)(y);
        };
    },
    Eq0: function () {
        return eqLocation;
    }
};
var eqDirection = {
    eq: function (x) {
        return function (y) {
            return eq(x)(y);
        };
    }
};
var ordDirection = {
    compare: function (x) {
        return function (y) {
            return compare(x)(y);
        };
    },
    Eq0: function () {
        return eqDirection;
    }
};
var eqBackgroundSize = {
    eq: function (x) {
        return function (y) {
            return eq(x)(y);
        };
    }
};
var ordBackgroundSize = {
    compare: function (x) {
        return function (y) {
            return compare(x)(y);
        };
    },
    Eq0: function () {
        return eqBackgroundSize;
    }
};
var eqBackgroundRepeat = {
    eq: function (x) {
        return function (y) {
            return eq(x)(y);
        };
    }
};
var ordBackgroundRepeat = {
    compare: function (x) {
        return function (y) {
            return compare(x)(y);
        };
    },
    Eq0: function () {
        return eqBackgroundRepeat;
    }
};
var eqBackgroundPosition = {
    eq: function (x) {
        return function (y) {
            return eq(x)(y);
        };
    }
};
var ordBackgroundPosition = {
    compare: function (x) {
        return function (y) {
            return compare(x)(y);
        };
    },
    Eq0: function () {
        return eqBackgroundPosition;
    }
};
var eqBackgroundOrigin = {
    eq: function (x) {
        return function (y) {
            return eq(x)(y);
        };
    }
};
var ordBackgroundOrigin = {
    compare: function (x) {
        return function (y) {
            return compare(x)(y);
        };
    },
    Eq0: function () {
        return eqBackgroundOrigin;
    }
};
var eqBackgroundImage = {
    eq: function (x) {
        return function (y) {
            return eq(x)(y);
        };
    }
};
var ordBackgroundImage = {
    compare: function (x) {
        return function (y) {
            return compare(x)(y);
        };
    },
    Eq0: function () {
        return eqBackgroundImage;
    }
};
var eqBackgroundClip = {
    eq: function (x) {
        return function (y) {
            return eq(x)(y);
        };
    }
};
var ordBackgroundClip = {
    compare: function (x) {
        return function (y) {
            return compare(x)(y);
        };
    },
    Eq0: function () {
        return eqBackgroundClip;
    }
};
var eqBackgroundAttachment = {
    eq: function (x) {
        return function (y) {
            return eq(x)(y);
        };
    }
};
var ordBackgroundAttachment = {
    compare: function (x) {
        return function (y) {
            return compare(x)(y);
        };
    },
    Eq0: function () {
        return eqBackgroundAttachment;
    }
};
var cover = /* #__PURE__ */ fromString("cover");
var contain = /* #__PURE__ */ fromString("contain");
var by = function (a) {
    return function (b) {
        return value1(new Data_Tuple.Tuple(a, b));
    };
};
var boxClip = function (b) {
    return value2(b);
};
var backgroundTuple = function (dictBackground) {
    var valTuple = CSS_Property.valTuple(dictBackground.Val0());
    return function (dictBackground1) {
        var valTuple1 = valTuple(dictBackground1.Val0());
        return {
            background: CSS_Stylesheet.key(valTuple1)(fromString1("background")),
            Val0: function () {
                return valTuple1;
            }
        };
    };
};
var backgroundSizes = /* #__PURE__ */ CSS_Stylesheet.key(/* #__PURE__ */ CSS_Property.valList(valBackgroundSize))(/* #__PURE__ */ fromString1("background-size"));
var backgroundSize$prime = {
    background: /* #__PURE__ */ key1(/* #__PURE__ */ fromString1("background")),
    Val0: function () {
        return valBackgroundSize;
    }
};
var backgroundSize = /* #__PURE__ */ key1(/* #__PURE__ */ fromString1("background-size"));
var backgroundRepeats = /* #__PURE__ */ CSS_Stylesheet.key(/* #__PURE__ */ CSS_Property.valList(valBackgroundRepeat))(/* #__PURE__ */ fromString1("background-repeat"));
var backgroundRepeat = /* #__PURE__ */ key2(/* #__PURE__ */ fromString1("background-repeat"));
var backgroundPositions = /* #__PURE__ */ CSS_Stylesheet.key(/* #__PURE__ */ CSS_Property.valList(valBackgroundPosition))(/* #__PURE__ */ fromString1("background-position"));
var backgroundPosition$prime = {
    background: /* #__PURE__ */ key3(/* #__PURE__ */ fromString1("background")),
    Val0: function () {
        return valBackgroundPosition;
    }
};
var backgroundPosition = /* #__PURE__ */ key3(/* #__PURE__ */ fromString1("background-position"));
var backgroundOrigins = /* #__PURE__ */ CSS_Stylesheet.key(/* #__PURE__ */ CSS_Property.valList(valBackgroundOrigin))(/* #__PURE__ */ fromString1("background-origin"));
var backgroundOrigin = /* #__PURE__ */ key4(/* #__PURE__ */ fromString1("background-origin"));
var backgroundImages = /* #__PURE__ */ CSS_Stylesheet.key(/* #__PURE__ */ CSS_Property.valList(valBackgroundImage))(/* #__PURE__ */ fromString1("background-image"));
var backgroundImage = /* #__PURE__ */ key5(/* #__PURE__ */ fromString1("background-image"));
var backgroundColor$prime = {
    background: /* #__PURE__ */ key(/* #__PURE__ */ fromString1("background")),
    Val0: function () {
        return CSS_Property.valColor;
    }
};
var backgroundColor = /* #__PURE__ */ key(/* #__PURE__ */ fromString1("background-color"));
var backgroundClips = /* #__PURE__ */ CSS_Stylesheet.key(/* #__PURE__ */ CSS_Property.valList(valBackgroundClip))(/* #__PURE__ */ fromString1("background-clip"));
var backgroundClip = /* #__PURE__ */ key6(/* #__PURE__ */ fromString1("background-clip"));
var backgroundBackgroundRepeat = {
    background: /* #__PURE__ */ key2(/* #__PURE__ */ fromString1("background")),
    Val0: function () {
        return valBackgroundRepeat;
    }
};
var backgroundBackgroundOrigin = {
    background: /* #__PURE__ */ key4(/* #__PURE__ */ fromString1("background")),
    Val0: function () {
        return valBackgroundOrigin;
    }
};
var backgroundBackgroundImage = {
    background: /* #__PURE__ */ key5(/* #__PURE__ */ fromString1("background")),
    Val0: function () {
        return valBackgroundImage;
    }
};
var backgroundBackgroundClip = {
    background: /* #__PURE__ */ key6(/* #__PURE__ */ fromString1("background")),
    Val0: function () {
        return valBackgroundClip;
    }
};
var backgroundBackgroundAttachment = {
    background: /* #__PURE__ */ key7(/* #__PURE__ */ fromString1("background")),
    Val0: function () {
        return valBackgroundAttachment;
    }
};
var backgroundAttachments = /* #__PURE__ */ CSS_Stylesheet.key(/* #__PURE__ */ CSS_Property.valList(valBackgroundAttachment))(/* #__PURE__ */ fromString1("background-attachment"));
var backgroundAttachment = /* #__PURE__ */ key7(/* #__PURE__ */ fromString1("background-attachment"));
var backgroundArray = function (dictBackground) {
    var valList = CSS_Property.valList(dictBackground.Val0());
    return {
        background: CSS_Stylesheet.key(valList)(fromString1("background")),
        Val0: function () {
            return valList;
        }
    };
};
var background = function (dict) {
    return dict.background;
};
var autoBackgroundSize = {
    auto: /* #__PURE__ */ by(auto)(auto)
};
var attachScroll = /* #__PURE__ */ fromString("scroll");
var attachFixed = /* #__PURE__ */ fromString("fixed");
var angular = function (a) {
    return value3(a);
};
export {
    background,
    backgroundColor,
    backgroundPosition,
    backgroundPositions,
    placed,
    positioned,
    backgroundSize,
    backgroundSizes,
    contain,
    cover,
    by,
    backgroundRepeat,
    backgroundRepeats,
    repeat,
    space,
    round,
    noRepeat,
    xyRepeat,
    repeatX,
    repeatY,
    backgroundOrigin,
    backgroundOrigins,
    origin,
    backgroundClip,
    backgroundClips,
    boxClip,
    backgroundAttachment,
    backgroundAttachments,
    attachFixed,
    attachScroll,
    backgroundImage,
    backgroundImages,
    url,
    sideTop,
    sideLeft,
    sideRight,
    sideBottom,
    sideCenter,
    sideMiddle,
    straight,
    angular,
    location,
    backgroundArray,
    backgroundTuple,
    backgroundColor$prime,
    backgroundPosition$prime,
    backgroundSize$prime,
    backgroundBackgroundRepeat,
    backgroundBackgroundOrigin,
    backgroundBackgroundClip,
    backgroundBackgroundAttachment,
    backgroundBackgroundImage,
    eqBackgroundPosition,
    ordBackgroundPosition,
    isStringBackgroundPosition,
    valBackgroundPosition,
    otherBackgroundPosition,
    inheritBackgroundPosition,
    eqBackgroundSize,
    ordBackgroundSize,
    isStringBackgroundSize,
    valBackgroundSize,
    otherBackgroundSize,
    inheritBackgroundSize,
    autoBackgroundSize,
    eqBackgroundRepeat,
    ordBackgroundRepeat,
    isStringBackgroundRepeat,
    valBackgroundRepeat,
    otherBackgroundRepeat,
    inheritBackgroundRepeat,
    noneBackgroundRepeat,
    eqBackgroundImage,
    ordBackgroundImage,
    isStringBackgroundImage,
    valBackgroundImage,
    otherBackgroundImage,
    inheritBackgroundImage,
    noneBackgroundImage,
    eqBackgroundOrigin,
    ordBackgroundOrigin,
    isStringBackgroundOrigin,
    valBackgroundOrigin,
    otherBackgroundOrigin,
    inheritBackgroundOrigin,
    eqBackgroundClip,
    ordBackgroundClip,
    isStringBackgroundClip,
    valBackgroundClip,
    otherBackgroundClip,
    inheritBackgroundClip,
    eqBackgroundAttachment,
    ordBackgroundAttachment,
    isStringBackgroundAttachment,
    valBackgroundAttachment,
    otherBackgroundAttachment,
    inheritBackgroundAttachment,
    eqSide,
    ordSide,
    isStringSide,
    valSide,
    otherSide,
    inheritSide,
    eqDirection,
    ordDirection,
    valDirection,
    otherDirection,
    eqLocation,
    ordLocation,
    valLocation,
    otherLocation,
    locSide,
    locSize,
    locTuple
};
