// Generated by purs version 0.15.4
import * as Data_Generic_Rep from "../Data.Generic.Rep/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_Show_Generic from "../Data.Show.Generic/index.js";
var genericShowConstructor = /* #__PURE__ */ Data_Show_Generic.genericShowConstructor(/* #__PURE__ */ Data_Show_Generic.genericShowArgsArgument(Data_Show.showNumber));
var R = function (x) {
    return x;
};
var G = function (x) {
    return x;
};
var B = function (x) {
    return x;
};
var Rgb = /* #__PURE__ */ (function () {
    function Rgb(value0, value1, value2) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
    };
    Rgb.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return new Rgb(value0, value1, value2);
            };
        };
    };
    return Rgb;
})();
var genericRgb = {
    to: function (x) {
        return new Rgb(x.value0, x.value1.value0, x.value1.value1);
    },
    from: function (x) {
        return new Data_Generic_Rep.Product(x.value0, new Data_Generic_Rep.Product(x.value1, x.value2));
    }
};
var genericR = {
    to: function (x) {
        return x;
    },
    from: function (x) {
        return x;
    }
};
var showR = {
    show: /* #__PURE__ */ Data_Show_Generic.genericShow(genericR)(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "R";
        }
    }))
};
var genericG = {
    to: function (x) {
        return x;
    },
    from: function (x) {
        return x;
    }
};
var showG = {
    show: /* #__PURE__ */ Data_Show_Generic.genericShow(genericG)(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "G";
        }
    }))
};
var genericB = {
    to: function (x) {
        return x;
    },
    from: function (x) {
        return x;
    }
};
var showB = {
    show: /* #__PURE__ */ Data_Show_Generic.genericShow(genericB)(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "B";
        }
    }))
};
var showRgb = {
    show: /* #__PURE__ */ Data_Show_Generic.genericShow(genericRgb)(/* #__PURE__ */ Data_Show_Generic.genericShowConstructor(/* #__PURE__ */ Data_Show_Generic.genericShowArgsProduct(/* #__PURE__ */ Data_Show_Generic.genericShowArgsArgument(showR))(/* #__PURE__ */ Data_Show_Generic.genericShowArgsProduct(/* #__PURE__ */ Data_Show_Generic.genericShowArgsArgument(showG))(/* #__PURE__ */ Data_Show_Generic.genericShowArgsArgument(showB))))({
        reflectSymbol: function () {
            return "Rgb";
        }
    }))
};
export {
    R,
    G,
    B,
    Rgb,
    genericR,
    showR,
    genericG,
    showG,
    genericB,
    showB,
    genericRgb,
    showRgb
};
