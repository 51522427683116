// Generated by purs version 0.15.4
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Apply from "../Control.Apply/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Bounded from "../Data.Bounded/index.js";
import * as Data_Date from "../Data.Date/index.js";
import * as Data_Date_Component from "../Data.Date.Component/index.js";
import * as Data_DateTime from "../Data.DateTime/index.js";
import * as Data_Enum from "../Data.Enum/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Int from "../Data.Int/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Newtype from "../Data.Newtype/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_Time from "../Data.Time/index.js";
import * as Data_Time_Duration from "../Data.Time.Duration/index.js";
import * as Effect_Aff from "../Effect.Aff/index.js";
import * as Effect_Aff_Fetch from "../Effect.Aff.Fetch/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
import * as Effect_Now from "../Effect.Now/index.js";
import * as Toad_Concept from "../Toad.Concept/index.js";
import * as Yoga_Fetch from "../Yoga.Fetch/index.js";
var apply = /* #__PURE__ */ Control_Apply.apply(Data_Maybe.applyMaybe);
var pure = /* #__PURE__ */ Control_Applicative.pure(Data_Maybe.applicativeMaybe);
var show = /* #__PURE__ */ Data_Show.show(Data_Show.showInt);
var unwrap = /* #__PURE__ */ Data_Newtype.unwrap();
var bind = /* #__PURE__ */ Control_Bind.bind(Effect_Aff.bindAff);
var apply1 = /* #__PURE__ */ Control_Apply.apply(Effect_Aff.applyAff);
var pure1 = /* #__PURE__ */ Control_Applicative.pure(Effect_Aff.applicativeAff);
var liftEffect = /* #__PURE__ */ Effect_Class.liftEffect(Effect_Aff.monadEffectAff);
var mempty = /* #__PURE__ */ Data_Monoid.mempty(Effect_Aff_Fetch.monoidOptions);
var map = /* #__PURE__ */ Data_Functor.map(Effect_Aff.functorAff);
var rawContentBaseUrl = "https://raw.githubusercontent.com/clov-coffee/toad.dev-docs/main/";
var rawContentUrl = function (p) {
    return rawContentBaseUrl + ("concepts/" + Toad_Concept.pathString(p));
};
var maybeEpoch = /* #__PURE__ */ (function () {
    return Data_Functor.map(Data_Maybe.functorMaybe)(Data_Function.flip(Data_DateTime.DateTime.create)(Data_Bounded.bottom(Data_Time.boundedTime)))(apply(apply(apply(pure(Data_Date.canonicalDate))(Data_Enum.toEnum(Data_Date_Component.boundedEnumYear)(1970)))(pure(Data_Date_Component.January.value)))(Data_Enum.toEnum(Data_Date_Component.boundedEnumDay)(1)));
})();
var unixTime = /* #__PURE__ */ Data_Maybe.maybe(/* #__PURE__ */ Data_Function["const"](0.0))(/* #__PURE__ */ Data_Function.flip(/* #__PURE__ */ Data_DateTime.diff(Data_Time_Duration.durationSeconds)))(maybeEpoch);
var manifestUrl = /* #__PURE__ */ (function () {
    return rawContentBaseUrl + "concepts.json";
})();
var humanBaseUrl = "https://github.com/clov-coffee/toad.dev-docs/tree/main/";
var humanUrl = function (p) {
    return humanBaseUrl + ("concepts/" + Toad_Concept.pathString(p));
};
var cacheBust = function (dt) {
    return function (v) {
        return v + ("?cache-bust=" + show(Data_Int.floor(unwrap(unixTime(dt)))));
    };
};
var manifest = function (impl) {
    return bind(apply1(apply1(pure1(cacheBust))(liftEffect(Effect_Now.nowDateTime)))(pure1(manifestUrl)))(function (url$prime) {
        return bind(Effect_Aff_Fetch.fetch(impl)(url$prime)(Effect_Aff_Fetch.Get.value)(mempty))(function (res) {
            return map(Toad_Concept.decodeManifest)(Yoga_Fetch.text(res));
        });
    });
};
var one = function (impl) {
    return function (p) {
        return bind(apply1(apply1(pure1(cacheBust))(liftEffect(Effect_Now.nowDateTime)))(pure1(rawContentUrl(p))))(function (url$prime) {
            return bind(Effect_Aff_Fetch.fetch(impl)(url$prime)(Effect_Aff_Fetch.Get.value)(mempty))(function (res) {
                return Yoga_Fetch.text(res);
            });
        });
    };
};
export {
    manifest,
    one,
    humanUrl
};
