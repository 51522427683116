// Generated by purs version 0.15.4
import * as CSS_Property from "../CSS.Property/index.js";
import * as CSS_Size from "../CSS.Size/index.js";
import * as CSS_String from "../CSS.String/index.js";
import * as CSS_Stylesheet from "../CSS.Stylesheet/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Ordering from "../Data.Ordering/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
var fromString = /* #__PURE__ */ CSS_String.fromString(CSS_Property.isStringValue);
var append = /* #__PURE__ */ Data_Semigroup.append(CSS_Property.semigroupValue);
var eq = /* #__PURE__ */ Data_Eq.eq(CSS_Property.eqValue);
var compare = /* #__PURE__ */ Data_Ord.compare(CSS_Property.ordValue);
var ObliqueDefault = /* #__PURE__ */ (function () {
    function ObliqueDefault() {

    };
    ObliqueDefault.value = new ObliqueDefault();
    return ObliqueDefault;
})();
var ObliqueAngle = /* #__PURE__ */ (function () {
    function ObliqueAngle(value0) {
        this.value0 = value0;
    };
    ObliqueAngle.create = function (value0) {
        return new ObliqueAngle(value0);
    };
    return ObliqueAngle;
})();
var Normal = /* #__PURE__ */ (function () {
    function Normal() {

    };
    Normal.value = new Normal();
    return Normal;
})();
var Initial = /* #__PURE__ */ (function () {
    function Initial() {

    };
    Initial.value = new Initial();
    return Initial;
})();
var Inherit = /* #__PURE__ */ (function () {
    function Inherit() {

    };
    Inherit.value = new Inherit();
    return Inherit;
})();
var Unset = /* #__PURE__ */ (function () {
    function Unset() {

    };
    Unset.value = new Unset();
    return Unset;
})();
var Italic = /* #__PURE__ */ (function () {
    function Italic() {

    };
    Italic.value = new Italic();
    return Italic;
})();
var Oblique = /* #__PURE__ */ (function () {
    function Oblique(value0) {
        this.value0 = value0;
    };
    Oblique.create = function (value0) {
        return new Oblique(value0);
    };
    return Oblique;
})();
var valObliqueValue = {
    value: function (v) {
        if (v instanceof ObliqueDefault) {
            return fromString("oblique");
        };
        if (v instanceof ObliqueAngle) {
            return append(fromString("oblique "))(v.value0);
        };
        throw new Error("Failed pattern match at CSS.FontStyle (line 17, column 1 - line 19, column 54): " + [ v.constructor.name ]);
    }
};
var value = /* #__PURE__ */ CSS_Property.value(valObliqueValue);
var valFontStyle = {
    value: function (v) {
        if (v instanceof Normal) {
            return fromString("normal");
        };
        if (v instanceof Initial) {
            return fromString("initial");
        };
        if (v instanceof Inherit) {
            return fromString("inherit");
        };
        if (v instanceof Unset) {
            return fromString("unset");
        };
        if (v instanceof Italic) {
            return fromString("italic");
        };
        if (v instanceof Oblique) {
            return value(v.value0);
        };
        throw new Error("Failed pattern match at CSS.FontStyle (line 32, column 1 - line 38, column 30): " + [ v.constructor.name ]);
    }
};
var unsetFontStyle = /* #__PURE__ */ (function () {
    return {
        unset: Unset.value
    };
})();
var obliqueAngle = /* #__PURE__ */ (function () {
    var $49 = CSS_Property.value(CSS_Size.valAngle);
    return function ($50) {
        return Oblique.create(ObliqueAngle.create($49($50)));
    };
})();
var oblique = /* #__PURE__ */ (function () {
    return new Oblique(ObliqueDefault.value);
})();
var normalFontStyle = /* #__PURE__ */ (function () {
    return {
        normal: Normal.value
    };
})();
var italic = /* #__PURE__ */ (function () {
    return Italic.value;
})();
var initialFontStyle = /* #__PURE__ */ (function () {
    return {
        initial: Initial.value
    };
})();
var inheritFontStyle = /* #__PURE__ */ (function () {
    return {
        inherit: Inherit.value
    };
})();
var fontStyle = /* #__PURE__ */ CSS_Stylesheet.key(valFontStyle)(/* #__PURE__ */ CSS_String.fromString(CSS_Property.isStringKey)("font-style"));
var eqObliqueValue = {
    eq: function (x) {
        return function (y) {
            if (x instanceof ObliqueDefault && y instanceof ObliqueDefault) {
                return true;
            };
            if (x instanceof ObliqueAngle && y instanceof ObliqueAngle) {
                return eq(x.value0)(y.value0);
            };
            return false;
        };
    }
};
var eq1 = /* #__PURE__ */ Data_Eq.eq(eqObliqueValue);
var ordObliqueValue = {
    compare: function (x) {
        return function (y) {
            if (x instanceof ObliqueDefault && y instanceof ObliqueDefault) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ObliqueDefault) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ObliqueDefault) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ObliqueAngle && y instanceof ObliqueAngle) {
                return compare(x.value0)(y.value0);
            };
            throw new Error("Failed pattern match at CSS.FontStyle (line 0, column 0 - line 0, column 0): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqObliqueValue;
    }
};
var compare1 = /* #__PURE__ */ Data_Ord.compare(ordObliqueValue);
var eqFontStyle = {
    eq: function (x) {
        return function (y) {
            if (x instanceof Normal && y instanceof Normal) {
                return true;
            };
            if (x instanceof Initial && y instanceof Initial) {
                return true;
            };
            if (x instanceof Inherit && y instanceof Inherit) {
                return true;
            };
            if (x instanceof Unset && y instanceof Unset) {
                return true;
            };
            if (x instanceof Italic && y instanceof Italic) {
                return true;
            };
            if (x instanceof Oblique && y instanceof Oblique) {
                return eq1(x.value0)(y.value0);
            };
            return false;
        };
    }
};
var ordFontStyle = {
    compare: function (x) {
        return function (y) {
            if (x instanceof Normal && y instanceof Normal) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Normal) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Normal) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Initial && y instanceof Initial) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Initial) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Initial) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Inherit && y instanceof Inherit) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Inherit) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Inherit) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Unset && y instanceof Unset) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Unset) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Unset) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Italic && y instanceof Italic) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Italic) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Italic) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Oblique && y instanceof Oblique) {
                return compare1(x.value0)(y.value0);
            };
            throw new Error("Failed pattern match at CSS.FontStyle (line 0, column 0 - line 0, column 0): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqFontStyle;
    }
};
export {
    ObliqueDefault,
    ObliqueAngle,
    Normal,
    Initial,
    Inherit,
    Unset,
    Italic,
    Oblique,
    italic,
    oblique,
    obliqueAngle,
    fontStyle,
    eqObliqueValue,
    ordObliqueValue,
    valObliqueValue,
    eqFontStyle,
    ordFontStyle,
    valFontStyle,
    normalFontStyle,
    initialFontStyle,
    inheritFontStyle,
    unsetFontStyle
};
