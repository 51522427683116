// Generated by purs version 0.15.4
import * as $foreign from "./foreign.js";
import * as CSS_Geometry from "../CSS.Geometry/index.js";
import * as CSS_Size from "../CSS.Size/index.js";
import * as CSS_Stylesheet from "../CSS.Stylesheet/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Halogen_HTML_Elements from "../Halogen.HTML.Elements/index.js";
import * as Halogen_HTML_Properties from "../Halogen.HTML.Properties/index.js";
import * as Toad_Css from "../Toad.Css/index.js";
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit)(CSS_Stylesheet.bindStyleM);
var X = /* #__PURE__ */ (function () {
    function X() {

    };
    X.value = new X();
    return X;
})();
var ChevronDown = /* #__PURE__ */ (function () {
    function ChevronDown() {

    };
    ChevronDown.value = new ChevronDown();
    return ChevronDown;
})();
var ChevronUp = /* #__PURE__ */ (function () {
    function ChevronUp() {

    };
    ChevronUp.value = new ChevronUp();
    return ChevronUp;
})();
var Github = /* #__PURE__ */ (function () {
    function Github() {

    };
    Github.value = new Github();
    return Github;
})();
var url = function (v) {
    if (v instanceof X) {
        return $foreign["_x"];
    };
    if (v instanceof ChevronDown) {
        return $foreign["_chevronDown"];
    };
    if (v instanceof ChevronUp) {
        return $foreign["_chevronUp"];
    };
    if (v instanceof Github) {
        return $foreign["_github"];
    };
    throw new Error("Failed pattern match at Toad.Atom.Icon (line 24, column 1 - line 24, column 22): " + [ v.constructor.name ]);
};
var render = function (i) {
    return Halogen_HTML_Elements.img([ Toad_Css.style(discard(CSS_Geometry.height(CSS_Size.pct(100.0)))(function () {
        return CSS_Geometry.width(CSS_Size.pct(100.0));
    })), Halogen_HTML_Properties.src(url(i)) ]);
};
export {
    X,
    ChevronDown,
    ChevronUp,
    Github,
    render
};
