// Generated by purs version 0.15.4
import * as $foreign from "./foreign.js";
import * as Control_Alt from "../Control.Alt/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Map_Internal from "../Data.Map.Internal/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Unfoldable from "../Data.Unfoldable/index.js";
import * as Yoga_Fetch from "../Yoga.Fetch/index.js";
import * as Yoga_Fetch_Impl from "../Yoga.Fetch.Impl/index.js";
var fromJust = /* #__PURE__ */ Data_Maybe.fromJust();
var map = /* #__PURE__ */ Data_Functor.map(Data_Maybe.functorMaybe);
var map1 = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var toUnfoldableUnordered = /* #__PURE__ */ Data_Map_Internal.toUnfoldableUnordered(Data_Unfoldable.unfoldableArray);
var alt = /* #__PURE__ */ Control_Alt.alt(Data_Maybe.altMaybe);
var ErrorOnRedirect = /* #__PURE__ */ (function () {
    function ErrorOnRedirect() {

    };
    ErrorOnRedirect.value = new ErrorOnRedirect();
    return ErrorOnRedirect;
})();
var FollowRedirects = /* #__PURE__ */ (function () {
    function FollowRedirects() {

    };
    FollowRedirects.value = new FollowRedirects();
    return FollowRedirects;
})();
var NoFollowRedirects = /* #__PURE__ */ (function () {
    function NoFollowRedirects() {

    };
    NoFollowRedirects.value = new NoFollowRedirects();
    return NoFollowRedirects;
})();
var Get = /* #__PURE__ */ (function () {
    function Get() {

    };
    Get.value = new Get();
    return Get;
})();
var Put = /* #__PURE__ */ (function () {
    function Put() {

    };
    Put.value = new Put();
    return Put;
})();
var Post = /* #__PURE__ */ (function () {
    function Post() {

    };
    Post.value = new Post();
    return Post;
})();
var Delete = /* #__PURE__ */ (function () {
    function Delete() {

    };
    Delete.value = new Delete();
    return Delete;
})();
var OmitCredentials = /* #__PURE__ */ (function () {
    function OmitCredentials() {

    };
    OmitCredentials.value = new OmitCredentials();
    return OmitCredentials;
})();
var IncludeCredentials = /* #__PURE__ */ (function () {
    function IncludeCredentials() {

    };
    IncludeCredentials.value = new IncludeCredentials();
    return IncludeCredentials;
})();
var SameOriginCredentials = /* #__PURE__ */ (function () {
    function SameOriginCredentials() {

    };
    SameOriginCredentials.value = new SameOriginCredentials();
    return SameOriginCredentials;
})();
var Body = function (x) {
    return x;
};
var Options = /* #__PURE__ */ (function () {
    function Options(value0) {
        this.value0 = value0;
    };
    Options.create = function (value0) {
        return new Options(value0);
    };
    return Options;
})();
var yogaRedirect = function (v) {
    if (v instanceof ErrorOnRedirect) {
        return Yoga_Fetch.redirectError;
    };
    if (v instanceof FollowRedirects) {
        return Yoga_Fetch.redirectFollow;
    };
    if (v instanceof NoFollowRedirects) {
        return Yoga_Fetch.redirectManual;
    };
    throw new Error("Failed pattern match at Effect.Aff.Fetch (line 66, column 16 - line 69, column 44): " + [ v.constructor.name ]);
};
var yogaMethod = function (v) {
    if (v instanceof Get) {
        return Yoga_Fetch.getMethod;
    };
    if (v instanceof Put) {
        return Yoga_Fetch.putMethod;
    };
    if (v instanceof Post) {
        return Yoga_Fetch.postMethod;
    };
    if (v instanceof Delete) {
        return Yoga_Fetch.deleteMethod;
    };
    throw new Error("Failed pattern match at Effect.Aff.Fetch (line 74, column 14 - line 78, column 31): " + [ v.constructor.name ]);
};
var yogaCredentials = function (v) {
    if (v instanceof OmitCredentials) {
        return Yoga_Fetch.omitCredentials;
    };
    if (v instanceof IncludeCredentials) {
        return Yoga_Fetch.includeCredentials;
    };
    if (v instanceof SameOriginCredentials) {
        return Yoga_Fetch.sameOriginCredentials;
    };
    throw new Error("Failed pattern match at Effect.Aff.Fetch (line 58, column 19 - line 61, column 55): " + [ v.constructor.name ]);
};
var unwrapBody = function (v) {
    return v;
};
var yogaOptions = function (method) {
    return function (v) {
        return $foreign.optionsToFetchOptions(Data_Maybe.isJust)(fromJust)({
            body: map(unwrapBody)(v.value0.body),
            credentials: map(yogaCredentials)(v.value0.credentials),
            redirect: map(yogaRedirect)(v.value0.redirect),
            headers: map(function (headers$prime) {
                return map1(function (v1) {
                    return {
                        key: v1.value0,
                        value: v1.value1
                    };
                })(toUnfoldableUnordered(headers$prime));
            })(v.value0.headers),
            method: yogaMethod(method)
        });
    };
};
var semiOptions = {
    append: function (v) {
        return function (v1) {
            return new Options({
                body: alt(v1.value0.body)(v.value0.body),
                credentials: alt(v1.value0.credentials)(v.value0.credentials),
                redirect: alt(v1.value0.redirect)(v.value0.redirect),
                headers: alt(v1.value0.headers)(v.value0.headers)
            });
        };
    }
};
var opt = function (dict) {
    return dict.opt;
};
var monoidOptions = /* #__PURE__ */ (function () {
    return {
        mempty: new Options({
            body: Data_Maybe.Nothing.value,
            credentials: Data_Maybe.Nothing.value,
            redirect: Data_Maybe.Nothing.value,
            headers: Data_Maybe.Nothing.value
        }),
        Semigroup0: function () {
            return semiOptions;
        }
    };
})();
var mempty = /* #__PURE__ */ Data_Monoid.mempty(monoidOptions);
var modify = function (f) {
    return function (v) {
        return new Options(f(v.value0));
    };
};
var redirectOpt = {
    opt: function (redirect) {
        return modify(function (v) {
            return {
                body: v.body,
                credentials: v.credentials,
                redirect: new Data_Maybe.Just(redirect),
                headers: v.headers
            };
        })(mempty);
    }
};
var headersOpt = {
    opt: function (headers) {
        return modify(function (v) {
            return {
                body: v.body,
                credentials: v.credentials,
                redirect: v.redirect,
                headers: new Data_Maybe.Just(headers)
            };
        })(mempty);
    }
};
var fetch = function (impl) {
    return function (url) {
        return function (method) {
            return function (opts) {
                return Yoga_Fetch.fetch(impl)()(url)(yogaOptions(method)(opts));
            };
        };
    };
};
var credsOpt = {
    opt: function (credentials) {
        return modify(function (v) {
            return {
                body: v.body,
                credentials: new Data_Maybe.Just(credentials),
                redirect: v.redirect,
                headers: v.headers
            };
        })(mempty);
    }
};
var bodyOpt = {
    opt: function (body) {
        return modify(function (v) {
            return {
                body: new Data_Maybe.Just(body),
                credentials: v.credentials,
                redirect: v.redirect,
                headers: v.headers
            };
        })(mempty);
    }
};
export {
    fetch,
    Body,
    opt,
    OmitCredentials,
    IncludeCredentials,
    SameOriginCredentials,
    ErrorOnRedirect,
    FollowRedirects,
    NoFollowRedirects,
    Get,
    Put,
    Post,
    Delete,
    bodyOpt,
    headersOpt,
    redirectOpt,
    credsOpt,
    semiOptions,
    monoidOptions
};
export {
    URL,
    arrayBuffer,
    headers,
    json,
    statusCode,
    text,
    url
} from "../Yoga.Fetch/index.js";
