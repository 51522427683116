// Generated by purs version 0.15.4
import * as CSS_Common from "../CSS.Common/index.js";
import * as CSS_Display from "../CSS.Display/index.js";
import * as CSS_Flexbox from "../CSS.Flexbox/index.js";
import * as CSS_Font from "../CSS.Font/index.js";
import * as CSS_Geometry from "../CSS.Geometry/index.js";
import * as CSS_Overflow from "../CSS.Overflow/index.js";
import * as CSS_Size from "../CSS.Size/index.js";
import * as CSS_Stylesheet from "../CSS.Stylesheet/index.js";
import * as CSS_Text_Overflow from "../CSS.Text.Overflow/index.js";
import * as CSS_Text_Whitespace from "../CSS.Text.Whitespace/index.js";
import * as CSS_TextAlign from "../CSS.TextAlign/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Active from "../Data.Active/index.js";
import * as Data_Array_Mve from "../Data.Array.Mve/index.js";
import * as Data_Boolean from "../Data.Boolean/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Expanded from "../Data.Expanded/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_Show_Generic from "../Data.Show.Generic/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Halogen_HTML_Core from "../Halogen.HTML.Core/index.js";
import * as Halogen_HTML_Elements from "../Halogen.HTML.Elements/index.js";
import * as Halogen_HTML_Properties from "../Halogen.HTML.Properties/index.js";
import * as Toad_Atom_Button from "../Toad.Atom.Button/index.js";
import * as Toad_Atom_Button_Style from "../Toad.Atom.Button.Style/index.js";
import * as Toad_Atom_Icon from "../Toad.Atom.Icon/index.js";
import * as Toad_Css from "../Toad.Css/index.js";
import * as Toad_Css_Color from "../Toad.Css.Color/index.js";
import * as Toad_Css_Font from "../Toad.Css.Font/index.js";
import * as Toad_Html from "../Toad.Html/index.js";
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit)(CSS_Stylesheet.bindStyleM);
var center = /* #__PURE__ */ CSS_Common.center(CSS_Flexbox.centerAlignItemsValue);
var center1 = /* #__PURE__ */ CSS_Common.center(CSS_Flexbox.centerJustifyContentValue);
var append = /* #__PURE__ */ Data_Semigroup.append(Toad_Css_Font.fontSemigroup);
var pure = /* #__PURE__ */ Control_Applicative.pure(CSS_Stylesheet.applicativeStyleM);
var bindFlipped = /* #__PURE__ */ Control_Bind.bindFlipped(Data_Maybe.bindMaybe);
var append1 = /* #__PURE__ */ Data_Semigroup.append(Data_Semigroup.semigroupArray);
var map = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var eq = /* #__PURE__ */ Data_Eq.eq(Data_Expanded.eqExpanded);
var flexStart = /* #__PURE__ */ CSS_Flexbox.flexStart(CSS_Flexbox.flexStartJustifyContentValue);
var Title = function (x) {
    return x;
};
var Item = /* #__PURE__ */ (function () {
    function Item(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Item.create = function (value0) {
        return function (value1) {
            return new Item(value0, value1);
        };
    };
    return Item;
})();
var HeaderItem = /* #__PURE__ */ (function () {
    function HeaderItem(value0) {
        this.value0 = value0;
    };
    HeaderItem.create = function (value0) {
        return new HeaderItem(value0);
    };
    return HeaderItem;
})();
var HeaderItemWithValue = /* #__PURE__ */ (function () {
    function HeaderItemWithValue(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    HeaderItemWithValue.create = function (value0) {
        return function (value1) {
            return new HeaderItemWithValue(value0, value1);
        };
    };
    return HeaderItemWithValue;
})();
var renderRow = function (actionNoop) {
    return function (actionExpand) {
        return function (actionPickValue) {
            return function (colorFamily) {
                return function (expanded) {
                    return function (isHeader) {
                        return function (text) {
                            return function (value) {
                                return function (active) {
                                    var squareStyle = discard(CSS_Geometry.height(CSS_Size.rem(3.0)))(function () {
                                        return discard(CSS_Geometry.width(CSS_Size.rem(3.0)))(function () {
                                            return discard(CSS_Display.display(CSS_Display.flex))(function () {
                                                return discard(CSS_Flexbox.alignItems(center))(function () {
                                                    return discard(CSS_Flexbox.justifyContent(center1))(function () {
                                                        return CSS_Flexbox.flexShrink(0.0);
                                                    });
                                                });
                                            });
                                        });
                                    });
                                    return Halogen_HTML_Elements.div([ Toad_Css.style(discard(CSS_Display.display(CSS_Display.flex))(function () {
                                        return discard(CSS_Flexbox.alignItems(center))(function () {
                                            return CSS_Geometry.marginBottom(CSS_Size.rem(0.25));
                                        });
                                    })) ])([ Toad_Atom_Button.render({
                                        active: active,
                                        children: [ Halogen_HTML_Elements.h4([ Toad_Css.style(discard(CSS_Font.color(Toad_Css.oklab(Toad_Css_Color.colorBg(Toad_Css_Color.grey))))(function () {
                                            return discard(Toad_Css_Font.font(append(Toad_Html.h3Font)(Toad_Css_Font.fontSize((function () {
                                                if (isHeader) {
                                                    return Toad_Css_Font.FontSizeH3.value;
                                                };
                                                return Toad_Css_Font.FontSizeH4.value;
                                            })()))))(function () {
                                                return discard(CSS_Geometry.width(CSS_Size.pct(100.0)))(function () {
                                                    return discard(CSS_Text_Whitespace.textWhitespace(CSS_Text_Whitespace.whitespaceNoWrap))(function () {
                                                        return discard(CSS_Text_Overflow.textOverflow(CSS_Text_Overflow.ellipsis))(function () {
                                                            return discard(CSS_Overflow.overflow(CSS_Overflow.hidden))(function () {
                                                                return discard(CSS_TextAlign.textAlign(CSS_TextAlign.leftTextAlign))(function () {
                                                                    return CSS_Size.sym(CSS_Geometry.margin)(CSS_Size.nil);
                                                                });
                                                            });
                                                        });
                                                    });
                                                });
                                            });
                                        })), Halogen_HTML_Properties.title(text) ])([ Halogen_HTML_Core.text(text) ]) ],
                                        onClick: Data_Maybe.maybe((function () {
                                            if (isHeader) {
                                                return actionExpand;
                                            };
                                            return actionNoop;
                                        })())(actionPickValue)(value),
                                        styleButton: discard(CSS_Display.display(CSS_Display.flex))(function () {
                                            return discard(CSS_Flexbox.alignItems(center))(function () {
                                                return discard(CSS_Geometry.paddingLeft(CSS_Size.rem(0.5)))(function () {
                                                    return CSS_Overflow.overflow(CSS_Overflow.hidden);
                                                });
                                            });
                                        }),
                                        styleContainer: discard(CSS_Geometry.width(CSS_Size.pct(100.0)))(function () {
                                            return CSS_Geometry.height(CSS_Size.rem(3.0));
                                        }),
                                        theme: Toad_Atom_Button_Style.primary(Toad_Css_Color.green)
                                    }), (function () {
                                        var $43 = !isHeader;
                                        if ($43) {
                                            return Halogen_HTML_Elements.div([ Toad_Css.style(discard(squareStyle)(function () {
                                                return CSS_Display.position(CSS_Display.relative);
                                            })) ])([  ]);
                                        };
                                        return Toad_Atom_Button.render({
                                            active: Data_Active.Active.value,
                                            children: [ Toad_Atom_Icon.render((function () {
                                                if (expanded instanceof Data_Expanded.Expanded) {
                                                    return Toad_Atom_Icon.ChevronUp.value;
                                                };
                                                if (expanded instanceof Data_Expanded.Collapsed) {
                                                    return Toad_Atom_Icon.ChevronDown.value;
                                                };
                                                throw new Error("Failed pattern match at Toad.Atom.Accordion (line 175, column 31 - line 177, column 50): " + [ expanded.constructor.name ]);
                                            })()) ],
                                            onClick: actionExpand,
                                            styleButton: discard(squareStyle)(function () {
                                                return CSS_Size.sym(CSS_Geometry.padding)(CSS_Size.rem(0.25));
                                            }),
                                            styleContainer: pure(Data_Unit.unit),
                                            theme: Toad_Atom_Button_Style.primary(Toad_Css_Color.green)
                                        });
                                    })() ]);
                                };
                            };
                        };
                    };
                };
            };
        };
    };
};
var ordTitle = Data_Ord.ordString;
var newtypeTitle = {
    Coercible0: function () {
        return undefined;
    }
};
var itemData = function (v) {
    return v.value1;
};
var itemsWithActive = function (dictEq) {
    var eq1 = Data_Eq.eq(dictEq);
    return function (is) {
        return function (a) {
            return Data_Array_Mve.fromArray((function () {
                var $79 = eq1(a);
                return function ($80) {
                    return $79(itemData($80));
                };
            })())(is);
        };
    };
};
var headerText = function (v) {
    if (v instanceof HeaderItem) {
        return v.value0;
    };
    if (v instanceof HeaderItemWithValue) {
        return v.value0;
    };
    throw new Error("Failed pattern match at Toad.Atom.Accordion (line 91, column 1 - line 91, column 42): " + [ v.constructor.name ]);
};
var headerItemData = function (v) {
    if (v instanceof HeaderItem) {
        return Data_Maybe.Nothing.value;
    };
    if (v instanceof HeaderItemWithValue) {
        return new Data_Maybe.Just(v.value1);
    };
    throw new Error("Failed pattern match at Toad.Atom.Accordion (line 87, column 1 - line 87, column 47): " + [ v.constructor.name ]);
};
var render = function (dictEq) {
    var itemsWithActive1 = itemsWithActive(dictEq);
    var eq1 = Data_Eq.eq(Data_Maybe.eqMaybe(dictEq));
    return function (x) {
        return function (v) {
            var rowBase = renderRow(v.actionNoop)(v.actionToggleExpanded)(v.actionClickItem)(Toad_Css_Color.green)(v.expanded);
            var itemsWithActive$prime = bindFlipped(itemsWithActive1(v.items))(v.active);
            var itemRow = function (active$prime) {
                return function (v1) {
                    return rowBase(false)(v1.value0)(new Data_Maybe.Just(v1.value1))(active$prime);
                };
            };
            var renderItemsWithActive = function (before) {
                return function (activeOne) {
                    return function (after) {
                        return append1(map(itemRow(Data_Active.Inactive.value))(before))(append1([ itemRow(Data_Active.Active.value)(activeOne) ])(map(itemRow(Data_Active.Inactive.value))(after)));
                    };
                };
            };
            var headerIsActive = (function () {
                if (eq1(headerItemData(v.header))(v.active)) {
                    return Data_Active.Active.value;
                };
                if (eq(v.expanded)(Data_Expanded.Collapsed.value) && Data_Maybe.isJust(itemsWithActive$prime)) {
                    return Data_Active.Active.value;
                };
                if (Data_Boolean.otherwise) {
                    return Data_Active.Inactive.value;
                };
                throw new Error("Failed pattern match at Toad.Atom.Accordion (line 257, column 5 - line 260, column 29): " + [  ]);
            })();
            var headerRow = rowBase(true)(headerText(v.header))(headerItemData(v.header))(headerIsActive);
            return Halogen_HTML_Elements.div([ Toad_Css.style(discard(CSS_Display.display(CSS_Display.flex))(function () {
                return discard(CSS_Flexbox.justifyContent(flexStart))(function () {
                    return Data_Maybe.fromMaybe(pure(Data_Unit.unit))(x);
                });
            })) ])([ Halogen_HTML_Elements.div([ Toad_Css.style(CSS_Geometry.width(CSS_Size.pct(100.0))) ])(append1([ headerRow ])((function () {
                if (v.expanded instanceof Data_Expanded.Collapsed) {
                    return [  ];
                };
                if (v.expanded instanceof Data_Expanded.Expanded) {
                    return [ Halogen_HTML_Elements.div([ Toad_Css.style(CSS_Geometry.width(CSS_Size.pct(100.0))) ])(Data_Maybe.maybe(map(itemRow(Data_Active.Inactive.value))(v.items))(Data_Array_Mve.foldParts(renderItemsWithActive))(itemsWithActive$prime)) ];
                };
                throw new Error("Failed pattern match at Toad.Atom.Accordion (line 274, column 13 - line 285, column 18): " + [ v.expanded.constructor.name ]);
            })())) ]);
        };
    };
};
var genericTitle = {
    to: function (x) {
        return x;
    },
    from: function (x) {
        return x;
    }
};
var showTitle = {
    show: /* #__PURE__ */ Data_Show_Generic.genericShow(genericTitle)(/* #__PURE__ */ Data_Show_Generic.genericShowConstructor(/* #__PURE__ */ Data_Show_Generic.genericShowArgsArgument(Data_Show.showString))({
        reflectSymbol: function () {
            return "Title";
        }
    }))
};
var show = /* #__PURE__ */ Data_Show.show(showTitle);
var showHeaderItem = function (dictShow) {
    var show1 = Data_Show.show(dictShow);
    return {
        show: function (v) {
            if (v instanceof HeaderItem) {
                return "HeaderItem (" + (show(v.value0) + ")");
            };
            if (v instanceof HeaderItemWithValue) {
                return "HeaderItemWithValue (" + (show(v.value0) + (") (" + (show1(v.value1) + ")")));
            };
            throw new Error("Failed pattern match at Toad.Atom.Accordion (line 72, column 1 - line 82, column 13): " + [ v.constructor.name ]);
        }
    };
};
var showItem = function (dictShow) {
    var show1 = Data_Show.show(dictShow);
    return {
        show: function (v) {
            return "Item (" + (show(v.value0) + (") (" + (show1(v.value1) + ")")));
        }
    };
};
var eqTitle = Data_Eq.eqString;
export {
    Item,
    Title,
    render,
    HeaderItem,
    HeaderItemWithValue,
    newtypeTitle,
    genericTitle,
    eqTitle,
    ordTitle,
    showTitle,
    showItem,
    showHeaderItem
};
