// Generated by purs version 0.15.4
import * as Halogen_Query from "../Halogen.Query/index.js";
var Navigate = /* #__PURE__ */ (function () {
    function Navigate(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Navigate.create = function (value0) {
        return function (value1) {
            return new Navigate(value0, value1);
        };
    };
    return Navigate;
})();
var sendNavigate = function (route) {
    return function (app) {
        return app.query(Halogen_Query.mkTell(Navigate.create(route)));
    };
};
export {
    Navigate,
    sendNavigate
};
