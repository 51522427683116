// Generated by purs version 0.15.4
import * as Control_Alt from "../Control.Alt/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_Rec_Class from "../Control.Monad.Rec.Class/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Array_NonEmpty from "../Data.Array.NonEmpty/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_List_Types from "../Data.List.Types/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
import * as Parsing from "../Parsing/index.js";
import * as Parsing_Combinators from "../Parsing.Combinators/index.js";
var bind = /* #__PURE__ */ Control_Bind.bind(Parsing.bindParserT);
var tailRecM = /* #__PURE__ */ Control_Monad_Rec_Class.tailRecM(Parsing.monadRecParserT);
var alt = /* #__PURE__ */ Control_Alt.alt(Parsing.altParserT);
var pure = /* #__PURE__ */ Control_Applicative.pure(Parsing.applicativeParserT);
var fromFoldable = /* #__PURE__ */ Data_Array.fromFoldable(Data_List_Types.foldableList);
var manyTill_ = function (p) {
    return function (end) {
        return bind(Data_Function.flip(tailRecM)(Data_List_Types.Nil.value)(function (xs) {
            return alt(bind(end)(function (t) {
                return pure(new Control_Monad_Rec_Class.Done(new Data_Tuple.Tuple(xs, t)));
            }))(bind(p)(function (x) {
                return pure(new Control_Monad_Rec_Class.Loop(new Data_List_Types.Cons(x, xs)));
            }));
        }))(function (v) {
            return pure(new Data_Tuple.Tuple(Data_Array.reverse(fromFoldable(v.value0)), v.value1));
        });
    };
};
var manyIndex = function (from) {
    return function (to) {
        return function (p) {
            var go = function (v) {
                var $18 = v.value0 >= to;
                if ($18) {
                    return pure(new Control_Monad_Rec_Class.Done(new Data_Tuple.Tuple(v.value0, v.value1)));
                };
                return alt(bind(p(v.value0))(function (x) {
                    return pure(new Control_Monad_Rec_Class.Loop(new Data_Tuple.Tuple(v.value0 + 1 | 0, new Data_List_Types.Cons(x, v.value1))));
                }))((function () {
                    var $19 = v.value0 >= from;
                    if ($19) {
                        return pure(new Control_Monad_Rec_Class.Done(new Data_Tuple.Tuple(v.value0, v.value1)));
                    };
                    return Parsing.fail("Expected more phrases");
                })());
            };
            var $22 = from > to || from < 0;
            if ($22) {
                return pure(new Data_Tuple.Tuple(0, [  ]));
            };
            return bind(tailRecM(go)(new Data_Tuple.Tuple(0, Data_List_Types.Nil.value)))(function (v) {
                return pure(new Data_Tuple.Tuple(v.value0, Data_Array.reverse(fromFoldable(v.value1))));
            });
        };
    };
};
var many = function (p) {
    return bind(Data_Function.flip(tailRecM)(Data_List_Types.Nil.value)(function (xs) {
        return alt(bind(Parsing_Combinators["try"](p))(function (x) {
            return pure(new Control_Monad_Rec_Class.Loop(new Data_List_Types.Cons(x, xs)));
        }))(pure(new Control_Monad_Rec_Class.Done(xs)));
    }))(function (rlist) {
        return pure(Data_Array.reverse(fromFoldable(rlist)));
    });
};
var many1 = function (p) {
    return bind(many(p))(function (xs) {
        var v = Data_Array_NonEmpty.fromArray(xs);
        if (v instanceof Data_Maybe.Nothing) {
            return Parsing.fail("Expected at least 1");
        };
        if (v instanceof Data_Maybe.Just) {
            return pure(v.value0);
        };
        throw new Error("Failed pattern match at Parsing.Combinators.Array (line 51, column 3 - line 53, column 25): " + [ v.constructor.name ]);
    });
};
export {
    many,
    many1,
    manyTill_,
    manyIndex
};
