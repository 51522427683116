// Generated by purs version 0.15.4
import * as CSS_Common from "../CSS.Common/index.js";
import * as CSS_Property from "../CSS.Property/index.js";
import * as CSS_String from "../CSS.String/index.js";
import * as CSS_Stylesheet from "../CSS.Stylesheet/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Show from "../Data.Show/index.js";
var fromString = /* #__PURE__ */ CSS_String.fromString(CSS_Property.isStringKey);
var fromString1 = /* #__PURE__ */ CSS_String.fromString(CSS_Property.isStringValue);
var eq = /* #__PURE__ */ Data_Eq.eq(CSS_Property.eqValue);
var compare = /* #__PURE__ */ Data_Ord.compare(CSS_Property.ordValue);
var Visibility = function (x) {
    return x;
};
var Position = function (x) {
    return x;
};
var FloatLeft = /* #__PURE__ */ (function () {
    function FloatLeft() {

    };
    FloatLeft.value = new FloatLeft();
    return FloatLeft;
})();
var FloatRight = /* #__PURE__ */ (function () {
    function FloatRight() {

    };
    FloatRight.value = new FloatRight();
    return FloatRight;
})();
var FloatNone = /* #__PURE__ */ (function () {
    function FloatNone() {

    };
    FloatNone.value = new FloatNone();
    return FloatNone;
})();
var Display = function (x) {
    return x;
};
var ClearFloatLeft = /* #__PURE__ */ (function () {
    function ClearFloatLeft() {

    };
    ClearFloatLeft.value = new ClearFloatLeft();
    return ClearFloatLeft;
})();
var ClearFloatRight = /* #__PURE__ */ (function () {
    function ClearFloatRight() {

    };
    ClearFloatRight.value = new ClearFloatRight();
    return ClearFloatRight;
})();
var ClearFloatBoth = /* #__PURE__ */ (function () {
    function ClearFloatBoth() {

    };
    ClearFloatBoth.value = new ClearFloatBoth();
    return ClearFloatBoth;
})();
var ClearFloatNone = /* #__PURE__ */ (function () {
    function ClearFloatNone() {

    };
    ClearFloatNone.value = new ClearFloatNone();
    return ClearFloatNone;
})();
var ClearFloatInherit = /* #__PURE__ */ (function () {
    function ClearFloatInherit() {

    };
    ClearFloatInherit.value = new ClearFloatInherit();
    return ClearFloatInherit;
})();
var ClearFloatInlineStart = /* #__PURE__ */ (function () {
    function ClearFloatInlineStart() {

    };
    ClearFloatInlineStart.value = new ClearFloatInlineStart();
    return ClearFloatInlineStart;
})();
var ClearFloatInlineEnd = /* #__PURE__ */ (function () {
    function ClearFloatInlineEnd() {

    };
    ClearFloatInlineEnd.value = new ClearFloatInlineEnd();
    return ClearFloatInlineEnd;
})();
var visibleVisibility = CSS_Common.visibleValue;
var valVisibility = CSS_Property.valValue;
var unsetVisibility = CSS_Common.unsetValue;
var otherVisibility = CSS_Common.otherValue;
var initialVisibility = CSS_Common.initialValue;
var inheritVisibility = CSS_Common.inheritValue;
var hiddenVisibility = CSS_Common.hiddenValue;
var zIndex = /* #__PURE__ */ (function () {
    var $64 = CSS_Stylesheet.key(CSS_Property.valString)(fromString("z-index"));
    var $65 = Data_Show.show(Data_Show.showInt);
    return function ($66) {
        return $64($65($66));
    };
})();
var visibility = /* #__PURE__ */ CSS_Stylesheet.key(valVisibility)(/* #__PURE__ */ fromString("visibility"));
var valPosition = {
    value: function (v) {
        return v;
    }
};
var valFloat = {
    value: function (v) {
        if (v instanceof FloatLeft) {
            return fromString1("left");
        };
        if (v instanceof FloatRight) {
            return fromString1("right");
        };
        if (v instanceof FloatNone) {
            return fromString1("none");
        };
        throw new Error("Failed pattern match at CSS.Display (line 116, column 1 - line 119, column 40): " + [ v.constructor.name ]);
    }
};
var valDisplay = {
    value: function (v) {
        return v;
    }
};
var valClearFloat = {
    value: function (v) {
        if (v instanceof ClearFloatLeft) {
            return fromString1("left");
        };
        if (v instanceof ClearFloatRight) {
            return fromString1("right");
        };
        if (v instanceof ClearFloatBoth) {
            return fromString1("both");
        };
        if (v instanceof ClearFloatNone) {
            return fromString1("none");
        };
        if (v instanceof ClearFloatInherit) {
            return fromString1("inherit");
        };
        if (v instanceof ClearFloatInlineStart) {
            return fromString1("inline-start");
        };
        if (v instanceof ClearFloatInlineEnd) {
            return fromString1("inline-end");
        };
        throw new Error("Failed pattern match at CSS.Display (line 153, column 1 - line 160, column 56): " + [ v.constructor.name ]);
    }
};
var tableRowGroup = /* #__PURE__ */ fromString1("table-row-Group");
var tableRow = /* #__PURE__ */ fromString1("table-row");
var tableHeaderGroup = /* #__PURE__ */ fromString1("table-header-group");
var tableFooterGroup = /* #__PURE__ */ fromString1("table-footer-group");
var tableColumnGroup = /* #__PURE__ */ fromString1("table-column-group");
var tableColumn = /* #__PURE__ */ fromString1("table-column");
var tableCell = /* #__PURE__ */ fromString1("table-cell");
var tableCaption = /* #__PURE__ */ fromString1("table-caption");
var $$static = /* #__PURE__ */ fromString1("static");
var showFloat = {
    show: function (v) {
        if (v instanceof FloatLeft) {
            return "FloatLeft";
        };
        if (v instanceof FloatRight) {
            return "FloatRight";
        };
        if (v instanceof FloatNone) {
            return "FloatNone";
        };
        throw new Error("Failed pattern match at CSS.Display (line 111, column 1 - line 114, column 31): " + [ v.constructor.name ]);
    }
};
var showClearFloat = {
    show: function (v) {
        if (v instanceof ClearFloatLeft) {
            return "ClearFloatLeft";
        };
        if (v instanceof ClearFloatRight) {
            return "ClearFloatRight";
        };
        if (v instanceof ClearFloatBoth) {
            return "ClearFloatBoth";
        };
        if (v instanceof ClearFloatNone) {
            return "ClearFloatNone";
        };
        if (v instanceof ClearFloatInherit) {
            return "ClearFloatInherit";
        };
        if (v instanceof ClearFloatInlineStart) {
            return "ClearFloatInlineStart";
        };
        if (v instanceof ClearFloatInlineEnd) {
            return "ClearFloatInlineEnd";
        };
        throw new Error("Failed pattern match at CSS.Display (line 144, column 1 - line 151, column 51): " + [ v.constructor.name ]);
    }
};
var runIn = /* #__PURE__ */ fromString1("runIn");
var relative = /* #__PURE__ */ fromString1("relative");
var position = /* #__PURE__ */ CSS_Stylesheet.key(valPosition)(/* #__PURE__ */ fromString("position"));
var opacity = /* #__PURE__ */ CSS_Stylesheet.key(CSS_Property.valNumber)(/* #__PURE__ */ fromString("opacity"));
var noneFloat = /* #__PURE__ */ (function () {
    return {
        none: FloatNone.value
    };
})();
var noneClearFloat = /* #__PURE__ */ (function () {
    return {
        none: ClearFloatNone.value
    };
})();
var listItem = /* #__PURE__ */ fromString1("list-item");
var inlineTable = /* #__PURE__ */ fromString1("inline-table");
var inlineGrid = /* #__PURE__ */ fromString1("inline-grid");
var inlineFlex = /* #__PURE__ */ fromString1("inline-flex");
var inlineBlock = /* #__PURE__ */ fromString1("inline-block");
var inline = /* #__PURE__ */ fromString1("inline");
var inheritClearFloat = /* #__PURE__ */ (function () {
    return {
        inherit: ClearFloatInherit.value
    };
})();
var grid = /* #__PURE__ */ fromString1("grid");
var floatRight = /* #__PURE__ */ (function () {
    return FloatRight.value;
})();
var floatLeft = /* #__PURE__ */ (function () {
    return FloatLeft.value;
})();
var $$float = /* #__PURE__ */ CSS_Stylesheet.key(valFloat)(/* #__PURE__ */ fromString("float"));
var flex = /* #__PURE__ */ fromString1("flex");
var fixed = /* #__PURE__ */ fromString1("fixed");
var eqPosition = {
    eq: function (x) {
        return function (y) {
            return eq(x)(y);
        };
    }
};
var ordPosition = {
    compare: function (x) {
        return function (y) {
            return compare(x)(y);
        };
    },
    Eq0: function () {
        return eqPosition;
    }
};
var eqFloat = {
    eq: function (x) {
        return function (y) {
            if (x instanceof FloatLeft && y instanceof FloatLeft) {
                return true;
            };
            if (x instanceof FloatRight && y instanceof FloatRight) {
                return true;
            };
            if (x instanceof FloatNone && y instanceof FloatNone) {
                return true;
            };
            return false;
        };
    }
};
var eqDisplay = {
    eq: function (x) {
        return function (y) {
            return eq(x)(y);
        };
    }
};
var ordDisplay = {
    compare: function (x) {
        return function (y) {
            return compare(x)(y);
        };
    },
    Eq0: function () {
        return eqDisplay;
    }
};
var eqClearFloat = {
    eq: function (x) {
        return function (y) {
            if (x instanceof ClearFloatLeft && y instanceof ClearFloatLeft) {
                return true;
            };
            if (x instanceof ClearFloatRight && y instanceof ClearFloatRight) {
                return true;
            };
            if (x instanceof ClearFloatBoth && y instanceof ClearFloatBoth) {
                return true;
            };
            if (x instanceof ClearFloatNone && y instanceof ClearFloatNone) {
                return true;
            };
            if (x instanceof ClearFloatInherit && y instanceof ClearFloatInherit) {
                return true;
            };
            if (x instanceof ClearFloatInlineStart && y instanceof ClearFloatInlineStart) {
                return true;
            };
            if (x instanceof ClearFloatInlineEnd && y instanceof ClearFloatInlineEnd) {
                return true;
            };
            return false;
        };
    }
};
var displayTable = /* #__PURE__ */ fromString1("table");
var displayNone = /* #__PURE__ */ fromString1("none");
var displayInherit = /* #__PURE__ */ fromString1("inherit");
var display = /* #__PURE__ */ CSS_Stylesheet.key(valDisplay)(/* #__PURE__ */ fromString("display"));
var collapse = /* #__PURE__ */ fromString1("collapse");
var clearRight = /* #__PURE__ */ (function () {
    return ClearFloatRight.value;
})();
var clearLeft = /* #__PURE__ */ (function () {
    return ClearFloatLeft.value;
})();
var clearInlineStart = /* #__PURE__ */ (function () {
    return ClearFloatInlineStart.value;
})();
var clearInlineEnd = /* #__PURE__ */ (function () {
    return ClearFloatInlineEnd.value;
})();
var clearBoth = /* #__PURE__ */ (function () {
    return ClearFloatBoth.value;
})();
var clear = /* #__PURE__ */ CSS_Stylesheet.key(valClearFloat)(/* #__PURE__ */ fromString("clear"));
var block = /* #__PURE__ */ fromString1("block");
var absolute = /* #__PURE__ */ fromString1("absolute");
export {
    Position,
    position,
    $$static as static,
    absolute,
    fixed,
    relative,
    Display,
    inline,
    block,
    listItem,
    runIn,
    inlineBlock,
    displayTable,
    inlineTable,
    tableRowGroup,
    tableHeaderGroup,
    tableFooterGroup,
    tableRow,
    tableColumnGroup,
    tableColumn,
    tableCell,
    tableCaption,
    displayNone,
    displayInherit,
    flex,
    inlineFlex,
    grid,
    inlineGrid,
    display,
    FloatLeft,
    FloatRight,
    FloatNone,
    floatLeft,
    floatRight,
    $$float as float,
    ClearFloatLeft,
    ClearFloatRight,
    ClearFloatBoth,
    ClearFloatNone,
    ClearFloatInherit,
    ClearFloatInlineStart,
    ClearFloatInlineEnd,
    clearLeft,
    clearRight,
    clearBoth,
    clearInlineStart,
    clearInlineEnd,
    clear,
    opacity,
    Visibility,
    collapse,
    visibility,
    zIndex,
    eqPosition,
    ordPosition,
    valPosition,
    eqDisplay,
    ordDisplay,
    valDisplay,
    eqFloat,
    showFloat,
    valFloat,
    noneFloat,
    eqClearFloat,
    showClearFloat,
    valClearFloat,
    noneClearFloat,
    inheritClearFloat,
    valVisibility,
    otherVisibility,
    inheritVisibility,
    initialVisibility,
    unsetVisibility,
    hiddenVisibility,
    visibleVisibility
};
