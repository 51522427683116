// Generated by purs version 0.15.4
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
var UpperIncl = /* #__PURE__ */ (function () {
    function UpperIncl(value0) {
        this.value0 = value0;
    };
    UpperIncl.create = function (value0) {
        return new UpperIncl(value0);
    };
    return UpperIncl;
})();
var UpperExcl = /* #__PURE__ */ (function () {
    function UpperExcl(value0) {
        this.value0 = value0;
    };
    UpperExcl.create = function (value0) {
        return new UpperExcl(value0);
    };
    return UpperExcl;
})();
var Range = /* #__PURE__ */ (function () {
    function Range(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Range.create = function (value0) {
        return function (value1) {
            return new Range(value0, value1);
        };
    };
    return Range;
})();
var LowerIncl = /* #__PURE__ */ (function () {
    function LowerIncl(value0) {
        this.value0 = value0;
    };
    LowerIncl.create = function (value0) {
        return new LowerIncl(value0);
    };
    return LowerIncl;
})();
var LowerExcl = /* #__PURE__ */ (function () {
    function LowerExcl(value0) {
        this.value0 = value0;
    };
    LowerExcl.create = function (value0) {
        return new LowerExcl(value0);
    };
    return LowerExcl;
})();
var upperInclBound = function (dictOrd) {
    var lessThanOrEq = Data_Ord.lessThanOrEq(dictOrd);
    return {
        contains: function (v) {
            return function (x) {
                return lessThanOrEq(x)(v.value0);
            };
        },
        max: function (v) {
            return new Data_Maybe.Just(v.value0);
        },
        min: function (v) {
            return Data_Maybe.Nothing.value;
        },
        Ord0: function () {
            return dictOrd;
        }
    };
};
var upperExclBound = function (dictOrd) {
    var lessThan = Data_Ord.lessThan(dictOrd);
    return {
        contains: function (v) {
            return function (x) {
                return lessThan(x)(v.value0);
            };
        },
        max: function (v) {
            return new Data_Maybe.Just(v.value0);
        },
        min: function (v) {
            return Data_Maybe.Nothing.value;
        },
        Ord0: function () {
            return dictOrd;
        }
    };
};
var min = function (dict) {
    return dict.min;
};
var max = function (dict) {
    return dict.max;
};
var lowerInclBound = function (dictOrd) {
    var greaterThanOrEq = Data_Ord.greaterThanOrEq(dictOrd);
    return {
        contains: function (v) {
            return function (x) {
                return greaterThanOrEq(x)(v.value0);
            };
        },
        max: function (v) {
            return Data_Maybe.Nothing.value;
        },
        min: function (v) {
            return new Data_Maybe.Just(v.value0);
        },
        Ord0: function () {
            return dictOrd;
        }
    };
};
var lowerExclBound = function (dictOrd) {
    var greaterThan = Data_Ord.greaterThan(dictOrd);
    return {
        contains: function (v) {
            return function (x) {
                return greaterThan(x)(v.value0);
            };
        },
        max: function (v) {
            return Data_Maybe.Nothing.value;
        },
        min: function (v) {
            return new Data_Maybe.Just(v.value0);
        },
        Ord0: function () {
            return dictOrd;
        }
    };
};
var contains = function (dict) {
    return dict.contains;
};
var rangeBound = function (dictBound) {
    var contains1 = contains(dictBound);
    var min1 = min(dictBound);
    var Ord0 = dictBound.Ord0();
    return function (dictBound1) {
        var contains2 = contains(dictBound1);
        var max1 = max(dictBound1);
        return {
            contains: function (v) {
                return function (x) {
                    return contains1(v.value0)(x) && contains2(v.value1)(x);
                };
            },
            max: function (v) {
                return max1(v.value1);
            },
            min: function (v) {
                return min1(v.value0);
            },
            Ord0: function () {
                return Ord0;
            }
        };
    };
};
var clamp = function (dictOrd) {
    var contains1 = contains(lowerInclBound(dictOrd));
    var contains2 = contains(upperInclBound(dictOrd));
    return function (v) {
        return function (a) {
            var $75 = !contains1(v.value0)(a);
            if ($75) {
                return v.value0.value0;
            };
            var $76 = !contains2(v.value1)(a);
            if ($76) {
                return v.value1.value0;
            };
            return a;
        };
    };
};
var betweenInclusive = function (dictOrd) {
    return function (low) {
        return function (upp) {
            return new Range(new LowerIncl(low), new UpperIncl(upp));
        };
    };
};
var betweenExclusive = function (dictOrd) {
    return function (low) {
        return function (upp) {
            return new Range(new LowerIncl(low), new UpperExcl(upp));
        };
    };
};
export {
    contains,
    max,
    min,
    Range,
    clamp,
    betweenExclusive,
    betweenInclusive,
    LowerIncl,
    LowerExcl,
    UpperIncl,
    UpperExcl,
    rangeBound,
    lowerInclBound,
    lowerExclBound,
    upperInclBound,
    upperExclBound
};
