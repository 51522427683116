// Generated by purs version 0.15.4
import * as CSS_Stylesheet from "../CSS.Stylesheet/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Halogen_HTML_CSS from "../Halogen.HTML.CSS/index.js";
import * as Toad_Atom_AppTitle_Style from "../Toad.Atom.AppTitle.Style/index.js";
import * as Toad_Css_BoxSizing from "../Toad.Css.BoxSizing/index.js";
import * as Toad_Navbar_Button_Style from "../Toad.Navbar.Button.Style/index.js";
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit)(CSS_Stylesheet.bindStyleM);
var stylesheet = /* #__PURE__ */ Halogen_HTML_CSS.stylesheet(/* #__PURE__ */ discard(Toad_Css_BoxSizing.global)(function () {
    return discard(Toad_Navbar_Button_Style.global)(function () {
        return Toad_Atom_AppTitle_Style.global;
    });
}));
export {
    stylesheet
};
