// Generated by purs version 0.15.4
import * as Control_Category from "../Control.Category/index.js";
import * as Data_Coord_Polar from "../Data.Coord.Polar/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Eq_Generic from "../Data.Eq.Generic/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Generic_Rep from "../Data.Generic.Rep/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Newtype from "../Data.Newtype/index.js";
import * as Data_Number from "../Data.Number/index.js";
import * as Data_Show_Generic from "../Data.Show.Generic/index.js";
import * as Data_Traversable from "../Data.Traversable/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
import * as Data_Tuple_Nested from "../Data.Tuple.Nested/index.js";
import * as Effect from "../Effect/index.js";
import * as Prelude from "../Prelude/index.js";
var id = /* #__PURE__ */ Control_Category.identity(Control_Category.categoryFn);
export {
    id
};
export {
    Degrees,
    Radians
} from "../Data.Coord.Polar/index.js";
export {
    Left,
    Right
} from "../Data.Either/index.js";
export {
    genericEq
} from "../Data.Eq.Generic/index.js";
export {
    fold,
    foldl,
    foldr
} from "../Data.Foldable/index.js";
export {
    Just,
    Nothing,
    maybe
} from "../Data.Maybe/index.js";
export {
    pi
} from "../Data.Number/index.js";
export {
    genericShow
} from "../Data.Show.Generic/index.js";
export {
    sequence,
    traverse
} from "../Data.Traversable/index.js";
export {
    Tuple
} from "../Data.Tuple/index.js";
export {
    EQ,
    GT,
    LT,
    absurd,
    add,
    ap,
    append,
    apply,
    between,
    bind,
    bottom,
    clamp,
    compare,
    comparing,
    compose,
    conj,
    const,
    degree,
    discard,
    disj,
    div,
    eq,
    flap,
    flip,
    gcd,
    identity,
    ifM,
    join,
    lcm,
    liftA1,
    liftM1,
    map,
    max,
    mempty,
    min,
    mod,
    mul,
    negate,
    not,
    notEq,
    one,
    otherwise,
    pure,
    recip,
    show,
    sub,
    top,
    unit,
    unless,
    unlessM,
    void,
    when,
    whenM,
    zero
} from "../Prelude/index.js";
