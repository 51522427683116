// Generated by purs version 0.15.4
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Ordering from "../Data.Ordering/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_String_CodePoints from "../Data.String.CodePoints/index.js";
var append = /* #__PURE__ */ Data_Semigroup.append(Data_Semigroup.semigroupArray);
var pure = /* #__PURE__ */ Control_Applicative.pure(Control_Applicative.applicativeArray);
var eq1 = /* #__PURE__ */ Data_Eq.eq(/* #__PURE__ */ Data_Eq.eqArray(Data_Eq.eqString));
var compare = /* #__PURE__ */ Data_Ord.compare(Data_Ord.ordString);
var compare1 = /* #__PURE__ */ Data_Ord.compare(/* #__PURE__ */ Data_Ord.ordArray(Data_Ord.ordString));
var Id = /* #__PURE__ */ (function () {
    function Id(value0) {
        this.value0 = value0;
    };
    Id.create = function (value0) {
        return new Id(value0);
    };
    return Id;
})();
var Class = /* #__PURE__ */ (function () {
    function Class(value0) {
        this.value0 = value0;
    };
    Class.create = function (value0) {
        return new Class(value0);
    };
    return Class;
})();
var Attr = /* #__PURE__ */ (function () {
    function Attr(value0) {
        this.value0 = value0;
    };
    Attr.create = function (value0) {
        return new Attr(value0);
    };
    return Attr;
})();
var AttrVal = /* #__PURE__ */ (function () {
    function AttrVal(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    AttrVal.create = function (value0) {
        return function (value1) {
            return new AttrVal(value0, value1);
        };
    };
    return AttrVal;
})();
var AttrBegins = /* #__PURE__ */ (function () {
    function AttrBegins(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    AttrBegins.create = function (value0) {
        return function (value1) {
            return new AttrBegins(value0, value1);
        };
    };
    return AttrBegins;
})();
var AttrEnds = /* #__PURE__ */ (function () {
    function AttrEnds(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    AttrEnds.create = function (value0) {
        return function (value1) {
            return new AttrEnds(value0, value1);
        };
    };
    return AttrEnds;
})();
var AttrContains = /* #__PURE__ */ (function () {
    function AttrContains(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    AttrContains.create = function (value0) {
        return function (value1) {
            return new AttrContains(value0, value1);
        };
    };
    return AttrContains;
})();
var AttrSpace = /* #__PURE__ */ (function () {
    function AttrSpace(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    AttrSpace.create = function (value0) {
        return function (value1) {
            return new AttrSpace(value0, value1);
        };
    };
    return AttrSpace;
})();
var AttrHyph = /* #__PURE__ */ (function () {
    function AttrHyph(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    AttrHyph.create = function (value0) {
        return function (value1) {
            return new AttrHyph(value0, value1);
        };
    };
    return AttrHyph;
})();
var Pseudo = /* #__PURE__ */ (function () {
    function Pseudo(value0) {
        this.value0 = value0;
    };
    Pseudo.create = function (value0) {
        return new Pseudo(value0);
    };
    return Pseudo;
})();
var PseudoFunc = /* #__PURE__ */ (function () {
    function PseudoFunc(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    PseudoFunc.create = function (value0) {
        return function (value1) {
            return new PseudoFunc(value0, value1);
        };
    };
    return PseudoFunc;
})();
var Refinement = function (x) {
    return x;
};
var Star = /* #__PURE__ */ (function () {
    function Star() {

    };
    Star.value = new Star();
    return Star;
})();
var Elem = /* #__PURE__ */ (function () {
    function Elem(value0) {
        this.value0 = value0;
    };
    Elem.create = function (value0) {
        return new Elem(value0);
    };
    return Elem;
})();
var PathChild = /* #__PURE__ */ (function () {
    function PathChild(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    PathChild.create = function (value0) {
        return function (value1) {
            return new PathChild(value0, value1);
        };
    };
    return PathChild;
})();
var Deep = /* #__PURE__ */ (function () {
    function Deep(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Deep.create = function (value0) {
        return function (value1) {
            return new Deep(value0, value1);
        };
    };
    return Deep;
})();
var Adjacent = /* #__PURE__ */ (function () {
    function Adjacent(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Adjacent.create = function (value0) {
        return function (value1) {
            return new Adjacent(value0, value1);
        };
    };
    return Adjacent;
})();
var Combined = /* #__PURE__ */ (function () {
    function Combined(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Combined.create = function (value0) {
        return function (value1) {
            return new Combined(value0, value1);
        };
    };
    return Combined;
})();
var Selector = /* #__PURE__ */ (function () {
    function Selector(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Selector.create = function (value0) {
        return function (value1) {
            return new Selector(value0, value1);
        };
    };
    return Selector;
})();
var $$with = function (v) {
    return function (v1) {
        return new Selector(append(v.value0)(v1), v.value1);
    };
};
var star = /* #__PURE__ */ (function () {
    return new Selector([  ], Star.value);
})();
var pseudo = function ($372) {
    return Refinement(pure(Pseudo.create($372)));
};
var isStringRefinement = {
    fromString: function (s) {
        return [ (function () {
            var v = Data_String_CodePoints.take(1)(s);
            if (v === "#") {
                return new Id(Data_String_CodePoints.drop(1)(s));
            };
            if (v === ".") {
                return new Class(Data_String_CodePoints.drop(1)(s));
            };
            if (v === ":") {
                return new Pseudo(Data_String_CodePoints.drop(1)(s));
            };
            if (v === "@") {
                return new Attr(Data_String_CodePoints.drop(1)(s));
            };
            return new Attr(s);
        })() ];
    }
};
var func = function (f) {
    var $373 = PseudoFunc.create(f);
    return function ($374) {
        return Refinement(pure($373($374)));
    };
};
var eqPredicate = {
    eq: function (x) {
        return function (y) {
            if (x instanceof Id && y instanceof Id) {
                return x.value0 === y.value0;
            };
            if (x instanceof Class && y instanceof Class) {
                return x.value0 === y.value0;
            };
            if (x instanceof Attr && y instanceof Attr) {
                return x.value0 === y.value0;
            };
            if (x instanceof AttrVal && y instanceof AttrVal) {
                return x.value0 === y.value0 && x.value1 === y.value1;
            };
            if (x instanceof AttrBegins && y instanceof AttrBegins) {
                return x.value0 === y.value0 && x.value1 === y.value1;
            };
            if (x instanceof AttrEnds && y instanceof AttrEnds) {
                return x.value0 === y.value0 && x.value1 === y.value1;
            };
            if (x instanceof AttrContains && y instanceof AttrContains) {
                return x.value0 === y.value0 && x.value1 === y.value1;
            };
            if (x instanceof AttrSpace && y instanceof AttrSpace) {
                return x.value0 === y.value0 && x.value1 === y.value1;
            };
            if (x instanceof AttrHyph && y instanceof AttrHyph) {
                return x.value0 === y.value0 && x.value1 === y.value1;
            };
            if (x instanceof Pseudo && y instanceof Pseudo) {
                return x.value0 === y.value0;
            };
            if (x instanceof PseudoFunc && y instanceof PseudoFunc) {
                return x.value0 === y.value0 && eq1(x.value1)(y.value1);
            };
            return false;
        };
    }
};
var eq2 = /* #__PURE__ */ Data_Eq.eq(/* #__PURE__ */ Data_Eq.eqArray(eqPredicate));
var eqRefinement = {
    eq: function (x) {
        return function (y) {
            return eq2(x)(y);
        };
    }
};
var eq3 = /* #__PURE__ */ Data_Eq.eq(eqRefinement);
var ordPredicate = {
    compare: function (x) {
        return function (y) {
            if (x instanceof Id && y instanceof Id) {
                return compare(x.value0)(y.value0);
            };
            if (x instanceof Id) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Id) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Class && y instanceof Class) {
                return compare(x.value0)(y.value0);
            };
            if (x instanceof Class) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Class) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Attr && y instanceof Attr) {
                return compare(x.value0)(y.value0);
            };
            if (x instanceof Attr) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Attr) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AttrVal && y instanceof AttrVal) {
                var v = compare(x.value0)(y.value0);
                if (v instanceof Data_Ordering.LT) {
                    return Data_Ordering.LT.value;
                };
                if (v instanceof Data_Ordering.GT) {
                    return Data_Ordering.GT.value;
                };
                return compare(x.value1)(y.value1);
            };
            if (x instanceof AttrVal) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AttrVal) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AttrBegins && y instanceof AttrBegins) {
                var v = compare(x.value0)(y.value0);
                if (v instanceof Data_Ordering.LT) {
                    return Data_Ordering.LT.value;
                };
                if (v instanceof Data_Ordering.GT) {
                    return Data_Ordering.GT.value;
                };
                return compare(x.value1)(y.value1);
            };
            if (x instanceof AttrBegins) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AttrBegins) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AttrEnds && y instanceof AttrEnds) {
                var v = compare(x.value0)(y.value0);
                if (v instanceof Data_Ordering.LT) {
                    return Data_Ordering.LT.value;
                };
                if (v instanceof Data_Ordering.GT) {
                    return Data_Ordering.GT.value;
                };
                return compare(x.value1)(y.value1);
            };
            if (x instanceof AttrEnds) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AttrEnds) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AttrContains && y instanceof AttrContains) {
                var v = compare(x.value0)(y.value0);
                if (v instanceof Data_Ordering.LT) {
                    return Data_Ordering.LT.value;
                };
                if (v instanceof Data_Ordering.GT) {
                    return Data_Ordering.GT.value;
                };
                return compare(x.value1)(y.value1);
            };
            if (x instanceof AttrContains) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AttrContains) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AttrSpace && y instanceof AttrSpace) {
                var v = compare(x.value0)(y.value0);
                if (v instanceof Data_Ordering.LT) {
                    return Data_Ordering.LT.value;
                };
                if (v instanceof Data_Ordering.GT) {
                    return Data_Ordering.GT.value;
                };
                return compare(x.value1)(y.value1);
            };
            if (x instanceof AttrSpace) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AttrSpace) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AttrHyph && y instanceof AttrHyph) {
                var v = compare(x.value0)(y.value0);
                if (v instanceof Data_Ordering.LT) {
                    return Data_Ordering.LT.value;
                };
                if (v instanceof Data_Ordering.GT) {
                    return Data_Ordering.GT.value;
                };
                return compare(x.value1)(y.value1);
            };
            if (x instanceof AttrHyph) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AttrHyph) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Pseudo && y instanceof Pseudo) {
                return compare(x.value0)(y.value0);
            };
            if (x instanceof Pseudo) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Pseudo) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof PseudoFunc && y instanceof PseudoFunc) {
                var v = compare(x.value0)(y.value0);
                if (v instanceof Data_Ordering.LT) {
                    return Data_Ordering.LT.value;
                };
                if (v instanceof Data_Ordering.GT) {
                    return Data_Ordering.GT.value;
                };
                return compare1(x.value1)(y.value1);
            };
            throw new Error("Failed pattern match at CSS.Selector (line 0, column 0 - line 0, column 0): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqPredicate;
    }
};
var compare2 = /* #__PURE__ */ Data_Ord.compare(/* #__PURE__ */ Data_Ord.ordArray(ordPredicate));
var ordRefinement = {
    compare: function (x) {
        return function (y) {
            return compare2(x)(y);
        };
    },
    Eq0: function () {
        return eqRefinement;
    }
};
var compare3 = /* #__PURE__ */ Data_Ord.compare(ordRefinement);
var eqPath = function (dictEq) {
    var eq4 = Data_Eq.eq(dictEq);
    return {
        eq: function (x) {
            return function (y) {
                if (x instanceof Star && y instanceof Star) {
                    return true;
                };
                if (x instanceof Elem && y instanceof Elem) {
                    return x.value0 === y.value0;
                };
                if (x instanceof PathChild && y instanceof PathChild) {
                    return eq4(x.value0)(y.value0) && eq4(x.value1)(y.value1);
                };
                if (x instanceof Deep && y instanceof Deep) {
                    return eq4(x.value0)(y.value0) && eq4(x.value1)(y.value1);
                };
                if (x instanceof Adjacent && y instanceof Adjacent) {
                    return eq4(x.value0)(y.value0) && eq4(x.value1)(y.value1);
                };
                if (x instanceof Combined && y instanceof Combined) {
                    return eq4(x.value0)(y.value0) && eq4(x.value1)(y.value1);
                };
                return false;
            };
        }
    };
};
var eqSelector = {
    eq: function (x) {
        return function (y) {
            return eq3(x.value0)(y.value0) && Data_Eq.eq(eqPath(eqSelector))(x.value1)(y.value1);
        };
    }
};
var ordPath = function (dictOrd) {
    var compare4 = Data_Ord.compare(dictOrd);
    var eqPath1 = eqPath(dictOrd.Eq0());
    return {
        compare: function (x) {
            return function (y) {
                if (x instanceof Star && y instanceof Star) {
                    return Data_Ordering.EQ.value;
                };
                if (x instanceof Star) {
                    return Data_Ordering.LT.value;
                };
                if (y instanceof Star) {
                    return Data_Ordering.GT.value;
                };
                if (x instanceof Elem && y instanceof Elem) {
                    return compare(x.value0)(y.value0);
                };
                if (x instanceof Elem) {
                    return Data_Ordering.LT.value;
                };
                if (y instanceof Elem) {
                    return Data_Ordering.GT.value;
                };
                if (x instanceof PathChild && y instanceof PathChild) {
                    var v = compare4(x.value0)(y.value0);
                    if (v instanceof Data_Ordering.LT) {
                        return Data_Ordering.LT.value;
                    };
                    if (v instanceof Data_Ordering.GT) {
                        return Data_Ordering.GT.value;
                    };
                    return compare4(x.value1)(y.value1);
                };
                if (x instanceof PathChild) {
                    return Data_Ordering.LT.value;
                };
                if (y instanceof PathChild) {
                    return Data_Ordering.GT.value;
                };
                if (x instanceof Deep && y instanceof Deep) {
                    var v = compare4(x.value0)(y.value0);
                    if (v instanceof Data_Ordering.LT) {
                        return Data_Ordering.LT.value;
                    };
                    if (v instanceof Data_Ordering.GT) {
                        return Data_Ordering.GT.value;
                    };
                    return compare4(x.value1)(y.value1);
                };
                if (x instanceof Deep) {
                    return Data_Ordering.LT.value;
                };
                if (y instanceof Deep) {
                    return Data_Ordering.GT.value;
                };
                if (x instanceof Adjacent && y instanceof Adjacent) {
                    var v = compare4(x.value0)(y.value0);
                    if (v instanceof Data_Ordering.LT) {
                        return Data_Ordering.LT.value;
                    };
                    if (v instanceof Data_Ordering.GT) {
                        return Data_Ordering.GT.value;
                    };
                    return compare4(x.value1)(y.value1);
                };
                if (x instanceof Adjacent) {
                    return Data_Ordering.LT.value;
                };
                if (y instanceof Adjacent) {
                    return Data_Ordering.GT.value;
                };
                if (x instanceof Combined && y instanceof Combined) {
                    var v = compare4(x.value0)(y.value0);
                    if (v instanceof Data_Ordering.LT) {
                        return Data_Ordering.LT.value;
                    };
                    if (v instanceof Data_Ordering.GT) {
                        return Data_Ordering.GT.value;
                    };
                    return compare4(x.value1)(y.value1);
                };
                throw new Error("Failed pattern match at CSS.Selector (line 0, column 0 - line 0, column 0): " + [ x.constructor.name, y.constructor.name ]);
            };
        },
        Eq0: function () {
            return eqPath1;
        }
    };
};
var ordSelector = {
    compare: function (x) {
        return function (y) {
            var v = compare3(x.value0)(y.value0);
            if (v instanceof Data_Ordering.LT) {
                return Data_Ordering.LT.value;
            };
            if (v instanceof Data_Ordering.GT) {
                return Data_Ordering.GT.value;
            };
            return Data_Ord.compare(ordPath(ordSelector))(x.value1)(y.value1);
        };
    },
    Eq0: function () {
        return eqSelector;
    }
};
var element = function (e) {
    return new Selector([  ], new Elem(e));
};
var deep = function (a) {
    return function (b) {
        return new Selector([  ], new Deep(a, b));
    };
};
var child = function (a) {
    return function (b) {
        return new Selector([  ], new PathChild(a, b));
    };
};
var byId = function ($375) {
    return Refinement(pure(Id.create($375)));
};
var byClass = function ($376) {
    return Refinement(pure(Class.create($376)));
};
var isStringSelector = {
    fromString: function (s) {
        var v = Data_String_CodePoints.take(1)(s);
        if (v === "#") {
            return new Selector(byId(Data_String_CodePoints.drop(1)(s)), Star.value);
        };
        if (v === ".") {
            return new Selector(byClass(Data_String_CodePoints.drop(1)(s)), Star.value);
        };
        return new Selector([  ], new Elem(s));
    }
};
var attrVal = function (a) {
    var $377 = AttrVal.create(a);
    return function ($378) {
        return Refinement(pure($377($378)));
    };
};
var attrSpace = function (a) {
    var $379 = AttrSpace.create(a);
    return function ($380) {
        return Refinement(pure($379($380)));
    };
};
var attrHyph = function (a) {
    var $381 = AttrHyph.create(a);
    return function ($382) {
        return Refinement(pure($381($382)));
    };
};
var attrEnds = function (a) {
    var $383 = AttrEnds.create(a);
    return function ($384) {
        return Refinement(pure($383($384)));
    };
};
var attrContains = function (a) {
    var $385 = AttrContains.create(a);
    return function ($386) {
        return Refinement(pure($385($386)));
    };
};
var attrBegins = function (a) {
    var $387 = AttrBegins.create(a);
    return function ($388) {
        return Refinement(pure($387($388)));
    };
};
var attr = function ($389) {
    return Refinement(pure(Attr.create($389)));
};
var adjacent = function (a) {
    return function (b) {
        return new Selector([  ], new Adjacent(a, b));
    };
};
export {
    Id,
    Class,
    Attr,
    AttrVal,
    AttrBegins,
    AttrEnds,
    AttrContains,
    AttrSpace,
    AttrHyph,
    Pseudo,
    PseudoFunc,
    Refinement,
    Star,
    Elem,
    PathChild,
    Deep,
    Adjacent,
    Combined,
    Selector,
    star,
    element,
    deep,
    child,
    adjacent,
    $$with as with,
    byId,
    byClass,
    pseudo,
    func,
    attr,
    attrVal,
    attrBegins,
    attrEnds,
    attrContains,
    attrSpace,
    attrHyph,
    eqPredicate,
    ordPredicate,
    eqRefinement,
    ordRefinement,
    isStringRefinement,
    eqPath,
    ordPath,
    eqSelector,
    ordSelector,
    isStringSelector
};
