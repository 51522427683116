// Generated by purs version 0.15.4
import * as CSS_Property from "../CSS.Property/index.js";
import * as CSS_String from "../CSS.String/index.js";
import * as CSS_Stylesheet from "../CSS.Stylesheet/index.js";
import * as CSS_Time from "../CSS.Time/index.js";
import * as CSS_Transition from "../CSS.Transition/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
var fromString = /* #__PURE__ */ CSS_String.fromString(CSS_Property.isStringValue);
var eq = /* #__PURE__ */ Data_Eq.eq(CSS_Property.eqValue);
var compare = /* #__PURE__ */ Data_Ord.compare(CSS_Property.ordValue);
var for_ = /* #__PURE__ */ Data_Foldable.for_(CSS_Stylesheet.applicativeStyleM)(Data_Foldable.foldableArray);
var fromString1 = /* #__PURE__ */ CSS_String.fromString(CSS_Property.isStringKey);
var IterationCount = function (x) {
    return x;
};
var FillMode = function (x) {
    return x;
};
var AnimationName = function (x) {
    return x;
};
var AnimationDirection = function (x) {
    return x;
};
var valIterationCount = {
    value: function (v) {
        return v;
    }
};
var valFillMode = {
    value: function (v) {
        return v;
    }
};
var valAnimationName = {
    value: function (v) {
        return v;
    }
};
var valAnimationDirection = {
    value: function (v) {
        return v;
    }
};
var key = /* #__PURE__ */ CSS_Stylesheet.key(/* #__PURE__ */ CSS_Property.valTuple(/* #__PURE__ */ CSS_Property.valTuple(/* #__PURE__ */ CSS_Property.valTuple(valAnimationName)(CSS_Time.valTime))(/* #__PURE__ */ CSS_Property.valTuple(CSS_Transition.valTimingFunction)(CSS_Time.valTime)))(/* #__PURE__ */ CSS_Property.valTuple(/* #__PURE__ */ CSS_Property.valTuple(valIterationCount)(valAnimationDirection))(valFillMode)));
var reverse = /* #__PURE__ */ fromString("reverse");
var normalAnimationDirection = /* #__PURE__ */ fromString("normal");
var iterationCount = /* #__PURE__ */ (function () {
    var $77 = CSS_Property.value(CSS_Property.valNumber);
    return function ($78) {
        return IterationCount($77($78));
    };
})();
var isStringAnimationName = {
    fromString: function ($79) {
        return AnimationName(fromString($79));
    }
};
var infinite = /* #__PURE__ */ fromString("infinite");
var forwards = /* #__PURE__ */ fromString("forwards");
var eqIterationCount = {
    eq: function (x) {
        return function (y) {
            return eq(x)(y);
        };
    }
};
var ordIterationCount = {
    compare: function (x) {
        return function (y) {
            return compare(x)(y);
        };
    },
    Eq0: function () {
        return eqIterationCount;
    }
};
var eqFillMode = {
    eq: function (x) {
        return function (y) {
            return eq(x)(y);
        };
    }
};
var ordFillMode = {
    compare: function (x) {
        return function (y) {
            return compare(x)(y);
        };
    },
    Eq0: function () {
        return eqFillMode;
    }
};
var eqAnimationName = {
    eq: function (x) {
        return function (y) {
            return eq(x)(y);
        };
    }
};
var ordAnimationName = {
    compare: function (x) {
        return function (y) {
            return compare(x)(y);
        };
    },
    Eq0: function () {
        return eqAnimationName;
    }
};
var eqAnimationDirection = {
    eq: function (x) {
        return function (y) {
            return eq(x)(y);
        };
    }
};
var ordAnimationDirection = {
    compare: function (x) {
        return function (y) {
            return compare(x)(y);
        };
    },
    Eq0: function () {
        return eqAnimationDirection;
    }
};
var backwards = /* #__PURE__ */ fromString("backwards");
var animation = function (p) {
    return function (de) {
        return function (f) {
            return function (du) {
                return function (i) {
                    return function (di) {
                        return function (fm) {
                            var animationKeys = [ "animation", "-webkit-animation", "-moz-animation", "-o-animation" ];
                            return for_(animationKeys)(function (k) {
                                return key(fromString1(k))(new Data_Tuple.Tuple(new Data_Tuple.Tuple(new Data_Tuple.Tuple(p, de), new Data_Tuple.Tuple(f, du)), new Data_Tuple.Tuple(new Data_Tuple.Tuple(i, di), fm)));
                            });
                        };
                    };
                };
            };
        };
    };
};
var alternateReverse = /* #__PURE__ */ fromString("alternate-reverse");
var alternate = /* #__PURE__ */ fromString("alternate");
export {
    AnimationDirection,
    normalAnimationDirection,
    alternate,
    reverse,
    alternateReverse,
    IterationCount,
    infinite,
    iterationCount,
    FillMode,
    forwards,
    backwards,
    animation,
    AnimationName,
    eqAnimationDirection,
    ordAnimationDirection,
    valAnimationDirection,
    eqIterationCount,
    ordIterationCount,
    valIterationCount,
    eqFillMode,
    ordFillMode,
    valFillMode,
    eqAnimationName,
    ordAnimationName,
    valAnimationName,
    isStringAnimationName
};
