// Generated by purs version 0.15.4
import * as Color from "../Color/index.js";
import * as Data_Color_Cie1931 from "../Data.Color.Cie1931/index.js";
import * as Data_Coord_Cart from "../Data.Coord.Cart/index.js";
import * as Data_Coord_Polar from "../Data.Coord.Polar/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_EuclideanRing from "../Data.EuclideanRing/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Generic_Rep from "../Data.Generic.Rep/index.js";
import * as Data_Mat from "../Data.Mat/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Number from "../Data.Number/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Semiring from "../Data.Semiring/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_Show_Generic from "../Data.Show.Generic/index.js";
var toPolar = /* #__PURE__ */ Data_Coord_Cart.toPolar(Data_Coord_Polar.polarPos)(Data_Coord_Cart.cartesianPos);
var make = /* #__PURE__ */ Data_Coord_Cart.make(Data_Coord_Cart.cartesianPos);
var fromPolar = /* #__PURE__ */ Data_Coord_Cart.fromPolar(Data_Coord_Polar.polarPos)(Data_Coord_Cart.cartesianPos);
var x = /* #__PURE__ */ Data_Coord_Cart.x(Data_Coord_Cart.cartesianPos);
var y = /* #__PURE__ */ Data_Coord_Cart.y(Data_Coord_Cart.cartesianPos);
var fromJust = /* #__PURE__ */ Data_Maybe.fromJust();
var inverse3x3 = /* #__PURE__ */ Data_Mat.inverse3x3(Data_Eq.eqNumber)(Data_EuclideanRing.euclideanRingNumber);
var mul3x3_1x3 = /* #__PURE__ */ Data_Mat.mul3x3_1x3(Data_Semiring.semiringNumber);
var make1 = /* #__PURE__ */ Data_Coord_Polar.make(Data_Coord_Polar.polarPos);
var add1 = /* #__PURE__ */ Data_Semiring.add(Data_Coord_Polar.semiringRadians);
var genericShowConstructor = /* #__PURE__ */ Data_Show_Generic.genericShowConstructor(/* #__PURE__ */ Data_Show_Generic.genericShowArgsArgument(Data_Show.showNumber));
var Lightness = function (x1) {
    return x1;
};
var B = function (x1) {
    return x1;
};
var A = function (x1) {
    return x1;
};
var Lab = /* #__PURE__ */ (function () {
    function Lab(value0, value1, value2) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
    };
    Lab.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return new Lab(value0, value1, value2);
            };
        };
    };
    return Lab;
})();
var polar = function (v) {
    return toPolar(make(v.value1)(v.value2));
};
var ordL = Data_Ord.ordNumber;
var ofPolar = function (l) {
    return function (p) {
        var get = function (n) {
            return n(fromPolar(p));
        };
        return new Lab(l, get(x), get(y));
    };
};
var newtypeL = {
    Coercible0: function () {
        return undefined;
    }
};
var modifyLightness = function (f) {
    return function (v) {
        return new Lab(f(v.value0), v.value1, v.value2);
    };
};
var setLightness = function ($106) {
    return modifyLightness(Data_Function["const"]($106));
};
var m2 = /* #__PURE__ */ (function () {
    return new Data_Mat.M3x3(0.2104542553, 0.793617785, -4.0720468e-3, 1.9779984951, -2.428592205, 0.4505937099, 2.59040371e-2, 0.7827717662, -0.808675766);
})();
var m2i = /* #__PURE__ */ fromJust(/* #__PURE__ */ inverse3x3(m2));
var m1 = /* #__PURE__ */ (function () {
    return new Data_Mat.M3x3(0.8189330101, 0.3618667424, -0.1288597137, 3.29845436e-2, 0.9293118715, 3.61456387e-2, 4.82003018e-2, 0.2643662691, 0.633851707);
})();
var m1i = /* #__PURE__ */ fromJust(/* #__PURE__ */ inverse3x3(m1));
var toCieXyz = function (v) {
    var lms = mul3x3_1x3(m2i)(new Data_Mat.M1x3(v.value0, v.value1, v.value2));
    var xyz = mul3x3_1x3(m1i)(new Data_Mat.M1x3(Data_Number.pow(lms.value0)(3.0), Data_Number.pow(lms.value1)(3.0), Data_Number.pow(lms.value2)(3.0)));
    return new Data_Color_Cie1931.Xyz(xyz.value0, xyz.value1, xyz.value2);
};
var toRgb = function ($107) {
    return Data_Color_Cie1931.toRgb(toCieXyz($107));
};
var lightness = function (v) {
    return v.value0;
};
var modifyChroma = function (f) {
    return function (l) {
        return ofPolar(lightness(l))(Data_Coord_Polar.modifyRadius(f)(polar(l)));
    };
};
var setChroma = function ($108) {
    return modifyChroma(Data_Function["const"]($108));
};
var modifyHue = function (f) {
    return function (l) {
        return ofPolar(lightness(l))(Data_Coord_Polar.modifyAngle(f)(polar(l)));
    };
};
var setHue = function ($109) {
    return modifyHue(Data_Function["const"]($109));
};
var lighten = function (dl) {
    return function (l) {
        return setLightness(lightness(l) + dl)(l);
    };
};
var lch = function (dictAngle) {
    var toRadians = Data_Coord_Polar.toRadians(dictAngle);
    return function (l) {
        return function (c) {
            return function (h) {
                return ofPolar(l)(make1(c)(toRadians(h)));
            };
        };
    };
};
var hue = /* #__PURE__ */ (function () {
    var $110 = Data_Coord_Polar.angle(Data_Coord_Polar.polarPos);
    return function ($111) {
        return $110(polar($111));
    };
})();
var shiftHue = function (dh) {
    return function (l) {
        return setHue(add1(hue(l))(dh))(l);
    };
};
var genericLab = {
    to: function (x1) {
        return new Lab(x1.value0, x1.value1.value0, x1.value1.value1);
    },
    from: function (x1) {
        return new Data_Generic_Rep.Product(x1.value0, new Data_Generic_Rep.Product(x1.value1, x1.value2));
    }
};
var genericL = {
    to: function (x1) {
        return x1;
    },
    from: function (x1) {
        return x1;
    }
};
var showL = {
    show: /* #__PURE__ */ Data_Show_Generic.genericShow(genericL)(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "Lightness";
        }
    }))
};
var genericB = {
    to: function (x1) {
        return x1;
    },
    from: function (x1) {
        return x1;
    }
};
var showB = {
    show: /* #__PURE__ */ Data_Show_Generic.genericShow(genericB)(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "B";
        }
    }))
};
var genericA = {
    to: function (x1) {
        return x1;
    },
    from: function (x1) {
        return x1;
    }
};
var showA = {
    show: /* #__PURE__ */ Data_Show_Generic.genericShow(genericA)(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "A";
        }
    }))
};
var showLab = {
    show: /* #__PURE__ */ Data_Show_Generic.genericShow(genericLab)(/* #__PURE__ */ Data_Show_Generic.genericShowConstructor(/* #__PURE__ */ Data_Show_Generic.genericShowArgsProduct(/* #__PURE__ */ Data_Show_Generic.genericShowArgsArgument(showL))(/* #__PURE__ */ Data_Show_Generic.genericShowArgsProduct(/* #__PURE__ */ Data_Show_Generic.genericShowArgsArgument(showA))(/* #__PURE__ */ Data_Show_Generic.genericShowArgsArgument(showB))))({
        reflectSymbol: function () {
            return "Lab";
        }
    }))
};
var eqL = Data_Eq.eqNumber;
var css = function ($112) {
    return (function (v) {
        return Color["rgb$prime"](v.value0)(v.value1)(v.value2);
    })(toRgb($112));
};
var chroma = /* #__PURE__ */ (function () {
    var $113 = Data_Coord_Polar.radius(Data_Coord_Polar.polarPos);
    return function ($114) {
        return $113(polar($114));
    };
})();
var shiftChroma = function (dc) {
    return function (l) {
        return setChroma(chroma(l) + dc)(l);
    };
};
export {
    Lab,
    Lightness,
    A,
    B,
    css,
    lch,
    lightness,
    chroma,
    hue,
    modifyLightness,
    setLightness,
    lighten,
    modifyChroma,
    setChroma,
    shiftChroma,
    modifyHue,
    setHue,
    shiftHue,
    toRgb,
    toCieXyz,
    newtypeL,
    genericL,
    eqL,
    ordL,
    showL,
    genericA,
    showA,
    genericB,
    showB,
    genericLab,
    showLab
};
