// Generated by purs version 0.15.4
import * as Data_Generic_Rep from "../Data.Generic.Rep/index.js";
import * as Data_Show_Generic from "../Data.Show.Generic/index.js";
var genericShowConstructor = /* #__PURE__ */ Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsNoArguments);
var Home = /* #__PURE__ */ (function () {
    function Home() {

    };
    Home.value = new Home();
    return Home;
})();
var Book = /* #__PURE__ */ (function () {
    function Book() {

    };
    Book.value = new Book();
    return Book;
})();
var Concepts = /* #__PURE__ */ (function () {
    function Concepts() {

    };
    Concepts.value = new Concepts();
    return Concepts;
})();
var genericSection = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return Home.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return Book.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr) {
            return Concepts.value;
        };
        throw new Error("Failed pattern match at Toad.Navbar.Section (line 8, column 1 - line 8, column 52): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof Home) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof Book) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
        };
        if (x instanceof Concepts) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value));
        };
        throw new Error("Failed pattern match at Toad.Navbar.Section (line 8, column 1 - line 8, column 52): " + [ x.constructor.name ]);
    }
};
var showSection = {
    show: /* #__PURE__ */ Data_Show_Generic.genericShow(genericSection)(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "Home";
        }
    }))(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "Book";
        }
    }))(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "Concepts";
        }
    }))))
};
var eqSection = {
    eq: function (x) {
        return function (y) {
            if (x instanceof Home && y instanceof Home) {
                return true;
            };
            if (x instanceof Book && y instanceof Book) {
                return true;
            };
            if (x instanceof Concepts && y instanceof Concepts) {
                return true;
            };
            return false;
        };
    }
};
export {
    Home,
    Book,
    Concepts,
    eqSection,
    genericSection,
    showSection
};
