// Generated by purs version 0.15.4
import * as CSS_Stylesheet from "../CSS.Stylesheet/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Halogen_HTML from "../Halogen.HTML/index.js";
import * as Halogen_HTML_Elements from "../Halogen.HTML.Elements/index.js";
import * as Toad_Atom_AppTitle_Style from "../Toad.Atom.AppTitle.Style/index.js";
import * as Toad_Css from "../Toad.Css/index.js";
var fold = /* #__PURE__ */ Data_Foldable.fold(Data_Foldable.foldableArray)(Data_Monoid.monoidString);
var show = /* #__PURE__ */ Data_Show.show(Data_Show.showInt);
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit)(CSS_Stylesheet.bindStyleM);
var append = /* #__PURE__ */ Data_Semigroup.append(Data_Semigroup.semigroupArray);
var map = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var AppTitle = function (x) {
    return x;
};
var showAppTitle = {
    show: function (v) {
        return fold([ "(AppTitle { h1: <", show(Data_Array.length(v.h1)), " elements>, accessory: ", show(Data_Array.length(v.accessory)), " elements>, hash: ", show(v.hash), "})" ]);
    }
};
var render = function (css) {
    return function (v) {
        return Halogen_HTML_Elements.div([ Toad_Css.style(discard(Toad_Atom_AppTitle_Style.container)(function () {
            return css;
        })) ])(append([ Halogen_HTML_Elements.h1([ Toad_Atom_AppTitle_Style.h1Class ])(map(Halogen_HTML.fromPlainHTML)(v.h1)) ])(map(Halogen_HTML.fromPlainHTML)(v.accessory)));
    };
};
var ntAppTitle = {
    Coercible0: function () {
        return undefined;
    }
};
var eqAppTitle = {
    eq: function (v) {
        return function (v1) {
            return v.hash === v1.hash;
        };
    }
};
var hashAppTitle = {
    hash: function (v) {
        return v.hash;
    },
    Eq0: function () {
        return eqAppTitle;
    }
};
export {
    AppTitle,
    render,
    ntAppTitle,
    showAppTitle,
    eqAppTitle,
    hashAppTitle
};
