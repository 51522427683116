// Generated by purs version 0.15.4
import * as CSS_String from "../CSS.String/index.js";
import * as Color from "../Color/index.js";
import * as Control_Alternative from "../Control.Alternative/index.js";
import * as Control_Category from "../Control.Category/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_NonEmpty from "../Data.NonEmpty/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Ordering from "../Data.Ordering/index.js";
import * as Data_Profunctor_Strong from "../Data.Profunctor.Strong/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
var map = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var second = /* #__PURE__ */ Data_Profunctor_Strong.second(Data_Profunctor_Strong.strongFn);
var append1 = /* #__PURE__ */ Data_Semigroup.append(Data_Semigroup.semigroupArray);
var lookup = /* #__PURE__ */ Data_Foldable.lookup(Data_Foldable.foldableArray)(Data_Eq.eqString);
var oneOf = /* #__PURE__ */ Data_NonEmpty.oneOf(Control_Alternative.alternativeArray);
var eq = /* #__PURE__ */ Data_Eq.eq(/* #__PURE__ */ Data_Eq.eqArray(/* #__PURE__ */ Data_Tuple.eqTuple(Data_Eq.eqString)(Data_Eq.eqString)));
var compare = /* #__PURE__ */ Data_Ord.compare(/* #__PURE__ */ Data_Ord.ordArray(/* #__PURE__ */ Data_Tuple.ordTuple(Data_Ord.ordString)(Data_Ord.ordString)));
var compare1 = /* #__PURE__ */ Data_Ord.compare(Data_Ord.ordString);
var Prefixed = /* #__PURE__ */ (function () {
    function Prefixed(value0) {
        this.value0 = value0;
    };
    Prefixed.create = function (value0) {
        return new Prefixed(value0);
    };
    return Prefixed;
})();
var Plain = /* #__PURE__ */ (function () {
    function Plain(value0) {
        this.value0 = value0;
    };
    Plain.create = function (value0) {
        return new Plain(value0);
    };
    return Plain;
})();
var Value = function (x) {
    return x;
};
var Literal = function (x) {
    return x;
};
var Key = function (x) {
    return x;
};
var value = function (dict) {
    return dict.value;
};
var valValue = {
    value: /* #__PURE__ */ Control_Category.identity(Control_Category.categoryFn)
};
var semigroupPrefixed = {
    append: function (v) {
        return function (v1) {
            if (v instanceof Plain && v1 instanceof Plain) {
                return new Plain(v.value0 + v1.value0);
            };
            if (v instanceof Plain && v1 instanceof Prefixed) {
                return new Prefixed(map(second(function (v2) {
                    return v.value0 + v2;
                }))(v1.value0));
            };
            if (v instanceof Prefixed && v1 instanceof Plain) {
                return new Prefixed(map(second(function (v2) {
                    return v1.value0 + v2;
                }))(v.value0));
            };
            if (v instanceof Prefixed && v1 instanceof Prefixed) {
                return new Prefixed(append1(v.value0)(v1.value0));
            };
            throw new Error("Failed pattern match at CSS.Property (line 23, column 1 - line 27, column 59): " + [ v.constructor.name, v1.constructor.name ]);
        };
    }
};
var append2 = /* #__PURE__ */ Data_Semigroup.append(semigroupPrefixed);
var semigroupValue = {
    append: function (v) {
        return function (v1) {
            return append2(v)(v1);
        };
    }
};
var append3 = /* #__PURE__ */ Data_Semigroup.append(semigroupValue);
var quote = function (s) {
    return "\"" + (s + "\"");
};
var plain = function (v) {
    if (v instanceof Prefixed) {
        return Data_Maybe.fromMaybe("")(lookup("")(v.value0));
    };
    if (v instanceof Plain) {
        return v.value0;
    };
    throw new Error("Failed pattern match at CSS.Property (line 32, column 1 - line 32, column 28): " + [ v.constructor.name ]);
};
var monoidPrefixed = /* #__PURE__ */ (function () {
    return {
        mempty: new Plain(Data_Monoid.mempty(Data_Monoid.monoidString)),
        Semigroup0: function () {
            return semigroupPrefixed;
        }
    };
})();
var monoidValue = {
    mempty: /* #__PURE__ */ Data_Monoid.mempty(monoidPrefixed),
    Semigroup0: function () {
        return semigroupValue;
    }
};
var intercalate = /* #__PURE__ */ Data_Foldable.intercalate(Data_Foldable.foldableArray)(monoidValue);
var isStringPrefixed = /* #__PURE__ */ (function () {
    return {
        fromString: Plain.create
    };
})();
var fromString = /* #__PURE__ */ CSS_String.fromString(isStringPrefixed);
var isStringValue = {
    fromString: function ($141) {
        return Value(fromString($141));
    }
};
var fromString1 = /* #__PURE__ */ CSS_String.fromString(isStringValue);
var noCommas = function (dictVal) {
    var value1 = value(dictVal);
    var $142 = intercalate(fromString1(" "));
    return function ($143) {
        return $142((function (v) {
            return map(value1)(v);
        })($143));
    };
};
var valColor = {
    value: function ($144) {
        return fromString1(Color.cssStringHSLA($144));
    }
};
var valList = function (dictVal) {
    var value1 = value(dictVal);
    return {
        value: (function () {
            var $145 = intercalate(fromString1(", "));
            return function ($146) {
                return $145((function (v) {
                    return map(value1)(v);
                })($146));
            };
        })()
    };
};
var valNonEmpty = function (dictVal) {
    return {
        value: (function () {
            var $147 = value(valList(dictVal));
            return function ($148) {
                return $147(oneOf($148));
            };
        })()
    };
};
var valLiteral = {
    value: function (v) {
        return fromString1(quote(v));
    }
};
var valNumber = {
    value: /* #__PURE__ */ (function () {
        var $149 = Data_Show.show(Data_Show.showNumber);
        return function ($150) {
            return fromString1($149($150));
        };
    })()
};
var valString = {
    value: fromString1
};
var valTuple = function (dictVal) {
    var value1 = value(dictVal);
    return function (dictVal1) {
        var value2 = value(dictVal1);
        return {
            value: function (v) {
                return append3(value1(v.value0))(append3(fromString1(" "))(value2(v.value1)));
            }
        };
    };
};
var valUnit = {
    value: function (v) {
        return fromString1("");
    }
};
var isStringKey = {
    fromString: function ($151) {
        return Key(fromString($151));
    }
};
var eqPrefixed = {
    eq: function (x) {
        return function (y) {
            if (x instanceof Prefixed && y instanceof Prefixed) {
                return eq(x.value0)(y.value0);
            };
            if (x instanceof Plain && y instanceof Plain) {
                return x.value0 === y.value0;
            };
            return false;
        };
    }
};
var eq2 = /* #__PURE__ */ Data_Eq.eq(eqPrefixed);
var eqValue = {
    eq: function (x) {
        return function (y) {
            return eq2(x)(y);
        };
    }
};
var ordPrefixed = {
    compare: function (x) {
        return function (y) {
            if (x instanceof Prefixed && y instanceof Prefixed) {
                return compare(x.value0)(y.value0);
            };
            if (x instanceof Prefixed) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Prefixed) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Plain && y instanceof Plain) {
                return compare1(x.value0)(y.value0);
            };
            throw new Error("Failed pattern match at CSS.Property (line 0, column 0 - line 0, column 0): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqPrefixed;
    }
};
var compare2 = /* #__PURE__ */ Data_Ord.compare(ordPrefixed);
var ordValue = {
    compare: function (x) {
        return function (y) {
            return compare2(x)(y);
        };
    },
    Eq0: function () {
        return eqValue;
    }
};
var eqLiteral = {
    eq: function (x) {
        return function (y) {
            return x === y;
        };
    }
};
var ordLiteral = {
    compare: function (x) {
        return function (y) {
            return compare1(x)(y);
        };
    },
    Eq0: function () {
        return eqLiteral;
    }
};
var eqKey = function (dictEq) {
    return {
        eq: function (x) {
            return function (y) {
                return eq2(x)(y);
            };
        }
    };
};
var ordKey = function (dictOrd) {
    var eqKey1 = eqKey(dictOrd.Eq0());
    return {
        compare: function (x) {
            return function (y) {
                return compare2(x)(y);
            };
        },
        Eq0: function () {
            return eqKey1;
        }
    };
};
var cast = function (v) {
    return v;
};
export {
    value,
    Prefixed,
    Plain,
    plain,
    quote,
    Key,
    cast,
    Value,
    Literal,
    noCommas,
    eqPrefixed,
    ordPrefixed,
    isStringPrefixed,
    semigroupPrefixed,
    monoidPrefixed,
    eqKey,
    ordKey,
    isStringKey,
    eqValue,
    ordValue,
    isStringValue,
    semigroupValue,
    monoidValue,
    eqLiteral,
    ordLiteral,
    valLiteral,
    valValue,
    valString,
    valUnit,
    valTuple,
    valNumber,
    valList,
    valNonEmpty,
    valColor
};
