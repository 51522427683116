// Generated by purs version 0.15.4
import * as $foreign from "./foreign.js";
import * as CSS_Common from "../CSS.Common/index.js";
import * as CSS_Display from "../CSS.Display/index.js";
import * as CSS_Flexbox from "../CSS.Flexbox/index.js";
import * as CSS_Stylesheet from "../CSS.Stylesheet/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
var center = /* #__PURE__ */ CSS_Common.center(CSS_Flexbox.centerJustifyContentValue);
var logoContainer = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit)(CSS_Stylesheet.bindStyleM)(/* #__PURE__ */ CSS_Display.display(CSS_Display.flex))(function () {
    return CSS_Flexbox.justifyContent(center);
});
export {
    toadLogoUrl
} from "./foreign.js";
export {
    logoContainer
};
