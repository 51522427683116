// Generated by purs version 0.15.4
import * as Data_Boolean from "../Data.Boolean/index.js";
import * as Data_Color_OkLab from "../Data.Color.OkLab/index.js";
import * as Data_Coord_Polar from "../Data.Coord.Polar/index.js";
import * as Data_Newtype from "../Data.Newtype/index.js";
import * as Data_Number from "../Data.Number/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Range from "../Data.Range/index.js";
var lch = /* #__PURE__ */ Data_Color_OkLab.lch(Data_Coord_Polar.angleDegrees);
var unwrap = /* #__PURE__ */ Data_Newtype.unwrap();
var contains = /* #__PURE__ */ Data_Range.contains(/* #__PURE__ */ Data_Range.rangeBound(/* #__PURE__ */ Data_Range.lowerInclBound(Data_Ord.ordNumber))(/* #__PURE__ */ Data_Range.upperExclBound(Data_Ord.ordNumber)));
var betweenExclusive = /* #__PURE__ */ Data_Range.betweenExclusive(Data_Ord.ordNumber);
var grey = function (l) {
    return lch(unwrap(l))(0.0)(0.0);
};
var green = function (l) {
    var c = function (l$prime) {
        if (contains(betweenExclusive(0.0)(0.87))(l$prime)) {
            return 0.2 * Data_Number.pow(l$prime)(2.0);
        };
        if (Data_Boolean.otherwise) {
            return 13.0 * Data_Number.pow(0.98 - l$prime)(2.0);
        };
        throw new Error("Failed pattern match at Toad.Css.Color (line 29, column 5 - line 31, column 49): " + [ l$prime.constructor.name ]);
    };
    return lch(unwrap(l))(c(unwrap(l)))(142.0);
};
var colorPrimary5 = function (f) {
    return f(0.92);
};
var colorPrimary4 = function (f) {
    return f(0.9);
};
var colorPrimary3_5 = function (f) {
    return f(0.8);
};
var colorPrimary3 = function (f) {
    return f(0.75);
};
var colorPrimary2 = function (f) {
    return f(0.55);
};
var colorPrimary = function (f) {
    return f(0.45);
};
var colorFg = function (f) {
    return f(0.99);
};
var colorBg2 = function (f) {
    return f(0.2);
};
var colorBg = function (f) {
    return f(0.0);
};
export {
    green,
    grey,
    colorBg,
    colorBg2,
    colorFg,
    colorPrimary,
    colorPrimary2,
    colorPrimary3,
    colorPrimary3_5,
    colorPrimary4,
    colorPrimary5
};
