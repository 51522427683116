// Generated by purs version 0.15.4
import * as CSS_Render from "../CSS.Render/index.js";
import * as CSS_Stylesheet from "../CSS.Stylesheet/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_String_Common from "../Data.String.Common/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
import * as Foreign_Object from "../Foreign.Object/index.js";
import * as Halogen_HTML_Core from "../Halogen.HTML.Core/index.js";
import * as Halogen_HTML_Elements from "../Halogen.HTML.Elements/index.js";
import * as Halogen_HTML_Properties from "../Halogen.HTML.Properties/index.js";
var type_ = /* #__PURE__ */ Halogen_HTML_Properties.type_(Halogen_HTML_Core.isPropMediaType);
var bind = /* #__PURE__ */ Control_Bind.bind(Control_Bind.bindArray);
var fromFoldable = /* #__PURE__ */ Foreign_Object.fromFoldable(Data_Foldable.foldableArray);
var stylesheet = function (css) {
    var content = Data_Maybe.fromMaybe("")(CSS_Render.renderedSheet(CSS_Render.render(css)));
    return Halogen_HTML_Elements.style([ type_("text/css") ])([ Halogen_HTML_Core.text(content) ]);
};
var style = /* #__PURE__ */ (function () {
    var toString = (function () {
        var $13 = Data_String_Common.joinWith("; ");
        var $14 = Foreign_Object.foldMap(Data_Monoid.monoidArray)(function (key) {
            return function (val) {
                return [ key + (": " + val) ];
            };
        });
        return function ($15) {
            return $13($14($15));
        };
    })();
    var rights = Data_Array.concatMap(Data_Foldable.foldMap(Data_Foldable.foldableEither)(Data_Monoid.monoidArray)(Data_Array.singleton));
    var property = function (v) {
        if (v instanceof CSS_Stylesheet.Property) {
            return new Data_Maybe.Just(new Data_Tuple.Tuple(v.value0, v.value1));
        };
        return Data_Maybe.Nothing.value;
    };
    var rules = function (rs) {
        var properties = bind(Data_Array.mapMaybe(property)(rs))(function ($16) {
            return rights(CSS_Render.collect($16));
        });
        return fromFoldable(properties);
    };
    var $17 = Halogen_HTML_Properties.attr("style");
    return function ($18) {
        return $17(toString(rules(CSS_Stylesheet.runS($18))));
    };
})();
export {
    style,
    stylesheet
};
