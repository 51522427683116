// Generated by purs version 0.15.4
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Apply from "../Control.Apply/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_String_CodeUnits from "../Data.String.CodeUnits/index.js";
import * as Effect from "../Effect/index.js";
import * as Effect_Ref from "../Effect.Ref/index.js";
import * as Routing from "../Routing/index.js";
import * as Web_Event_EventTarget from "../Web.Event.EventTarget/index.js";
import * as Web_HTML from "../Web.HTML/index.js";
import * as Web_HTML_Event_HashChangeEvent_EventTypes from "../Web.HTML.Event.HashChangeEvent.EventTypes/index.js";
import * as Web_HTML_Location from "../Web.HTML.Location/index.js";
import * as Web_HTML_Window from "../Web.HTML.Window/index.js";
var bind = /* #__PURE__ */ Control_Bind.bind(Effect.bindEffect);
var map = /* #__PURE__ */ Data_Functor.map(Effect.functorEffect);
var bindFlipped = /* #__PURE__ */ Control_Bind.bindFlipped(Effect.bindEffect);
var join = /* #__PURE__ */ Control_Bind.join(Effect.bindEffect);
var apply = /* #__PURE__ */ Control_Apply.apply(Effect.applyEffect);
var pure = /* #__PURE__ */ Control_Applicative.pure(Effect.applicativeEffect);
var voidRight = /* #__PURE__ */ Data_Functor.voidRight(Effect.functorEffect);
var setHash = function (h) {
    return bind(bind(Web_HTML.window)(Web_HTML_Window.location))(Web_HTML_Location.setHash(h));
};
var getHash = /* #__PURE__ */ bind(/* #__PURE__ */ bind(Web_HTML.window)(Web_HTML_Window.location))(/* #__PURE__ */ (function () {
    var $16 = map((function () {
        var $18 = Data_Maybe.fromMaybe("");
        var $19 = Data_String_CodeUnits.stripPrefix("#");
        return function ($20) {
            return $18($19($20));
        };
    })());
    return function ($17) {
        return $16(Web_HTML_Location.hash($17));
    };
})());
var modifyHash = function (fn) {
    return bind(map(fn)(getHash))(setHash);
};
var foldHashes = function (cb) {
    return function (init) {
        return function __do() {
            var ref = bindFlipped(Effect_Ref["new"])(bindFlipped(init)(getHash))();
            var win = map(Web_HTML_Window.toEventTarget)(Web_HTML.window)();
            var listener = Web_Event_EventTarget.eventListener(function (v) {
                return bindFlipped(Data_Function.flip(Effect_Ref.write)(ref))(join(apply(map(cb)(Effect_Ref.read(ref)))(getHash)));
            })();
            Web_Event_EventTarget.addEventListener(Web_HTML_Event_HashChangeEvent_EventTypes.hashchange)(listener)(false)(win)();
            return Web_Event_EventTarget.removeEventListener(Web_HTML_Event_HashChangeEvent_EventTypes.hashchange)(listener)(false)(win);
        };
    };
};
var matchesWith = function (dictFoldable) {
    var indexl = Data_Foldable.indexl(dictFoldable);
    return function (parser) {
        return function (cb) {
            var go = function (a) {
                var $21 = Data_Maybe.maybe(pure(a))(function (b) {
                    return voidRight(new Data_Maybe.Just(b))(cb(a)(b));
                });
                var $22 = indexl(0);
                return function ($23) {
                    return $21($22(parser($23)));
                };
            };
            return foldHashes(go)(go(Data_Maybe.Nothing.value));
        };
    };
};
var hashes = /* #__PURE__ */ (function () {
    return matchesWith(Data_Foldable.foldableMaybe)(Data_Maybe.Just.create);
})();
var matches = /* #__PURE__ */ (function () {
    var $24 = matchesWith(Data_Foldable.foldableEither);
    return function ($25) {
        return $24(Routing.match($25));
    };
})();
export {
    getHash,
    setHash,
    modifyHash,
    foldHashes,
    hashes,
    matches,
    matchesWith
};
export {
    match,
    matchWith
} from "../Routing/index.js";
