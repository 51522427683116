// Generated by purs version 0.15.4
import * as Control_Alt from "../Control.Alt/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Expanded from "../Data.Expanded/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Hashable from "../Data.Hashable/index.js";
import * as Data_List_Types from "../Data.List.Types/index.js";
import * as Data_Map from "../Data.Map/index.js";
import * as Data_Map_Internal from "../Data.Map.Internal/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Newtype from "../Data.Newtype/index.js";
import * as Data_Set from "../Data.Set/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_Show_Generic from "../Data.Show.Generic/index.js";
import * as Toad_Atom_AppTitle from "../Toad.Atom.AppTitle/index.js";
import * as Toad_Concept from "../Toad.Concept/index.js";
import * as Toad_Error from "../Toad.Error/index.js";
import * as Toad_Markdown from "../Toad.Markdown/index.js";
import * as Toad_Prelude from "../Toad.Prelude/index.js";
import * as Toad_Route from "../Toad.Route/index.js";
var alt = /* #__PURE__ */ Control_Alt.alt(Data_Maybe.altMaybe);
var alt1 = /* #__PURE__ */ Control_Alt.alt(/* #__PURE__ */ Data_Map.altSemigroupMap(Toad_Concept.ordIdent));
var showRecord = /* #__PURE__ */ Data_Show.showRecord()();
var map = /* #__PURE__ */ Data_Functor.map(Data_Maybe.functorMaybe);
var eq = /* #__PURE__ */ Data_Eq.eq(Data_Expanded.eqExpanded);
var eq1 = /* #__PURE__ */ Data_Eq.eq(/* #__PURE__ */ Data_Maybe.eqMaybe(Toad_Atom_AppTitle.eqAppTitle));
var eq2 = /* #__PURE__ */ Data_Eq.eq(/* #__PURE__ */ Data_Maybe.eqMaybe(Toad_Error.eqErrorMessage));
var eq3 = /* #__PURE__ */ Data_Eq.eq(/* #__PURE__ */ Data_Maybe.eqMaybe(Toad_Concept.eqManifest));
var eq4 = /* #__PURE__ */ Data_Eq.eq(/* #__PURE__ */ Data_Map.eqSemigroupMap(Toad_Concept.eqIdent)(Toad_Markdown.documentEq));
var eq5 = /* #__PURE__ */ Data_Eq.eq(/* #__PURE__ */ Data_Maybe.eqMaybe(Toad_Route.eqRoute));
var wrap = /* #__PURE__ */ Data_Newtype.wrap();
var sortWith = /* #__PURE__ */ Data_Array.sortWith(Toad_Concept.ordTitle);
var bind = /* #__PURE__ */ Control_Bind.bind(Data_Maybe.bindMaybe);
var find = /* #__PURE__ */ Data_Foldable.find(Data_Foldable.foldableArray);
var eq6 = /* #__PURE__ */ Data_Eq.eq(Toad_Concept.eqIdent);
var NavAccordions = function (x) {
    return x;
};
var State = /* #__PURE__ */ (function () {
    function State(value0) {
        this.value0 = value0;
    };
    State.create = function (value0) {
        return new State(value0);
    };
    return State;
})();
var state = /* #__PURE__ */ (function () {
    return State.create;
})();
var semiState = {
    append: function (v) {
        return function (v1) {
            var route_ = alt(v.value0.route)(v1.value0.route);
            var parsedConcepts_ = alt1(v.value0.parsedConcepts)(v1.value0.parsedConcepts);
            var navAccordions_ = alt(v.value0.navAccordions)(v1.value0.navAccordions);
            var manifest_ = alt(v.value0.manifest)(v1.value0.manifest);
            var error_ = alt(v.value0.error)(v1.value0.error);
            var appTitle_ = alt(v.value0.appTitle)(v1.value0.appTitle);
            return new State({
                error: error_,
                manifest: manifest_,
                route: route_,
                parsedConcepts: parsedConcepts_,
                navAccordions: navAccordions_,
                appTitle: appTitle_
            });
        };
    }
};
var record = function (v) {
    return v.value0;
};
var route = /* #__PURE__ */ (function () {
    var $167 = Data_Maybe.fromMaybe(Toad_Route.init);
    return function ($168) {
        return $167((function (v) {
            return v.route;
        })(record($168)));
    };
})();
var routeHash = /* #__PURE__ */ (function () {
    var $169 = Data_Hashable.hash(Toad_Route.hashRoute);
    return function ($170) {
        return $169(route($170));
    };
})();
var navAccordionsToggleConcepts = function (v) {
    return {
        book: v.book,
        concepts: Data_Expanded.toggle(v.concepts)
    };
};
var navAccordionsToggleBook = function (v) {
    return {
        book: Data_Expanded.toggle(v.book),
        concepts: v.concepts
    };
};
var navAccordionsInit = function (v) {
    if (v instanceof Data_Maybe.Just && v.value0 instanceof Toad_Route.Book) {
        return {
            book: Data_Expanded.Expanded.value,
            concepts: Data_Expanded.Collapsed.value
        };
    };
    if (v instanceof Data_Maybe.Just && v.value0 instanceof Toad_Route.Concepts) {
        return {
            book: Data_Expanded.Collapsed.value,
            concepts: Data_Expanded.Expanded.value
        };
    };
    return {
        book: Data_Expanded.Collapsed.value,
        concepts: Data_Expanded.Collapsed.value
    };
};
var navAccordionsConceptsExpanded = function (v) {
    return v.concepts;
};
var navAccordionsBookExpanded = function (v) {
    return v.book;
};
var navAccordions = /* #__PURE__ */ (function () {
    var $171 = Data_Maybe.maybe(navAccordionsInit(Data_Maybe.Nothing.value))(Toad_Prelude.id);
    return function ($172) {
        return $171((function (v) {
            return v.navAccordions;
        })(record($172)));
    };
})();
var monoidState = /* #__PURE__ */ (function () {
    return {
        mempty: new State({
            error: Data_Maybe.Nothing.value,
            manifest: Data_Maybe.Nothing.value,
            route: Data_Maybe.Nothing.value,
            parsedConcepts: Data_Map_Internal.empty,
            navAccordions: Data_Maybe.Nothing.value,
            appTitle: Data_Maybe.Nothing.value
        }),
        Semigroup0: function () {
            return semiState;
        }
    };
})();
var liftState = function (dict) {
    return dict.liftState;
};
var init = /* #__PURE__ */ Data_Monoid.mempty(monoidState);
var initWith = function (f) {
    return state(f(record(init)));
};
var navAccordionsAppState = {
    liftState: function (n) {
        return initWith(function (v) {
            return {
                error: v.error,
                manifest: v.manifest,
                route: v.route,
                parsedConcepts: v.parsedConcepts,
                navAccordions: new Data_Maybe.Just(n),
                appTitle: v.appTitle
            };
        });
    }
};
var routeAppState = {
    liftState: function (r) {
        return initWith(function (v) {
            return {
                error: v.error,
                manifest: v.manifest,
                route: new Data_Maybe.Just(r),
                parsedConcepts: v.parsedConcepts,
                navAccordions: new Data_Maybe.Just(navAccordionsInit(new Data_Maybe.Just(r))),
                appTitle: v.appTitle
            };
        });
    }
};
var genericState = {
    to: function (x) {
        return new State(x);
    },
    from: function (x) {
        return x.value0;
    }
};
var genericNavAccordions = {
    to: function (x) {
        return x;
    },
    from: function (x) {
        return x;
    }
};
var showNavAccordions = {
    show: /* #__PURE__ */ Data_Show_Generic.genericShow(genericNavAccordions)(/* #__PURE__ */ Data_Show_Generic.genericShowConstructor(/* #__PURE__ */ Data_Show_Generic.genericShowArgsArgument(/* #__PURE__ */ showRecord(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
        reflectSymbol: function () {
            return "book";
        }
    })(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
        reflectSymbol: function () {
            return "concepts";
        }
    })(Data_Show.showRecordFieldsNil)(Data_Expanded.showExpanded))(Data_Expanded.showExpanded))))({
        reflectSymbol: function () {
            return "NavAccordions";
        }
    }))
};
var showState = {
    show: /* #__PURE__ */ Data_Show_Generic.genericShow(genericState)(/* #__PURE__ */ Data_Show_Generic.genericShowConstructor(/* #__PURE__ */ Data_Show_Generic.genericShowArgsArgument(/* #__PURE__ */ showRecord(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
        reflectSymbol: function () {
            return "appTitle";
        }
    })(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
        reflectSymbol: function () {
            return "error";
        }
    })(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
        reflectSymbol: function () {
            return "manifest";
        }
    })(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
        reflectSymbol: function () {
            return "navAccordions";
        }
    })(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
        reflectSymbol: function () {
            return "parsedConcepts";
        }
    })(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
        reflectSymbol: function () {
            return "route";
        }
    })(Data_Show.showRecordFieldsNil)(/* #__PURE__ */ Data_Maybe.showMaybe(Toad_Route.showRoute)))(/* #__PURE__ */ Data_Map.showSemigroupMap(Toad_Concept.showIdent)(Toad_Markdown.showDocument)))(/* #__PURE__ */ Data_Maybe.showMaybe(showNavAccordions)))(/* #__PURE__ */ Data_Maybe.showMaybe(Toad_Concept.showManifest)))(/* #__PURE__ */ Data_Maybe.showMaybe(Toad_Error.showErrorMessage)))(/* #__PURE__ */ Data_Maybe.showMaybe(Toad_Atom_AppTitle.showAppTitle)))))({
        reflectSymbol: function () {
            return "State";
        }
    }))
};
var errorMessageAppState = {
    liftState: function (e) {
        return initWith(function (v) {
            return {
                error: new Data_Maybe.Just(e),
                manifest: v.manifest,
                route: v.route,
                parsedConcepts: v.parsedConcepts,
                navAccordions: v.navAccordions,
                appTitle: v.appTitle
            };
        });
    }
};
var error = /* #__PURE__ */ (function () {
    var $173 = map(Toad_Error.errorMessageString);
    return function ($174) {
        return $173((function (v) {
            return v.error;
        })(record($174)));
    };
})();
var eqNavAccordions = {
    eq: function (x) {
        return function (y) {
            return eq(x.book)(y.book) && eq(x.concepts)(y.concepts);
        };
    }
};
var eq7 = /* #__PURE__ */ Data_Eq.eq(/* #__PURE__ */ Data_Maybe.eqMaybe(eqNavAccordions));
var eqState = {
    eq: function (x) {
        return function (y) {
            return eq1(x.value0.appTitle)(y.value0.appTitle) && eq2(x.value0.error)(y.value0.error) && eq3(x.value0.manifest)(y.value0.manifest) && eq7(x.value0.navAccordions)(y.value0.navAccordions) && eq4(x.value0.parsedConcepts)(y.value0.parsedConcepts) && eq5(x.value0.route)(y.value0.route);
        };
    }
};
var dismissError = function ($175) {
    return state((function (v) {
        return {
            error: Data_Maybe.Nothing.value,
            appTitle: v.appTitle,
            manifest: v.manifest,
            navAccordions: v.navAccordions,
            parsedConcepts: v.parsedConcepts,
            route: v.route
        };
    })(record($175)));
};
var conceptsAppState = {
    liftState: function (ds) {
        return initWith(function (v) {
            return {
                error: v.error,
                manifest: v.manifest,
                route: v.route,
                parsedConcepts: wrap(ds),
                navAccordions: v.navAccordions,
                appTitle: v.appTitle
            };
        });
    }
};
var concepts = /* #__PURE__ */ (function () {
    var $176 = Data_Newtype.unwrap();
    return function ($177) {
        return $176((function (v) {
            return v.parsedConcepts;
        })(record($177)));
    };
})();
var conceptsHash = /* #__PURE__ */ (function () {
    var $178 = Data_Hashable.hash(Data_Hashable.hashableList(Toad_Concept.hashIdent));
    var $179 = Data_Set.toUnfoldable(Data_List_Types.unfoldableList);
    return function ($180) {
        return $178($179(Data_Map.keys(concepts($180))));
    };
})();
var conceptManifestAppState = {
    liftState: function (v) {
        return initWith(function (v1) {
            return {
                error: v1.error,
                manifest: Data_Maybe.Just.create(Toad_Concept.Manifest(sortWith(Toad_Concept.title)(v))),
                route: v1.route,
                parsedConcepts: v1.parsedConcepts,
                navAccordions: v1.navAccordions,
                appTitle: v1.appTitle
            };
        });
    }
};
var conceptManifest = function ($181) {
    return (function (v) {
        return v.manifest;
    })(record($181));
};
var conceptManifestHash = /* #__PURE__ */ (function () {
    var $182 = Data_Hashable.hash(Data_Hashable.hashableArray(Toad_Concept.hashIdent));
    var $183 = Data_Functor.map(Data_Functor.functorArray)(Toad_Concept.ident);
    var $184 = Data_Maybe.maybe([  ])(Toad_Concept.decls);
    return function ($185) {
        return $182($183($184(conceptManifest($185))));
    };
})();
var lookupDecl = function (ident) {
    return function (s) {
        return bind(map(Toad_Concept.decls)(conceptManifest(s)))(function (ds) {
            return find((function () {
                var $186 = eq6(ident);
                return function ($187) {
                    return $186(Toad_Concept.ident($187));
                };
            })())(ds);
        });
    };
};
var appTitleLiftState = {
    liftState: function (at) {
        return initWith(function (v) {
            return {
                error: v.error,
                manifest: v.manifest,
                route: v.route,
                parsedConcepts: v.parsedConcepts,
                navAccordions: v.navAccordions,
                appTitle: new Data_Maybe.Just(at)
            };
        });
    }
};
export {
    state,
    record,
    init,
    concepts,
    conceptsHash,
    dismissError,
    liftState,
    lookupDecl,
    error,
    conceptManifest,
    conceptManifestHash,
    route,
    routeHash,
    NavAccordions,
    navAccordions,
    navAccordionsToggleBook,
    navAccordionsToggleConcepts,
    navAccordionsBookExpanded,
    navAccordionsConceptsExpanded,
    genericNavAccordions,
    eqNavAccordions,
    showNavAccordions,
    eqState,
    genericState,
    showState,
    semiState,
    monoidState,
    errorMessageAppState,
    conceptManifestAppState,
    routeAppState,
    conceptsAppState,
    navAccordionsAppState,
    appTitleLiftState
};
