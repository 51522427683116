// Generated by purs version 0.15.4
import * as CSS_Property from "../CSS.Property/index.js";
import * as CSS_Size from "../CSS.Size/index.js";
import * as CSS_String from "../CSS.String/index.js";
import * as CSS_Stylesheet from "../CSS.Stylesheet/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
var fromString = /* #__PURE__ */ CSS_String.fromString(CSS_Property.isStringValue);
var key = /* #__PURE__ */ CSS_Stylesheet.key(CSS_Size.valSize);
var fromString1 = /* #__PURE__ */ CSS_String.fromString(CSS_Property.isStringKey);
var key1 = /* #__PURE__ */ CSS_Stylesheet.key(CSS_Property.valColor);
var valTuple = /* #__PURE__ */ CSS_Property.valTuple(CSS_Size.valSize);
var eq = /* #__PURE__ */ Data_Eq.eq(CSS_Property.eqValue);
var compare = /* #__PURE__ */ Data_Ord.compare(CSS_Property.ordValue);
var valTuple1 = /* #__PURE__ */ valTuple(CSS_Size.valSize);
var key2 = /* #__PURE__ */ CSS_Stylesheet.key(/* #__PURE__ */ CSS_Property.valTuple(valTuple1)(valTuple1));
var Stroke = function (x) {
    return x;
};
var wavy = /* #__PURE__ */ fromString("wavy");
var valStroke = {
    value: function (v) {
        return v;
    }
};
var key3 = /* #__PURE__ */ CSS_Stylesheet.key(/* #__PURE__ */ CSS_Property.valTuple(valStroke)(/* #__PURE__ */ valTuple(CSS_Property.valColor)));
var solid = /* #__PURE__ */ fromString("solid");
var ridge = /* #__PURE__ */ fromString("ridge");
var outset = /* #__PURE__ */ fromString("outset");
var outlineWidth = /* #__PURE__ */ key(/* #__PURE__ */ fromString1("outline-width"));
var outlineStyle = /* #__PURE__ */ CSS_Stylesheet.key(valStroke)(/* #__PURE__ */ fromString1("outline-style"));
var outlineOffset = /* #__PURE__ */ key(/* #__PURE__ */ fromString1("outline-offset"));
var outlineColor = /* #__PURE__ */ key1(/* #__PURE__ */ fromString1("outline-color"));
var outline = function (a) {
    return function (b) {
        return function (c) {
            return key3(fromString1("outline"))(new Data_Tuple.Tuple(a, new Data_Tuple.Tuple(b, c)));
        };
    };
};
var isStringStroke = {
    fromString: function ($32) {
        return Stroke(fromString($32));
    }
};
var fromString2 = /* #__PURE__ */ CSS_String.fromString(isStringStroke);
var unsetStroke = {
    unset: /* #__PURE__ */ fromString2("unset")
};
var inset = /* #__PURE__ */ fromString("inset");
var initialStroke = {
    initial: /* #__PURE__ */ fromString2("initial")
};
var inheritStroke = {
    inherit: /* #__PURE__ */ fromString2("inherit")
};
var groove = /* #__PURE__ */ fromString("groove");
var eqStroke = {
    eq: function (x) {
        return function (y) {
            return eq(x)(y);
        };
    }
};
var ordStroke = {
    compare: function (x) {
        return function (y) {
            return compare(x)(y);
        };
    },
    Eq0: function () {
        return eqStroke;
    }
};
var $$double = /* #__PURE__ */ fromString("double");
var dotted = /* #__PURE__ */ fromString("dotted");
var dashed = /* #__PURE__ */ fromString("dashed");
var borderTop = function (a) {
    return function (b) {
        return function (c) {
            return key3(fromString1("border-top"))(new Data_Tuple.Tuple(a, new Data_Tuple.Tuple(b, c)));
        };
    };
};
var borderSpacing = /* #__PURE__ */ key(/* #__PURE__ */ fromString1("border-spacing"));
var borderRight = function (a) {
    return function (b) {
        return function (c) {
            return key3(fromString1("border-right"))(new Data_Tuple.Tuple(a, new Data_Tuple.Tuple(b, c)));
        };
    };
};
var borderRadius = function (a) {
    return function (b) {
        return function (c) {
            return function (d) {
                return key2(fromString1("border-radius"))(new Data_Tuple.Tuple(new Data_Tuple.Tuple(a, b), new Data_Tuple.Tuple(c, d)));
            };
        };
    };
};
var borderLeft = function (a) {
    return function (b) {
        return function (c) {
            return key3(fromString1("border-left"))(new Data_Tuple.Tuple(a, new Data_Tuple.Tuple(b, c)));
        };
    };
};
var borderColor = /* #__PURE__ */ key1(/* #__PURE__ */ fromString1("border-color"));
var borderBottom = function (a) {
    return function (b) {
        return function (c) {
            return key3(fromString1("border-bottom"))(new Data_Tuple.Tuple(a, new Data_Tuple.Tuple(b, c)));
        };
    };
};
var border = function (a) {
    return function (b) {
        return function (c) {
            return key3(fromString1("border"))(new Data_Tuple.Tuple(a, new Data_Tuple.Tuple(b, c)));
        };
    };
};
export {
    Stroke,
    solid,
    dotted,
    dashed,
    $$double as double,
    wavy,
    groove,
    ridge,
    inset,
    outset,
    border,
    borderTop,
    borderLeft,
    borderBottom,
    borderRight,
    borderColor,
    outline,
    outlineColor,
    outlineStyle,
    outlineWidth,
    outlineOffset,
    borderRadius,
    borderSpacing,
    eqStroke,
    ordStroke,
    isStringStroke,
    valStroke,
    inheritStroke,
    initialStroke,
    unsetStroke
};
