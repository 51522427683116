// Generated by purs version 0.15.4
import * as CSS_Background from "../CSS.Background/index.js";
import * as CSS_Common from "../CSS.Common/index.js";
import * as CSS_Display from "../CSS.Display/index.js";
import * as CSS_Flexbox from "../CSS.Flexbox/index.js";
import * as CSS_Font from "../CSS.Font/index.js";
import * as CSS_Geometry from "../CSS.Geometry/index.js";
import * as CSS_Overflow from "../CSS.Overflow/index.js";
import * as CSS_Size from "../CSS.Size/index.js";
import * as CSS_Stylesheet from "../CSS.Stylesheet/index.js";
import * as CSS_TextAlign from "../CSS.TextAlign/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Apply from "../Control.Apply/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_State_Class from "../Control.Monad.State.Class/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Hashable from "../Data.Hashable/index.js";
import * as Data_Map_Internal from "../Data.Map.Internal/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Effect_Aff from "../Effect.Aff/index.js";
import * as Effect_Aff_Class from "../Effect.Aff.Class/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
import * as Effect_Console from "../Effect.Console/index.js";
import * as Halogen_HTML from "../Halogen.HTML/index.js";
import * as Halogen_HTML_Core from "../Halogen.HTML.Core/index.js";
import * as Halogen_HTML_Elements from "../Halogen.HTML.Elements/index.js";
import * as Halogen_Query_HalogenM from "../Halogen.Query.HalogenM/index.js";
import * as Routing_Hash from "../Routing.Hash/index.js";
import * as Toad_Action from "../Toad.Action/index.js";
import * as Toad_Atom_Accordion from "../Toad.Atom.Accordion/index.js";
import * as Toad_Atom_AppTitle from "../Toad.Atom.AppTitle/index.js";
import * as Toad_Atom_Logo from "../Toad.Atom.Logo/index.js";
import * as Toad_Concept from "../Toad.Concept/index.js";
import * as Toad_Css from "../Toad.Css/index.js";
import * as Toad_Css_Color from "../Toad.Css.Color/index.js";
import * as Toad_Css_Font from "../Toad.Css.Font/index.js";
import * as Toad_Css_Grid from "../Toad.Css.Grid/index.js";
import * as Toad_Html from "../Toad.Html/index.js";
import * as Toad_Page_Concepts from "../Toad.Page.Concepts/index.js";
import * as Toad_Prelude from "../Toad.Prelude/index.js";
import * as Toad_Route from "../Toad.Route/index.js";
import * as Toad_State from "../Toad.State/index.js";
import * as Toad_Style from "../Toad.Style/index.js";
import * as Toad_Style_Global from "../Toad.Style.Global/index.js";
import * as Type_Proxy from "../Type.Proxy/index.js";
var bind = /* #__PURE__ */ Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM);
var put1 = /* #__PURE__ */ Control_Monad_State_Class.put(Halogen_Query_HalogenM.monadStateHalogenM);
var modify = /* #__PURE__ */ Control_Monad_State_Class.modify(Halogen_Query_HalogenM.monadStateHalogenM);
var append = /* #__PURE__ */ Data_Semigroup.append(Toad_State.semiState);
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit);
var discard1 = /* #__PURE__ */ discard(Halogen_Query_HalogenM.bindHalogenM);
var pure = /* #__PURE__ */ Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM);
var discard2 = /* #__PURE__ */ discard(CSS_Stylesheet.bindStyleM);
var render1 = /* #__PURE__ */ Toad_Atom_Accordion.render(Data_Eq.eqUnit);
var render2 = /* #__PURE__ */ Toad_Atom_Accordion.render(Toad_Route.eqRoute);
var map = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var map1 = /* #__PURE__ */ Data_Functor.map(Data_Maybe.functorMaybe);
var center = /* #__PURE__ */ CSS_Common.center(CSS_Flexbox.centerAlignItemsValue);
var spaceBetween = /* #__PURE__ */ CSS_Flexbox.spaceBetween(CSS_Flexbox.spaceBetweenJustifyContentValue);
var append1 = /* #__PURE__ */ Data_Semigroup.append(Toad_Css_Font.fontSemigroup);
var slot = /* #__PURE__ */ Halogen_HTML.slot()({
    reflectSymbol: function () {
        return "concepts";
    }
})(Data_Ord.ordInt);
var apply = /* #__PURE__ */ Control_Apply.apply(Control_Apply.applyArray);
var hash = /* #__PURE__ */ Data_Hashable.hash(/* #__PURE__ */ Data_Hashable.hashableMaybe(Toad_Atom_AppTitle.hashAppTitle));
var eq = /* #__PURE__ */ Data_Eq.eq(/* #__PURE__ */ Data_Maybe.eqMaybe(Toad_Atom_AppTitle.eqAppTitle));
var lookup = /* #__PURE__ */ Data_Map_Internal.lookup(Toad_Concept.ordIdent);
var M = function (x) {
    return x;
};
var runM = function (v) {
    return v;
};
var put = function (dictLiftState) {
    var liftState = Toad_State.liftState(dictLiftState);
    return function (dictMonad) {
        var $53 = Data_Function.flip(bind)(put1);
        return function ($54) {
            return $53(modify(append(liftState($54))));
        };
    };
};
var put2 = /* #__PURE__ */ put(Toad_State.errorMessageAppState);
var monadM = Effect_Aff.monadAff;
var monadEffectM = Effect_Aff.monadEffectAff;
var navigateM = {
    navigate: /* #__PURE__ */ (function () {
        var $55 = Effect_Class.liftEffect(monadEffectM);
        return function ($56) {
            return $55(Routing_Hash.setHash(Toad_Route.print($56)));
        };
    })(),
    Monad0: function () {
        return monadM;
    }
};
var monadAffM = Effect_Aff_Class.monadAffAff;
var handleError = function (dictMonad) {
    var put3 = put2(dictMonad);
    return function (dictMonadEffect) {
        var liftEffect = Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadEffect));
        return function (v) {
            if (v instanceof Data_Either.Right) {
                return v.value0;
            };
            if (v instanceof Data_Either.Left) {
                return discard1(liftEffect(Effect_Console.error(v.value0.value0)))(function () {
                    return discard1(put3(v.value0.value1))(function () {
                        return pure(Data_Unit.unit);
                    });
                });
            };
            throw new Error("Failed pattern match at Toad.App (line 69, column 1 - line 74, column 33): " + [ v.constructor.name ]);
        };
    };
};
var functorM = Effect_Aff.functorAff;
var conceptsPageSlot = 0;
var bindM = Effect_Aff.bindAff;
var applyM = Effect_Aff.applyAff;
var applicativeM = Effect_Aff.applicativeAff;
var _concepts = /* #__PURE__ */ (function () {
    return Type_Proxy["Proxy"].value;
})();
var render = function (dictMonadEffect) {
    var concepts = Toad_Page_Concepts.concepts(dictMonadEffect);
    return function (state) {
        return Halogen_HTML_Elements.div_([ Toad_Style_Global.stylesheet, Halogen_HTML_Elements.div([ Toad_Css.style(Toad_Style.appWrap) ])([ Halogen_HTML_Elements.div([ Toad_Css.style(discard2(CSS_Geometry.width(CSS_Size.pct(100.0)))(function () {
            return discard2(CSS_Geometry.height(CSS_Size.pct(100.0)))(function () {
                return discard2(CSS_Display.display(CSS_Display.flex))(function () {
                    return discard2(CSS_Flexbox.flexDirection(CSS_Flexbox.column))(function () {
                        return discard2(CSS_Overflow.overflow(CSS_Overflow.overflowAuto))(function () {
                            return Toad_Style.navbarWrap;
                        });
                    });
                });
            });
        })) ])([ render1(Data_Maybe.Nothing.value)({
            expanded: Toad_State.navAccordionsBookExpanded(Toad_State.navAccordions(state)),
            header: new Toad_Atom_Accordion.HeaderItem("Book"),
            active: new Data_Maybe.Just(Data_Unit.unit),
            items: [  ],
            actionToggleExpanded: Toad_Action.NavAccordionExpandBook.value,
            actionClickItem: Data_Function["const"](Toad_Action.Nop.value),
            actionNoop: Toad_Action.Nop.value
        }), render2(Data_Maybe.Nothing.value)({
            expanded: Toad_State.navAccordionsConceptsExpanded(Toad_State.navAccordions(state)),
            header: new Toad_Atom_Accordion.HeaderItem("Concepts"),
            active: new Data_Maybe.Just(Toad_State.route(state)),
            items: map(function (d) {
                return new Toad_Atom_Accordion.Item(Toad_Atom_Accordion.Title(Toad_Concept.titleString(Toad_Concept.title(d))), Toad_Route.Concepts.create(Toad_Route.One.create(Toad_Concept.ident(d))));
            })(Data_Maybe.maybe([  ])(Toad_Prelude.id)(map1(Toad_Concept.decls)(Toad_State.conceptManifest(state)))),
            actionToggleExpanded: Toad_Action.NavAccordionExpandConcepts.value,
            actionClickItem: Toad_Action.Navigate.create,
            actionNoop: Toad_Action.Nop.value
        }) ]), Halogen_HTML_Elements.div([ Toad_Css.style(discard2(Toad_Css_Grid.inAppLogo)(function () {
            return discard2(CSS_Background.backgroundColor(Toad_Css.oklab(Toad_Css_Color.green(0.95))))(function () {
                return discard2(CSS_Display.display(CSS_Display.flex))(function () {
                    return discard2(CSS_Flexbox.alignItems(center))(function () {
                        return discard2(CSS_Flexbox.justifyContent(spaceBetween))(function () {
                            return CSS_Size.sym(CSS_Geometry.padding)(CSS_Size.rem(2.0));
                        });
                    });
                });
            });
        })) ])([ Toad_Atom_Logo.render(CSS_Geometry.marginRight(CSS_Size.rem(1.0))), Halogen_HTML_Elements.div([ Toad_Css.style(discard2(CSS_Display.display(CSS_Display.flex))(function () {
            return discard2(CSS_Flexbox.flexDirection(CSS_Flexbox.column))(function () {
                return CSS_TextAlign.textAlign(CSS_TextAlign.rightTextAlign);
            });
        })) ])([ Halogen_HTML_Elements.h1([ Toad_Css.style(Toad_Html.headingStyle(append1(Toad_Html.h1Font)(append1(Toad_Css_Font.fontSize(Toad_Css_Font.FontSizeHuge.value))(Toad_Css_Font.fontFamily(Toad_Css_Font.WorkSansSemibold.value))))) ])([ Halogen_HTML_Core.text("toad") ]), Halogen_HTML_Elements.h4([ Toad_Css.style(discard2(Toad_Html.headingStyle(Toad_Html.h4Font))(function () {
            return CSS_Font.color(Toad_Css.oklab(Toad_Css_Color.grey(0.4)));
        })) ])([ Halogen_HTML_Core.text("CoAP - fast, simple, and universal") ]) ]) ]), Data_Maybe.maybe(Halogen_HTML_Elements.div([  ])([  ]))(Toad_Atom_AppTitle.render(Toad_Css_Grid.inAppContentTitle))((function (v) {
            return v.appTitle;
        })(Toad_State.record(state))), (function () {
            var v = Toad_State.route(state);
            if (v instanceof Toad_Route.Home) {
                return Halogen_HTML_Elements.div_([  ]);
            };
            if (v instanceof Toad_Route.Concepts) {
                return slot(_concepts)(conceptsPageSlot)(concepts)({
                    hash: apply([ Toad_State.routeHash, Toad_State.conceptManifestHash, Toad_State.conceptsHash, function ($57) {
                        return hash((function (v1) {
                            return v1.appTitle;
                        })(Toad_State.record($57)));
                    } ])([ state ]),
                    route: v.value0,
                    manifest: Toad_State.conceptManifest(state),
                    titleStyle: Toad_Css_Grid.inAppContentTitle,
                    bodyStyle: (function () {
                        var $51 = eq(Data_Maybe.Nothing.value)((function (v1) {
                            return v1.appTitle;
                        })(Toad_State.record(state)));
                        if ($51) {
                            return Toad_Css_Grid.inAppContentAndTitle;
                        };
                        return Toad_Css_Grid.inAppContent;
                    })(),
                    lookupDocument: Data_Function.flip(lookup)(Toad_State.concepts(state))
                })(Toad_Action.ConceptsPageOutput.create);
            };
            if (v instanceof Toad_Route.Book) {
                return Halogen_HTML_Elements.div_([  ]);
            };
            throw new Error("Failed pattern match at Toad.App (line 190, column 11 - line 216, column 39): " + [ v.constructor.name ]);
        })() ]) ]);
    };
};
export {
    runM,
    put,
    render,
    handleError,
    functorM,
    applyM,
    applicativeM,
    bindM,
    monadM,
    monadEffectM,
    monadAffM,
    navigateM
};
