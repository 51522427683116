// Generated by purs version 0.15.4
import * as CSS_Background from "../CSS.Background/index.js";
import * as CSS_Display from "../CSS.Display/index.js";
import * as CSS_Font from "../CSS.Font/index.js";
import * as CSS_Geometry from "../CSS.Geometry/index.js";
import * as CSS_Overflow from "../CSS.Overflow/index.js";
import * as CSS_Property from "../CSS.Property/index.js";
import * as CSS_Size from "../CSS.Size/index.js";
import * as CSS_String from "../CSS.String/index.js";
import * as CSS_Stylesheet from "../CSS.Stylesheet/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Toad_Atom_AppTitle_Style from "../Toad.Atom.AppTitle.Style/index.js";
import * as Toad_Atom_Button_Style from "../Toad.Atom.Button.Style/index.js";
import * as Toad_Css from "../Toad.Css/index.js";
import * as Toad_Css_Color from "../Toad.Css.Color/index.js";
import * as Toad_Css_Font from "../Toad.Css.Font/index.js";
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit)(CSS_Stylesheet.bindStyleM);
var key = /* #__PURE__ */ CSS_Stylesheet.key(CSS_Property.valValue);
var fromString = /* #__PURE__ */ CSS_String.fromString(CSS_Property.isStringKey);
var noCommas = /* #__PURE__ */ CSS_Property.noCommas(CSS_Property.valValue);
var value = /* #__PURE__ */ CSS_Property.value(CSS_Property.valString);
var value1 = /* #__PURE__ */ CSS_Property.value(CSS_Property.valColor);
var topLevel = /* #__PURE__ */ discard(/* #__PURE__ */ CSS_Display.display(CSS_Display.block))(function () {
    return CSS_Geometry.marginTop(CSS_Size.rem(2.0));
});
var topLevelFirst = /* #__PURE__ */ discard(topLevel)(function () {
    return CSS_Geometry.marginTop(CSS_Size.nil);
});
var span = /* #__PURE__ */ discard(/* #__PURE__ */ Toad_Css_Font.font(/* #__PURE__ */ Data_Monoid.mempty(Toad_Css_Font.fontMonoid)))(function () {
    return CSS_Font.color(Toad_Css.oklab(Toad_Css_Color.green(0.1)));
});
var topLevelSpan = /* #__PURE__ */ discard(span)(function () {
    return discard(CSS_Display.display(CSS_Display.block))(function () {
        return CSS_Geometry.marginTop(CSS_Size.rem(1.0));
    });
});
var githubButtonTheme = /* #__PURE__ */ (function () {
    return new Toad_Atom_Button_Style.Theme({
        color: Toad_Css_Color.green,
        inert: Toad_Atom_AppTitle_Style.backgroundLightness,
        hover: 0.85,
        active: 0.9
    });
})();
var githubButton = /* #__PURE__ */ discard(/* #__PURE__ */ CSS_Size.sym(CSS_Geometry.padding)(/* #__PURE__ */ CSS_Size.rem(1.0)))(function () {
    return CSS_Geometry.height(CSS_Size.rem(3.0));
});
var documentBody = /* #__PURE__ */ discard(/* #__PURE__ */ CSS_Geometry.height(/* #__PURE__ */ CSS_Size.pct(100.0)))(function () {
    return discard(CSS_Overflow.overflow(CSS_Overflow.overflowAuto))(function () {
        return discard(CSS_Size.sym(CSS_Geometry.padding)(CSS_Size.rem(2.0)))(function () {
            return CSS_Background.backgroundColor(Toad_Css.oklab(Toad_Css_Color.green(0.96)));
        });
    });
});
var anchorSpan = /* #__PURE__ */ (function () {
    return discard(Toad_Css_Font.font(Toad_Css_Font.fontFamily(Toad_Css_Font.AtkinsonBold.value)))(function () {
        var color$prime = Toad_Css.oklab(Toad_Css_Color.green(0.2));
        return discard(CSS_Font.color(color$prime))(function () {
            return key(fromString("text-decoration"))(noCommas([ value("wavy underline "), value1(color$prime) ]));
        });
    });
})();
export {
    anchorSpan,
    documentBody,
    githubButton,
    githubButtonTheme,
    span,
    topLevel,
    topLevelFirst,
    topLevelSpan
};
