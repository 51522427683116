// Generated by purs version 0.15.4
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_Rec_Class from "../Control.Monad.Rec.Class/index.js";
import * as Control_Monad_State_Class from "../Control.Monad.State.Class/index.js";
import * as Data_Bifunctor from "../Data.Bifunctor/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Map_Internal from "../Data.Map.Internal/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_Traversable from "../Data.Traversable/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Effect from "../Effect/index.js";
import * as Effect_Aff from "../Effect.Aff/index.js";
import * as Effect_Aff_Class from "../Effect.Aff.Class/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
import * as Effect_Console from "../Effect.Console/index.js";
import * as Halogen_Aff_Util from "../Halogen.Aff.Util/index.js";
import * as Halogen_Component from "../Halogen.Component/index.js";
import * as Halogen_Query_HalogenM from "../Halogen.Query.HalogenM/index.js";
import * as Halogen_Subscription from "../Halogen.Subscription/index.js";
import * as Halogen_VDom_Driver from "../Halogen.VDom.Driver/index.js";
import * as Parsing from "../Parsing/index.js";
import * as Routing_Duplex from "../Routing.Duplex/index.js";
import * as Routing_Hash from "../Routing.Hash/index.js";
import * as Toad_Action from "../Toad.Action/index.js";
import * as Toad_App from "../Toad.App/index.js";
import * as Toad_Concept from "../Toad.Concept/index.js";
import * as Toad_Concept_Fetch from "../Toad.Concept.Fetch/index.js";
import * as Toad_Error from "../Toad.Error/index.js";
import * as Toad_Markdown from "../Toad.Markdown/index.js";
import * as Toad_Navigate from "../Toad.Navigate/index.js";
import * as Toad_Page_Concepts from "../Toad.Page.Concepts/index.js";
import * as Toad_Query from "../Toad.Query/index.js";
import * as Toad_Route from "../Toad.Route/index.js";
import * as Toad_State from "../Toad.State/index.js";
import * as Yoga_Fetch_Impl_Window from "../Yoga.Fetch.Impl.Window/index.js";
var forever = /* #__PURE__ */ Control_Monad_Rec_Class.forever(Effect_Aff.monadRecAff);
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit);
var discard1 = /* #__PURE__ */ discard(Effect_Aff.bindAff);
var liftEffect = /* #__PURE__ */ Effect_Class.liftEffect(Effect_Aff.monadEffectAff);
var discard2 = /* #__PURE__ */ discard(Halogen_Query_HalogenM.bindHalogenM);
var put = /* #__PURE__ */ Toad_App.put(Toad_State.routeAppState)(Toad_App.monadM);
var pure = /* #__PURE__ */ Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM);
var bind = /* #__PURE__ */ Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM);
var liftAff = /* #__PURE__ */ Effect_Aff_Class.liftAff(/* #__PURE__ */ Halogen_Query_HalogenM.monadAffHalogenM(Toad_App.monadAffM));
var map = /* #__PURE__ */ Data_Functor.map(Data_Maybe.functorMaybe);
var liftEffect1 = /* #__PURE__ */ Effect_Class.liftEffect(/* #__PURE__ */ Halogen_Query_HalogenM.monadEffectHalogenM(Toad_App.monadEffectM));
var handleError = /* #__PURE__ */ Toad_App.handleError(Toad_App.monadM)(Toad_App.monadEffectM);
var lmap = /* #__PURE__ */ Data_Bifunctor.lmap(Data_Bifunctor.bifunctorEither);
var rmap = /* #__PURE__ */ Data_Bifunctor.rmap(Data_Bifunctor.bifunctorEither);
var put1 = /* #__PURE__ */ Toad_App.put(Toad_State.conceptManifestAppState)(Toad_App.monadM);
var mempty = /* #__PURE__ */ Data_Monoid.mempty(/* #__PURE__ */ Halogen_Query_HalogenM.monoidHalogenM(Data_Monoid.monoidUnit));
var put2 = /* #__PURE__ */ Toad_App.put(Toad_State.appTitleLiftState)(Toad_App.monadM);
var navigate = /* #__PURE__ */ Toad_Navigate.navigate(/* #__PURE__ */ Toad_Navigate.navigateHalogenM(Toad_App.navigateM));
var bindFlipped = /* #__PURE__ */ Control_Bind.bindFlipped(Halogen_Query_HalogenM.bindHalogenM);
var put3 = /* #__PURE__ */ Control_Monad_State_Class.put(Halogen_Query_HalogenM.monadStateHalogenM);
var modify = /* #__PURE__ */ Control_Monad_State_Class.modify(Halogen_Query_HalogenM.monadStateHalogenM);
var put4 = /* #__PURE__ */ Toad_App.put(Toad_State.navAccordionsAppState)(Toad_App.monadM);
var map1 = /* #__PURE__ */ Data_Functor.map(Halogen_Query_HalogenM.functorHalogenM);
var get = /* #__PURE__ */ Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM);
var bind1 = /* #__PURE__ */ Control_Bind.bind(Data_Either.bindEither);
var show = /* #__PURE__ */ Data_Show.show(Parsing.showParseError);
var insert = /* #__PURE__ */ Data_Map_Internal.insert(Toad_Concept.ordIdent);
var put5 = /* #__PURE__ */ Toad_App.put(Toad_State.conceptsAppState)(Toad_App.monadM);
var traverse = /* #__PURE__ */ Data_Traversable.traverse(Data_Traversable.traversableEither)(Halogen_Query_HalogenM.applicativeHalogenM);
var show1 = /* #__PURE__ */ Data_Show.show(Toad_Concept.showIdent);
var eq = /* #__PURE__ */ Data_Eq.eq(/* #__PURE__ */ Data_Maybe.eqMaybe(Toad_Route.eqRoute));
var pure1 = /* #__PURE__ */ Control_Applicative.pure(Effect.applicativeEffect);
var $$void = /* #__PURE__ */ Data_Functor["void"](Effect.functorEffect);
var bind2 = /* #__PURE__ */ Control_Bind.bind(Effect_Aff.bindAff);
var hoist = /* #__PURE__ */ Halogen_Component.hoist(Effect_Aff.functorAff);
var matchesWith = /* #__PURE__ */ Routing_Hash.matchesWith(Data_Foldable.foldableEither);
var timer = function (dictMonadAff) {
    var MonadEffect0 = dictMonadAff.MonadEffect0();
    var Monad0 = MonadEffect0.Monad0();
    var bind3 = Control_Bind.bind(Monad0.Bind1());
    var liftEffect2 = Effect_Class.liftEffect(MonadEffect0);
    var liftAff1 = Effect_Aff_Class.liftAff(dictMonadAff);
    var pure2 = Control_Applicative.pure(Monad0.Applicative0());
    return function (ms) {
        return function (val) {
            return bind3(liftEffect2(Halogen_Subscription.create))(function (v) {
                return bind3(liftAff1(Effect_Aff.forkAff(forever(discard1(Effect_Aff.delay(ms))(function () {
                    return liftEffect(Halogen_Subscription.notify(v.listener)(val));
                })))))(function () {
                    return pure2(v.emitter);
                });
            });
        };
    };
};
var left = Data_Either.blush;
var handleQuery = function (v) {
    return discard2(put(v.value0))(function () {
        return pure(Data_Maybe.Nothing.value);
    });
};
var handleAction = function ($copy_v) {
    var $tco_done = false;
    var $tco_result;
    function $tco_loop(v) {
        if (v instanceof Toad_Action.Init) {
            $tco_done = true;
            return bind(liftAff(Toad_Concept_Fetch.manifest(Yoga_Fetch_Impl_Window.windowFetch)))(function (conceptManifest) {
                return discard2(Data_Maybe.fromMaybe(pure(Data_Unit.unit))(map(liftEffect1)(map(Effect_Console.error)(left(conceptManifest)))))(function () {
                    return handleError(lmap(Toad_Error.fetchingManifest)(rmap(put1)(conceptManifest)));
                });
            });
        };
        if (v instanceof Toad_Action.NavbarSectionPicked) {
            $tco_done = true;
            return mempty;
        };
        if (v instanceof Toad_Action.AppTitleChanged) {
            $tco_done = true;
            return put2(v.value0);
        };
        if (v instanceof Toad_Action.Navigate) {
            $tco_done = true;
            return navigate(v.value0);
        };
        if (v instanceof Toad_Action.Tick) {
            $tco_done = true;
            return mempty;
        };
        if (v instanceof Toad_Action.DismissError) {
            $tco_done = true;
            return bindFlipped(put3)(modify(Toad_State.dismissError));
        };
        if (v instanceof Toad_Action.Nop) {
            $tco_done = true;
            return mempty;
        };
        if (v instanceof Toad_Action.NavAccordionExpandBook) {
            $tco_done = true;
            return Data_Function.flip(bind)(put4)(map1(Toad_State.navAccordionsToggleBook)(map1(Toad_State.navAccordions)(get)));
        };
        if (v instanceof Toad_Action.NavAccordionExpandConcepts) {
            $tco_done = true;
            return Data_Function.flip(bind)(put4)(map1(Toad_State.navAccordionsToggleConcepts)(map1(Toad_State.navAccordions)(get)));
        };
        if (v instanceof Toad_Action.ConceptsPageOutput && v.value0 instanceof Toad_Page_Concepts.TitleChanged) {
            $copy_v = new Toad_Action.AppTitleChanged(v.value0.value0);
            return;
        };
        if (v instanceof Toad_Action.ConceptsPageOutput && v.value0 instanceof Toad_Page_Concepts.FetchConcept) {
            var fetchConcept = (function () {
                var $84 = Toad_Concept_Fetch.one(Yoga_Fetch_Impl_Window.windowFetch);
                return function ($85) {
                    return liftAff($84(Toad_Concept.path($85)));
                };
            })();
            var tryParseConcept = Data_Function.flip(bind1)((function () {
                var $86 = lmap(Toad_Error.parsingConcept);
                var $87 = lmap(show);
                var $88 = Data_Function.flip(Parsing.runParser)(Toad_Markdown.documentP);
                return function ($89) {
                    return $86($87($88($89)));
                };
            })());
            var storeDoc = function (s) {
                return function (d) {
                    return insert(v.value0.value0)(d)(Toad_State.concepts(s));
                };
            };
            var parseDocAndPut = function (mdM) {
                return bind(get)(function (state) {
                    return bind(mdM)(function (md) {
                        return handleError(rmap((function () {
                            var $90 = storeDoc(state);
                            return function ($91) {
                                return put5($90($91));
                            };
                        })())(tryParseConcept(md)));
                    });
                });
            };
            $tco_done = true;
            return bind(get)((function () {
                var $92 = traverse(fetchConcept);
                var $93 = lmap(Toad_Error.lookingUpRouteConcept);
                var $94 = Data_Either.note("concept " + (show1(v.value0.value0) + " not found"));
                var $95 = Toad_State.lookupDecl(v.value0.value0);
                return function ($96) {
                    return parseDocAndPut($92($93($94($95($96)))));
                };
            })());
        };
        throw new Error("Failed pattern match at Main (line 93, column 3 - line 163, column 38): " + [ v.constructor.name ]);
    };
    while (!$tco_done) {
        $tco_result = $tco_loop($copy_v);
    };
    return $tco_result;
};
var component = /* #__PURE__ */ (function () {
    return Halogen_Component.mkComponent({
        initialState: Data_Function["const"](Toad_State.init),
        render: Toad_App.render(Toad_App.monadEffectM),
        "eval": Halogen_Component.mkEval({
            handleAction: handleAction,
            handleQuery: handleQuery,
            receive: Halogen_Component.defaultEval.receive,
            initialize: new Data_Maybe.Just(Toad_Action.Init.value),
            finalize: Halogen_Component.defaultEval.finalize
        })
    });
})();
var main = /* #__PURE__ */ (function () {
    var tellAppRouteChanged = function (v) {
        return function (v1) {
            return function (v2) {
                if (v1 instanceof Data_Maybe.Just && eq(v1.value0)(v2)) {
                    return pure1(Data_Unit.unit);
                };
                return $$void(Effect_Aff.launchAff(Toad_Query.sendNavigate(Data_Maybe.fromMaybe(Toad_Route.Home.value)(v2))(v)));
            };
        };
    };
    return Halogen_Aff_Util.runHalogenAff(bind2(Halogen_Aff_Util.awaitBody)(function (body) {
        return bind2(Halogen_VDom_Driver.runUI(hoist(Toad_App.runM)(component))(Data_Unit.unit)(body))(function (io) {
            return liftEffect($$void(matchesWith(Routing_Duplex.parse(Routing_Duplex.optional(Toad_Route.codec)))(tellAppRouteChanged(io))));
        });
    }));
})();
export {
    left,
    main,
    component,
    timer,
    handleQuery,
    handleAction
};
