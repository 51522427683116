// Generated by purs version 0.15.4
import * as Control_Bind from "../Control.Bind/index.js";
import * as Effect from "../Effect/index.js";
import * as HighlightJs_Highlighter from "../HighlightJs.Highlighter/index.js";
import * as HighlightJs_Language from "../HighlightJs.Language/index.js";
var bindFlipped = /* #__PURE__ */ Control_Bind.bindFlipped(Effect.bindEffect);
var highlight = function (lang) {
    return function (html) {
        var hljs = function __do() {
            var hljs$prime = HighlightJs_Highlighter.make();
            HighlightJs_Highlighter.registerLanguage(HighlightJs_Language.defaultAlias(lang))(HighlightJs_Language.requireLanguage(lang))(hljs$prime)();
            return hljs$prime;
        };
        return bindFlipped(HighlightJs_Highlighter.highlight(HighlightJs_Language.defaultAlias(lang))(html))(hljs);
    };
};
export {
    highlight
};
export {
    RawHtml
} from "../HighlightJs.Highlighter/index.js";
export {
    Abnf,
    Accesslog,
    Actionscript,
    Ada,
    Angelscript,
    Apache,
    Applescript,
    Arcade,
    Arduino,
    Armasm,
    Asciidoc,
    Aspectj,
    Autohotkey,
    Autoit,
    Avrasm,
    Awk,
    Axapta,
    Bash,
    Basic,
    Bnf,
    Brainfuck,
    C,
    Cal,
    Capnproto,
    Ceylon,
    Clean,
    Clojure,
    ClojureRepl,
    Cmake,
    Coffeescript,
    Coq,
    Cos,
    Cpp,
    Crmsh,
    Crystal,
    Csharp,
    Csp,
    Css,
    D,
    Dart,
    Delphi,
    Diff,
    Django,
    Dns,
    Dockerfile,
    Dos,
    Dsconfig,
    Dts,
    Dust,
    Ebnf,
    Elixir,
    Elm,
    Erb,
    Erlang,
    ErlangRepl,
    Excel,
    Fix,
    Flix,
    Fortran,
    Fsharp,
    Gams,
    Gauss,
    Gcode,
    Gherkin,
    Glsl,
    Gml,
    Go,
    Golo,
    Gradle,
    Graphql,
    Groovy,
    Haml,
    Handlebars,
    Haskell,
    Haxe,
    Hsp,
    Html,
    Http,
    Hy,
    Inform7,
    Ini,
    Irpf90,
    Isbl,
    Java,
    Javascript,
    JbossCli,
    Json,
    Julia,
    JuliaRepl,
    Kotlin,
    Lang1c,
    Lasso,
    Latex,
    Ldif,
    Leaf,
    Less,
    Lisp,
    Livecodeserver,
    Livescript,
    Llvm,
    Lsl,
    Lua,
    Makefile,
    Markdown,
    Mathematica,
    Matlab,
    Maxima,
    Mel,
    Mercury,
    Mipsasm,
    Mizar,
    Mojolicious,
    Monkey,
    Moonscript,
    N1ql,
    Nestedtext,
    Nginx,
    Nim,
    Nix,
    NodeRepl,
    Nsis,
    Objectivec,
    Ocaml,
    Openscad,
    Oxygene,
    Parser3,
    Perl,
    Pf,
    Pgsql,
    Php,
    PhpTemplate,
    Plaintext,
    Pony,
    Powershell,
    Processing,
    Profile,
    Prolog,
    Properties,
    Protobuf,
    Puppet,
    Purebasic,
    Python,
    PythonRepl,
    Q,
    Qml,
    R,
    Reasonml,
    Rib,
    Roboconf,
    Routeros,
    Rsl,
    Ruby,
    Ruleslanguage,
    Rust,
    Sas,
    Scala,
    Scheme,
    Scilab,
    Scss,
    Shell,
    Smali,
    Smalltalk,
    Sml,
    Sqf,
    Sql,
    Stan,
    Stata,
    Step21,
    Stylus,
    Subunit,
    Swift,
    Taggerscript,
    Tap,
    Tcl,
    Thrift,
    Tp,
    Twig,
    Typescript,
    Vala,
    Vbnet,
    Vbscript,
    VbscriptHtml,
    Verilog,
    Vhdl,
    Vim,
    Wasm,
    Wren,
    X86asm,
    Xl,
    Xml,
    Xquery,
    Yaml,
    Zephir
} from "../HighlightJs.Language/index.js";
