// Generated by purs version 0.15.4
import * as CSS_Common from "../CSS.Common/index.js";
import * as CSS_Geometry from "../CSS.Geometry/index.js";
import * as CSS_Property from "../CSS.Property/index.js";
import * as CSS_Size from "../CSS.Size/index.js";
import * as CSS_String from "../CSS.String/index.js";
import * as CSS_Stylesheet from "../CSS.Stylesheet/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Css_Grid from "../Css.Grid/index.js";
import * as Data_Fist from "../Data.Fist/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Toad_Css from "../Toad.Css/index.js";
import * as Toad_Layout from "../Toad.Layout/index.js";
var auto = /* #__PURE__ */ CSS_Common.auto(CSS_Size.autoSize);
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit)(CSS_Stylesheet.bindStyleM);
var key = /* #__PURE__ */ CSS_Stylesheet.key(CSS_Size.valSize);
var fromString = /* #__PURE__ */ CSS_String.fromString(CSS_Property.isStringKey);
var AppGridLogo = /* #__PURE__ */ (function () {
    function AppGridLogo() {

    };
    AppGridLogo.value = new AppGridLogo();
    return AppGridLogo;
})();
var AppGridContentTitle = /* #__PURE__ */ (function () {
    function AppGridContentTitle() {

    };
    AppGridContentTitle.value = new AppGridContentTitle();
    return AppGridContentTitle;
})();
var AppGridNavbar = /* #__PURE__ */ (function () {
    function AppGridNavbar() {

    };
    AppGridNavbar.value = new AppGridNavbar();
    return AppGridNavbar;
})();
var AppGridContent = /* #__PURE__ */ (function () {
    function AppGridContent() {

    };
    AppGridContent.value = new AppGridContent();
    return AppGridContent;
})();
var appGridLabel = function (v) {
    if (v instanceof AppGridNavbar) {
        return "navbar";
    };
    if (v instanceof AppGridContent) {
        return "content";
    };
    if (v instanceof AppGridLogo) {
        return "logo";
    };
    if (v instanceof AppGridContentTitle) {
        return "content-title";
    };
    throw new Error("Failed pattern match at Toad.Css.Grid (line 25, column 16 - line 29, column 41): " + [ v.constructor.name ]);
};
var appGridMobile = /* #__PURE__ */ (function () {
    var rem = function ($16) {
        return Toad_Css.anySize(CSS_Size.pct($16));
    };
    var pct = function ($17) {
        return Toad_Css.anySize(CSS_Size.rem($17));
    };
    return Css_Grid.grid(Data_Fist.ft1Fixed)(Data_Functor.functorArray)(Data_Foldable.foldableArray)(Data_Fist.fist1(pct(100.0)))([ new Css_Grid.GridRow(rem(16.0), Data_Fist.fist1(AppGridNavbar.value)), new Css_Grid.GridRow(auto, Data_Fist.fist1(AppGridContent.value)) ])(appGridLabel);
})();
var inAppContent = /* #__PURE__ */ (function () {
    return Css_Grid.gridArea(appGridLabel)(AppGridContent.value);
})();
var inAppContentAndTitle = /* #__PURE__ */ (function () {
    return discard(Css_Grid.gridArea(appGridLabel)(AppGridContent.value))(function () {
        return Css_Grid.gridRowStart(appGridLabel)(AppGridContentTitle.value);
    });
})();
var inAppContentTitle = /* #__PURE__ */ (function () {
    return Css_Grid.gridArea(appGridLabel)(AppGridContentTitle.value);
})();
var inAppLogo = /* #__PURE__ */ (function () {
    return Css_Grid.gridArea(appGridLabel)(AppGridLogo.value);
})();
var inAppNavbar = /* #__PURE__ */ (function () {
    return Css_Grid.gridArea(appGridLabel)(AppGridNavbar.value);
})();
var appGridDesktop = /* #__PURE__ */ (function () {
    var rem = function ($18) {
        return Toad_Css.anySize(CSS_Size.rem($18));
    };
    return discard(Css_Grid.grid(Data_Fist.ft2Fixed)(Data_Functor.functorArray)(Data_Foldable.foldableArray)(Data_Fist.fist2(rem(32.0))(auto))([ new Css_Grid.GridRow(rem(12.0), Data_Fist.fist2(AppGridLogo.value)(AppGridContentTitle.value)), new Css_Grid.GridRow(auto, Data_Fist.fist2(AppGridNavbar.value)(AppGridContent.value)) ])(appGridLabel))(function () {
        return discard(key(fromString("row-gap"))(rem(2.0)))(function () {
            return discard(key(fromString("column-gap"))(rem(2.0)))(function () {
                return CSS_Size.sym(CSS_Geometry.padding)(rem(2.0));
            });
        });
    });
})();
var appGrid = function (v) {
    if (v instanceof Toad_Layout.AppLayoutDesktop) {
        return appGridDesktop;
    };
    if (v instanceof Toad_Layout.AppLayoutMobile) {
        return appGridMobile;
    };
    throw new Error("Failed pattern match at Toad.Css.Grid (line 48, column 1 - line 48, column 32): " + [ v.constructor.name ]);
};
export {
    appGrid,
    inAppContent,
    inAppContentAndTitle,
    inAppContentTitle,
    inAppNavbar,
    inAppLogo
};
