// Generated by purs version 0.15.4
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Compactable from "../Data.Compactable/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_HeytingAlgebra from "../Data.HeytingAlgebra/index.js";
import * as Data_List from "../Data.List/index.js";
import * as Data_List_Types from "../Data.List.Types/index.js";
import * as Data_Map_Internal from "../Data.Map.Internal/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
var foldr = /* #__PURE__ */ Data_Foldable.foldr(Data_List_Types.foldableList);
var append = /* #__PURE__ */ Data_Semigroup.append(Data_Semigroup.semigroupArray);
var foldl = /* #__PURE__ */ Data_Foldable.foldl(Data_Foldable.foldableArray);
var not = /* #__PURE__ */ Data_HeytingAlgebra.not(/* #__PURE__ */ Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean));
var toUnfoldable = /* #__PURE__ */ Data_Map_Internal.toUnfoldable(Data_List_Types.unfoldableList);
var partitionMapDefault = function (dictFilterable) {
    var separate = Data_Compactable.separate(dictFilterable.Compactable0());
    var map = Data_Functor.map(dictFilterable.Functor1());
    return function (p) {
        var $117 = map(p);
        return function ($118) {
            return separate($117($118));
        };
    };
};
var partitionMap = function (dict) {
    return dict.partitionMap;
};
var partition = function (dict) {
    return dict.partition;
};
var maybeBool = function (p) {
    return function (x) {
        var $66 = p(x);
        if ($66) {
            return new Data_Maybe.Just(x);
        };
        return Data_Maybe.Nothing.value;
    };
};
var filterableList = {
    partitionMap: function (p) {
        return function (xs) {
            var select = function (x) {
                return function (v) {
                    var v1 = p(x);
                    if (v1 instanceof Data_Either.Left) {
                        return {
                            left: new Data_List_Types.Cons(v1.value0, v.left),
                            right: v.right
                        };
                    };
                    if (v1 instanceof Data_Either.Right) {
                        return {
                            left: v.left,
                            right: new Data_List_Types.Cons(v1.value0, v.right)
                        };
                    };
                    throw new Error("Failed pattern match at Data.Filterable (line 190, column 36 - line 192, column 83): " + [ v1.constructor.name ]);
                };
            };
            return foldr(select)({
                left: Data_List_Types.Nil.value,
                right: Data_List_Types.Nil.value
            })(xs);
        };
    },
    partition: function (p) {
        return function (xs) {
            var select = function (x) {
                return function (v) {
                    var $76 = p(x);
                    if ($76) {
                        return {
                            no: v.no,
                            yes: new Data_List_Types.Cons(x, v.yes)
                        };
                    };
                    return {
                        no: new Data_List_Types.Cons(x, v.no),
                        yes: v.yes
                    };
                };
            };
            return foldr(select)({
                no: Data_List_Types.Nil.value,
                yes: Data_List_Types.Nil.value
            })(xs);
        };
    },
    filterMap: function (p) {
        return Data_List.mapMaybe(p);
    },
    filter: Data_List.filter,
    Compactable0: function () {
        return Data_Compactable.compactableList;
    },
    Functor1: function () {
        return Data_List_Types.functorList;
    }
};
var filterableArray = {
    partitionMap: function (p) {
        var go = function (acc) {
            return function (x) {
                var v = p(x);
                if (v instanceof Data_Either.Left) {
                    return {
                        left: append(acc.left)([ v.value0 ]),
                        right: acc.right
                    };
                };
                if (v instanceof Data_Either.Right) {
                    return {
                        right: append(acc.right)([ v.value0 ]),
                        left: acc.left
                    };
                };
                throw new Error("Failed pattern match at Data.Filterable (line 149, column 16 - line 151, column 50): " + [ v.constructor.name ]);
            };
        };
        return foldl(go)({
            left: [  ],
            right: [  ]
        });
    },
    partition: Data_Array.partition,
    filterMap: Data_Array.mapMaybe,
    filter: Data_Array.filter,
    Compactable0: function () {
        return Data_Compactable.compactableArray;
    },
    Functor1: function () {
        return Data_Functor.functorArray;
    }
};
var filterMapDefault = function (dictFilterable) {
    var compact = Data_Compactable.compact(dictFilterable.Compactable0());
    var map = Data_Functor.map(dictFilterable.Functor1());
    return function (p) {
        var $119 = map(p);
        return function ($120) {
            return compact($119($120));
        };
    };
};
var filterMap = function (dict) {
    return dict.filterMap;
};
var partitionDefaultFilterMap = function (dictFilterable) {
    var filterMap1 = filterMap(dictFilterable);
    return function (p) {
        return function (xs) {
            return {
                yes: filterMap1(maybeBool(p))(xs),
                no: filterMap1(maybeBool(not(p)))(xs)
            };
        };
    };
};
var filterDefaultPartition = function (dictFilterable) {
    var partition1 = partition(dictFilterable);
    return function (p) {
        return function (xs) {
            return (partition1(p)(xs)).yes;
        };
    };
};
var filterDefault = function (dictFilterable) {
    var $121 = filterMap(dictFilterable);
    return function ($122) {
        return $121(maybeBool($122));
    };
};
var filter = function (dict) {
    return dict.filter;
};
var partitionDefaultFilter = function (dictFilterable) {
    var filter1 = filter(dictFilterable);
    return function (p) {
        return function (xs) {
            return {
                yes: filter1(p)(xs),
                no: filter1(not(p))(xs)
            };
        };
    };
};
var eitherBool = function (p) {
    return function (x) {
        var $84 = p(x);
        if ($84) {
            return new Data_Either.Right(x);
        };
        return new Data_Either.Left(x);
    };
};
var filterDefaultPartitionMap = function (dictFilterable) {
    var partitionMap1 = partitionMap(dictFilterable);
    return function (p) {
        return function (xs) {
            return (partitionMap1(eitherBool(p))(xs)).right;
        };
    };
};
var partitionDefault = function (dictFilterable) {
    var partitionMap1 = partitionMap(dictFilterable);
    return function (p) {
        return function (xs) {
            var o = partitionMap1(eitherBool(p))(xs);
            return {
                no: o.left,
                yes: o.right
            };
        };
    };
};
var filterableEither = function (dictMonoid) {
    var mempty = Data_Monoid.mempty(dictMonoid);
    var compactableEither = Data_Compactable.compactableEither(dictMonoid);
    return {
        partitionMap: function (v) {
            return function (v1) {
                if (v1 instanceof Data_Either.Left) {
                    return {
                        left: new Data_Either.Left(v1.value0),
                        right: new Data_Either.Left(v1.value0)
                    };
                };
                if (v1 instanceof Data_Either.Right) {
                    var v2 = v(v1.value0);
                    if (v2 instanceof Data_Either.Left) {
                        return {
                            left: new Data_Either.Right(v2.value0),
                            right: new Data_Either.Left(mempty)
                        };
                    };
                    if (v2 instanceof Data_Either.Right) {
                        return {
                            left: new Data_Either.Left(mempty),
                            right: new Data_Either.Right(v2.value0)
                        };
                    };
                    throw new Error("Failed pattern match at Data.Filterable (line 173, column 30 - line 175, column 53): " + [ v2.constructor.name ]);
                };
                throw new Error("Failed pattern match at Data.Filterable (line 171, column 1 - line 184, column 29): " + [ v.constructor.name, v1.constructor.name ]);
            };
        },
        partition: function (p) {
            return partitionDefault(filterableEither(dictMonoid))(p);
        },
        filterMap: function (v) {
            return function (v1) {
                if (v1 instanceof Data_Either.Left) {
                    return new Data_Either.Left(v1.value0);
                };
                if (v1 instanceof Data_Either.Right) {
                    var v2 = v(v1.value0);
                    if (v2 instanceof Data_Maybe.Nothing) {
                        return new Data_Either.Left(mempty);
                    };
                    if (v2 instanceof Data_Maybe.Just) {
                        return new Data_Either.Right(v2.value0);
                    };
                    throw new Error("Failed pattern match at Data.Filterable (line 180, column 27 - line 182, column 22): " + [ v2.constructor.name ]);
                };
                throw new Error("Failed pattern match at Data.Filterable (line 171, column 1 - line 184, column 29): " + [ v.constructor.name, v1.constructor.name ]);
            };
        },
        filter: function (p) {
            return filterDefault(filterableEither(dictMonoid))(p);
        },
        Compactable0: function () {
            return compactableEither;
        },
        Functor1: function () {
            return Data_Either.functorEither;
        }
    };
};
var filterableMap = function (dictOrd) {
    var insert = Data_Map_Internal.insert(dictOrd);
    var alter = Data_Map_Internal.alter(dictOrd);
    var compactableMap = Data_Compactable.compactableMap(dictOrd);
    return {
        partitionMap: function (p) {
            return function (xs) {
                var select = function (v) {
                    return function (v1) {
                        var v2 = p(v.value1);
                        if (v2 instanceof Data_Either.Left) {
                            return {
                                left: insert(v.value0)(v2.value0)(v1.left),
                                right: v1.right
                            };
                        };
                        if (v2 instanceof Data_Either.Right) {
                            return {
                                left: v1.left,
                                right: insert(v.value0)(v2.value0)(v1.right)
                            };
                        };
                        throw new Error("Failed pattern match at Data.Filterable (line 215, column 44 - line 217, column 57): " + [ v2.constructor.name ]);
                    };
                };
                return foldr(select)({
                    left: Data_Map_Internal.empty,
                    right: Data_Map_Internal.empty
                })(toUnfoldable(xs));
            };
        },
        partition: function (p) {
            return partitionDefault(filterableMap(dictOrd))(p);
        },
        filterMap: function (p) {
            return function (xs) {
                var select = function (v) {
                    return function (m) {
                        return alter(Data_Function["const"](p(v.value1)))(v.value0)(m);
                    };
                };
                return foldr(select)(Data_Map_Internal.empty)(toUnfoldable(xs));
            };
        },
        filter: function (p) {
            return filterDefault(filterableMap(dictOrd))(p);
        },
        Compactable0: function () {
            return compactableMap;
        },
        Functor1: function () {
            return Data_Map_Internal.functorMap;
        }
    };
};
var filterableMaybe = {
    partitionMap: function (v) {
        return function (v1) {
            if (v1 instanceof Data_Maybe.Nothing) {
                return {
                    left: Data_Maybe.Nothing.value,
                    right: Data_Maybe.Nothing.value
                };
            };
            if (v1 instanceof Data_Maybe.Just) {
                var v2 = v(v1.value0);
                if (v2 instanceof Data_Either.Left) {
                    return {
                        left: new Data_Maybe.Just(v2.value0),
                        right: Data_Maybe.Nothing.value
                    };
                };
                if (v2 instanceof Data_Either.Right) {
                    return {
                        left: Data_Maybe.Nothing.value,
                        right: new Data_Maybe.Just(v2.value0)
                    };
                };
                throw new Error("Failed pattern match at Data.Filterable (line 161, column 29 - line 163, column 48): " + [ v2.constructor.name ]);
            };
            throw new Error("Failed pattern match at Data.Filterable (line 159, column 1 - line 169, column 29): " + [ v.constructor.name, v1.constructor.name ]);
        };
    },
    partition: function (p) {
        return partitionDefault(filterableMaybe)(p);
    },
    filterMap: /* #__PURE__ */ Control_Bind.bindFlipped(Data_Maybe.bindMaybe),
    filter: function (p) {
        return filterDefault(filterableMaybe)(p);
    },
    Compactable0: function () {
        return Data_Compactable.compactableMaybe;
    },
    Functor1: function () {
        return Data_Maybe.functorMaybe;
    }
};
var cleared = function (dictFilterable) {
    return filterMap(dictFilterable)(Data_Function["const"](Data_Maybe.Nothing.value));
};
export {
    partitionMap,
    partition,
    filterMap,
    filter,
    eitherBool,
    partitionDefault,
    partitionDefaultFilter,
    partitionDefaultFilterMap,
    partitionMapDefault,
    maybeBool,
    filterDefault,
    filterDefaultPartition,
    filterDefaultPartitionMap,
    filterMapDefault,
    cleared,
    filterableArray,
    filterableMaybe,
    filterableEither,
    filterableList,
    filterableMap
};
export {
    compact,
    separate
} from "../Data.Compactable/index.js";
