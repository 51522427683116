// Generated by purs version 0.15.4
import * as CSS_Property from "../CSS.Property/index.js";
import * as CSS_Selector from "../CSS.Selector/index.js";
import * as CSS_String from "../CSS.String/index.js";
import * as CSS_Stylesheet from "../CSS.Stylesheet/index.js";
import * as Control_Alternative from "../Control.Alternative/index.js";
import * as Control_Apply from "../Control.Apply/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_HeytingAlgebra from "../Data.HeytingAlgebra/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_NonEmpty from "../Data.NonEmpty/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Semigroup_Foldable from "../Data.Semigroup.Foldable/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_These from "../Data.These/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
import * as Effect_Console from "../Effect.Console/index.js";
var append = /* #__PURE__ */ Data_Semigroup.append(Data_Semigroup.semigroupString);
var mempty = /* #__PURE__ */ Data_Monoid.mempty(Data_Monoid.monoidString);
var fold = /* #__PURE__ */ Data_Foldable.fold(Data_Foldable.foldableArray);
var fold1 = /* #__PURE__ */ fold(Data_Monoid.monoidString);
var intercalate = /* #__PURE__ */ Data_Foldable.intercalate(Data_Foldable.foldableArray)(Data_Monoid.monoidString);
var map = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var apply = /* #__PURE__ */ Control_Apply.apply(Control_Apply.applyArray);
var append1 = /* #__PURE__ */ Data_Semigroup.append(Data_Semigroup.semigroupArray);
var foldMap = /* #__PURE__ */ Data_Foldable.foldMap(Data_Foldable.foldableArray);
var foldMap1 = /* #__PURE__ */ foldMap(Data_Monoid.monoidString);
var sort = /* #__PURE__ */ Data_Array.sort(CSS_Selector.ordPredicate);
var map1 = /* #__PURE__ */ Data_Functor.map(Data_Maybe.functorMaybe);
var fromString = /* #__PURE__ */ CSS_String.fromString(CSS_String.isStringString);
var bind = /* #__PURE__ */ Control_Bind.bind(Data_Maybe.bindMaybe);
var foldl1 = /* #__PURE__ */ Data_Semigroup_Foldable.foldl1(/* #__PURE__ */ Data_NonEmpty.foldable1NonEmpty(Data_Foldable.foldableArray));
var map2 = /* #__PURE__ */ Data_Functor.map(/* #__PURE__ */ Data_NonEmpty.functorNonEmpty(Data_Functor.functorArray));
var compare = /* #__PURE__ */ Data_Ord.compare(Data_Ord.ordString);
var lookup = /* #__PURE__ */ Data_Foldable.lookup(Data_Foldable.foldableArray)(Data_Eq.eqString);
var bind1 = /* #__PURE__ */ Control_Bind.bind(Control_Bind.bindArray);
var oneOf = /* #__PURE__ */ Data_NonEmpty.oneOf(Control_Alternative.alternativeArray);
var not = /* #__PURE__ */ Data_HeytingAlgebra.not(/* #__PURE__ */ Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean));
var intercalate1 = /* #__PURE__ */ Data_Foldable.intercalate(/* #__PURE__ */ Data_NonEmpty.foldableNonEmpty(Data_Foldable.foldableArray))(Data_Monoid.monoidString);
var show = /* #__PURE__ */ Data_Show.show(Data_Show.showNumber);
var Sheet = function (x) {
    return x;
};
var Inline = function (x) {
    return x;
};
var sepWith = function (s) {
    return function (a) {
        return function (b) {
            return a + (s + b);
        };
    };
};
var semigroupInline = {
    append: function (v) {
        return function (v1) {
            return v + v1;
        };
    }
};
var semigroupFile = {
    append: function (v) {
        return function (v1) {
            return v + v1;
        };
    }
};
var semigroupThese = /* #__PURE__ */ Data_These.semigroupThese(semigroupInline)(semigroupFile);
var monoidMaybe = /* #__PURE__ */ Data_Maybe.monoidMaybe(semigroupThese);
var foldMap2 = /* #__PURE__ */ foldMap(monoidMaybe);
var fold2 = /* #__PURE__ */ fold(monoidMaybe);
var append2 = /* #__PURE__ */ Data_Semigroup.append(/* #__PURE__ */ Data_Maybe.semigroupMaybe(semigroupThese));
var properties = function (xs) {
    var sheetRules = Data_Either.either(function (v) {
        return mempty;
    })(function (v) {
        return fold1([ v.value0, ": ", v.value1 ]);
    });
    return intercalate("; ")(map(sheetRules)(xs));
};
var predicate = function (v) {
    if (v instanceof CSS_Selector.Id) {
        return "#" + v.value0;
    };
    if (v instanceof CSS_Selector.Class) {
        return "." + v.value0;
    };
    if (v instanceof CSS_Selector.Attr) {
        return "[" + (v.value0 + "]");
    };
    if (v instanceof CSS_Selector.AttrVal) {
        return "[" + (v.value0 + ("='" + (v.value1 + "']")));
    };
    if (v instanceof CSS_Selector.AttrBegins) {
        return "[" + (v.value0 + ("^='" + (v.value1 + "']")));
    };
    if (v instanceof CSS_Selector.AttrEnds) {
        return "[" + (v.value0 + ("$='" + (v.value1 + "']")));
    };
    if (v instanceof CSS_Selector.AttrContains) {
        return "[" + (v.value0 + ("*='" + (v.value1 + "']")));
    };
    if (v instanceof CSS_Selector.AttrSpace) {
        return "[" + (v.value0 + ("~='" + (v.value1 + "']")));
    };
    if (v instanceof CSS_Selector.AttrHyph) {
        return "[" + (v.value0 + ("|='" + (v.value1 + "']")));
    };
    if (v instanceof CSS_Selector.Pseudo) {
        return ":" + v.value0;
    };
    if (v instanceof CSS_Selector.PseudoFunc) {
        return ":" + (v.value0 + ("(" + (intercalate(",")(v.value1) + ")")));
    };
    throw new Error("Failed pattern match at CSS.Render (line 178, column 1 - line 178, column 33): " + [ v.constructor.name ]);
};
var selector$prime$prime = function (v) {
    return function (v1) {
        if (v.length === 0 && v1 instanceof CSS_Selector.Star) {
            return [ "*" ];
        };
        if (v1 instanceof CSS_Selector.Star) {
            return [ "" ];
        };
        if (v1 instanceof CSS_Selector.Elem) {
            return [ v1.value0 ];
        };
        if (v1 instanceof CSS_Selector.PathChild) {
            return apply(map(sepWith(" > "))(selector$prime(v1.value0)))(selector$prime(v1.value1));
        };
        if (v1 instanceof CSS_Selector.Deep) {
            return apply(map(sepWith(" "))(selector$prime(v1.value0)))(selector$prime(v1.value1));
        };
        if (v1 instanceof CSS_Selector.Adjacent) {
            return apply(map(sepWith(" + "))(selector$prime(v1.value0)))(selector$prime(v1.value1));
        };
        if (v1 instanceof CSS_Selector.Combined) {
            return append1(selector$prime(v1.value0))(selector$prime(v1.value1));
        };
        throw new Error("Failed pattern match at CSS.Render (line 143, column 1 - line 143, column 63): " + [ v.constructor.name, v1.constructor.name ]);
    };
};
var selector$prime = function (v) {
    return map(function (v1) {
        return v1 + foldMap1(predicate)(sort(v.value0));
    })(selector$prime$prime(v.value0)(v.value1));
};
var selector = /* #__PURE__ */ (function () {
    var $206 = intercalate(", ");
    return function ($207) {
        return $206(selector$prime($207));
    };
})();
var nel = function (v) {
    if (v.length === 0) {
        return Data_Maybe.Nothing.value;
    };
    return map1(function (v1) {
        return new Data_NonEmpty.NonEmpty(v1.head, v1.tail);
    })(Data_Array.uncons(v));
};
var monoidInline = {
    mempty: mempty,
    Semigroup0: function () {
        return semigroupInline;
    }
};
var monoidFile = {
    mempty: mempty,
    Semigroup0: function () {
        return semigroupFile;
    }
};
var merger = function (v) {
    if (v.value0 instanceof CSS_Stylesheet.Child) {
        return Data_Maybe.maybe(v.value0.value0)(function (xs$prime) {
            return CSS_Selector.child(merger(xs$prime))(v.value0.value0);
        })(nel(v.value1));
    };
    if (v.value0 instanceof CSS_Stylesheet.Sub) {
        return Data_Maybe.maybe(v.value0.value0)(function (xs$prime) {
            return CSS_Selector.deep(merger(xs$prime))(v.value0.value0);
        })(nel(v.value1));
    };
    if (v.value0 instanceof CSS_Stylesheet.Root) {
        return Data_Maybe.maybe(v.value0.value0)(function (xs$prime) {
            return CSS_Selector.deep(v.value0.value0)(merger(xs$prime));
        })(nel(v.value1));
    };
    if (v.value0 instanceof CSS_Stylesheet.Pop) {
        return Data_Maybe.maybe(CSS_Selector.element("TODO"))(merger)(nel(Data_Array.drop(v.value0.value0)(Data_Array.cons(v.value0)(v.value1))));
    };
    if (v.value0 instanceof CSS_Stylesheet.Self) {
        return Data_Maybe.maybe(CSS_Selector["with"](CSS_Selector.star)(v.value0.value0))(function (xs$prime) {
            return CSS_Selector["with"](merger(xs$prime))(v.value0.value0);
        })(nel(v.value1));
    };
    throw new Error("Failed pattern match at CSS.Render (line 171, column 3 - line 176, column 102): " + [ v.value0.constructor.name ]);
};
var mediaType = function (v) {
    return CSS_Property.plain(v);
};
var imp = function (t) {
    return Data_Maybe.Just.create(Data_These.That.create(Sheet(fromString("@import url(" + (t + ");\x0a")))));
};
var getSheet = function (v) {
    return v;
};
var renderedSheet = function (v) {
    return bind(v)((function () {
        var $208 = map1(getSheet);
        return function ($209) {
            return $208(Data_These.theseRight($209));
        };
    })());
};
var getInline = function (v) {
    return v;
};
var renderedInline = function (v) {
    return bind(v)((function () {
        var $210 = map1(getInline);
        return function ($211) {
            return $210(Data_These.theseLeft($211));
        };
    })());
};
var feature = function (v) {
    return Data_Maybe.maybe(v.value0)(function (v1) {
        return "(" + (v.value0 + (": " + (CSS_Property.plain(v1) + ")")));
    })(v.value1);
};
var mediaQuery = function (v) {
    return "@media " + (mediaType(v.value1) + foldl1(append)(map2(function ($212) {
        return (function (v1) {
            return " and " + v1;
        })(feature($212));
    })(v.value2)));
};
var eqSheet = {
    eq: function (x) {
        return function (y) {
            return x === y;
        };
    }
};
var ordSheet = {
    compare: function (x) {
        return function (y) {
            return compare(x)(y);
        };
    },
    Eq0: function () {
        return eqSheet;
    }
};
var eqInline = {
    eq: function (x) {
        return function (y) {
            return x === y;
        };
    }
};
var ordInline = {
    compare: function (x) {
        return function (y) {
            return compare(x)(y);
        };
    },
    Eq0: function () {
        return eqInline;
    }
};
var collect$prime = function (v) {
    return function (v1) {
        if (v instanceof CSS_Property.Plain && v1 instanceof CSS_Property.Plain) {
            return [ new Data_Either.Right(new Data_Tuple.Tuple(v.value0, v1.value0)) ];
        };
        if (v instanceof CSS_Property.Prefixed && v1 instanceof CSS_Property.Plain) {
            return map(function (v3) {
                return new Data_Either.Right(new Data_Tuple.Tuple(v3.value0 + v3.value1, v1.value0));
            })(v.value0);
        };
        if (v instanceof CSS_Property.Plain && v1 instanceof CSS_Property.Prefixed) {
            return map(function (v2) {
                return new Data_Either.Right(new Data_Tuple.Tuple(v.value0, v2.value0 + v2.value1));
            })(v1.value0);
        };
        if (v instanceof CSS_Property.Prefixed && v1 instanceof CSS_Property.Prefixed) {
            return map(function (v2) {
                return Data_Maybe.maybe(new Data_Either.Left(v2.value0 + v2.value1))((function () {
                    var $213 = Data_Tuple.Tuple.create(v2.value0 + v2.value1);
                    return function ($214) {
                        return Data_Either.Right.create($213((function (v3) {
                            return v2.value0 + v3;
                        })($214)));
                    };
                })())(lookup(v2.value0)(v1.value0));
            })(v.value0);
        };
        throw new Error("Failed pattern match at CSS.Render (line 158, column 1 - line 158, column 80): " + [ v.constructor.name, v1.constructor.name ]);
    };
};
var collect = function (v) {
    return collect$prime(v.value0)(v.value1);
};
var rule$prime = function (sel) {
    return function (props) {
        var p = bind1(props)(collect);
        var q = map1(function ($215) {
            return Data_These.This.create(Inline(properties(oneOf($215))));
        })(nel(p));
        var o = function (sel$prime) {
            return Data_Maybe.Just.create(Data_These.That.create(Sheet(intercalate(" ")([ selector(merger(sel$prime)), "{", properties(p), "}\x0a" ]))));
        };
        return Data_Maybe.maybe(q)(o)(nel(sel));
    };
};
var rules = function (sel) {
    return function (rs) {
        var queries = function (v) {
            if (v instanceof CSS_Stylesheet.Query) {
                return new Data_Maybe.Just(new Data_Tuple.Tuple(v.value0, v.value1));
            };
            return Data_Maybe.Nothing.value;
        };
        var queryRules = foldMap2(Data_Tuple.uncurry(Data_Function.flip(query$prime)(sel)))(Data_Array.mapMaybe(queries)(rs));
        var property = function (v) {
            if (v instanceof CSS_Stylesheet.Property) {
                return new Data_Maybe.Just(new Data_Tuple.Tuple(v.value0, v.value1));
            };
            return Data_Maybe.Nothing.value;
        };
        var topRules = (function () {
            var rs$prime = Data_Array.mapMaybe(property)(rs);
            var $193 = not(Data_Array["null"])(rs$prime);
            if ($193) {
                return rule$prime(sel)(rs$prime);
            };
            return Data_Maybe.Nothing.value;
        })();
        var nestedRules = function (a) {
            return rules(Data_Array.cons(a)(sel));
        };
        var nested = function (v) {
            if (v instanceof CSS_Stylesheet.Nested) {
                return new Data_Maybe.Just(new Data_Tuple.Tuple(v.value0, v.value1));
            };
            return Data_Maybe.Nothing.value;
        };
        var nestedSheets = fold2(map(Data_Tuple.uncurry(nestedRules))(Data_Array.mapMaybe(nested)(rs)));
        var kframes = function (v) {
            if (v instanceof CSS_Stylesheet.Keyframe) {
                return new Data_Maybe.Just(v.value0);
            };
            return Data_Maybe.Nothing.value;
        };
        var keyframeRules = foldMap2(kframe)(Data_Array.mapMaybe(kframes)(rs));
        var imports = function (v) {
            if (v instanceof CSS_Stylesheet.Import) {
                return new Data_Maybe.Just(v.value0);
            };
            return Data_Maybe.Nothing.value;
        };
        var importRules = foldMap2(imp)(Data_Array.mapMaybe(imports)(rs));
        var faces = function (v) {
            if (v instanceof CSS_Stylesheet.Face) {
                return new Data_Maybe.Just(v.value0);
            };
            return Data_Maybe.Nothing.value;
        };
        var faceRules = foldMap2(face)(Data_Array.mapMaybe(faces)(rs));
        return append2(topRules)(append2(importRules)(append2(keyframeRules)(append2(faceRules)(append2(nestedSheets)(queryRules)))));
    };
};
var query$prime = function (q) {
    return function (sel) {
        return function (rs) {
            return Data_Maybe.Just.create(Data_These.That.create(Sheet(mediaQuery(q) + (" { " + (Data_Maybe.fromMaybe("")(renderedSheet(rules(sel)(rs))) + " }\x0a")))));
        };
    };
};
var kframe = function (v) {
    var renderContent = " " + (v.value0 + (" { " + (intercalate1(" ")(map2(Data_Tuple.uncurry(frame))(v.value1)) + " }\x0a")));
    var keywords = [ "@keyframes", "@-webkit-keyframes", "@-moz-keyframes", "@-o-keyframes" ];
    var allKeywordsWithContent = fold1(map(function (v1) {
        return v1 + renderContent;
    })(keywords));
    return new Data_Maybe.Just(new Data_These.That(allKeywordsWithContent));
};
var frame = function (p) {
    return function (rs) {
        var x = Data_Maybe.fromMaybe("")(renderedInline(rules([  ])(rs)));
        return show(p) + ("% " + ("{ " + (x + " }")));
    };
};
var face = function (rs) {
    return Data_Maybe.Just.create(Data_These.That.create(Sheet("@font-face { " + (Data_Maybe.fromMaybe("")(renderedInline(rules([  ])(rs))) + " }\x0a"))));
};
var render = /* #__PURE__ */ (function () {
    var $216 = rules([  ]);
    return function ($217) {
        return $216(CSS_Stylesheet.runS($217));
    };
})();
var putInline = function (s) {
    return Effect_Console.log(Data_Maybe.fromMaybe("")(renderedInline(render(s))));
};
var putStyleSheet = function (s) {
    return Effect_Console.log(Data_Maybe.fromMaybe("")(renderedSheet(render(s))));
};
export {
    Inline,
    getInline,
    Sheet,
    getSheet,
    renderedInline,
    renderedSheet,
    render,
    putInline,
    putStyleSheet,
    kframe,
    frame,
    query$prime,
    mediaQuery,
    mediaType,
    feature,
    face,
    rules,
    imp,
    rule$prime,
    selector,
    selector$prime,
    selector$prime$prime,
    sepWith,
    collect,
    collect$prime,
    properties,
    merger,
    predicate,
    nel,
    eqInline,
    ordInline,
    semigroupInline,
    monoidInline,
    eqSheet,
    ordSheet,
    semigroupFile,
    monoidFile
};
