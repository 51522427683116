// Generated by purs version 0.15.4
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
var ErrorMessage = function (x) {
    return x;
};
var showErrorMessage = Data_Show.showString;
var errorMessageString = function (v) {
    return v;
};
var eqErrorMessage = Data_Eq.eqString;
var addHumanErrorMessage = function (h) {
    return function (i) {
        return new Data_Tuple.Tuple(i, h);
    };
};
var fetchingManifest = /* #__PURE__ */ addHumanErrorMessage("An error occurred fetching concepts. Please file an issue on GitHub!");
var lookingUpRouteConcept = /* #__PURE__ */ addHumanErrorMessage("Concept not found!");
var parsingConcept = /* #__PURE__ */ addHumanErrorMessage("Failed to parse this concept. Please file an issue in GitHub!");
export {
    ErrorMessage,
    errorMessageString,
    fetchingManifest,
    lookingUpRouteConcept,
    parsingConcept,
    showErrorMessage,
    eqErrorMessage
};
