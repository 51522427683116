// Generated by purs version 0.15.4
import * as CSS_Selector from "../CSS.Selector/index.js";
import * as CSS_String from "../CSS.String/index.js";
var fromString = /* #__PURE__ */ CSS_String.fromString(CSS_Selector.isStringSelector);
var $$var = /* #__PURE__ */ fromString("var");
var ul = /* #__PURE__ */ fromString("ul");
var u = /* #__PURE__ */ fromString("u");
var tr = /* #__PURE__ */ fromString("tr");
var thead = /* #__PURE__ */ fromString("thead");
var th = /* #__PURE__ */ fromString("th");
var tfoot = /* #__PURE__ */ fromString("tfoot");
var textarea = /* #__PURE__ */ fromString("textarea");
var td = /* #__PURE__ */ fromString("td");
var tbody = /* #__PURE__ */ fromString("tbody");
var table = /* #__PURE__ */ fromString("table");
var sup = /* #__PURE__ */ fromString("sup");
var summary = /* #__PURE__ */ fromString("summary");
var sub = /* #__PURE__ */ fromString("sub");
var strong = /* #__PURE__ */ fromString("strong");
var span = /* #__PURE__ */ fromString("span");
var small = /* #__PURE__ */ fromString("small");
var section = /* #__PURE__ */ fromString("section");
var samp = /* #__PURE__ */ fromString("samp");
var s = /* #__PURE__ */ fromString("s");
var q = /* #__PURE__ */ fromString("q");
var progress = /* #__PURE__ */ fromString("progress");
var pre = /* #__PURE__ */ fromString("pre");
var p = /* #__PURE__ */ fromString("p");
var output = /* #__PURE__ */ fromString("output");
var optgroup = /* #__PURE__ */ fromString("optgroup");
var ol = /* #__PURE__ */ fromString("ol");
var object = /* #__PURE__ */ fromString("object");
var nav = /* #__PURE__ */ fromString("nav");
var meter = /* #__PURE__ */ fromString("meter");
var mark = /* #__PURE__ */ fromString("mark");
var map = /* #__PURE__ */ fromString("map");
var main = /* #__PURE__ */ fromString("main");
var li = /* #__PURE__ */ fromString("li");
var legend = /* #__PURE__ */ fromString("legend");
var label = /* #__PURE__ */ fromString("label");
var kbd = /* #__PURE__ */ fromString("kbd");
var ins = /* #__PURE__ */ fromString("ins");
var input = /* #__PURE__ */ fromString("input");
var img = /* #__PURE__ */ fromString("img");
var iframe = /* #__PURE__ */ fromString("iframe");
var i = /* #__PURE__ */ fromString("i");
var html = /* #__PURE__ */ fromString("html");
var hr = /* #__PURE__ */ fromString("hr");
var header = /* #__PURE__ */ fromString("header");
var h6 = /* #__PURE__ */ fromString("h6");
var h5 = /* #__PURE__ */ fromString("h5");
var h4 = /* #__PURE__ */ fromString("h4");
var h3 = /* #__PURE__ */ fromString("h3");
var h2 = /* #__PURE__ */ fromString("h2");
var h1 = /* #__PURE__ */ fromString("h1");
var form = /* #__PURE__ */ fromString("form");
var footer = /* #__PURE__ */ fromString("footer");
var figure = /* #__PURE__ */ fromString("figure");
var figcaption = /* #__PURE__ */ fromString("figcaption");
var fieldset = /* #__PURE__ */ fromString("fieldset");
var embed = /* #__PURE__ */ fromString("embed");
var dt = /* #__PURE__ */ fromString("dt");
var dl = /* #__PURE__ */ fromString("dl");
var div = /* #__PURE__ */ fromString("div");
var dfn = /* #__PURE__ */ fromString("dfn");
var details = /* #__PURE__ */ fromString("details");
var del = /* #__PURE__ */ fromString("del");
var dd = /* #__PURE__ */ fromString("dd");
var datalist = /* #__PURE__ */ fromString("datalist");
var colgroup = /* #__PURE__ */ fromString("colgroup");
var col = /* #__PURE__ */ fromString("col");
var code = /* #__PURE__ */ fromString("code");
var cite = /* #__PURE__ */ fromString("cite");
var caption = /* #__PURE__ */ fromString("caption");
var canvas = /* #__PURE__ */ fromString("canvas");
var button = /* #__PURE__ */ fromString("button");
var br = /* #__PURE__ */ fromString("br");
var body = /* #__PURE__ */ fromString("body");
var blockquote = /* #__PURE__ */ fromString("blockquote");
var bdo = /* #__PURE__ */ fromString("bdo");
var bdi = /* #__PURE__ */ fromString("bdi");
var b = /* #__PURE__ */ fromString("b");
var audio = /* #__PURE__ */ fromString("audio");
var aside = /* #__PURE__ */ fromString("aside");
var article = /* #__PURE__ */ fromString("article");
var area = /* #__PURE__ */ fromString("area");
var address = /* #__PURE__ */ fromString("address");
var abbr = /* #__PURE__ */ fromString("abbr");
var a = /* #__PURE__ */ fromString("a");
export {
    a,
    abbr,
    address,
    area,
    article,
    aside,
    audio,
    b,
    bdi,
    bdo,
    blockquote,
    body,
    br,
    button,
    canvas,
    caption,
    cite,
    code,
    col,
    colgroup,
    datalist,
    dd,
    del,
    details,
    dfn,
    div,
    dl,
    dt,
    embed,
    fieldset,
    figcaption,
    figure,
    footer,
    form,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    header,
    hr,
    html,
    i,
    iframe,
    img,
    input,
    ins,
    kbd,
    label,
    legend,
    li,
    main,
    map,
    mark,
    meter,
    nav,
    object,
    ol,
    optgroup,
    output,
    p,
    pre,
    progress,
    q,
    s,
    samp,
    section,
    small,
    span,
    strong,
    sub,
    summary,
    sup,
    tbody,
    td,
    textarea,
    tfoot,
    table,
    th,
    thead,
    tr,
    u,
    ul,
    $$var as var
};
