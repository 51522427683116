// Generated by purs version 0.15.4
import * as CSS_Property from "../CSS.Property/index.js";
import * as CSS_String from "../CSS.String/index.js";
import * as CSS_Stylesheet from "../CSS.Stylesheet/index.js";
import * as Data_Ordering from "../Data.Ordering/index.js";
var fromString = /* #__PURE__ */ CSS_String.fromString(CSS_Property.isStringValue);
var Ltr = /* #__PURE__ */ (function () {
    function Ltr() {

    };
    Ltr.value = new Ltr();
    return Ltr;
})();
var Rtl = /* #__PURE__ */ (function () {
    function Rtl() {

    };
    Rtl.value = new Rtl();
    return Rtl;
})();
var valTextDirection = {
    value: function (v) {
        if (v instanceof Ltr) {
            return fromString("ltr");
        };
        if (v instanceof Rtl) {
            return fromString("rtl");
        };
        throw new Error("Failed pattern match at CSS.Text.Direction (line 13, column 1 - line 15, column 31): " + [ v.constructor.name ]);
    }
};
var rtl = /* #__PURE__ */ (function () {
    return Rtl.value;
})();
var ltr = /* #__PURE__ */ (function () {
    return Ltr.value;
})();
var eqTextDirection = {
    eq: function (x) {
        return function (y) {
            if (x instanceof Ltr && y instanceof Ltr) {
                return true;
            };
            if (x instanceof Rtl && y instanceof Rtl) {
                return true;
            };
            return false;
        };
    }
};
var ordTextDirection = {
    compare: function (x) {
        return function (y) {
            if (x instanceof Ltr && y instanceof Ltr) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Ltr) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Ltr) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Rtl && y instanceof Rtl) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at CSS.Text.Direction (line 0, column 0 - line 0, column 0): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqTextDirection;
    }
};
var direction = /* #__PURE__ */ CSS_Stylesheet.key(valTextDirection)(/* #__PURE__ */ CSS_String.fromString(CSS_Property.isStringKey)("direction"));
export {
    Ltr,
    Rtl,
    direction,
    ltr,
    rtl,
    eqTextDirection,
    ordTextDirection,
    valTextDirection
};
