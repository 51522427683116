// Generated by purs version 0.15.4
import * as CSS_Property from "../CSS.Property/index.js";
import * as CSS_String from "../CSS.String/index.js";
import * as CSS_Stylesheet from "../CSS.Stylesheet/index.js";
import * as Data_Ordering from "../Data.Ordering/index.js";
var fromString = /* #__PURE__ */ CSS_String.fromString(CSS_Property.isStringValue);
var Default = /* #__PURE__ */ (function () {
    function Default() {

    };
    Default.value = new Default();
    return Default;
})();
var Help = /* #__PURE__ */ (function () {
    function Help() {

    };
    Help.value = new Help();
    return Help;
})();
var Pointer = /* #__PURE__ */ (function () {
    function Pointer() {

    };
    Pointer.value = new Pointer();
    return Pointer;
})();
var Progress = /* #__PURE__ */ (function () {
    function Progress() {

    };
    Progress.value = new Progress();
    return Progress;
})();
var Wait = /* #__PURE__ */ (function () {
    function Wait() {

    };
    Wait.value = new Wait();
    return Wait;
})();
var Cell = /* #__PURE__ */ (function () {
    function Cell() {

    };
    Cell.value = new Cell();
    return Cell;
})();
var Crosshair = /* #__PURE__ */ (function () {
    function Crosshair() {

    };
    Crosshair.value = new Crosshair();
    return Crosshair;
})();
var Text = /* #__PURE__ */ (function () {
    function Text() {

    };
    Text.value = new Text();
    return Text;
})();
var VerticalText = /* #__PURE__ */ (function () {
    function VerticalText() {

    };
    VerticalText.value = new VerticalText();
    return VerticalText;
})();
var Alias = /* #__PURE__ */ (function () {
    function Alias() {

    };
    Alias.value = new Alias();
    return Alias;
})();
var Copy = /* #__PURE__ */ (function () {
    function Copy() {

    };
    Copy.value = new Copy();
    return Copy;
})();
var Move = /* #__PURE__ */ (function () {
    function Move() {

    };
    Move.value = new Move();
    return Move;
})();
var NoDrop = /* #__PURE__ */ (function () {
    function NoDrop() {

    };
    NoDrop.value = new NoDrop();
    return NoDrop;
})();
var NotAllowed = /* #__PURE__ */ (function () {
    function NotAllowed() {

    };
    NotAllowed.value = new NotAllowed();
    return NotAllowed;
})();
var Grab = /* #__PURE__ */ (function () {
    function Grab() {

    };
    Grab.value = new Grab();
    return Grab;
})();
var Grabbing = /* #__PURE__ */ (function () {
    function Grabbing() {

    };
    Grabbing.value = new Grabbing();
    return Grabbing;
})();
var AllScroll = /* #__PURE__ */ (function () {
    function AllScroll() {

    };
    AllScroll.value = new AllScroll();
    return AllScroll;
})();
var ColResize = /* #__PURE__ */ (function () {
    function ColResize() {

    };
    ColResize.value = new ColResize();
    return ColResize;
})();
var RowResize = /* #__PURE__ */ (function () {
    function RowResize() {

    };
    RowResize.value = new RowResize();
    return RowResize;
})();
var NResize = /* #__PURE__ */ (function () {
    function NResize() {

    };
    NResize.value = new NResize();
    return NResize;
})();
var EResize = /* #__PURE__ */ (function () {
    function EResize() {

    };
    EResize.value = new EResize();
    return EResize;
})();
var SResize = /* #__PURE__ */ (function () {
    function SResize() {

    };
    SResize.value = new SResize();
    return SResize;
})();
var WResize = /* #__PURE__ */ (function () {
    function WResize() {

    };
    WResize.value = new WResize();
    return WResize;
})();
var NEResize = /* #__PURE__ */ (function () {
    function NEResize() {

    };
    NEResize.value = new NEResize();
    return NEResize;
})();
var NWResize = /* #__PURE__ */ (function () {
    function NWResize() {

    };
    NWResize.value = new NWResize();
    return NWResize;
})();
var SEResize = /* #__PURE__ */ (function () {
    function SEResize() {

    };
    SEResize.value = new SEResize();
    return SEResize;
})();
var SWResize = /* #__PURE__ */ (function () {
    function SWResize() {

    };
    SWResize.value = new SWResize();
    return SWResize;
})();
var EWResize = /* #__PURE__ */ (function () {
    function EWResize() {

    };
    EWResize.value = new EWResize();
    return EWResize;
})();
var NSResize = /* #__PURE__ */ (function () {
    function NSResize() {

    };
    NSResize.value = new NSResize();
    return NSResize;
})();
var NESWResize = /* #__PURE__ */ (function () {
    function NESWResize() {

    };
    NESWResize.value = new NESWResize();
    return NESWResize;
})();
var NWSEResize = /* #__PURE__ */ (function () {
    function NWSEResize() {

    };
    NWSEResize.value = new NWSEResize();
    return NWSEResize;
})();
var ZoomIn = /* #__PURE__ */ (function () {
    function ZoomIn() {

    };
    ZoomIn.value = new ZoomIn();
    return ZoomIn;
})();
var ZoomOut = /* #__PURE__ */ (function () {
    function ZoomOut() {

    };
    ZoomOut.value = new ZoomOut();
    return ZoomOut;
})();
var zoomOut = /* #__PURE__ */ (function () {
    return ZoomOut.value;
})();
var zoomIn = /* #__PURE__ */ (function () {
    return ZoomIn.value;
})();
var wait = /* #__PURE__ */ (function () {
    return Wait.value;
})();
var wResize = /* #__PURE__ */ (function () {
    return WResize.value;
})();
var verticalText = /* #__PURE__ */ (function () {
    return VerticalText.value;
})();
var valCursor = {
    value: function (v) {
        if (v instanceof Default) {
            return fromString("default");
        };
        if (v instanceof Help) {
            return fromString("help");
        };
        if (v instanceof Pointer) {
            return fromString("pointer");
        };
        if (v instanceof Progress) {
            return fromString("progress");
        };
        if (v instanceof Wait) {
            return fromString("wait");
        };
        if (v instanceof Cell) {
            return fromString("cell");
        };
        if (v instanceof Crosshair) {
            return fromString("crosshair");
        };
        if (v instanceof Text) {
            return fromString("text");
        };
        if (v instanceof VerticalText) {
            return fromString("vertical-text");
        };
        if (v instanceof Alias) {
            return fromString("alias");
        };
        if (v instanceof Copy) {
            return fromString("copy");
        };
        if (v instanceof Move) {
            return fromString("move");
        };
        if (v instanceof NoDrop) {
            return fromString("no-drop");
        };
        if (v instanceof NotAllowed) {
            return fromString("not-allowed");
        };
        if (v instanceof Grab) {
            return fromString("grab");
        };
        if (v instanceof Grabbing) {
            return fromString("grabbing");
        };
        if (v instanceof AllScroll) {
            return fromString("all-scroll");
        };
        if (v instanceof ColResize) {
            return fromString("col-resize");
        };
        if (v instanceof RowResize) {
            return fromString("row-resize");
        };
        if (v instanceof NResize) {
            return fromString("n-resize");
        };
        if (v instanceof EResize) {
            return fromString("e-resize");
        };
        if (v instanceof SResize) {
            return fromString("s-resize");
        };
        if (v instanceof WResize) {
            return fromString("w-resize");
        };
        if (v instanceof NEResize) {
            return fromString("ne-resize");
        };
        if (v instanceof NWResize) {
            return fromString("nw-resize");
        };
        if (v instanceof SEResize) {
            return fromString("se-resize");
        };
        if (v instanceof SWResize) {
            return fromString("sw-resize");
        };
        if (v instanceof EWResize) {
            return fromString("ew-resize");
        };
        if (v instanceof NSResize) {
            return fromString("ns-resize");
        };
        if (v instanceof NESWResize) {
            return fromString("nesw-resize");
        };
        if (v instanceof NWSEResize) {
            return fromString("nwse-resize");
        };
        if (v instanceof ZoomIn) {
            return fromString("zoom-in");
        };
        if (v instanceof ZoomOut) {
            return fromString("zoom-out");
        };
        throw new Error("Failed pattern match at CSS.Cursor (line 47, column 1 - line 80, column 40): " + [ v.constructor.name ]);
    }
};
var text = /* #__PURE__ */ (function () {
    return Text.value;
})();
var swResize = /* #__PURE__ */ (function () {
    return SWResize.value;
})();
var seResize = /* #__PURE__ */ (function () {
    return SEResize.value;
})();
var sResize = /* #__PURE__ */ (function () {
    return SResize.value;
})();
var rowResize = /* #__PURE__ */ (function () {
    return RowResize.value;
})();
var progress = /* #__PURE__ */ (function () {
    return Progress.value;
})();
var pointer = /* #__PURE__ */ (function () {
    return Pointer.value;
})();
var nwseResize = /* #__PURE__ */ (function () {
    return NWSEResize.value;
})();
var nwResize = /* #__PURE__ */ (function () {
    return NWResize.value;
})();
var nsResize = /* #__PURE__ */ (function () {
    return NSResize.value;
})();
var notAllowed = /* #__PURE__ */ (function () {
    return NotAllowed.value;
})();
var noDrop = /* #__PURE__ */ (function () {
    return NoDrop.value;
})();
var neswResize = /* #__PURE__ */ (function () {
    return NESWResize.value;
})();
var neResize = /* #__PURE__ */ (function () {
    return NEResize.value;
})();
var nResize = /* #__PURE__ */ (function () {
    return NResize.value;
})();
var move = /* #__PURE__ */ (function () {
    return Move.value;
})();
var help = /* #__PURE__ */ (function () {
    return Help.value;
})();
var grabbing = /* #__PURE__ */ (function () {
    return Grabbing.value;
})();
var grab = /* #__PURE__ */ (function () {
    return Grab.value;
})();
var ewResize = /* #__PURE__ */ (function () {
    return EWResize.value;
})();
var eqCursor = {
    eq: function (x) {
        return function (y) {
            if (x instanceof Default && y instanceof Default) {
                return true;
            };
            if (x instanceof Help && y instanceof Help) {
                return true;
            };
            if (x instanceof Pointer && y instanceof Pointer) {
                return true;
            };
            if (x instanceof Progress && y instanceof Progress) {
                return true;
            };
            if (x instanceof Wait && y instanceof Wait) {
                return true;
            };
            if (x instanceof Cell && y instanceof Cell) {
                return true;
            };
            if (x instanceof Crosshair && y instanceof Crosshair) {
                return true;
            };
            if (x instanceof Text && y instanceof Text) {
                return true;
            };
            if (x instanceof VerticalText && y instanceof VerticalText) {
                return true;
            };
            if (x instanceof Alias && y instanceof Alias) {
                return true;
            };
            if (x instanceof Copy && y instanceof Copy) {
                return true;
            };
            if (x instanceof Move && y instanceof Move) {
                return true;
            };
            if (x instanceof NoDrop && y instanceof NoDrop) {
                return true;
            };
            if (x instanceof NotAllowed && y instanceof NotAllowed) {
                return true;
            };
            if (x instanceof Grab && y instanceof Grab) {
                return true;
            };
            if (x instanceof Grabbing && y instanceof Grabbing) {
                return true;
            };
            if (x instanceof AllScroll && y instanceof AllScroll) {
                return true;
            };
            if (x instanceof ColResize && y instanceof ColResize) {
                return true;
            };
            if (x instanceof RowResize && y instanceof RowResize) {
                return true;
            };
            if (x instanceof NResize && y instanceof NResize) {
                return true;
            };
            if (x instanceof EResize && y instanceof EResize) {
                return true;
            };
            if (x instanceof SResize && y instanceof SResize) {
                return true;
            };
            if (x instanceof WResize && y instanceof WResize) {
                return true;
            };
            if (x instanceof NEResize && y instanceof NEResize) {
                return true;
            };
            if (x instanceof NWResize && y instanceof NWResize) {
                return true;
            };
            if (x instanceof SEResize && y instanceof SEResize) {
                return true;
            };
            if (x instanceof SWResize && y instanceof SWResize) {
                return true;
            };
            if (x instanceof EWResize && y instanceof EWResize) {
                return true;
            };
            if (x instanceof NSResize && y instanceof NSResize) {
                return true;
            };
            if (x instanceof NESWResize && y instanceof NESWResize) {
                return true;
            };
            if (x instanceof NWSEResize && y instanceof NWSEResize) {
                return true;
            };
            if (x instanceof ZoomIn && y instanceof ZoomIn) {
                return true;
            };
            if (x instanceof ZoomOut && y instanceof ZoomOut) {
                return true;
            };
            return false;
        };
    }
};
var ordCursor = {
    compare: function (x) {
        return function (y) {
            if (x instanceof Default && y instanceof Default) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Default) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Default) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Help && y instanceof Help) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Help) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Help) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Pointer && y instanceof Pointer) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Pointer) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Pointer) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Progress && y instanceof Progress) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Progress) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Progress) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Wait && y instanceof Wait) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Wait) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Wait) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Cell && y instanceof Cell) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Cell) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Cell) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Crosshair && y instanceof Crosshair) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Crosshair) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Crosshair) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Text && y instanceof Text) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Text) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Text) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof VerticalText && y instanceof VerticalText) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof VerticalText) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof VerticalText) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Alias && y instanceof Alias) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Alias) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Alias) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Copy && y instanceof Copy) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Copy) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Copy) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Move && y instanceof Move) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Move) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Move) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof NoDrop && y instanceof NoDrop) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof NoDrop) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof NoDrop) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof NotAllowed && y instanceof NotAllowed) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof NotAllowed) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof NotAllowed) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Grab && y instanceof Grab) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Grab) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Grab) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Grabbing && y instanceof Grabbing) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Grabbing) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Grabbing) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AllScroll && y instanceof AllScroll) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AllScroll) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AllScroll) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ColResize && y instanceof ColResize) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ColResize) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ColResize) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof RowResize && y instanceof RowResize) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof RowResize) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof RowResize) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof NResize && y instanceof NResize) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof NResize) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof NResize) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof EResize && y instanceof EResize) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof EResize) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof EResize) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof SResize && y instanceof SResize) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof SResize) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof SResize) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof WResize && y instanceof WResize) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof WResize) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof WResize) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof NEResize && y instanceof NEResize) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof NEResize) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof NEResize) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof NWResize && y instanceof NWResize) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof NWResize) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof NWResize) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof SEResize && y instanceof SEResize) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof SEResize) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof SEResize) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof SWResize && y instanceof SWResize) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof SWResize) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof SWResize) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof EWResize && y instanceof EWResize) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof EWResize) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof EWResize) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof NSResize && y instanceof NSResize) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof NSResize) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof NSResize) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof NESWResize && y instanceof NESWResize) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof NESWResize) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof NESWResize) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof NWSEResize && y instanceof NWSEResize) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof NWSEResize) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof NWSEResize) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ZoomIn && y instanceof ZoomIn) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ZoomIn) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ZoomIn) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ZoomOut && y instanceof ZoomOut) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at CSS.Cursor (line 0, column 0 - line 0, column 0): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqCursor;
    }
};
var eResize = /* #__PURE__ */ (function () {
    return EResize.value;
})();
var $$default = /* #__PURE__ */ (function () {
    return Default.value;
})();
var cursor = /* #__PURE__ */ CSS_Stylesheet.key(valCursor)(/* #__PURE__ */ CSS_String.fromString(CSS_Property.isStringKey)("cursor"));
var crosshair = /* #__PURE__ */ (function () {
    return Crosshair.value;
})();
var copy = /* #__PURE__ */ (function () {
    return Copy.value;
})();
var colResize = /* #__PURE__ */ (function () {
    return ColResize.value;
})();
var cell = /* #__PURE__ */ (function () {
    return Cell.value;
})();
var allScroll = /* #__PURE__ */ (function () {
    return AllScroll.value;
})();
var alias = /* #__PURE__ */ (function () {
    return Alias.value;
})();
export {
    Default,
    Help,
    Pointer,
    Progress,
    Wait,
    Cell,
    Crosshair,
    Text,
    VerticalText,
    Alias,
    Copy,
    Move,
    NoDrop,
    NotAllowed,
    Grab,
    Grabbing,
    AllScroll,
    ColResize,
    RowResize,
    NResize,
    EResize,
    SResize,
    WResize,
    NEResize,
    NWResize,
    SEResize,
    SWResize,
    EWResize,
    NSResize,
    NESWResize,
    NWSEResize,
    ZoomIn,
    ZoomOut,
    cursor,
    $$default as default,
    help,
    pointer,
    progress,
    wait,
    cell,
    crosshair,
    text,
    verticalText,
    alias,
    copy,
    move,
    noDrop,
    notAllowed,
    grab,
    grabbing,
    allScroll,
    colResize,
    rowResize,
    nResize,
    eResize,
    sResize,
    wResize,
    neResize,
    nwResize,
    seResize,
    swResize,
    ewResize,
    nsResize,
    neswResize,
    nwseResize,
    zoomIn,
    zoomOut,
    eqCursor,
    ordCursor,
    valCursor
};
