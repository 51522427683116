// Generated by purs version 0.15.4
import * as Data_Codec_Argonaut from "../Data.Codec.Argonaut/index.js";
import * as Record from "../Record/index.js";
import * as Type_Equality from "../Type.Equality/index.js";
import * as Type_Proxy from "../Type.Proxy/index.js";
var from = /* #__PURE__ */ Type_Equality.from(Type_Equality.refl);
var rowListCodecNil = {
    rowListCodec: function (v) {
        return function (v1) {
            return Data_Codec_Argonaut.record;
        };
    }
};
var rowListCodec = function (dict) {
    return dict.rowListCodec;
};
var rowListCodecCons = function (dictRowListCodec) {
    var rowListCodec1 = rowListCodec(dictRowListCodec);
    return function () {
        return function () {
            return function (dictIsSymbol) {
                var get = Record.get(dictIsSymbol)();
                var recordProp = Data_Codec_Argonaut.recordProp(dictIsSymbol)();
                return function (dictTypeEquals) {
                    return {
                        rowListCodec: function (v) {
                            return function (codecs) {
                                var tail = rowListCodec1(Type_Proxy["Proxy"].value)(codecs);
                                var codec = from(get(Type_Proxy["Proxy"].value)(codecs));
                                return recordProp(Type_Proxy["Proxy"].value)(codec)(tail);
                            };
                        }
                    };
                };
            };
        };
    };
};
var record = function () {
    return function (dictRowListCodec) {
        return rowListCodec(dictRowListCodec)(Type_Proxy["Proxy"].value);
    };
};
var record1 = /* #__PURE__ */ record();
var object = function () {
    return function (dictRowListCodec) {
        var record2 = record1(dictRowListCodec);
        return function (name) {
            return function (rec) {
                return Data_Codec_Argonaut.object(name)(record2(rec));
            };
        };
    };
};
export {
    rowListCodec,
    object,
    record,
    rowListCodecNil,
    rowListCodecCons
};
