// Generated by purs version 0.15.4
import * as CSS_Property from "../CSS.Property/index.js";
import * as CSS_Selector from "../CSS.Selector/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Apply from "../Control.Apply/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_Writer from "../Control.Monad.Writer/index.js";
import * as Control_Monad_Writer_Class from "../Control.Monad.Writer.Class/index.js";
import * as Control_Monad_Writer_Trans from "../Control.Monad.Writer.Trans/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Identity from "../Data.Identity/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_NonEmpty from "../Data.NonEmpty/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Ordering from "../Data.Ordering/index.js";
import * as Data_Profunctor_Strong from "../Data.Profunctor.Strong/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
var map = /* #__PURE__ */ Data_Functor.map(/* #__PURE__ */ Data_NonEmpty.functorNonEmpty(Data_Functor.functorArray));
var second = /* #__PURE__ */ Data_Profunctor_Strong.second(Data_Profunctor_Strong.strongFn);
var map1 = /* #__PURE__ */ Data_Functor.map(/* #__PURE__ */ Control_Monad_Writer_Trans.functorWriterT(Data_Identity.functorIdentity));
var eq = /* #__PURE__ */ Data_Eq.eq(CSS_Property.eqValue);
var compare = /* #__PURE__ */ Data_Ord.compare(CSS_Property.ordValue);
var eq2 = /* #__PURE__ */ Data_Eq.eq(/* #__PURE__ */ Data_Maybe.eqMaybe(CSS_Property.eqValue));
var eqNonEmpty = /* #__PURE__ */ Data_NonEmpty.eqNonEmpty(Data_Eq.eq1Array);
var compare1 = /* #__PURE__ */ Data_Ord.compare(Data_Ord.ordString);
var compare2 = /* #__PURE__ */ Data_Ord.compare(/* #__PURE__ */ Data_Maybe.ordMaybe(CSS_Property.ordValue));
var ordNonEmpty = /* #__PURE__ */ Data_NonEmpty.ordNonEmpty(Data_Ord.ord1Array);
var eq3 = /* #__PURE__ */ Data_Eq.eq(CSS_Selector.eqRefinement);
var eq4 = /* #__PURE__ */ Data_Eq.eq(CSS_Selector.eqSelector);
var eq6 = /* #__PURE__ */ Data_Eq.eq(/* #__PURE__ */ CSS_Property.eqKey(Data_Eq.eqUnit));
var eqTuple = /* #__PURE__ */ Data_Tuple.eqTuple(Data_Eq.eqNumber);
var compare3 = /* #__PURE__ */ Data_Ord.compare(CSS_Selector.ordRefinement);
var compare4 = /* #__PURE__ */ Data_Ord.compare(CSS_Selector.ordSelector);
var compare5 = /* #__PURE__ */ Data_Ord.compare(Data_Ord.ordInt);
var compare6 = /* #__PURE__ */ Data_Ord.compare(/* #__PURE__ */ CSS_Property.ordKey(Data_Ord.ordUnit));
var ordTuple = /* #__PURE__ */ Data_Tuple.ordTuple(Data_Ord.ordNumber);
var apply = /* #__PURE__ */ Control_Apply.apply(/* #__PURE__ */ Control_Monad_Writer_Trans.applyWriterT(Data_Semigroup.semigroupArray)(Data_Identity.applyIdentity));
var bind = /* #__PURE__ */ Control_Bind.bind(/* #__PURE__ */ Control_Monad_Writer_Trans.bindWriterT(Data_Semigroup.semigroupArray)(Data_Identity.bindIdentity));
var Not = /* #__PURE__ */ (function () {
    function Not() {

    };
    Not.value = new Not();
    return Not;
})();
var Only = /* #__PURE__ */ (function () {
    function Only() {

    };
    Only.value = new Only();
    return Only;
})();
var MediaType = function (x) {
    return x;
};
var Feature = /* #__PURE__ */ (function () {
    function Feature(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Feature.create = function (value0) {
        return function (value1) {
            return new Feature(value0, value1);
        };
    };
    return Feature;
})();
var MediaQuery = /* #__PURE__ */ (function () {
    function MediaQuery(value0, value1, value2) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
    };
    MediaQuery.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return new MediaQuery(value0, value1, value2);
            };
        };
    };
    return MediaQuery;
})();
var Self = /* #__PURE__ */ (function () {
    function Self(value0) {
        this.value0 = value0;
    };
    Self.create = function (value0) {
        return new Self(value0);
    };
    return Self;
})();
var Root = /* #__PURE__ */ (function () {
    function Root(value0) {
        this.value0 = value0;
    };
    Root.create = function (value0) {
        return new Root(value0);
    };
    return Root;
})();
var Pop = /* #__PURE__ */ (function () {
    function Pop(value0) {
        this.value0 = value0;
    };
    Pop.create = function (value0) {
        return new Pop(value0);
    };
    return Pop;
})();
var Child = /* #__PURE__ */ (function () {
    function Child(value0) {
        this.value0 = value0;
    };
    Child.create = function (value0) {
        return new Child(value0);
    };
    return Child;
})();
var Sub = /* #__PURE__ */ (function () {
    function Sub(value0) {
        this.value0 = value0;
    };
    Sub.create = function (value0) {
        return new Sub(value0);
    };
    return Sub;
})();
var Property = /* #__PURE__ */ (function () {
    function Property(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Property.create = function (value0) {
        return function (value1) {
            return new Property(value0, value1);
        };
    };
    return Property;
})();
var Nested = /* #__PURE__ */ (function () {
    function Nested(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Nested.create = function (value0) {
        return function (value1) {
            return new Nested(value0, value1);
        };
    };
    return Nested;
})();
var Query = /* #__PURE__ */ (function () {
    function Query(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Query.create = function (value0) {
        return function (value1) {
            return new Query(value0, value1);
        };
    };
    return Query;
})();
var Face = /* #__PURE__ */ (function () {
    function Face(value0) {
        this.value0 = value0;
    };
    Face.create = function (value0) {
        return new Face(value0);
    };
    return Face;
})();
var Keyframe = /* #__PURE__ */ (function () {
    function Keyframe(value0) {
        this.value0 = value0;
    };
    Keyframe.create = function (value0) {
        return new Keyframe(value0);
    };
    return Keyframe;
})();
var Import = /* #__PURE__ */ (function () {
    function Import(value0) {
        this.value0 = value0;
    };
    Import.create = function (value0) {
        return new Import(value0);
    };
    return Import;
})();
var Keyframes = /* #__PURE__ */ (function () {
    function Keyframes(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Keyframes.create = function (value0) {
        return function (value1) {
            return new Keyframes(value0, value1);
        };
    };
    return Keyframes;
})();
var S = function (x) {
    return x;
};
var runS = function (v) {
    return Control_Monad_Writer.execWriter(v);
};
var rule = /* #__PURE__ */ (function () {
    var $340 = Control_Monad_Writer_Class.tell(Control_Monad_Writer_Trans.monadTellWriterT(Data_Monoid.monoidArray)(Data_Identity.monadIdentity));
    return function ($341) {
        return S($340(Data_Array.singleton($341)));
    };
})();
var select = function (sel) {
    return function (rs) {
        return rule(new Nested(new Sub(sel), runS(rs)));
    };
};
var query = function (ty) {
    return function (fs) {
        var $342 = Query.create(new MediaQuery(Data_Maybe.Nothing.value, ty, fs));
        return function ($343) {
            return rule($342(runS($343)));
        };
    };
};
var keyframes = function (n) {
    return function (xs) {
        return rule(new Keyframe(new Keyframes(n, map(second(runS))(xs))));
    };
};
var keyframesFromTo = function (n) {
    return function (a) {
        return function (b) {
            return keyframes(n)(new Data_NonEmpty.NonEmpty(new Data_Tuple.Tuple(0.0, a), [ new Data_Tuple.Tuple(100.0, b) ]));
        };
    };
};
var key = function (dictVal) {
    var value = CSS_Property.value(dictVal);
    return function (k) {
        return function (v) {
            return rule(new Property(CSS_Property.cast(k), value(v)));
        };
    };
};
var prefixed = function (dictVal) {
    var key1 = key(dictVal);
    return function (xs) {
        return key1(xs);
    };
};
var importUrl = function ($344) {
    return rule(Import.create($344));
};
var functorStyleM = {
    map: function (f) {
        return function (v) {
            return map1(f)(v);
        };
    }
};
var fontFace = function ($345) {
    return rule(Face.create(runS($345)));
};
var eqNotOrOnly = {
    eq: function (x) {
        return function (y) {
            if (x instanceof Not && y instanceof Not) {
                return true;
            };
            if (x instanceof Only && y instanceof Only) {
                return true;
            };
            return false;
        };
    }
};
var eq7 = /* #__PURE__ */ Data_Eq.eq(/* #__PURE__ */ Data_Maybe.eqMaybe(eqNotOrOnly));
var ordNotOrOnly = {
    compare: function (x) {
        return function (y) {
            if (x instanceof Not && y instanceof Not) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Not) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Not) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Only && y instanceof Only) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at CSS.Stylesheet (line 0, column 0 - line 0, column 0): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqNotOrOnly;
    }
};
var compare7 = /* #__PURE__ */ Data_Ord.compare(/* #__PURE__ */ Data_Maybe.ordMaybe(ordNotOrOnly));
var eqMediaType = {
    eq: function (x) {
        return function (y) {
            return eq(x)(y);
        };
    }
};
var eq8 = /* #__PURE__ */ Data_Eq.eq(eqMediaType);
var ordMediaType = {
    compare: function (x) {
        return function (y) {
            return compare(x)(y);
        };
    },
    Eq0: function () {
        return eqMediaType;
    }
};
var compare8 = /* #__PURE__ */ Data_Ord.compare(ordMediaType);
var eqFeature = {
    eq: function (x) {
        return function (y) {
            return x.value0 === y.value0 && eq2(x.value1)(y.value1);
        };
    }
};
var eq9 = /* #__PURE__ */ Data_Eq.eq(/* #__PURE__ */ eqNonEmpty(eqFeature));
var eqMediaQuery = {
    eq: function (x) {
        return function (y) {
            return eq7(x.value0)(y.value0) && eq8(x.value1)(y.value1) && eq9(x.value2)(y.value2);
        };
    }
};
var eq10 = /* #__PURE__ */ Data_Eq.eq(eqMediaQuery);
var ordFeature = {
    compare: function (x) {
        return function (y) {
            var v = compare1(x.value0)(y.value0);
            if (v instanceof Data_Ordering.LT) {
                return Data_Ordering.LT.value;
            };
            if (v instanceof Data_Ordering.GT) {
                return Data_Ordering.GT.value;
            };
            return compare2(x.value1)(y.value1);
        };
    },
    Eq0: function () {
        return eqFeature;
    }
};
var compare9 = /* #__PURE__ */ Data_Ord.compare(/* #__PURE__ */ ordNonEmpty(ordFeature));
var ordMediaQuery = {
    compare: function (x) {
        return function (y) {
            var v = compare7(x.value0)(y.value0);
            if (v instanceof Data_Ordering.LT) {
                return Data_Ordering.LT.value;
            };
            if (v instanceof Data_Ordering.GT) {
                return Data_Ordering.GT.value;
            };
            var v1 = compare8(x.value1)(y.value1);
            if (v1 instanceof Data_Ordering.LT) {
                return Data_Ordering.LT.value;
            };
            if (v1 instanceof Data_Ordering.GT) {
                return Data_Ordering.GT.value;
            };
            return compare9(x.value2)(y.value2);
        };
    },
    Eq0: function () {
        return eqMediaQuery;
    }
};
var compare10 = /* #__PURE__ */ Data_Ord.compare(ordMediaQuery);
var eqApp = {
    eq: function (x) {
        return function (y) {
            if (x instanceof Self && y instanceof Self) {
                return eq3(x.value0)(y.value0);
            };
            if (x instanceof Root && y instanceof Root) {
                return eq4(x.value0)(y.value0);
            };
            if (x instanceof Pop && y instanceof Pop) {
                return x.value0 === y.value0;
            };
            if (x instanceof Child && y instanceof Child) {
                return eq4(x.value0)(y.value0);
            };
            if (x instanceof Sub && y instanceof Sub) {
                return eq4(x.value0)(y.value0);
            };
            return false;
        };
    }
};
var eq11 = /* #__PURE__ */ Data_Eq.eq(eqApp);
var eqRule = {
    eq: function (x) {
        return function (y) {
            if (x instanceof Property && y instanceof Property) {
                return eq6(x.value0)(y.value0) && eq(x.value1)(y.value1);
            };
            if (x instanceof Nested && y instanceof Nested) {
                return eq11(x.value0)(y.value0) && Data_Eq.eq(Data_Eq.eqArray(eqRule))(x.value1)(y.value1);
            };
            if (x instanceof Query && y instanceof Query) {
                return eq10(x.value0)(y.value0) && Data_Eq.eq(Data_Eq.eqArray(eqRule))(x.value1)(y.value1);
            };
            if (x instanceof Face && y instanceof Face) {
                return Data_Eq.eq(Data_Eq.eqArray(eqRule))(x.value0)(y.value0);
            };
            if (x instanceof Keyframe && y instanceof Keyframe) {
                return Data_Eq.eq(eqKeyframes)(x.value0)(y.value0);
            };
            if (x instanceof Import && y instanceof Import) {
                return x.value0 === y.value0;
            };
            return false;
        };
    }
};
var eqKeyframes = {
    eq: function (x) {
        return function (y) {
            return x.value0 === y.value0 && Data_Eq.eq(eqNonEmpty(eqTuple(Data_Eq.eqArray(eqRule))))(x.value1)(y.value1);
        };
    }
};
var ordApp = {
    compare: function (x) {
        return function (y) {
            if (x instanceof Self && y instanceof Self) {
                return compare3(x.value0)(y.value0);
            };
            if (x instanceof Self) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Self) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Root && y instanceof Root) {
                return compare4(x.value0)(y.value0);
            };
            if (x instanceof Root) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Root) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Pop && y instanceof Pop) {
                return compare5(x.value0)(y.value0);
            };
            if (x instanceof Pop) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Pop) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Child && y instanceof Child) {
                return compare4(x.value0)(y.value0);
            };
            if (x instanceof Child) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Child) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Sub && y instanceof Sub) {
                return compare4(x.value0)(y.value0);
            };
            throw new Error("Failed pattern match at CSS.Stylesheet (line 0, column 0 - line 0, column 0): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqApp;
    }
};
var compare11 = /* #__PURE__ */ Data_Ord.compare(ordApp);
var ordRule = {
    compare: function (x) {
        return function (y) {
            if (x instanceof Property && y instanceof Property) {
                var v = compare6(x.value0)(y.value0);
                if (v instanceof Data_Ordering.LT) {
                    return Data_Ordering.LT.value;
                };
                if (v instanceof Data_Ordering.GT) {
                    return Data_Ordering.GT.value;
                };
                return compare(x.value1)(y.value1);
            };
            if (x instanceof Property) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Property) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Nested && y instanceof Nested) {
                var v = compare11(x.value0)(y.value0);
                if (v instanceof Data_Ordering.LT) {
                    return Data_Ordering.LT.value;
                };
                if (v instanceof Data_Ordering.GT) {
                    return Data_Ordering.GT.value;
                };
                return Data_Ord.compare(Data_Ord.ordArray(ordRule))(x.value1)(y.value1);
            };
            if (x instanceof Nested) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Nested) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Query && y instanceof Query) {
                var v = compare10(x.value0)(y.value0);
                if (v instanceof Data_Ordering.LT) {
                    return Data_Ordering.LT.value;
                };
                if (v instanceof Data_Ordering.GT) {
                    return Data_Ordering.GT.value;
                };
                return Data_Ord.compare(Data_Ord.ordArray(ordRule))(x.value1)(y.value1);
            };
            if (x instanceof Query) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Query) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Face && y instanceof Face) {
                return Data_Ord.compare(Data_Ord.ordArray(ordRule))(x.value0)(y.value0);
            };
            if (x instanceof Face) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Face) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Keyframe && y instanceof Keyframe) {
                return Data_Ord.compare(ordKeyframes)(x.value0)(y.value0);
            };
            if (x instanceof Keyframe) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Keyframe) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Import && y instanceof Import) {
                return compare1(x.value0)(y.value0);
            };
            throw new Error("Failed pattern match at CSS.Stylesheet (line 0, column 0 - line 0, column 0): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqRule;
    }
};
var ordKeyframes = {
    compare: function (x) {
        return function (y) {
            var v = compare1(x.value0)(y.value0);
            if (v instanceof Data_Ordering.LT) {
                return Data_Ordering.LT.value;
            };
            if (v instanceof Data_Ordering.GT) {
                return Data_Ordering.GT.value;
            };
            return Data_Ord.compare(ordNonEmpty(ordTuple(Data_Ord.ordArray(ordRule))))(x.value1)(y.value1);
        };
    },
    Eq0: function () {
        return eqKeyframes;
    }
};
var applyStyleM = {
    apply: function (v) {
        return function (v1) {
            return apply(v)(v1);
        };
    },
    Functor0: function () {
        return functorStyleM;
    }
};
var bindStyleM = {
    bind: function (v) {
        return function (f) {
            return bind(v)(function ($346) {
                return (function (v1) {
                    return v1;
                })(f($346));
            });
        };
    },
    Apply0: function () {
        return applyStyleM;
    }
};
var semigroupCSS = {
    append: /* #__PURE__ */ Control_Apply.applySecond(applyStyleM)
};
var applicativeStyleM = {
    pure: /* #__PURE__ */ (function () {
        var $347 = Control_Applicative.pure(Control_Monad_Writer_Trans.applicativeWriterT(Data_Monoid.monoidArray)(Data_Identity.applicativeIdentity));
        return function ($348) {
            return S($347($348));
        };
    })(),
    Apply0: function () {
        return applyStyleM;
    }
};
var monadStyleM = {
    Applicative0: function () {
        return applicativeStyleM;
    },
    Bind1: function () {
        return bindStyleM;
    }
};
export {
    MediaType,
    Not,
    Only,
    MediaQuery,
    Feature,
    Self,
    Root,
    Pop,
    Child,
    Sub,
    Keyframes,
    Property,
    Nested,
    Query,
    Face,
    Keyframe,
    Import,
    S,
    runS,
    rule,
    key,
    prefixed,
    select,
    query,
    keyframes,
    keyframesFromTo,
    fontFace,
    importUrl,
    eqMediaType,
    ordMediaType,
    eqNotOrOnly,
    ordNotOrOnly,
    eqMediaQuery,
    ordMediaQuery,
    eqFeature,
    ordFeature,
    eqApp,
    ordApp,
    eqKeyframes,
    ordKeyframes,
    eqRule,
    ordRule,
    functorStyleM,
    applyStyleM,
    bindStyleM,
    applicativeStyleM,
    monadStyleM,
    semigroupCSS
};
