// Generated by purs version 0.15.4
import * as Data_Generic_Rep from "../Data.Generic.Rep/index.js";
import * as Data_Show_Generic from "../Data.Show.Generic/index.js";
var genericShowConstructor = /* #__PURE__ */ Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsNoArguments);
var Expanded = /* #__PURE__ */ (function () {
    function Expanded() {

    };
    Expanded.value = new Expanded();
    return Expanded;
})();
var Collapsed = /* #__PURE__ */ (function () {
    function Collapsed() {

    };
    Collapsed.value = new Collapsed();
    return Collapsed;
})();
var toggle = function (v) {
    if (v instanceof Expanded) {
        return Collapsed.value;
    };
    if (v instanceof Collapsed) {
        return Expanded.value;
    };
    throw new Error("Failed pattern match at Data.Expanded (line 16, column 1 - line 16, column 31): " + [ v.constructor.name ]);
};
var genericExpanded = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return Expanded.value;
        };
        if (x instanceof Data_Generic_Rep.Inr) {
            return Collapsed.value;
        };
        throw new Error("Failed pattern match at Data.Expanded (line 10, column 1 - line 10, column 54): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof Expanded) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof Collapsed) {
            return new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value);
        };
        throw new Error("Failed pattern match at Data.Expanded (line 10, column 1 - line 10, column 54): " + [ x.constructor.name ]);
    }
};
var showExpanded = {
    show: /* #__PURE__ */ Data_Show_Generic.genericShow(genericExpanded)(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "Expanded";
        }
    }))(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "Collapsed";
        }
    })))
};
var eqExpanded = {
    eq: function (x) {
        return function (y) {
            if (x instanceof Expanded && y instanceof Expanded) {
                return true;
            };
            if (x instanceof Collapsed && y instanceof Collapsed) {
                return true;
            };
            return false;
        };
    }
};
export {
    Expanded,
    Collapsed,
    toggle,
    genericExpanded,
    showExpanded,
    eqExpanded
};
