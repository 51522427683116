// Generated by purs version 0.15.4
import * as Control_Category from "../Control.Category/index.js";
import * as Data_Boolean from "../Data.Boolean/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_EuclideanRing from "../Data.EuclideanRing/index.js";
import * as Data_Generic_Rep from "../Data.Generic.Rep/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Ring from "../Data.Ring/index.js";
import * as Data_Semiring from "../Data.Semiring/index.js";
import * as Data_Show_Generic from "../Data.Show.Generic/index.js";
var identity = /* #__PURE__ */ Control_Category.identity(Control_Category.categoryFn);
var M3x3IsSymbol = {
    reflectSymbol: function () {
        return "M3x3";
    }
};
var M2x2IsSymbol = {
    reflectSymbol: function () {
        return "M2x2";
    }
};
var M1x3IsSymbol = {
    reflectSymbol: function () {
        return "M1x3";
    }
};
var M3x3 = /* #__PURE__ */ (function () {
    function M3x3(value0, value1, value2, value3, value4, value5, value6, value7, value8) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
        this.value3 = value3;
        this.value4 = value4;
        this.value5 = value5;
        this.value6 = value6;
        this.value7 = value7;
        this.value8 = value8;
    };
    M3x3.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return function (value3) {
                    return function (value4) {
                        return function (value5) {
                            return function (value6) {
                                return function (value7) {
                                    return function (value8) {
                                        return new M3x3(value0, value1, value2, value3, value4, value5, value6, value7, value8);
                                    };
                                };
                            };
                        };
                    };
                };
            };
        };
    };
    return M3x3;
})();
var M2x2 = /* #__PURE__ */ (function () {
    function M2x2(value0, value1, value2, value3) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
        this.value3 = value3;
    };
    M2x2.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return function (value3) {
                    return new M2x2(value0, value1, value2, value3);
                };
            };
        };
    };
    return M2x2;
})();
var M1x3 = /* #__PURE__ */ (function () {
    function M1x3(value0, value1, value2) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
    };
    M1x3.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return new M1x3(value0, value1, value2);
            };
        };
    };
    return M1x3;
})();
var transpose3x3 = function (v) {
    return new M3x3(v.value0, v.value3, v.value6, v.value1, v.value4, v.value7, v.value2, v.value5, v.value8);
};
var mulScalar3x3 = function (dictSemiring) {
    var mul = Data_Semiring.mul(dictSemiring);
    return function (x) {
        return function (v) {
            return new M3x3(mul(v.value0)(x), mul(v.value1)(x), mul(v.value2)(x), mul(v.value3)(x), mul(v.value4)(x), mul(v.value5)(x), mul(v.value6)(x), mul(v.value7)(x), mul(v.value8)(x));
        };
    };
};
var mul3x3_3x3 = function (v) {
    return identity;
};
var mul3x3_1x3 = function (dictSemiring) {
    var add = Data_Semiring.add(dictSemiring);
    var mul = Data_Semiring.mul(dictSemiring);
    return function (v) {
        return function (v1) {
            return new M1x3(add(add(mul(v.value0)(v1.value0))(mul(v.value1)(v1.value1)))(mul(v.value2)(v1.value2)), add(add(mul(v.value3)(v1.value0))(mul(v.value4)(v1.value1)))(mul(v.value5)(v1.value2)), add(add(mul(v.value6)(v1.value0))(mul(v.value7)(v1.value1)))(mul(v.value8)(v1.value2)));
        };
    };
};
var genericM3x3 = function (dictShow) {
    return {
        to: function (x) {
            return new M3x3(x.value0, x.value1.value0, x.value1.value1.value0, x.value1.value1.value1.value0, x.value1.value1.value1.value1.value0, x.value1.value1.value1.value1.value1.value0, x.value1.value1.value1.value1.value1.value1.value0, x.value1.value1.value1.value1.value1.value1.value1.value0, x.value1.value1.value1.value1.value1.value1.value1.value1);
        },
        from: function (x) {
            return new Data_Generic_Rep.Product(x.value0, new Data_Generic_Rep.Product(x.value1, new Data_Generic_Rep.Product(x.value2, new Data_Generic_Rep.Product(x.value3, new Data_Generic_Rep.Product(x.value4, new Data_Generic_Rep.Product(x.value5, new Data_Generic_Rep.Product(x.value6, new Data_Generic_Rep.Product(x.value7, x.value8))))))));
        }
    };
};
var showM3x3 = function (dictShow) {
    var genericShowArgsArgument = Data_Show_Generic.genericShowArgsArgument(dictShow);
    var genericShowArgsProduct = Data_Show_Generic.genericShowArgsProduct(genericShowArgsArgument);
    return {
        show: Data_Show_Generic.genericShow(genericM3x3(dictShow))(Data_Show_Generic.genericShowConstructor(genericShowArgsProduct(genericShowArgsProduct(genericShowArgsProduct(genericShowArgsProduct(genericShowArgsProduct(genericShowArgsProduct(genericShowArgsProduct(genericShowArgsProduct(genericShowArgsArgument)))))))))(M3x3IsSymbol))
    };
};
var genericM2x2 = {
    to: function (x) {
        return new M2x2(x.value0, x.value1.value0, x.value1.value1.value0, x.value1.value1.value1);
    },
    from: function (x) {
        return new Data_Generic_Rep.Product(x.value0, new Data_Generic_Rep.Product(x.value1, new Data_Generic_Rep.Product(x.value2, x.value3)));
    }
};
var genericShow = /* #__PURE__ */ Data_Show_Generic.genericShow(genericM2x2);
var showM2x2 = function (dictShow) {
    var genericShowArgsArgument = Data_Show_Generic.genericShowArgsArgument(dictShow);
    var genericShowArgsProduct = Data_Show_Generic.genericShowArgsProduct(genericShowArgsArgument);
    return {
        show: genericShow(Data_Show_Generic.genericShowConstructor(genericShowArgsProduct(genericShowArgsProduct(genericShowArgsProduct(genericShowArgsArgument))))(M2x2IsSymbol))
    };
};
var genericM1x3 = {
    to: function (x) {
        return new M1x3(x.value0, x.value1.value0, x.value1.value1);
    },
    from: function (x) {
        return new Data_Generic_Rep.Product(x.value0, new Data_Generic_Rep.Product(x.value1, x.value2));
    }
};
var genericShow1 = /* #__PURE__ */ Data_Show_Generic.genericShow(genericM1x3);
var showM1x3 = function (dictShow) {
    var genericShowArgsArgument = Data_Show_Generic.genericShowArgsArgument(dictShow);
    var genericShowArgsProduct = Data_Show_Generic.genericShowArgsProduct(genericShowArgsArgument);
    return {
        show: genericShow1(Data_Show_Generic.genericShowConstructor(genericShowArgsProduct(genericShowArgsProduct(genericShowArgsArgument)))(M1x3IsSymbol))
    };
};
var det2x2 = function (dictRing) {
    var sub = Data_Ring.sub(dictRing);
    var mul = Data_Semiring.mul(dictRing.Semiring0());
    return function (v) {
        return sub(mul(v.value0)(v.value3))(mul(v.value1)(v.value2));
    };
};
var det2x2$prime = function (dictRing) {
    var det2x21 = det2x2(dictRing);
    return function (a) {
        return function (b) {
            return function (c) {
                return function (d) {
                    return det2x21(new M2x2(a, b, c, d));
                };
            };
        };
    };
};
var det3x3 = function (dictRing) {
    var Semiring0 = dictRing.Semiring0();
    var add = Data_Semiring.add(Semiring0);
    var sub = Data_Ring.sub(dictRing);
    var mul = Data_Semiring.mul(Semiring0);
    var det2x2$prime1 = det2x2$prime(dictRing);
    return function (v) {
        return add(sub(mul(v.value0)(det2x2$prime1(v.value4)(v.value5)(v.value7)(v.value8)))(mul(v.value1)(det2x2$prime1(v.value3)(v.value5)(v.value6)(v.value8))))(mul(v.value2)(det2x2$prime1(v.value3)(v.value4)(v.value6)(v.value7)));
    };
};
var cofactor3x3 = function (dictRing) {
    var det2x2$prime1 = det2x2$prime(dictRing);
    var negate = Data_Ring.negate(dictRing);
    return function (v) {
        return new M3x3(det2x2$prime1(v.value4)(v.value5)(v.value7)(v.value8), negate(det2x2$prime1(v.value3)(v.value5)(v.value6)(v.value8)), det2x2$prime1(v.value3)(v.value4)(v.value6)(v.value7), negate(det2x2$prime1(v.value1)(v.value2)(v.value7)(v.value8)), det2x2$prime1(v.value0)(v.value2)(v.value6)(v.value8), negate(det2x2$prime1(v.value0)(v.value1)(v.value6)(v.value7)), det2x2$prime1(v.value1)(v.value2)(v.value4)(v.value5), negate(det2x2$prime1(v.value0)(v.value2)(v.value3)(v.value5)), det2x2$prime1(v.value0)(v.value1)(v.value3)(v.value4));
    };
};
var adjugate3x3 = function (dictRing) {
    var $211 = cofactor3x3(dictRing);
    return function ($212) {
        return transpose3x3($211($212));
    };
};
var inverse3x3 = function (dictEq) {
    var eq = Data_Eq.eq(dictEq);
    return function (dictEuclideanRing) {
        var Ring0 = (dictEuclideanRing.CommutativeRing0()).Ring0();
        var det3x31 = det3x3(Ring0);
        var Semiring0 = Ring0.Semiring0();
        var zero = Data_Semiring.zero(Semiring0);
        var mulScalar3x31 = mulScalar3x3(Semiring0);
        var div = Data_EuclideanRing.div(dictEuclideanRing);
        var one = Data_Semiring.one(Semiring0);
        var adjugate3x31 = adjugate3x3(Ring0);
        return function (m) {
            if (eq(det3x31(m))(zero)) {
                return Data_Maybe.Nothing.value;
            };
            if (Data_Boolean.otherwise) {
                return new Data_Maybe.Just(mulScalar3x31(div(one)(det3x31(m)))(adjugate3x31(m)));
            };
            throw new Error("Failed pattern match at Data.Mat (line 97, column 1 - line 97, column 76): " + [ m.constructor.name ]);
        };
    };
};
export {
    M1x3,
    M2x2,
    M3x3,
    mul3x3_3x3,
    mul3x3_1x3,
    mulScalar3x3,
    det2x2,
    det2x2$prime,
    det3x3,
    transpose3x3,
    cofactor3x3,
    adjugate3x3,
    inverse3x3,
    genericM1x3,
    showM1x3,
    genericM2x2,
    showM2x2,
    genericM3x3,
    showM3x3
};
