// Generated by purs version 0.15.4
import * as CSS_Property from "../CSS.Property/index.js";
import * as CSS_Size from "../CSS.Size/index.js";
import * as CSS_String from "../CSS.String/index.js";
import * as CSS_Stylesheet from "../CSS.Stylesheet/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
var value = /* #__PURE__ */ CSS_Property.value(CSS_Size.valSize);
var fromString = /* #__PURE__ */ CSS_String.fromString(CSS_Property.isStringValue);
var append = /* #__PURE__ */ Data_Semigroup.append(CSS_Property.semigroupValue);
var value1 = /* #__PURE__ */ CSS_Property.value(/* #__PURE__ */ CSS_Property.valList(CSS_Property.valValue));
var fromString1 = /* #__PURE__ */ CSS_String.fromString(CSS_Property.isStringKey);
var value2 = /* #__PURE__ */ CSS_Property.value(CSS_Property.valNumber);
var value3 = /* #__PURE__ */ CSS_Property.value(/* #__PURE__ */ CSS_Property.valList(CSS_Property.valNumber));
var value4 = /* #__PURE__ */ CSS_Property.value(CSS_Size.valAngle);
var eq = /* #__PURE__ */ Data_Eq.eq(CSS_Property.eqValue);
var compare = /* #__PURE__ */ Data_Ord.compare(CSS_Property.ordValue);
var Transformation = function (x) {
    return x;
};
var OffsetLength = /* #__PURE__ */ (function () {
    function OffsetLength(value0) {
        this.value0 = value0;
    };
    OffsetLength.create = function (value0) {
        return new OffsetLength(value0);
    };
    return OffsetLength;
})();
var OffsetTop = /* #__PURE__ */ (function () {
    function OffsetTop() {

    };
    OffsetTop.value = new OffsetTop();
    return OffsetTop;
})();
var OffsetBottom = /* #__PURE__ */ (function () {
    function OffsetBottom() {

    };
    OffsetBottom.value = new OffsetBottom();
    return OffsetBottom;
})();
var OffsetLeft = /* #__PURE__ */ (function () {
    function OffsetLeft() {

    };
    OffsetLeft.value = new OffsetLeft();
    return OffsetLeft;
})();
var OffsetRight = /* #__PURE__ */ (function () {
    function OffsetRight() {

    };
    OffsetRight.value = new OffsetRight();
    return OffsetRight;
})();
var OffsetCenter = /* #__PURE__ */ (function () {
    function OffsetCenter() {

    };
    OffsetCenter.value = new OffsetCenter();
    return OffsetCenter;
})();
var TransformOrigin = /* #__PURE__ */ (function () {
    function TransformOrigin(value0, value1, value2) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
    };
    TransformOrigin.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return new TransformOrigin(value0, value1, value2);
            };
        };
    };
    return TransformOrigin;
})();
var Initial = /* #__PURE__ */ (function () {
    function Initial() {

    };
    Initial.value = new Initial();
    return Initial;
})();
var Inherit = /* #__PURE__ */ (function () {
    function Inherit() {

    };
    Inherit.value = new Inherit();
    return Inherit;
})();
var Unset = /* #__PURE__ */ (function () {
    function Unset() {

    };
    Unset.value = new Unset();
    return Unset;
})();
var valTransformation = {
    value: function (v) {
        return v;
    }
};
var valTransformOriginOffset = {
    value: function (v) {
        if (v instanceof OffsetLength) {
            return value(v.value0);
        };
        if (v instanceof OffsetTop) {
            return fromString("top");
        };
        if (v instanceof OffsetBottom) {
            return fromString("bottom");
        };
        if (v instanceof OffsetLeft) {
            return fromString("left");
        };
        if (v instanceof OffsetRight) {
            return fromString("right");
        };
        if (v instanceof OffsetCenter) {
            return fromString("center");
        };
        throw new Error("Failed pattern match at CSS.Transform (line 125, column 1 - line 131, column 45): " + [ v.constructor.name ]);
    }
};
var value5 = /* #__PURE__ */ CSS_Property.value(/* #__PURE__ */ CSS_Property.valTuple(/* #__PURE__ */ CSS_Property.valTuple(valTransformOriginOffset)(valTransformOriginOffset))(CSS_Size.valSize));
var valTransformOrigin = {
    value: function (v) {
        if (v instanceof TransformOrigin) {
            return value5(new Data_Tuple.Tuple(new Data_Tuple.Tuple(v.value0, v.value1), v.value2));
        };
        if (v instanceof Initial) {
            return fromString("initial");
        };
        if (v instanceof Inherit) {
            return fromString("inherit");
        };
        if (v instanceof Unset) {
            return fromString("unset");
        };
        throw new Error("Failed pattern match at CSS.Transform (line 133, column 1 - line 137, column 37): " + [ v.constructor.name ]);
    }
};
var key = /* #__PURE__ */ CSS_Stylesheet.key(valTransformOrigin);
var unsetTransformOrigin = /* #__PURE__ */ (function () {
    return {
        unset: Unset.value
    };
})();
var translateZ = function (z) {
    return append(fromString("translateZ("))(append(value(z))(fromString(")")));
};
var translateY = function (y) {
    return append(fromString("translateY("))(append(value(y))(fromString(")")));
};
var translateX = function (x) {
    return append(fromString("translateX("))(append(value(x))(fromString(")")));
};
var translate3d = function (x) {
    return function (y) {
        return function (z) {
            return append(fromString("translate3d("))(append(value1([ value(x), value(y), value(z) ]))(fromString(")")));
        };
    };
};
var translate = function (x) {
    return function (y) {
        return append(fromString("translate("))(append(value1([ value(x), value(y) ]))(fromString(")")));
    };
};
var transforms = /* #__PURE__ */ (function () {
    var $44 = CSS_Stylesheet.key(CSS_Property.valValue)(fromString1("transform"));
    var $45 = CSS_Property.noCommas(valTransformation);
    return function ($46) {
        return $44($45($46));
    };
})();
var transformOrigin = function (x) {
    return function (y) {
        return function (z) {
            return key(fromString1("transform-origin"))(new TransformOrigin(x, y, z));
        };
    };
};
var transform = /* #__PURE__ */ CSS_Stylesheet.key(valTransformation)(/* #__PURE__ */ fromString1("transform"));
var skewY = function (y) {
    return append(fromString("skewY("))(append(value2(y))(fromString(")")));
};
var skewX = function (x) {
    return append(fromString("skewX("))(append(value2(x))(fromString(")")));
};
var skew = function (x) {
    return function (y) {
        return append(fromString("skew("))(append(value3([ x, y ]))(fromString(")")));
    };
};
var scaleZ = function (z) {
    return append(fromString("scaleZ("))(append(value2(z))(fromString(")")));
};
var scaleY = function (y) {
    return append(fromString("scaleY("))(append(value2(y))(fromString(")")));
};
var scaleX = function (x) {
    return append(fromString("scaleX("))(append(value2(x))(fromString(")")));
};
var scale3d = function (x) {
    return function (y) {
        return function (z) {
            return append(fromString("scale3d("))(append(value3([ x, y, z ]))(fromString(")")));
        };
    };
};
var scale = function (x) {
    return function (y) {
        return append(fromString("scale("))(append(value3([ x, y ]))(fromString(")")));
    };
};
var rotateZ = function (z) {
    return append(fromString("rotateZ("))(append(value4(z))(fromString(")")));
};
var rotateY = function (y) {
    return append(fromString("rotateY("))(append(value4(y))(fromString(")")));
};
var rotateX = function (x) {
    return append(fromString("rotateX("))(append(value4(x))(fromString(")")));
};
var rotate3d = function (x) {
    return function (y) {
        return function (z) {
            return function (a) {
                return append(fromString("rotate3d("))(append(value1([ value2(x), value2(y), value2(z), value4(a) ]))(fromString(")")));
            };
        };
    };
};
var rotate = function (a) {
    return append(fromString("rotate("))(append(value4(a))(fromString(")")));
};
var perspective = function (p) {
    return append(fromString("perspective("))(append(value2(p))(fromString(")")));
};
var offsetTop = /* #__PURE__ */ (function () {
    return OffsetTop.value;
})();
var offsetRight = /* #__PURE__ */ (function () {
    return OffsetRight.value;
})();
var offsetLeft = /* #__PURE__ */ (function () {
    return OffsetLeft.value;
})();
var offsetCenter = /* #__PURE__ */ (function () {
    return OffsetCenter.value;
})();
var offsetBottom = /* #__PURE__ */ (function () {
    return OffsetBottom.value;
})();
var offset = /* #__PURE__ */ (function () {
    return OffsetLength.create;
})();
var matrix3d = function (w0) {
    return function (x0) {
        return function (y0) {
            return function (z0) {
                return function (w1) {
                    return function (x1) {
                        return function (y1) {
                            return function (z1) {
                                return function (w2) {
                                    return function (x2) {
                                        return function (y2) {
                                            return function (z2) {
                                                return function (w3) {
                                                    return function (x3) {
                                                        return function (y3) {
                                                            return function (z3) {
                                                                return append(fromString("matrix3d("))(append(value3([ w0, x0, y0, z0, w1, x1, y1, z1, w2, x2, y2, z2, w3, x3, y3, z3 ]))(fromString(")")));
                                                            };
                                                        };
                                                    };
                                                };
                                            };
                                        };
                                    };
                                };
                            };
                        };
                    };
                };
            };
        };
    };
};
var matrix = function (u) {
    return function (v) {
        return function (w) {
            return function (x) {
                return function (y) {
                    return function (z) {
                        return append(fromString("matrix3d("))(append(value3([ u, v, w, x, y, z ]))(fromString(")")));
                    };
                };
            };
        };
    };
};
var initialTransformOrigin = /* #__PURE__ */ (function () {
    return {
        initial: Initial.value
    };
})();
var inheritTransformOrigin = /* #__PURE__ */ (function () {
    return {
        inherit: Inherit.value
    };
})();
var eqTransformation = {
    eq: function (x) {
        return function (y) {
            return eq(x)(y);
        };
    }
};
var ordTransformation = {
    compare: function (x) {
        return function (y) {
            return compare(x)(y);
        };
    },
    Eq0: function () {
        return eqTransformation;
    }
};
export {
    Transformation,
    transform,
    transforms,
    translate,
    translateX,
    translateY,
    translateZ,
    translate3d,
    scale,
    scaleX,
    scaleY,
    scaleZ,
    scale3d,
    rotate,
    rotateX,
    rotateY,
    rotateZ,
    rotate3d,
    skew,
    skewX,
    skewY,
    perspective,
    matrix,
    matrix3d,
    TransformOrigin,
    Initial,
    Inherit,
    Unset,
    OffsetLength,
    OffsetTop,
    OffsetBottom,
    OffsetLeft,
    OffsetRight,
    OffsetCenter,
    offset,
    offsetTop,
    offsetBottom,
    offsetLeft,
    offsetRight,
    offsetCenter,
    transformOrigin,
    eqTransformation,
    ordTransformation,
    valTransformation,
    valTransformOriginOffset,
    valTransformOrigin,
    initialTransformOrigin,
    inheritTransformOrigin,
    unsetTransformOrigin
};
