// Generated by purs version 0.15.4
import * as $foreign from "./foreign.js";
import * as Data_Either from "../Data.Either/index.js";
var RawHtml = function (x) {
    return x;
};
var registerLanguage = /* #__PURE__ */ $foreign.registerLanguage_({
    languageAliasString: function (v) {
        return v;
    }
});
var highlight = /* #__PURE__ */ (function () {
    return $foreign.highlight_({
        left: Data_Either.Left.create,
        right: Data_Either.Right.create,
        rawHtmlString: function (v) {
            return v;
        },
        languageAliasString: function (v) {
            return v;
        },
        rawHtml: RawHtml
    });
})();
export {
    make
} from "./foreign.js";
export {
    registerLanguage,
    highlight,
    RawHtml
};
