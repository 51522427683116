// Generated by purs version 0.15.4
import * as CSS_Font from "../CSS.Font/index.js";
import * as CSS_Geometry from "../CSS.Geometry/index.js";
import * as CSS_Size from "../CSS.Size/index.js";
import * as CSS_Stylesheet from "../CSS.Stylesheet/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Array_NonEmpty from "../Data.Array.NonEmpty/index.js";
import * as Data_Array_NonEmpty_Internal from "../Data.Array.NonEmpty.Internal/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Filterable from "../Data.Filterable/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_FunctorWithIndex from "../Data.FunctorWithIndex/index.js";
import * as Data_Hashable from "../Data.Hashable/index.js";
import * as Data_HeytingAlgebra from "../Data.HeytingAlgebra/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Newtype from "../Data.Newtype/index.js";
import * as Data_String_NonEmpty_Internal from "../Data.String.NonEmpty.Internal/index.js";
import * as Data_Traversable from "../Data.Traversable/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Effect from "../Effect/index.js";
import * as Halogen_HTML_CSS from "../Halogen.HTML.CSS/index.js";
import * as Halogen_HTML_Core from "../Halogen.HTML.Core/index.js";
import * as Halogen_HTML_Elements from "../Halogen.HTML.Elements/index.js";
import * as Halogen_HTML_Properties from "../Halogen.HTML.Properties/index.js";
import * as Halogen_HTML_Raw from "../Halogen.HTML.Raw/index.js";
import * as HighlightJs from "../HighlightJs/index.js";
import * as HighlightJs_Language from "../HighlightJs.Language/index.js";
import * as Toad_Atom_AppTitle from "../Toad.Atom.AppTitle/index.js";
import * as Toad_Atom_Button from "../Toad.Atom.Button/index.js";
import * as Toad_Atom_Icon from "../Toad.Atom.Icon/index.js";
import * as Toad_Concept_Fetch from "../Toad.Concept.Fetch/index.js";
import * as Toad_Html from "../Toad.Html/index.js";
import * as Toad_Markdown from "../Toad.Markdown/index.js";
import * as Toad_Markdown_Html_Style from "../Toad.Markdown.Html.Style/index.js";
import * as Toad_Route from "../Toad.Route/index.js";
var map = /* #__PURE__ */ Data_Functor.map(Data_Maybe.functorMaybe);
var pure = /* #__PURE__ */ Control_Applicative.pure(Effect.applicativeEffect);
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit)(CSS_Stylesheet.bindStyleM);
var map1 = /* #__PURE__ */ Data_Functor.map(Data_Array_NonEmpty_Internal.functorNonEmptyArray);
var pure1 = /* #__PURE__ */ Control_Applicative.pure(CSS_Stylesheet.applicativeStyleM);
var filter = /* #__PURE__ */ Data_Filterable.filter(Data_Filterable.filterableArray);
var not = /* #__PURE__ */ Data_HeytingAlgebra.not(/* #__PURE__ */ Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean));
var sequence = /* #__PURE__ */ Data_Traversable.sequence(Data_Traversable.traversableArray)(Effect.applicativeEffect);
var mapWithIndex = /* #__PURE__ */ Data_FunctorWithIndex.mapWithIndex(Data_FunctorWithIndex.functorWithIndexArray);
var bind1 = /* #__PURE__ */ Control_Bind.bind(CSS_Stylesheet.bindStyleM);
var hash = /* #__PURE__ */ Data_Hashable.hash(Data_Hashable.hashableString);
var unwrap = /* #__PURE__ */ Data_Newtype.unwrap();
var renderText = function (v) {
    if (v instanceof Toad_Markdown.Unstyled) {
        return Halogen_HTML_Core.text(v.value0);
    };
    if (v instanceof Toad_Markdown.Bold) {
        return Halogen_HTML_Elements.b_([ renderText(Toad_Markdown.Unstyled.create(v.value0)) ]);
    };
    if (v instanceof Toad_Markdown.Italic) {
        return Halogen_HTML_Elements.i_([ renderText(Toad_Markdown.Unstyled.create(v.value0)) ]);
    };
    if (v instanceof Toad_Markdown.BoldItalic) {
        return Halogen_HTML_Elements.b_([ renderText(Toad_Markdown.Italic.create(v.value0)) ]);
    };
    if (v instanceof Toad_Markdown.InlineCode) {
        return Halogen_HTML_Elements.code_([ renderText(Toad_Markdown.Unstyled.create(v.value0)) ]);
    };
    throw new Error("Failed pattern match at Toad.Markdown.Html (line 50, column 1 - line 50, column 37): " + [ v.constructor.name ]);
};
var renderCode = function (css) {
    return function (v) {
        var lang$prime = (function () {
            var v1 = map(function (v2) {
                return Data_String_NonEmpty_Internal.toString(v2.value0);
            })(v.value0);
            if (v1 instanceof Data_Maybe.Just && v1.value0 === "javascript") {
                return HighlightJs_Language.Javascript.value;
            };
            if (v1 instanceof Data_Maybe.Just && v1.value0 === "rust") {
                return HighlightJs_Language.Rust.value;
            };
            return HighlightJs_Language.Plaintext.value;
        })();
        return function __do() {
            var result = HighlightJs.highlight(lang$prime)(v.value1)();
            var rawHtml = Data_Either.either(Data_Function["const"]("failed"))(function (v1) {
                return v1;
            })(result);
            return Halogen_HTML_Elements.pre([ Halogen_HTML_CSS.style(discard(css)(function () {
                return discard(CSS_Font.fontSize(CSS_Size.pt(16.0)))(function () {
                    return CSS_Geometry.marginBottom(CSS_Size.nil);
                });
            })) ])([ Halogen_HTML_Elements.code([ Toad_Html.classNames([ "hljs" ]), Halogen_HTML_Raw.unsafeRawInnerHtml(rawHtml) ])([  ]) ]);
        };
    };
};
var renderAnchor = function (v) {
    if (v instanceof Toad_Markdown.Anchor) {
        return Halogen_HTML_Elements.a([ Halogen_HTML_Properties.href(v.value1) ])([ Halogen_HTML_Elements.span([ Halogen_HTML_CSS.style(Toad_Markdown_Html_Style.anchorSpan) ])(Data_Array_NonEmpty.toArray(map1(renderText)(v.value0))) ]);
    };
    if (v instanceof Toad_Markdown.ConceptAnchor) {
        return Toad_Html.a_(new Toad_Route.Concepts(new Toad_Route.One(v.value1)))([ Halogen_HTML_Elements.span([ Halogen_HTML_CSS.style(Toad_Markdown_Html_Style.anchorSpan) ])(Data_Array_NonEmpty.toArray(map1(renderText)(v.value0))) ]);
    };
    throw new Error("Failed pattern match at Toad.Markdown.Html (line 57, column 1 - line 57, column 41): " + [ v.constructor.name ]);
};
var renderToken = function (v) {
    if (v instanceof Toad_Markdown.TextToken) {
        return renderText(v.value0);
    };
    if (v instanceof Toad_Markdown.AnchorToken) {
        return renderAnchor(v.value0);
    };
    throw new Error("Failed pattern match at Toad.Markdown.Html (line 75, column 1 - line 75, column 39): " + [ v.constructor.name ]);
};
var renderSpan = function (s) {
    return function (v) {
        return Halogen_HTML_Elements.span([ Halogen_HTML_CSS.style(s) ])(Data_Array_NonEmpty.toArray(map1(renderToken)(v.value0)));
    };
};
var renderHeading = function (x) {
    var style$prime = function (f) {
        return Halogen_HTML_CSS.style(discard(Toad_Html.headingStyle(f))(function () {
            return x;
        }));
    };
    var span$prime = renderSpan(pure1(Data_Unit.unit));
    return function (v) {
        if (v instanceof Toad_Markdown.H1) {
            return Halogen_HTML_Elements.h1([ style$prime(Toad_Html.h1Font) ])([ span$prime(v.value0) ]);
        };
        if (v instanceof Toad_Markdown.H2) {
            return Halogen_HTML_Elements.h2([ style$prime(Toad_Html.h2Font) ])([ span$prime(v.value0) ]);
        };
        if (v instanceof Toad_Markdown.H3) {
            return Halogen_HTML_Elements.h3([ style$prime(Toad_Html.h3Font) ])([ span$prime(v.value0) ]);
        };
        if (v instanceof Toad_Markdown.H4) {
            return Halogen_HTML_Elements.h4([ style$prime(Toad_Html.h4Font) ])([ span$prime(v.value0) ]);
        };
        if (v instanceof Toad_Markdown.H5) {
            return Halogen_HTML_Elements.h5([ style$prime(Toad_Html.h5Font) ])([ span$prime(v.value0) ]);
        };
        if (v instanceof Toad_Markdown.H6) {
            return Halogen_HTML_Elements.h6([ style$prime(Toad_Html.h6Font) ])([ span$prime(v.value0) ]);
        };
        throw new Error("Failed pattern match at Toad.Markdown.Html (line 92, column 5 - line 98, column 57): " + [ v.constructor.name ]);
    };
};
var renderListUsing = function (parent) {
    return function (tokens) {
        return parent(Data_Array_NonEmpty.toArray(map1(renderListToken)(tokens)));
    };
};
var renderListToken = function (v) {
    if (v instanceof Toad_Markdown.ListTokenSpan) {
        return Halogen_HTML_Elements.li([ Halogen_HTML_CSS.style(Toad_Markdown_Html_Style.span) ])([ renderSpan(pure1(Data_Unit.unit))(v.value0) ]);
    };
    if (v instanceof Toad_Markdown.ListTokenSpanSublist) {
        return Halogen_HTML_Elements.li([ Halogen_HTML_CSS.style(Toad_Markdown_Html_Style.span) ])([ Halogen_HTML_Elements.div([  ])([ renderSpan(pure1(Data_Unit.unit))(v.value0), renderList(pure1(Data_Unit.unit))(v.value1) ]) ]);
    };
    throw new Error("Failed pattern match at Toad.Markdown.Html (line 120, column 1 - line 120, column 47): " + [ v.constructor.name ]);
};
var renderList = function (css) {
    return function (v) {
        if (v instanceof Toad_Markdown.OrderedList) {
            return renderListUsing(Halogen_HTML_Elements.ol([ Halogen_HTML_CSS.style(css) ]))(v.value0);
        };
        if (v instanceof Toad_Markdown.UnorderedList) {
            return renderListUsing(Halogen_HTML_Elements.ul([ Halogen_HTML_CSS.style(css) ]))(v.value0);
        };
        throw new Error("Failed pattern match at Toad.Markdown.Html (line 142, column 1 - line 142, column 44): " + [ css.constructor.name, v.constructor.name ]);
    };
};
var isComment = function (v) {
    if (v instanceof Toad_Markdown.ElementComment) {
        return true;
    };
    return false;
};
var renderBody = function (x) {
    return function (d) {
        var topLevelStyle = function (v) {
            if (v === 0) {
                return Toad_Markdown_Html_Style.topLevelFirst;
            };
            return Toad_Markdown_Html_Style.topLevel;
        };
        var render$prime = function (v) {
            return function (v1) {
                if (v1 instanceof Toad_Markdown.ElementList) {
                    return pure(renderList(topLevelStyle(v))(v1.value0));
                };
                if (v1 instanceof Toad_Markdown.ElementCodeFence) {
                    return renderCode(topLevelStyle(v))(v1.value0);
                };
                if (v1 instanceof Toad_Markdown.ElementHeading) {
                    return pure(renderHeading(topLevelStyle(v))(v1.value0));
                };
                if (v === 0 && v1 instanceof Toad_Markdown.ElementSpan) {
                    return pure(renderSpan(discard(Toad_Markdown_Html_Style.topLevelSpan)(function () {
                        return Toad_Markdown_Html_Style.topLevelFirst;
                    }))(v1.value0));
                };
                if (v1 instanceof Toad_Markdown.ElementSpan) {
                    return pure(renderSpan(Toad_Markdown_Html_Style.topLevelSpan)(v1.value0));
                };
                return pure(Halogen_HTML_Elements.div_([  ]));
            };
        };
        var body = (function () {
            var $89 = Data_Array.drop(1);
            var $90 = filter(not(isComment));
            return function ($91) {
                return $89($90($91));
            };
        })();
        return function __do() {
            var xs = sequence(mapWithIndex(render$prime)(body(Toad_Markdown.elements(d))))();
            return Halogen_HTML_Elements.div([ Halogen_HTML_CSS.style(bind1(Toad_Markdown_Html_Style.documentBody)(Data_Function["const"](x))) ])(xs);
        };
    };
};
var hashSpan = function (s) {
    return hash(Toad_Markdown.spanString(s));
};
var renderAppTitle = function (path) {
    return function (doc) {
        var v = Data_Array.take(1)(filter(not(isComment))(Toad_Markdown.elements(doc)));
        if (v.length === 1 && (v[0] instanceof Toad_Markdown.ElementHeading && v[0].value0 instanceof Toad_Markdown.H1)) {
            return Data_Maybe.Just.create(Toad_Atom_AppTitle.AppTitle({
                h1: [ renderSpan(pure1(Data_Unit.unit))(v[0].value0.value0) ],
                accessory: [ Toad_Atom_Button.renderPlain({
                    children: [ Toad_Html["a$prime"]([ Halogen_HTML_Properties.href(unwrap(Toad_Concept_Fetch.humanUrl(path))), Halogen_HTML_Properties.target("_blank"), Halogen_HTML_Properties.rel("noopener noreferrer") ])([ Halogen_HTML_Elements.div([ Halogen_HTML_CSS.style(Toad_Markdown_Html_Style.githubButton) ])([ Toad_Atom_Icon.render(Toad_Atom_Icon.Github.value) ]) ]) ],
                    styleButton: pure1(Data_Unit.unit),
                    styleContainer: pure1(Data_Unit.unit),
                    theme: Toad_Markdown_Html_Style.githubButtonTheme
                }) ],
                hash: hashSpan(v[0].value0.value0)
            }));
        };
        return Data_Maybe.Nothing.value;
    };
};
export {
    hashSpan,
    renderText,
    renderAnchor,
    renderToken,
    renderSpan,
    renderHeading,
    renderCode,
    renderListToken,
    renderListUsing,
    renderList,
    isComment,
    renderAppTitle,
    renderBody
};
