// Generated by purs version 0.15.4
import * as CSS_Property from "../CSS.Property/index.js";
import * as CSS_String from "../CSS.String/index.js";
import * as CSS_Stylesheet from "../CSS.Stylesheet/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Ordering from "../Data.Ordering/index.js";
var fromString = /* #__PURE__ */ CSS_String.fromString(CSS_Property.isStringValue);
var fromString1 = /* #__PURE__ */ CSS_String.fromString(CSS_Property.isStringKey);
var compare = /* #__PURE__ */ Data_Ord.compare(Data_Ord.ordString);
var WOFF = /* #__PURE__ */ (function () {
    function WOFF() {

    };
    WOFF.value = new WOFF();
    return WOFF;
})();
var WOFF2 = /* #__PURE__ */ (function () {
    function WOFF2() {

    };
    WOFF2.value = new WOFF2();
    return WOFF2;
})();
var TrueType = /* #__PURE__ */ (function () {
    function TrueType() {

    };
    TrueType.value = new TrueType();
    return TrueType;
})();
var OpenType = /* #__PURE__ */ (function () {
    function OpenType() {

    };
    OpenType.value = new OpenType();
    return OpenType;
})();
var EmbeddedOpenType = /* #__PURE__ */ (function () {
    function EmbeddedOpenType() {

    };
    EmbeddedOpenType.value = new EmbeddedOpenType();
    return EmbeddedOpenType;
})();
var SVG = /* #__PURE__ */ (function () {
    function SVG() {

    };
    SVG.value = new SVG();
    return SVG;
})();
var FontFaceSrcUrl = /* #__PURE__ */ (function () {
    function FontFaceSrcUrl(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    FontFaceSrcUrl.create = function (value0) {
        return function (value1) {
            return new FontFaceSrcUrl(value0, value1);
        };
    };
    return FontFaceSrcUrl;
})();
var FontFaceSrcLocal = /* #__PURE__ */ (function () {
    function FontFaceSrcLocal(value0) {
        this.value0 = value0;
    };
    FontFaceSrcLocal.create = function (value0) {
        return new FontFaceSrcLocal(value0);
    };
    return FontFaceSrcLocal;
})();
var formatName = function (v) {
    if (v instanceof WOFF) {
        return "woff";
    };
    if (v instanceof WOFF2) {
        return "woff2";
    };
    if (v instanceof TrueType) {
        return "truetype";
    };
    if (v instanceof OpenType) {
        return "opentype";
    };
    if (v instanceof EmbeddedOpenType) {
        return "embedded-opentype";
    };
    if (v instanceof SVG) {
        return "svg";
    };
    throw new Error("Failed pattern match at CSS.FontFace (line 26, column 1 - line 26, column 39): " + [ v.constructor.name ]);
};
var valFontFaceSrc = {
    value: function (v) {
        if (v instanceof FontFaceSrcUrl) {
            return fromString("url(" + (CSS_Property.quote(v.value0) + (")" + Data_Maybe.maybe("")(function (f$prime) {
                return " format(" + (CSS_Property.quote(formatName(f$prime)) + ")");
            })(v.value1))));
        };
        if (v instanceof FontFaceSrcLocal) {
            return fromString("local(" + (CSS_Property.quote(v.value0) + ")"));
        };
        throw new Error("Failed pattern match at CSS.FontFace (line 41, column 1 - line 43, column 71): " + [ v.constructor.name ]);
    }
};
var fontFaceSrc = /* #__PURE__ */ CSS_Stylesheet.key(/* #__PURE__ */ CSS_Property.valNonEmpty(valFontFaceSrc))(/* #__PURE__ */ fromString1("src"));
var fontFaceFamily = /* #__PURE__ */ (function () {
    var $67 = CSS_Stylesheet.key(CSS_Property.valLiteral)(fromString1("font-family"));
    return function ($68) {
        return $67(CSS_Property.Literal($68));
    };
})();
var eqFontFaceFormat = {
    eq: function (x) {
        return function (y) {
            if (x instanceof WOFF && y instanceof WOFF) {
                return true;
            };
            if (x instanceof WOFF2 && y instanceof WOFF2) {
                return true;
            };
            if (x instanceof TrueType && y instanceof TrueType) {
                return true;
            };
            if (x instanceof OpenType && y instanceof OpenType) {
                return true;
            };
            if (x instanceof EmbeddedOpenType && y instanceof EmbeddedOpenType) {
                return true;
            };
            if (x instanceof SVG && y instanceof SVG) {
                return true;
            };
            return false;
        };
    }
};
var eq1 = /* #__PURE__ */ Data_Eq.eq(/* #__PURE__ */ Data_Maybe.eqMaybe(eqFontFaceFormat));
var eqFontFaceSrc = {
    eq: function (x) {
        return function (y) {
            if (x instanceof FontFaceSrcUrl && y instanceof FontFaceSrcUrl) {
                return x.value0 === y.value0 && eq1(x.value1)(y.value1);
            };
            if (x instanceof FontFaceSrcLocal && y instanceof FontFaceSrcLocal) {
                return x.value0 === y.value0;
            };
            return false;
        };
    }
};
var ordFontFaceFormat = {
    compare: function (x) {
        return function (y) {
            if (x instanceof WOFF && y instanceof WOFF) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof WOFF) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof WOFF) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof WOFF2 && y instanceof WOFF2) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof WOFF2) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof WOFF2) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof TrueType && y instanceof TrueType) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof TrueType) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof TrueType) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof OpenType && y instanceof OpenType) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof OpenType) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof OpenType) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof EmbeddedOpenType && y instanceof EmbeddedOpenType) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof EmbeddedOpenType) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof EmbeddedOpenType) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof SVG && y instanceof SVG) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at CSS.FontFace (line 0, column 0 - line 0, column 0): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqFontFaceFormat;
    }
};
var compare1 = /* #__PURE__ */ Data_Ord.compare(/* #__PURE__ */ Data_Maybe.ordMaybe(ordFontFaceFormat));
var ordFontFaceSrc = {
    compare: function (x) {
        return function (y) {
            if (x instanceof FontFaceSrcUrl && y instanceof FontFaceSrcUrl) {
                var v = compare(x.value0)(y.value0);
                if (v instanceof Data_Ordering.LT) {
                    return Data_Ordering.LT.value;
                };
                if (v instanceof Data_Ordering.GT) {
                    return Data_Ordering.GT.value;
                };
                return compare1(x.value1)(y.value1);
            };
            if (x instanceof FontFaceSrcUrl) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof FontFaceSrcUrl) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof FontFaceSrcLocal && y instanceof FontFaceSrcLocal) {
                return compare(x.value0)(y.value0);
            };
            throw new Error("Failed pattern match at CSS.FontFace (line 0, column 0 - line 0, column 0): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqFontFaceSrc;
    }
};
export {
    fontFaceFamily,
    WOFF,
    WOFF2,
    TrueType,
    OpenType,
    EmbeddedOpenType,
    SVG,
    formatName,
    FontFaceSrcUrl,
    FontFaceSrcLocal,
    fontFaceSrc,
    eqFontFaceFormat,
    ordFontFaceFormat,
    eqFontFaceSrc,
    ordFontFaceSrc,
    valFontFaceSrc
};
